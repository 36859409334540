"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var t = require("io-ts");

exports.createEnum = function (e, name) {
  var keys = {};
  Object.keys(e).forEach(function (k) {
    keys[e[k]] = null;
  });
  return t.keyof(keys, name);
};