import './ShippingAddressFormContainer.scss';
import * as React from 'react';
import { AddressFields, ModelResellerCustomerAddress } from '@oyp/shared-lib';
import { Card, logError } from '@oyp/shared-components';
import { extractAddressFields, getCustomerAddressOptionLabel } from '../utils';
import { fetchResellerCustomerAddresses } from '../actions/address';
import { getCustomerAddressForAddressData, initialAddressValues } from '../address_helper';
import { toast } from 'mdbreact';
import Button from '../../../components/buttons/Button';
import Loader from '../../../components/Loader';
import SelectGroup, { SelectOption } from '../../../components/form/SelectGroup';
import ShippingAddressForm from '../components/ShippingAddressForm';

interface ShippingAddressFormContainerProps {
  resellerId: string;
  address: AddressFields;
  selectedCustomerAddress?: ModelResellerCustomerAddress;
  manualShippingReference: string;
  addressValidationErrors: any;
  processButtons?: JSX.Element;
  title: string;
  onChange: (value: {
    address: AddressFields;
    selectedCustomerAddress?: ModelResellerCustomerAddress;
    manualShippingReference: string;
  }) => void;
  onResetAddress: () => void;
  isDeliveryPickup?: boolean;
}

interface ShippingAddressFormContainerState {
  customerAddresses: ModelResellerCustomerAddress[];
  customerAddressOptions: SelectOption<string>[];
  isLoading: boolean;
  hasLoadingError: boolean;
}

class ShippingAddressFormContainer extends React.Component<
  ShippingAddressFormContainerProps,
  ShippingAddressFormContainerState
> {
  state: ShippingAddressFormContainerState = initialState;

  constructor(props: ShippingAddressFormContainerProps) {
    super(props);

    this.loadInitialData();

    this.loadInitialData = this.loadInitialData.bind(this);
    this.setSelectedAddressFromSelect = this.setSelectedAddressFromSelect.bind(this);
  }

  render() {
    const {
      processButtons,
      address,
      selectedCustomerAddress,
      addressValidationErrors,
      title,
      onChange,
      onResetAddress,
      isDeliveryPickup,
      manualShippingReference,
    } = this.props;
    const { customerAddressOptions, isLoading, hasLoadingError } = this.state;

    return (
      <Card className="section-wrapper shipping-address-form-container" title={title}>
        {(isLoading || hasLoadingError) && (
          <Loader error={hasLoadingError} onRetry={this.loadInitialData} />
        )}

        {!isDeliveryPickup && (
          <div className="address-chooser">
            <div className="row">
              <div className="col-md-12">
                <div className="select-container form-row">
                  <SelectGroup<string>
                    className="mb-0"
                    name="customer"
                    placeholder="Rechercher dans mon carnet d'adresses"
                    searchable={true}
                    noResultsText="Aucune adresse trouvée"
                    value={selectedCustomerAddress ? selectedCustomerAddress.id : null}
                    options={customerAddressOptions}
                    clearable={false}
                    onChange={(selectedOption: SelectOption<string>) =>
                      this.setSelectedAddressFromSelect(selectedOption)
                    }
                  />
                  <Button
                    look="white"
                    size="sm"
                    onClick={() => {
                      if (selectedCustomerAddress) {
                        this.deselectAddress();
                      } else {
                        onResetAddress();
                      }
                    }}
                  >
                    Effacer
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row mr-0 ml-0">
          <div className="address-form-container col-sm-12">
            <ShippingAddressForm
              address={address}
              validationErrors={addressValidationErrors}
              processButtons={processButtons}
              onAddressChange={updatedAddress =>
                onChange({
                  address: updatedAddress,
                  selectedCustomerAddress: getCustomerAddressForAddressData(
                    this.state.customerAddresses,
                    updatedAddress
                  ),
                  manualShippingReference,
                })
              }
              manualShippingReference={manualShippingReference}
              isDeliveryPickup={isDeliveryPickup}
              onManualShippingReferenceChange={updatedManualShippingReference =>
                onChange({
                  address,
                  selectedCustomerAddress,
                  manualShippingReference: updatedManualShippingReference,
                })
              }
            />
          </div>
        </div>
      </Card>
    );
  }

  async loadInitialData(showLoader = false) {
    if (showLoader) {
      this.setState({
        ...initialState,
      });
    }

    try {
      const customerAddresses = await fetchResellerCustomerAddresses(this.props.resellerId);

      const address = this.props.address ? extractAddressFields(this.props.address) : {};

      this.setState({
        customerAddresses,
        customerAddressOptions: customerAddresses.map(customerAddress => ({
          value: customerAddress.id,
          label: getCustomerAddressOptionLabel(customerAddress),
        })),
        isLoading: false,
        hasLoadingError: false,
      });

      const selectedCustomerAddress = this.props.address
        ? getCustomerAddressForAddressData(customerAddresses, address)
        : null;

      if (selectedCustomerAddress) {
        this.props.onChange({
          address,
          selectedCustomerAddress,
          manualShippingReference: this.props.manualShippingReference,
        });
      }
    } catch (error) {
      logError(error);
      toast.error(`Une erreur est survenue au chargement du panier`, { autoClose: 2000 });

      this.setState({
        ...initialState,
        isLoading: false,
        hasLoadingError: true,
      });
    }
  }

  setSelectedAddressFromSelect(selectedOption: SelectOption<string>) {
    if (
      this.props.selectedCustomerAddress &&
      this.props.selectedCustomerAddress.id === selectedOption.value
    ) {
      return;
    }

    const { onChange } = this.props;

    if (selectedOption) {
      const selectedAddressId = selectedOption.value;
      const selectedAddress = this.state.customerAddresses.find(
        address => address.id === selectedAddressId
      );

      onChange({
        selectedCustomerAddress: selectedAddress,
        address: {
          ...extractAddressFields(selectedAddress),
        },
        manualShippingReference: this.props.manualShippingReference,
      });
    } else {
      this.deselectAddress();
    }
  }

  deselectAddress() {
    this.props.onChange({
      selectedCustomerAddress: undefined,
      address: { ...initialAddressValues },
      manualShippingReference: this.props.manualShippingReference,
    });
  }
}

export default ShippingAddressFormContainer;

const initialState: ShippingAddressFormContainerState = {
  customerAddresses: [],
  customerAddressOptions: [],
  isLoading: true,
  hasLoadingError: false,
};
