import * as React from 'react';

export interface UserMenuContextInterface {
  invalidOrdersCount: number;
  quotesToValidateCount: number;
  lateInvoicesCount: number;
}

export const defaultUserMenuContext: UserMenuContextInterface = {
  invalidOrdersCount: 0,
  quotesToValidateCount: 0,
  lateInvoicesCount: 0,
};

export const UserMenuContext = React.createContext(defaultUserMenuContext);
