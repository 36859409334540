import { Link } from 'react-router-dom';
import { SECONDARY_COLOR } from '../style_variables';
import { Style } from 'jss/css';
import React from 'react';
import styled from 'styled-components';

export interface StyledLinkProps {
  to?: string;
  as?: string | React.ComponentType;
  href?: string;
  className?: string;
  style?: Style;
  target?: string;
  rel?: string;
}

const StyledLink: React.FC<StyledLinkProps> = styled(Link)<StyledLinkProps>`
  color: ${SECONDARY_COLOR};
  text-decoration: none;

  &:visited {
    color: ${SECONDARY_COLOR};
  }

  &:hover {
    color: ${SECONDARY_COLOR};
    text-decoration: underline;
  }
`;

export default StyledLink;
