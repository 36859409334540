import React from 'react';
import StyledLink, { StyledLinkProps } from './StyledLink';
import styled from 'styled-components';

const StyledGoBackLink: React.FC<StyledLinkProps> = styled(StyledLink)<StyledLinkProps>`
  color: white;
  position: absolute;
  left: 20px;
  fontsize: 16px;
  top: 16px;

  &:visited {
    color: white;
  }

  i {
    vertical-align: middle;
  }
`;

export default StyledGoBackLink;
