import * as React from 'react';
import {
  AddressFields,
  PICKUP_ADDRESS,
  isPostalCodeOutsideMetropolitanFrance,
} from '@oyp/shared-lib';
import { form, normalizers } from '@oyp/shared-components';
import InfoTooltip from '../../../components/InfoTooltip';
import InputGroup from '../../../components/form/InputGroup';

const { getOnEventChange } = form;
const { normalizePhoneNumber, normalizePostalCode } = normalizers;

interface ShippingAddressFormProps {
  address: AddressFields;
  manualShippingReference: string;
  validationErrors: any;
  processButtons?: JSX.Element;
  onAddressChange: (value: AddressFields) => void;
  isDeliveryPickup?: boolean;
  onManualShippingReferenceChange: (value: string) => void;
  onSubmit?: () => void;
}

const ShippingAddressForm: React.SFC<ShippingAddressFormProps> = props => {
  const {
    isDeliveryPickup,
    address,
    validationErrors = [],
    processButtons,
    onAddressChange,
    onManualShippingReferenceChange,
    manualShippingReference,
  } = props;

  const {
    name,
    companyName,
    street,
    complement,
    postalCode,
    city,
    email,
    phoneNumber,
    additionalInformation,
  } = address;

  const isCodePostalOutsideFrance = isPostalCodeOutsideMetropolitanFrance(postalCode);

  return (
    <form>
      {!isDeliveryPickup ? (
        <>
          <div className="form-row">
            <InputGroup
              name="companyName"
              label="Société"
              error={validationErrors.companyName}
              onChange={getOnEventChange('companyName', address, onAddressChange)}
              value={companyName}
              s={6}
            />

            <InputGroup
              name="name"
              label="Destinataire"
              error={validationErrors.name}
              onChange={getOnEventChange('name', address, onAddressChange)}
              value={name}
              s={6}
            />
          </div>
          <div className="form-row">
            <InputGroup
              name="street"
              label="Adresse"
              s={6}
              error={validationErrors.street}
              onChange={getOnEventChange('street', address, onAddressChange)}
              value={street}
            />

            <InputGroup
              name="complement"
              label="Complément d'adresse"
              s={6}
              type="text"
              error={validationErrors.complement}
              onChange={getOnEventChange('complement', address, onAddressChange)}
              value={complement}
            />
          </div>

          <div className="form-row">
            <InputGroup
              name="postalCode"
              label={
                isCodePostalOutsideFrance ? (
                  <span>
                    Code postal
                    <InfoTooltip
                      place="top"
                      left={75}
                      top={-4}
                      tooltipWidth={300}
                      tooltipContent="Pour cette destination, merci de contacter notre service commercial afin d'obtenir un devis incluant des frais de livraison adaptés à la destination"
                    />
                  </span>
                ) : (
                  'Code postal'
                )
              }
              s={6}
              error={validationErrors.postalCode}
              onChange={getOnEventChange(
                'postalCode',
                address,
                onAddressChange,
                normalizePostalCode
              )}
              value={postalCode}
            />

            <InputGroup
              name="city"
              label="Ville"
              s={6}
              error={validationErrors.city}
              onChange={getOnEventChange('city', address, onAddressChange)}
              value={city}
            />
          </div>

          <div className="form-row">
            <InputGroup
              name="email"
              label={
                <span>
                  Email de notification{' '}
                  <InfoTooltip
                    tooltipWidth={150}
                    left={122}
                    top={-4}
                    tooltipContent="Cette adresse recevra le lien de suivi du transport"
                    place="top"
                  />
                </span>
              }
              s={6}
              type="email"
              error={validationErrors.email}
              onChange={getOnEventChange('email', address, onAddressChange)}
              value={email}
            />

            <InputGroup
              name="phoneNumber"
              label="Téléphone"
              s={6}
              error={validationErrors.phoneNumber}
              onChange={getOnEventChange(
                'phoneNumber',
                address,
                onAddressChange,
                normalizePhoneNumber
              )}
              value={phoneNumber}
            />
          </div>

          <div className="form-row address-info-sup">
            <InputGroup
              name="additionalInformation"
              label="Informations supplémentaires (ex : interphone, code porte, étage)"
              s={12}
              onChange={getOnEventChange('additionalInformation', address, onAddressChange)}
              value={additionalInformation}
              error={validationErrors.additionalInformation}
            />
          </div>
        </>
      ) : (
        <>
          <div style={{ textAlign: 'center', padding: '1rem 2rem' }}>
            <p>Retrouvez votre commande à notre adresse :</p>
            <address>
              <p>
                {PICKUP_ADDRESS.companyName}
                <br />
                {PICKUP_ADDRESS.street}
                <br />
                {PICKUP_ADDRESS.postalCode} {PICKUP_ADDRESS.city}
              </p>
            </address>
          </div>

          <div className="form-row">
            <InputGroup
              name="manualShippingReference"
              label="Référence à mentionner sur vos colis"
              error={validationErrors.manualShippingReference}
              onChange={event => onManualShippingReferenceChange(event.target.value)}
              value={manualShippingReference}
              s={6}
            />
          </div>
        </>
      )}

      {processButtons ? processButtons : null}
    </form>
  );
};

export default ShippingAddressForm;
