import * as React from 'react';
import { WARNING_COLOR } from '../style_variables';
import styled from 'styled-components';
import styles from './InfoIcon.module.scss';

interface InfoIconProps {
  iconColor?: 'primary' | 'white';
  hasWarning?: boolean;
}

const InfoIcon: React.FC<InfoIconProps> = props => {
  const iconColor = props.iconColor || 'primary';
  const hasWarning = props.hasWarning;

  return (
    <span className={`${styles['info-icon-container']} ${styles[`color-${iconColor}`]}`}>
      <i className="material-icons">info</i>
      {hasWarning && <InfoWarningIcon className="material-icons">warning</InfoWarningIcon>}
    </span>
  );
};

export default InfoIcon;

interface InfoWarningIconProps {
  className: string;
}

const InfoWarningIcon: React.FC<InfoWarningIconProps> = styled.i<InfoWarningIconProps>`
  color: ${WARNING_COLOR};
  font-size: 16px;
  position: absolute;
  left: 13px;
  top: -8px;
`;
