"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = require("react");

exports.Radio = function (props) {
  var id = props.id,
      label = props.label,
      name = props.name,
      value = props.value,
      checked = props.checked,
      disabled = props.disabled,
      _onChange = props.onChange;
  return React.createElement("div", {
    className: "radio-line"
  }, React.createElement("input", {
    type: "radio",
    id: "radio-".concat(id),
    name: name,
    value: value,
    checked: checked,
    disabled: disabled,
    onChange: function onChange() {
      return !checked && _onChange(value);
    }
  }), React.createElement("label", {
    htmlFor: "radio-".concat(id)
  }, label));
};