import {
  AddressFields,
  ModelOrderItem,
  ORDER_ITEM_STATUSES,
  ORDER_STATUS_CONFIRMED,
  ORDER_STATUS_PENDING_PAYMENT_VALIDATION,
  ORDER_STATUS_SHIPPED,
  PAYMENT_STATUSES,
  QUOTE_STATUS_CREATED,
  QUOTE_STATUS_PENDING_CUSTOMER_APPROVAL,
  SHIPPING_MODES,
} from '@oyp/shared-lib';
import { OrderWithProprietaryEntities } from '../calculator/types';
import { formatter, objects } from '@oyp/shared-components';

const { truncateString } = formatter;
const { areEqualShallow } = objects;

export function extractAddressFields(addressSourceData: AddressFields) {
  const {
    name,
    companyName,
    street,
    complement,
    postalCode,
    city,
    countryCode,
    email,
    phoneNumber,
    additionalInformation,
  } = addressSourceData;

  return {
    name,
    companyName,
    street,
    complement,
    postalCode,
    city,
    countryCode,
    email,
    phoneNumber,
    additionalInformation,
  };
}

export function filterOrderPrefix(filters: { sort?: string }, activeFilter: string) {
  let prefix = '';
  const regex = '^-';

  if (filters.sort === activeFilter && !filters.sort.match(regex)) {
    prefix = '-';
  } else if (filters.sort === activeFilter && filters.sort.match(regex)) {
    prefix = '';
  } else if (filters.sort !== activeFilter) {
    prefix = '';
  }

  return prefix;
}

export function getCustomerAddressOptionLabel(customerAddress: AddressFields) {
  return `${customerAddress.companyName} - ${customerAddress.name} - ${truncateString(
    customerAddress.street,
    15
  )} ${customerAddress.postalCode} ${customerAddress.city}${
    customerAddress.phoneNumber ? ` - ${customerAddress.phoneNumber}` : ''
  }`;
}

export function areAddressesEqual(address1: AddressFields, address2: AddressFields) {
  return areEqualShallow(extractAddressFields(address1), extractAddressFields(address2));
}

export const orderHasRefusedPayment = (order: OrderWithProprietaryEntities) =>
  order.payment && order.payment.status === PAYMENT_STATUSES.ERROR;

export const ORDER_STATUS_LABELS = {
  [QUOTE_STATUS_CREATED]: 'Devis',
  [QUOTE_STATUS_PENDING_CUSTOMER_APPROVAL]: 'Devis',
  [ORDER_STATUS_PENDING_PAYMENT_VALIDATION]: `Paiement en validation`,
  [ORDER_STATUS_CONFIRMED]: `En cours`,
  [ORDER_STATUS_SHIPPED]: `Expédié`,
  [ORDER_ITEM_STATUSES.FILES_REFUSED]: `Fichiers invalides`,
};

export function getOrderStatusLabel(order: OrderWithProprietaryEntities) {
  if (orderHasRefusedPayment(order)) {
    return 'Payment refusé';
  } else if (order.isDisabled) {
    return 'Annulée';
  } else if (
    order.shippingMode === SHIPPING_MODES.PICKUP &&
    order.status === ORDER_STATUS_SHIPPED
  ) {
    return 'Mise à disposition';
  }

  return ORDER_STATUS_LABELS[order.status];
}

export enum ORDER_INVALIDITY_REASONS {
  HAS_INVALID_PAYMENT = 'ORDER_INVALIDITY_REASON_HAS_INVALID_PAYMENT',
  HAS_INVALID_FILES = 'ORDER_INVALIDITY_REASON_HAS_INVALID_FILES',
}

export function getInvalidityReason(
  order: OrderWithProprietaryEntities,
  orderItems: ModelOrderItem[]
): ORDER_INVALIDITY_REASONS | null {
  if (orderHasRefusedPayment(order)) {
    return ORDER_INVALIDITY_REASONS.HAS_INVALID_PAYMENT;
  } else if (orderItems.some(orderItem => orderItem.status === ORDER_ITEM_STATUSES.FILES_REFUSED)) {
    return ORDER_INVALIDITY_REASONS.HAS_INVALID_FILES;
  }

  return null;
}
