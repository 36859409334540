import './ShipmentParcelList.scss';
import * as React from 'react';
import { ModelParcel, TRANSPORTERS } from '@oyp/shared-lib';
import { ParcelWithShipment } from './types';
import Button from '../../../../components/buttons/Button';

interface ShipmentParcelListProps {
  parcels: ParcelWithShipment[];
}

const ShipmentParcelList: React.SFC<ShipmentParcelListProps> = props => {
  const { parcels } = props;

  const hasTrackingData = parcels.some(parcel => !!parcel.trackingUrl);

  return (
    <div className="transporter-detail-container mb-3">
      <div className="transporter-name row  ml-0 mr-0 pt-2 pb-2">
        <div className="col-md-6 text-left">
          <span>{TRANSPORTER_LABELS[parcels[0].shipment.transporter]}</span>
        </div>
        <div className="col-md-6 text-right">
          <span>{calculateWeight(parcels)} KG</span>
        </div>
      </div>
      <div className="item-container">
        {hasTrackingData ? (
          parcels.map((parcel, key) => {
            return (
              <div key={key} className="row item ml-0 mr-0 pt-2 pb-2">
                <div className="col-md-6 text-left">
                  <span className="tracking-number">
                    {parcel.trackingNumber ||
                      (parcel.trackingData && parcel.trackingData.parcelNumber)}
                  </span>
                </div>
                <div className="col-md-6 text-right">
                  {parcel.trackingUrl ? (
                    <Button
                      as="a"
                      href={parcel.trackingUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      look="primary"
                      size="xs"
                    >
                      Suivi
                    </Button>
                  ) : (
                    <Button disabled look="primary" size="xs">
                      Suivi
                    </Button>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="row item ml-0 mr-0 pt-2 pb-2">
            <div className="col-md-12 text-center">{`Le suivi de vos colis sera disponible d'ici 24h.`}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const TRANSPORTER_LABELS = {
  [TRANSPORTERS.NO_TRANSPORTER]: 'Pas de transporteur',
  [TRANSPORTERS.DPD]: 'DPD',
  [TRANSPORTERS.CHRONOPOST]: 'Chronopost',
  [TRANSPORTERS.TNT_13]: 'TNT',
  [TRANSPORTERS.TNT_18]: 'TNT',
  [TRANSPORTERS.PALLET]: 'Géodis',
  [TRANSPORTERS.COURIER]: 'Coursier',
  [TRANSPORTERS.SCHENKER]: 'Schenker',
};

function calculateWeight(parcels: ModelParcel[]) {
  const initialWeight = 0;
  return parcels.reduce((acc, val) => acc + val.weight, initialWeight).toFixed(2);
}

export default ShipmentParcelList;
