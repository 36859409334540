"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = require("react");

exports.ValidationErrorList = function (_ref) {
  var _ref$errors = _ref.errors,
      errors = _ref$errors === void 0 ? [] : _ref$errors;
  return React.createElement("ul", null, errors.map(function (error, index) {
    return React.createElement("li", {
      key: index
    }, error);
  }));
};