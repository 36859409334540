import { ModelResellerUser, ModelUser, identity } from '@oyp/shared-lib';
import { fetchCollection } from '@oyp/shared-components/dist/lib/rest-api/network';
import { fetchOneUser } from '../../user/actions';
import { resellerUserApiEndpoint } from '../../user/module';

export async function fetchResellerUsersForReseller(resellerId: string): Promise<ModelUser[]> {
  const resellerUsers = (
    await fetchCollection<ModelResellerUser>(resellerUserApiEndpoint, {
      resellerId,
    })
  ).data;

  return (
    await Promise.all(
      resellerUsers
        .map(resellerUser => resellerUser.userId)
        .map(id =>
          fetchOneUser(id)
            .then(identity)
            .catch(() => null)
        )
    )
  ).filter(identity);
}
