import { CustomerFileState, reduceCustomerFile } from '../_modules/files/reducers';
import { combineReducers } from 'redux';

export interface ReduxState {
  files: CustomerFileState;
}

const mainApp = combineReducers({
  files: reduceCustomerFile,
});

export default mainApp;
