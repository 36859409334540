"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function areEqualShallow(object1, object2) {
  for (var key in object1) {
    if (!object1.hasOwnProperty(key) || !object2.hasOwnProperty(key) || object1[key] !== object2[key]) {
      return false;
    }
  }

  for (var _key in object2) {
    if (!object2.hasOwnProperty(_key) || !object1.hasOwnProperty(_key) || object1[_key] !== object2[_key]) {
      return false;
    }
  }

  return true;
}

exports.areEqualShallow = areEqualShallow;
/**
 * Ne comparera que les valeurs des clés d'object1, donc peut être true si object2
 * a des clés que object1 n'a pas
 *
 * La comparaison des tableaux n'est pas déterministe !
 *
 * @param object1
 * @param object2
 * @returns {boolean}
 */

function matchObjectToSourceObjectRecursive(object1, object2) {
  var result = Object.keys(object1).map(function (key) {
    if (object1[key] && typeof object1[key] === 'object') {
      if (object2[key] && typeof object2[key] === 'object') {
        return matchObjectToSourceObjectRecursive(object1[key], object2[key]);
      }

      return false;
    }

    return object1[key] === object2[key];
  }).filter(function (isEqual) {
    return !isEqual;
  });
  return result.length === 0;
}

exports.matchObjectToSourceObjectRecursive = matchObjectToSourceObjectRecursive;