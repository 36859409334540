import {
  CALCULATORS,
  CalculatorRequestData,
  CalculatorRigidFormPermanentResources,
  InprintCalculator,
  ModelOrderItem,
  ORDER_ITEM_TYPES,
  OrderItemClientSource,
} from '@oyp/shared-lib';
import { CartProps } from '../../../types';
import { fetchRigidCalculationResult } from '../actions';
import { fetchRigidPermanentResources, getRigidInitialRequestData } from '../calculator_helper';
import { onRigidRequestDataChange } from '@oyp/shared-components';
import Calculator from '../../../components/Calculator';
import CalculatorForm from './CalculatorForm';
import React from 'react';

const RigidCalculator: React.FC<{
  isHidden: boolean;
  isLoading: boolean;
  editedItem?: ModelOrderItem;
  cartProps: CartProps;
  onSaveOrderItem: (orderItem: OrderItemClientSource) => void;
}> = props => {
  const { editedItem } = props;
  const editedItemRequestData =
    editedItem &&
    editedItem.orderItemType === ORDER_ITEM_TYPES.CALCULATOR &&
    editedItem.requestData.calculator === CALCULATORS.RIGID
      ? editedItem.requestData
      : null;

  return (
    !props.isHidden && (
      <Calculator<
        CalculatorRigidFormPermanentResources,
        CalculatorRequestData,
        InprintCalculator.SlimResult
      >
        isParentLoading={props.isLoading}
        isHidden={props.isHidden}
        editedItemRequestData={editedItemRequestData}
        onSaveOrderItem={props.onSaveOrderItem}
        cartProps={props.cartProps}
        FormType={CalculatorForm}
        getInitialRequestData={(permanentResources: CalculatorRigidFormPermanentResources) => ({
          ...getRigidInitialRequestData(permanentResources),
          productionMode: props.cartProps.productionMode,
        })}
        onRequestDataChange={onRigidRequestDataChange}
        fetchPermanentResources={fetchRigidPermanentResources}
        fetchCalculateResult={fetchRigidCalculationResult}
      />
    )
  );
};

export default RigidCalculator;
