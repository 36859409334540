import './OrderDetailsPage.scss';
import * as React from 'react';
import { Card, EditableText, formatter, logError, names } from '@oyp/shared-components';
import { FEATURE_SWITCH_ORDER_RENEWAL } from '../../../env-config';
import { FrontOrder } from '../../calculator/types';
import { Icon } from 'react-materialize';
import { Link, RouteComponentProps } from 'react-router-dom';
import { MDBAlert, MDBContainer } from 'mdbreact';
import { ORDER_INVALIDITY_REASONS, getInvalidityReason, getOrderStatusLabel } from '../utils';
import {
  ORDER_ONLY_STATUSES,
  PASSED_FOR_PRINT_STATUSES,
  QUOTE_ONLY_STATUSES,
  SHIPPING_MODES,
} from '@oyp/shared-lib';
import { fetchOrderItemsForOrder } from '../actions/order_item';
import { fetchOrderWithProprietaryEntities, saveOneOrder } from '../actions/order';
import { maxLength } from '@oyp/shared-components/dist/lib/utils/validation-constraints';
import BreadCrumb, { ORDER_BREADCRUMB_STEPS } from '../../../components/BreadCrumb';
import Button from '../../../components/buttons/Button';
import CardActionFooter from '../../../components/CardActionFooter';
import Cart from '../components/cart/Cart';
import Loader from '../../../components/Loader';
import RenewalAction from '../components/RenewalAction';
import StyledLink from '../../../components/StyledLink';
import queryString from 'query-string';
import styled from 'styled-components';

const { formatDateTimeStringToShortText } = formatter;
const { getUserDisplayName } = names;

type OrderDetailsPageProps = RouteComponentProps<{ id: string }>;

interface OrderDetailsPageState {
  isLoading: boolean;
  hasError: boolean;
  order?: FrontOrder;
}

class OrderDetailsPage extends React.Component<OrderDetailsPageProps, OrderDetailsPageState> {
  state: OrderDetailsPageState = {
    isLoading: true,
    hasError: false,
  };

  constructor(props: OrderDetailsPageProps) {
    super(props);

    loadData(this, props);

    this.saveManualQuoteReference = this.saveManualQuoteReference.bind(this);
    this.saveManualOrderReference = this.saveManualOrderReference.bind(this);
    this.saveManualShippingReference = this.saveManualShippingReference.bind(this);
  }

  render() {
    if (this.state.isLoading || this.state.hasError) {
      return <Loader error={this.state.hasError ? '' : null} />;
    }

    const { order } = this.state;
    const { match } = this.props;

    const isRecap = match.path.includes('recap');

    const invalidityReason = getInvalidityReason(order, order.orderItems);
    const hasPassedForPrintToValidated = order.orderItems.some(
      orderItem => orderItem.passedForPrintStatus === PASSED_FOR_PRINT_STATUSES.SENT
    );

    const isQuote = QUOTE_ONLY_STATUSES.some(status => order.status === status);
    const queryParams = queryString.parse(this.props.location.search);

    const isCardPayment = queryParams['card-payment'] === 'true';

    return (
      <div className={`order-details-page ${isRecap ? 'breadcrumb-padded' : ''}`}>
        {isRecap ? (
          <BreadCrumb selectedStepId={ORDER_BREADCRUMB_STEPS.RECAP} order={order} />
        ) : (
          <div className="reseller-app-page-title">
            <Link className="go-back-link" to={isQuote ? '/quotes' : '/orders'}>
              <i className="material-icons">chevron_left</i> Retour à la liste des{' '}
              {isQuote ? 'devis' : 'commandes'}
            </Link>
            {order.orderReference || order.quoteReference}
          </div>
        )}

        <MDBContainer>
          <div className="row">
            <div className="col-lg-4">
              <Card
                className={`section-wrapper order-details-infos ${
                  isQuote ? 'quote-details-infos' : ''
                }`}
                title={`Informations - ${isQuote ? order.quoteReference : order.orderReference}`}
              >
                {isRecap && (
                  <MDBAlert color="success" className="text-center" dismiss>
                    {isCardPayment ? (
                      <>
                        Votre paiement a été effectué avec succès ! <br />
                        Votre commande va être vérifiée pour mise en production.
                      </>
                    ) : (
                      <>
                        Votre commande est terminée !<br />
                        Voici un récapitulatif de son contenu.
                      </>
                    )}
                  </MDBAlert>
                )}
                {invalidityReason === ORDER_INVALIDITY_REASONS.HAS_INVALID_PAYMENT && (
                  <MDBAlert color="danger">
                    <p className="text-center">
                      {`Le paiement de la commande a été refusé par notre service bancaire. Merci de réessayer.`}
                    </p>
                    <div className="text-center">
                      <Button as={Link as any} to={`/order/${order.id}/payment`} look="danger">
                        Payer la commande
                      </Button>
                    </div>
                  </MDBAlert>
                )}
                {invalidityReason === ORDER_INVALIDITY_REASONS.HAS_INVALID_FILES && (
                  <MDBAlert color="danger">
                    <p className="text-center">
                      Certains fichiers ont été refusés suite à une vérification par nos services.
                      Merci de consulter ces fichiers et de renvoyer des fichiers conformes.
                    </p>
                    <div className="text-center">
                      <Button as={Link as any} to={`/orders/${order.id}/files`} look="danger">
                        Renvoyer les fichiers
                      </Button>
                    </div>
                  </MDBAlert>
                )}
                {hasPassedForPrintToValidated && (
                  <MDBAlert color="info">
                    <p className="text-center">
                      {`Certains produits de la commande ont des BAT prêts à être validés !
                        Vous pouvez voir le BAT d'un produit en cliquant sur l'icône `}
                      <Icon className="warning-text">assignment_turned_in</Icon>
                    </p>
                  </MDBAlert>
                )}

                <div className="row pl-3 pr-3">
                  <div className="col-md-6">Créateur</div>
                  <OrderDescriptionRightCell className="col-md-6">
                    {getUserDisplayName(order.creator)}
                  </OrderDescriptionRightCell>
                </div>
                <div className="row pl-3 pr-3">
                  <div className="col-md-6">Statut</div>
                  <OrderDescriptionRightCell className="col-md-6">
                    {getOrderStatusLabel(order)}
                  </OrderDescriptionRightCell>
                </div>
                {order.confirmedAt && (
                  <div className="row pl-3 pr-3">
                    <div className="col-md-6">Date de création</div>
                    <OrderDescriptionRightCell className="col-md-6">
                      {formatDateTimeStringToShortText(order.confirmedAt)}
                    </OrderDescriptionRightCell>
                  </div>
                )}
                <div className="row pl-3 pr-3">
                  {order.manualQuoteReference ? (
                    <>
                      <div className="col-md-5">Ref. devis</div>
                      <OrderDescriptionRightCell className="col-md-7">
                        <EditableText
                          placeholder="Entrer une référence de devis"
                          onUpdate={this.saveManualQuoteReference}
                          validate={maxLength(20)}
                          originalValue={order.manualQuoteReference}
                          editingClassName="in-line-edit-offset"
                        >
                          {order.manualQuoteReference}
                        </EditableText>
                      </OrderDescriptionRightCell>
                    </>
                  ) : (
                    <div className="col-md-12">
                      <EditableText
                        placeholder="Entrer une référence de devis"
                        onUpdate={this.saveManualQuoteReference}
                        validate={maxLength(20)}
                        originalValue={order.manualQuoteReference}
                      >
                        {order.manualQuoteReference}
                      </EditableText>
                    </div>
                  )}
                </div>
                {!isQuote && (
                  <div className="row pl-3 pr-3">
                    {order.manualOrderReference ? (
                      <>
                        <div className="col-md-5">Ref. commande</div>
                        <OrderDescriptionRightCell className="col-md-7">
                          <EditableText
                            placeholder="Entrer une référence de commande"
                            onUpdate={this.saveManualOrderReference}
                            validate={maxLength(20)}
                            originalValue={order.manualOrderReference}
                            editingClassName="in-line-edit-offset"
                          >
                            {order.manualOrderReference}
                          </EditableText>
                        </OrderDescriptionRightCell>
                      </>
                    ) : (
                      <div className="col-md-12">
                        <EditableText
                          placeholder="Entrer une référence de commande"
                          onUpdate={this.saveManualOrderReference}
                          validate={maxLength(20)}
                          originalValue={order.manualOrderReference}
                        >
                          {order.manualOrderReference}
                        </EditableText>
                      </div>
                    )}
                  </div>
                )}
                {order.shippingMode === SHIPPING_MODES.PICKUP && (
                  <div className="row pl-3 pr-3">
                    {order.manualShippingReference ? (
                      <>
                        <div className="col-md-5">Ref. colis</div>
                        <OrderDescriptionRightCell className="col-md-7">
                          <EditableText
                            placeholder="Entrer une référence colis"
                            onUpdate={this.saveManualShippingReference}
                            validate={maxLength(20)}
                            originalValue={order.manualShippingReference}
                            editingClassName="in-line-edit-offset"
                          >
                            {order.manualShippingReference}
                          </EditableText>
                        </OrderDescriptionRightCell>
                      </>
                    ) : (
                      <div className="col-md-12">
                        <EditableText
                          placeholder="Entrer une référence colis"
                          onUpdate={this.saveManualShippingReference}
                          validate={maxLength(20)}
                          originalValue={order.manualShippingReference}
                        >
                          {order.manualShippingReference}
                        </EditableText>
                      </div>
                    )}
                  </div>
                )}
                {order.orderItems.some(oI => !oI.isWithoutPrint) && (
                  <div className="row pl-3 pr-3 order-detail-link">
                    <div className="col-md-12">
                      <StyledLink to={`/orders/${order.id}/files`}>
                        Voir les fichiers envoyés
                      </StyledLink>
                    </div>
                  </div>
                )}
                {isQuote ? (
                  <CardActionFooter>
                    <Button as={Link as any} to={`/quote/${order.id}`} look="secondary" size="sm">
                      Reprendre le devis
                    </Button>
                  </CardActionFooter>
                ) : null}

                {FEATURE_SWITCH_ORDER_RENEWAL && (
                  <div style={{ width: '100%', paddingBottom: '77px' }}>
                    {ORDER_ONLY_STATUSES.includes(order.status) && (
                      <CardActionFooter>
                        <RenewalAction
                          order={order}
                          orderItems={order.orderItems}
                          history={this.props.history}
                        />
                      </CardActionFooter>
                    )}
                  </div>
                )}
              </Card>
            </div>
            <div className="col-lg-8">
              <Cart
                order={order}
                readOnly
                isLoading={false}
                cardTitle="Produits commandés"
                displayOrderItemStatus
              />
            </div>
          </div>
        </MDBContainer>
      </div>
    );
  }

  async saveManualQuoteReference(manualQuoteReference: string) {
    const order = await saveOneOrder({ ...this.state.order, manualQuoteReference });
    this.setState({
      order: { ...this.state.order, ...order },
    });
  }

  async saveManualOrderReference(manualOrderReference: string) {
    const order = await saveOneOrder({ ...this.state.order, manualOrderReference });
    this.setState({
      order: { ...this.state.order, ...order },
    });
  }

  async saveManualShippingReference(manualShippingReference: string) {
    const order = await saveOneOrder({ ...this.state.order, manualShippingReference });
    this.setState({
      order: { ...this.state.order, ...order },
    });
  }
}

export default OrderDetailsPage;

async function loadData(container: any, props: OrderDetailsPageProps) {
  try {
    const orderId = props.match.params.id;
    const order = await fetchOrderWithProprietaryEntities(orderId);
    const orderItems = await fetchOrderItemsForOrder(orderId);

    container.setState({
      order: {
        ...order,
        orderItems,
      },
      isLoading: false,
      hasError: false,
    });
  } catch (error) {
    logError(error);
    container.setState({
      order: null,
      isLoading: false,
      hasError: true,
    });
  }
}

const OrderDescriptionRightCell = styled.div`
  white-space: nowrap;
  text-align: right;
`;
