import * as React from 'react';
import { ModelAddress, PICKUP_ADDRESS, SHIPPING_MODES } from '@oyp/shared-lib';

interface AddressDisplayProps {
  address: ModelAddress;
  shippingMode: SHIPPING_MODES;
}

const AddressDisplay: React.SFC<AddressDisplayProps> = ({ address, shippingMode }) => {
  const {
    name,
    companyName,
    street,
    complement,
    postalCode,
    city,
    additionalInformation,
    email,
    phoneNumber,
  } = shippingMode === SHIPPING_MODES.PICKUP ? { ...address, ...PICKUP_ADDRESS } : address;

  return (
    <address>
      {name} <br />
      {companyName} <br />
      {street}
      <br />
      {complement && (
        <span>
          {complement}
          <br />
        </span>
      )}
      {postalCode}
      &nbsp;
      {city}
      <br />
      {email}
      {email && <span> / </span>}
      {phoneNumber}
      <br />
      {additionalInformation}
    </address>
  );
};

export default AddressDisplay;
