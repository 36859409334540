"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
}); // Enums

var APPS;

(function (APPS) {
  APPS["PROD_CENTER"] = "APP_PROD_CENTER";
  APPS["PRINT_CENTER"] = "APP_PRINT_CENTER";
})(APPS = exports.APPS || (exports.APPS = {}));

var WS_MESSAGES;

(function (WS_MESSAGES) {
  WS_MESSAGES["GREET"] = "greet";
  WS_MESSAGES["ORDER_UPDATED"] = "orderUpdated";
  WS_MESSAGES["NOTIFICATION_UPDATED"] = "notificationUpdated";
  WS_MESSAGES["LAMINATION_UPDATED"] = "laminationUpdated";
  WS_MESSAGES["FLEXIBLE_MATERIAL_UPDATED"] = "flexibleMaterialUpdated";
  WS_MESSAGES["RIGID_MATERIAL_UPDATED"] = "rigidMaterialUpdated";
  WS_MESSAGES["FINISHED_PRODUCT_UPDATED"] = "finishedProductUpdated";
  WS_MESSAGES["CUSTOMER_FILE_UPDATED"] = "customerFileUpdated";
  WS_MESSAGES["HP_SKINS_ORDER_UPDATED"] = "hpSkinsOrderUpdated";
  WS_MESSAGES["HP_SKINS_IMPORT_FAILURE_UPDATED"] = "hpSkinsImportFailureUpdated";
})(WS_MESSAGES = exports.WS_MESSAGES || (exports.WS_MESSAGES = {}));