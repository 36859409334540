import './Cart.scss';
import * as React from 'react';
import { Card } from '@oyp/shared-components';
import { CartProps } from '../../../calculator/types';
import { Icon } from 'react-materialize';
import CartFooter from './CartFooter';
import CartOrderItemLine from './CartOrderItemLine';

const Cart: React.SFC<CartProps> = props => {
  const {
    reseller,
    order,
    readOnly,
    isOnQuotePage,
    editedItem = null,
    footer,
    isLoading,
    onSaveItem,
    onRemoveItem,
    onStartEditItem,
    onCancelEditItem,
    onProductionModeChange = () => {},
    onSaveOrder,
    files,
    cardTitle = 'Panier',
    displayOrderItemStatus,
  } = props;

  const { orderItems } = order;

  return (
    <div className="cart-container">
      <Card
        title={
          <div>
            {cardTitle}
            <span className="custom-badge badge-with-left-margin">{orderItems.length}</span>
          </div>
        }
        className="cart-height"
      >
        <div className="cart-content">
          {orderItems.length ? (
            <div>
              <table className="table table-borderless table-striped table-header">
                <thead className="thead-light">
                  <tr>
                    {files ? (
                      <th className="bg-primary-lighter font-weight-bold text-white cart-content-header-files text-left">
                        Fichiers
                      </th>
                    ) : (
                      <th className="bg-primary-lighter font-weight-bold cart-content-header-actions text-left" />
                    )}
                    <th className="bg-primary-lighter text-left font-weight-bold text-white cart-content-header-label">
                      Produit
                    </th>
                    {displayOrderItemStatus && (
                      <th className="bg-primary-lighter font-weight-bold text-white text-left cart-content-header-statut">
                        Statut
                      </th>
                    )}
                    <th className="bg-primary-lighter font-weight-bold text-white text-center cart-content-header-quantity">
                      Quantité
                    </th>
                    <th className="bg-primary-lighter font-weight-bold text-white text-right cart-content-header-price">
                      Prix HT
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orderItems.map((item, index) => (
                    <CartOrderItemLine
                      files={files}
                      key={index}
                      order={order}
                      item={item}
                      displayStatus={displayOrderItemStatus}
                      isOnQuotePage={isOnQuotePage}
                      orderDetailOrientation="left"
                      isCurrentlyEdited={editedItem && editedItem.id === item.id}
                      onSaveItem={onSaveItem}
                      onRemoveItem={onRemoveItem}
                      onStartEditItem={onStartEditItem}
                      onCancelEditItem={onCancelEditItem}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="empty-cart-message">
              <Icon>shopping_cart</Icon>
              <h2>Votre panier est vide </h2>
              <p>Configurez votre produit pour l&apos;ajouter au panier</p>
            </div>
          )}

          <div className="container inner-cards">
            <CartFooter
              order={order}
              reseller={reseller}
              readOnly={readOnly}
              isLoading={isLoading}
              onProductionModeChange={onProductionModeChange}
              onSaveOrder={onSaveOrder}
            />
          </div>

          {footer ? footer : null}
        </div>
      </Card>
    </div>
  );
};

export default Cart;
