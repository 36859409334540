"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function debounce(fn, delay) {
  var timer;
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var context = this;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

exports.debounce = debounce;

function debouncePromise(fn) {
  var wait = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var lastCallAt;
  var deferred;
  var timer;
  var pendingArgs = [];
  return function debounced() {
    var currentWait = getWait(wait);
    var currentTime = new Date().getTime();
    var that = this;
    var isCold = !lastCallAt || currentTime - lastCallAt > currentWait;
    lastCallAt = currentTime;

    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    if (isCold && options.leading) {
      return options.accumulate ? Promise.resolve(fn.call(that, [args])).then(function (result) {
        return result[0];
      }) : Promise.resolve(fn.call.apply(fn, [that].concat(args)));
    }

    if (deferred) {
      clearTimeout(timer);
    } else {
      deferred = defer();
    }

    pendingArgs.push(args);
    timer = setTimeout(flush.bind(this), currentWait);

    if (options.accumulate) {
      var argsIndex = pendingArgs.length - 1;
      return deferred.promise.then(function (results) {
        return results[argsIndex];
      });
    }

    return deferred.promise;
  };

  function flush() {
    var thisDeferred = deferred;
    var that = this;
    clearTimeout(timer);
    Promise.resolve(options.accumulate ? fn.call(that, pendingArgs) : fn.apply(that, pendingArgs[pendingArgs.length - 1])).then(thisDeferred.resolve, thisDeferred.reject);
    pendingArgs = [];
    deferred = null;
  }
}

exports.debouncePromise = debouncePromise;

function getWait(wait) {
  return typeof wait === 'function' ? wait() : wait;
}

function defer() {
  var deferred = {};
  deferred.promise = new Promise(function (resolve, reject) {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });
  return deferred;
}