import { FILE_TYPE_CUSTOMER_FILE, ModelCustomerFile } from '@oyp/shared-lib';
import { centralStationApiEndpoint } from '../../env-config';

export const apiEndpoint = centralStationApiEndpoint;

// Entités
export const orderApiEndpoint = `${apiEndpoint}/orders`;
export const orderItemApiEndpoint = `${apiEndpoint}/order-items`;
export const orderItemEventApiEndpoint = `${apiEndpoint}/order-item-events`;
export const shippingAddressApiEndpoint = `${apiEndpoint}/shipping-addresses`;
export const parcelApiEndpoint = `${apiEndpoint}/parcels`;
export const shipmentApiEnpoint = `${apiEndpoint}/shipments`;
export const invoicesEndpoint = `${apiEndpoint}/invoices`;
export const paymentEndpoint = `${apiEndpoint}/payments`;
export const customerFileApiEndpoint = `${apiEndpoint}/customer-files`;
export const customerFileVersionApiEndpoint = `${apiEndpoint}/customer-file-versions`;
export const customerFileArchiveUploadApiEndpoint = `${apiEndpoint}/customer-files-archive`;
export const resellerCustomerAddressEndpoint = `${apiEndpoint}/reseller-customer-addresses`;

// Opérations
export const orderRecalculateDatesApiEndpoint = `${apiEndpoint}/order-recalculate-dates`;
export const renewOrderApiEndpoint = `${apiEndpoint}/renew-order`;
export const checkOrderRenewalApiEndpoint = `${apiEndpoint}/check-renewal`;
export const createOrderAddressApiEndpoint = `${apiEndpoint}/create-order-address`;

export const getFileDownloadUrl = (type: string, file: ModelCustomerFile) => {
  switch (type) {
    case FILE_TYPE_CUSTOMER_FILE:
      return `${customerFileApiEndpoint}/${file.id}/download`;
    default:
      throw new Error(`Unknown file type: ${type}`);
  }
};
