"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
}); // Enums & aliases

var SYSTEM_MESSAGES;

(function (SYSTEM_MESSAGES) {
  SYSTEM_MESSAGES["ORDER_CONFIRMED"] = "SYSTEM_MESSAGE_ORDER_CONFIRMED";
  SYSTEM_MESSAGES["ORDER_ITEM_FILES_REFUSED"] = "SYSTEM_MESSAGE_ORDER_ITEM_FILES_REFUSED";
  SYSTEM_MESSAGES["ORDER_ITEM_VALIDATED_FOR_PRODUCTION"] = "SYSTEM_MESSAGE_ORDER_ITEM_VALIDATED_FOR_PRODUCTION";
  SYSTEM_MESSAGES["ORDER_SHIPPED"] = "SYSTEM_MESSAGE_ORDER_SHIPPED";
  SYSTEM_MESSAGES["PASSED_FOR_PRINT_SENT"] = "PASSED_FOR_PRINT_SENT";
})(SYSTEM_MESSAGES = exports.SYSTEM_MESSAGES || (exports.SYSTEM_MESSAGES = {}));

var NOTIFICATION_TYPES;

(function (NOTIFICATION_TYPES) {
  NOTIFICATION_TYPES["ORDER_CONFIRMED"] = "NOTIFICATION_TYPE_ORDER_CONFIRMED";
  NOTIFICATION_TYPES["FILES_REFUSED"] = "NOTIFICATION_TYPE_FILES_REFUSED";
  NOTIFICATION_TYPES["ORDER_ITEM_VALIDATED_FOR_PRODUCTION"] = "NOTIFICATION_TYPE_ORDER_ITEM_VALIDATED_FOR_PRODUCTION";
  NOTIFICATION_TYPES["USER_MESSAGE"] = "NOTIFICATION_TYPE_USER_MESSAGE";
  NOTIFICATION_TYPES["ORDER_SHIPPED"] = "NOTIFICATION_TYPE_ORDER_SHIPPED";
})(NOTIFICATION_TYPES = exports.NOTIFICATION_TYPES || (exports.NOTIFICATION_TYPES = {}));