import { Card, logError } from '@oyp/shared-components';
import { History } from 'history';
import { ModelOrderItem, PASSED_FOR_PRINT_STATUSES } from '@oyp/shared-lib';
import { RouteComponentProps } from 'react-router';
import { acceptPassedForPrintApiEndpoint } from '../module';
import { fetchOrderItemById, saveOneOrderItem } from '../../order/actions/order_item';
import { fetchOrderItemPassedForPrintFiles } from './actions';
import { getSimpleFileDownloadLink } from '../../files/module';
import { saveOne } from '@oyp/shared-components/dist/lib/rest-api/network';
import { toast } from 'mdbreact';
import Button from '../../../components/buttons/Button';
import InputGroup from '../../../components/form/InputGroup';
import Loader from '../../../components/Loader';
import React, { useEffect, useState } from 'react';
import StyledLink from '../../../components/StyledLink';
import styles from './ValidatePassedForPrintPage.module.scss';

enum ValidatePassedForPrintPageStateKinds {
  LOADING,
  LOADED,
  LOADING_FAILED,
}

interface ValidatePassedForPrintPageState {
  kind: ValidatePassedForPrintPageStateKinds;
  orderItem?: ModelOrderItem;
  passedForPrintSimpleFileIds: string[];
  isRefusing: boolean;
  passedForPrintCustomerRefusalMessage: string;
}

const ValidatePassedForPrintPage: React.FC<RouteComponentProps<{ id: string }>> = props => {
  const [state, setState] = useState<ValidatePassedForPrintPageState>({
    kind: ValidatePassedForPrintPageStateKinds.LOADING,
    passedForPrintSimpleFileIds: [],
    isRefusing: false,
    passedForPrintCustomerRefusalMessage: '',
  });

  // Au premier render
  useEffect(() => {
    loadData(props.match.params.id)
      .then(initialData =>
        setState({
          ...state,
          kind: ValidatePassedForPrintPageStateKinds.LOADED,
          ...initialData,
        })
      )
      .catch(error => {
        logError(error);
        setState({ ...state, kind: ValidatePassedForPrintPageStateKinds.LOADING_FAILED });
      });
  }, []);

  const {
    kind,
    orderItem,
    passedForPrintSimpleFileIds: dtpFileIds,
    isRefusing,
    passedForPrintCustomerRefusalMessage,
  } = state;

  return (
    <div className={styles.page}>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          {kind === ValidatePassedForPrintPageStateKinds.LOADED ? (
            <Card title={`Valider un BAT - Produit N°${orderItem.reference}`}>
              <div className={styles['card-content']}>
                <p>
                  {`Merci de vérifier attentivement le BAT produit par notre équipe PAO.
                  S'il vous convient, vous pouvez cliquer sur Accepter, et votre produit passera en production.
                  Sinon, cliquez sur Refuser et indiquez-nous la raison de votre refus.
                  Nous vous renverrons alors un nouveau BAT sous les plus brefs délais.
                  `}
                </p>
                <div className={styles['download-section']}>
                  {dtpFileIds.length > 1 ? (
                    <>
                      Télécharger le BAT :
                      <ul>
                        {dtpFileIds.map((fileId, index) => (
                          <li key="">
                            <StyledLink
                              as="a"
                              href={getSimpleFileDownloadLink(fileId)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Fichier n°{index + 1}
                            </StyledLink>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <Button
                      as="a"
                      href={getSimpleFileDownloadLink(dtpFileIds[0])}
                      look="secondary"
                      variation="outline"
                    >
                      Télécharger le BAT
                    </Button>
                  )}
                </div>

                {!isRefusing ? (
                  <div className={styles['actions-container']}>
                    <Button look="danger" onClick={() => setState({ ...state, isRefusing: true })}>
                      Refuser
                    </Button>

                    <Button
                      look="success"
                      onClick={() => acceptPassedForPrint(orderItem, props.history)}
                    >
                      Accepter
                    </Button>
                  </div>
                ) : (
                  <>
                    <InputGroup
                      label="Raison du refus"
                      type="textarea"
                      value={passedForPrintCustomerRefusalMessage}
                      onChange={event =>
                        setState({
                          ...state,
                          passedForPrintCustomerRefusalMessage: event.target.value,
                        })
                      }
                    />
                    {passedForPrintCustomerRefusalMessage.length < 15 && (
                      <span style={{ position: 'absolute', right: '50px', marginTop: '-30px' }}>
                        Encore {15 - passedForPrintCustomerRefusalMessage.length} caractère
                        {15 - passedForPrintCustomerRefusalMessage.length > 1 && 's'}
                      </span>
                    )}
                    <div className={styles['actions-container']}>
                      <Button
                        look="secondary"
                        variation="flat"
                        onClick={() => setState({ ...state, isRefusing: false })}
                      >
                        Annuler
                      </Button>
                      <Button
                        look="secondary"
                        onClick={() =>
                          refusePassedForPrint(
                            orderItem,
                            passedForPrintCustomerRefusalMessage,
                            props.history
                          )
                        }
                        disabled={passedForPrintCustomerRefusalMessage.length < 15}
                      >
                        Confirmer le refus
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </Card>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};

export default ValidatePassedForPrintPage;

async function loadData(orderItemId: string): Promise<Partial<ValidatePassedForPrintPageState>> {
  const [orderItem, passedForPrintFiles] = await Promise.all([
    fetchOrderItemById(orderItemId),
    fetchOrderItemPassedForPrintFiles(orderItemId),
  ]);

  return {
    orderItem,
    passedForPrintSimpleFileIds: passedForPrintFiles.map(file => file.simpleFileId),
  };
}

async function acceptPassedForPrint(orderItem: ModelOrderItem, history: History) {
  await saveOne(acceptPassedForPrintApiEndpoint, orderItem);

  toast.success(`BAT accepté`, 2000);

  history.push(`/orders/${orderItem.orderId}/details`);
}

async function refusePassedForPrint(
  orderItem: ModelOrderItem,
  passedForPrintCustomerRefusalMessage: string,
  history: History
) {
  await saveOneOrderItem({
    ...orderItem,
    passedForPrintStatus: PASSED_FOR_PRINT_STATUSES.REFUSED,
    passedForPrintCustomerRefusalMessage,
  });

  toast.success(`BAT refusé`, 2000);

  history.push(`/orders/${orderItem.orderId}/details`);
}
