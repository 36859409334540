export function extractFilesFromFileList(fileList: any) {
  const files: any = [];
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i];
    files.push(file);
  }

  return files;
}

export function extractFilesFromDataTransfer(dataTransfer: any) {
  if (dataTransfer.items) {
    const files: any = [];
    for (let i = 0; i < dataTransfer.items.length; i++) {
      const item = dataTransfer.items[i];
      if (item.kind === 'file') {
        files.push(item.getAsFile());
      }
    }

    return files;
  }

  return extractFilesFromFileList(dataTransfer.files);
}
