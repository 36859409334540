import { MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import {
  ModelResellerCustomerAddress,
  isPostalCodeOutsideMetropolitanFrance,
} from '@oyp/shared-lib';
import { addressValidationConstraints } from '../../order/validation';
import { getOnEventChange } from '@oyp/shared-components/dist/lib/utils/form';
import {
  normalizePhoneNumber,
  normalizePostalCode,
} from '@oyp/shared-components/dist/lib/utils/normalizers';
import { validateForm } from '@oyp/shared-components/dist/lib/utils/validator';
import Button from '../../../components/buttons/Button';
import InfoTooltip from '../../../components/InfoTooltip';
import InputGroup from '../../../components/form/InputGroup';
import React, { useEffect, useState } from 'react';
import styles from './AddressModalForm.module.scss';

interface AddressModalFormProps {
  address: ModelResellerCustomerAddress | null;
  onClose: () => void;
  onSave: (address: ModelResellerCustomerAddress) => void;
}

const AddressModalForm: React.FC<AddressModalFormProps> = props => {
  const { address: propsAddress, onClose, onSave } = props;

  const [address, setAddress] = useState(propsAddress);

  useEffect(() => {
    setAddress(propsAddress);
  }, [propsAddress]);

  const {
    companyName,
    name,
    street,
    complement,
    postalCode,
    city,
    email,
    phoneNumber,
    additionalInformation,
  } = address || ({} as ModelResellerCustomerAddress);

  const isCodePostalOutsideFrance = isPostalCodeOutsideMetropolitanFrance(postalCode);

  const validationErrors = validateForm(address || {}, addressValidationConstraints);
  const isValid = Object.keys(validationErrors).length === 0;

  return (
    <MDBModal fullHeight position="right" isOpen={address ? true : false} backdrop toggle={onClose}>
      <MDBModalHeader toggle={onClose}>
        <div className="text-center">Modifier une adresse</div>
      </MDBModalHeader>

      <MDBModalBody>
        {address && (
          <form onSubmit={event => event.preventDefault()}>
            <div className="form-row">
              <InputGroup
                name="companyName"
                label="Société"
                error={validationErrors.companyName}
                onChange={getOnEventChange('companyName', address, setAddress)}
                value={companyName}
                s={6}
              />

              <InputGroup
                name="name"
                label="Destinataire"
                error={validationErrors.name}
                onChange={getOnEventChange('name', address, setAddress)}
                value={name}
                s={6}
              />
            </div>

            <div className="form-row">
              <InputGroup
                name="street"
                label="Adresse"
                s={6}
                error={validationErrors.street}
                onChange={getOnEventChange('street', address, setAddress)}
                value={street}
              />

              <InputGroup
                name="complement"
                label="Complément d'adresse"
                s={6}
                type="text"
                error={validationErrors.complement}
                onChange={getOnEventChange('complement', address, setAddress)}
                value={complement}
              />
            </div>

            <div className="form-row">
              <InputGroup
                name="postalCode"
                label={
                  isCodePostalOutsideFrance ? (
                    <span>
                      Code postal
                      <InfoTooltip
                        place="top"
                        left={75}
                        top={-4}
                        tooltipWidth={300}
                        tooltipContent="Pour cette destination, merci de contacter notre service commercial afin d'obtenir un devis incluant des frais de livraison adaptés à la destination"
                      />
                    </span>
                  ) : (
                    'Code postal'
                  )
                }
                s={6}
                error={validationErrors.postalCode}
                onChange={getOnEventChange('postalCode', address, setAddress, normalizePostalCode)}
                value={postalCode}
              />

              <InputGroup
                name="city"
                label="Ville"
                s={6}
                error={validationErrors.city}
                onChange={getOnEventChange('city', address, setAddress)}
                value={city}
              />
            </div>

            <div className="form-row">
              <InputGroup
                name="email"
                label={
                  <span>
                    Email de notification{' '}
                    <InfoTooltip
                      tooltipWidth={150}
                      left={150}
                      top={-4}
                      tooltipContent="Cette adresse recevra le lien de suivi du transport"
                      place="left"
                    />
                  </span>
                }
                s={6}
                type="email"
                error={validationErrors.email}
                onChange={getOnEventChange('email', address, setAddress)}
                value={email}
              />

              <InputGroup
                name="phoneNumber"
                label="Téléphone"
                s={6}
                error={validationErrors.phoneNumber}
                onChange={getOnEventChange(
                  'phoneNumber',
                  address,
                  setAddress,
                  normalizePhoneNumber
                )}
                value={phoneNumber}
              />
            </div>

            <div className="form-row address-info-sup">
              <InputGroup
                name="additionalInformation"
                label="Informations supplémentaires (ex : interphone, code porte, étage)"
                s={12}
                onChange={getOnEventChange('additionalInformation', address, setAddress)}
                value={additionalInformation}
                error={validationErrors.additionalInformation}
              />
            </div>

            <div className={styles['confirm-line']}>
              <Button
                look="primary"
                className="float-right"
                disabled={!isValid}
                onClick={() => onSave(address)}
                type="submit"
              >
                Enregistrer
              </Button>
            </div>
          </form>
        )}
      </MDBModalBody>
    </MDBModal>
  );
};

export default AddressModalForm;
