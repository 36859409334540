import './CellWideButton.scss';
import * as React from 'react';

interface CellWideButtonProps {
  onClick: () => void;
}

const CellWideButton: React.SFC<CellWideButtonProps> = props => (
  <div className="cancel-edit-button" onClick={props.onClick}>
    {props.children}
  </div>
);

export default CellWideButton;
