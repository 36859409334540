"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = require("react");

var TooltipContainer_1 = require("../TooltipContainer");

var formatter_1 = require("../../utils/formatter");

exports.RejectedFileItem = function (props) {
  var _props$file = props.file,
      file = _props$file === void 0 ? {} : _props$file,
      _props$onRejectedFile = props.onRejectedFileDismiss,
      onRejectedFileDismiss = _props$onRejectedFile === void 0 ? function (a) {
    return a;
  } : _props$onRejectedFile;
  return React.createElement("div", {
    className: "file-item-wrapper uploaded-file no-preview"
  }, React.createElement(TooltipContainer_1.TooltipContainer, {
    tooltipContent: file.name
  }, React.createElement("div", {
    className: "title"
  }, truncateFileNameIfNeeded(file.name))), "Une erreur est survenue lors de l'envoi du fichier.", React.createElement("button", {
    onClick: function onClick() {
      return onRejectedFileDismiss(file);
    }
  }, "x"));
};

function truncateFileNameIfNeeded(name) {
  if (name.length < 15) {
    return name;
  }

  var splitName = name.split('.');
  var extension = splitName[splitName.length - 1];
  var firstSegment = splitName.slice(0, splitName.length - 1).join('.');
  return "".concat(formatter_1.truncateString(firstSegment, 15), ".").concat(extension);
}