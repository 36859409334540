"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.normalizeFloat = function (value) {
  return isNaN(parseFloat(value)) ? null : parseFloat(value);
};

exports.normalizeInt = function (value) {
  return isNaN(parseInt(value, 10)) ? null : parseInt(value, 10);
};

exports.normalizePhoneNumber = function (value) {
  return value.length > 0 && value[0] === '+' ? "+".concat(value.replace(/\D+/gi, '')) : value.replace(/\D+/gi, '');
};

exports.normalizePostalCode = removeWhiteSpaces;

function removeWhiteSpaces(source) {
  return source.replace(/\s/g, '');
}