import * as React from 'react';
import { EditableText } from '@oyp/shared-components';
import {
  ModelOrderItem,
  SHIPPING_MODES,
  TRANSPORTERS,
  getDeliveryDate,
  isSmartLabelOrderItem,
} from '@oyp/shared-lib';
import { formatDateStringToDayMonth } from '@oyp/shared-components/dist/lib/utils/formatter';
import ItemInformationContainer from '../containers/ItemInformationContainer';
import Markdown from 'react-markdown';

interface ManufactureDataProps {
  item: ModelOrderItem;
  displaySmartLabelDeliveryDate?: boolean;
  onSaveOrderItem?: (orderItem: ModelOrderItem) => void;
}

const ManufactureData: React.SFC<ManufactureDataProps> = props => {
  const { item, displaySmartLabelDeliveryDate, onSaveOrderItem: saveOrderItem } = props;

  return (
    <div>
      <ItemInformationContainer orderItem={item} />
      <div>
        {displaySmartLabelDeliveryDate && isSmartLabelOrderItem(item) && (
          <div>
            <b>Date de livraison prévue : </b>{' '}
            {formatDateStringToDayMonth(
              getDeliveryDate(
                item.manufactureData.delivery.delay,
                new Date(),
                item.requestData.productionMode,
                SHIPPING_MODES.TRANSPORTER,
                TRANSPORTERS.NO_TRANSPORTER
              )
            )}
          </div>
        )}
        {saveOrderItem && (
          <EditableText
            inputType="textarea"
            originalValue={item.description}
            onUpdate={description => saveOrderItem({ ...item, description })}
            placeholder="Ajouter un commentaire"
          >
            Commentaire :&nbsp;
            {item.description ? <Markdown source={item.description} /> : null}
          </EditableText>
        )}
      </div>
    </div>
  );
};

export default ManufactureData;
