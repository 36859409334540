import './LoginForm.scss';
import * as React from 'react';
import { MDBInput } from 'mdbreact';
import Button from '../../../components/buttons/Button';
import StyledLink from '../../../components/StyledLink';
import logoBlanc from '../../../img/printcenter_logo-blanc.svg';

interface LoginFormProps {
  isLoading?: boolean;
  errorMessage?: string;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
}

class LoginForm extends React.Component<LoginFormProps> {
  render() {
    const { onSubmit, isLoading, errorMessage } = this.props;

    return (
      <div className="container">
        <div className="row">
          <div className="login col-md-6 col-lg-6 col-sm-8 offset-md-4 offset-md-3 offset-lg-3">
            <div className="login-logo-container">
              <img className="logo" src={logoBlanc} alt={'logo'} />
            </div>
            <form onSubmit={onSubmit} id="loginForm">
              <h1 className="text-center">Connexion</h1>
              <div className="col-md-10 offset-md-1">
                <label>Adresse Email</label>
                <MDBInput name="email" type="email" className="form-control " required id="email" />
                <label>Mot de passe</label>
                <MDBInput
                  name="password"
                  type="password"
                  className="form-control"
                  required
                  id="password"
                />
                <div className="reset-password-link">
                  <StyledLink to="/reset-password">Mot de passe oublié ?</StyledLink>
                </div>

                {errorMessage && <div className="alert alert-warning">{errorMessage}</div>}
              </div>
              <div className="login-bottom col-md-12">
                <Button
                  className="col-md-4 offset-md-4"
                  disabled={isLoading}
                  type="submit"
                  look="secondary"
                  id="submitButton"
                >
                  {isLoading ? <span>Chargement...</span> : 'SE CONNECTER'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginForm;
