import { APPS, WS_MESSAGES } from '@oyp/shared-lib';
import { CENTRAL_STATION_HOST } from './env-config';
import { getAuthenticationToken } from './_modules/authentication/actions';
import openSocket from 'socket.io-client';

class WebsocketHolder {
  ws: any = {
    on: () => {},
    removeAllListeners: () => {},
  };

  createSocket() {
    const queryParams = [
      ['token', getAuthenticationToken()],
      ['app', APPS.PRINT_CENTER],
    ].map(param => param.join('='));

    this.ws = openSocket(`${CENTRAL_STATION_HOST}?${queryParams.join('&')}`);
  }

  subscribeToCustomerFileUpdates(
    onCustomerFileUpdate: (data: { customerFileId: string; orderItemId: string }) => void
  ) {
    this.ws.on(WS_MESSAGES.CUSTOMER_FILE_UPDATED, onCustomerFileUpdate);
  }
  unsubscribeFromCustomerFileUpdates() {
    this.ws.removeAllListeners(WS_MESSAGES.CUSTOMER_FILE_UPDATED);
  }

  subscribe(message: WS_MESSAGES, callback: (data: any) => void) {
    this.ws.on(message, callback);
  }

  unsubscribe(message: WS_MESSAGES, originalCallback: (data: any) => void) {
    this.ws.removeListener(message, originalCallback);
  }
}

export default new WebsocketHolder();
