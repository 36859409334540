"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function extractFilesFromFileList(fileList) {
  if (!fileList) {
    return [];
  }

  var files = [];

  for (var i = 0; i < fileList.length; i++) {
    var file = fileList[i];
    files.push(file);
  }

  return files;
}

exports.extractFilesFromFileList = extractFilesFromFileList;

function extractFilesFromDataTransfer(dataTransfer) {
  if (dataTransfer.items) {
    var files = [];

    for (var i = 0; i < dataTransfer.items.length; i++) {
      var item = dataTransfer.items[i];

      if (item.kind === 'file') {
        files.push(item.getAsFile());
      }
    }

    return files;
  }

  return extractFilesFromFileList(dataTransfer.files);
}

exports.extractFilesFromDataTransfer = extractFilesFromDataTransfer;

function downloadFromUri(uri) {
  var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var link = document.createElement('a');
  link.setAttribute('style', 'position: absolute;opacity:0;');
  link.setAttribute('href', uri);
  link.setAttribute('download', fileName);
  document.body.appendChild(link); // Required for FF

  link.click();

  if (link.parentNode) {
    link.parentNode.removeChild(link);
  }
}

exports.downloadFromUri = downloadFromUri;