"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

require("./Card.scss");

var React = require("react");

var react_materialize_1 = require("react-materialize");

exports.Card = function (props) {
  var title = props.title,
      headerContent = props.headerContent,
      _props$internalTopPad = props.internalTopPadding,
      internalTopPadding = _props$internalTopPad === void 0 ? false : _props$internalTopPad,
      _props$noPadding = props.noPadding,
      noPadding = _props$noPadding === void 0 ? false : _props$noPadding,
      _props$className = props.className,
      className = _props$className === void 0 ? '' : _props$className,
      isFullContent = props.isFullContent,
      children = props.children,
      _props$onClick = props.onClick,
      onClick = _props$onClick === void 0 ? function () {} : _props$onClick;
  var classNames = ['card', 'custom-card'];

  if (title || headerContent) {
    classNames.push('titled-card');
  }

  if (internalTopPadding) {
    classNames.push('internal-top-padding');
  }

  if (noPadding) {
    classNames.push('no-padding');
  }

  if (className) {
    classNames.push(className);
  }

  return React.createElement("div", {
    className: "".concat(classNames.join(' '), " ").concat(isFullContent ? 'full-given-content' : 'wrapping-content'),
    onClick: onClick
  }, isFullContent ? children : React.createElement(CardRegularContent, Object.assign({}, props), children));
};

var CardHeader = function CardHeader(props) {
  var error = props.error,
      title = props.title,
      headerContent = props.headerContent;
  return React.createElement("div", {
    className: "header"
  }, error ? React.createElement(react_materialize_1.Badge, {
    className: "badge-error"
  }, React.createElement(react_materialize_1.Icon, {
    tiny: true
  }, "error_outline"), " Erreur") : '', title, headerContent ? React.createElement("span", {
    className: "header-content"
  }, headerContent) : null);
};

var CardRegularContent = function CardRegularContent(props) {
  var title = props.title,
      children = props.children,
      footerContent = props.footerContent,
      headerContent = props.headerContent,
      contentStyle = props.contentStyle;
  return React.createElement("div", null, title || headerContent ? React.createElement(CardHeader, Object.assign({}, props)) : null, React.createElement("div", {
    className: "content",
    style: contentStyle
  }, children), footerContent ? React.createElement("div", {
    className: "footer"
  }, React.createElement("div", {
    className: "footer-content"
  }, footerContent)) : null);
};