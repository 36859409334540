import * as React from 'react';
import { CALCULATORS, ORDER_ITEM_TYPES } from '@oyp/shared-lib';
import { Card } from '@oyp/shared-components';
import { CartProps } from '../../../calculator/types';
import { Col } from 'react-materialize';
import { ProductCategory } from './types';
import Cart from '../../components/cart/Cart';
import FinishedProductCalculator from '../../../calculator/_modules/finished_product/components/FinishedProductCalculator';
import FlexibleCalculator from '../../../calculator/_modules/flexible/components/FlexibleCalculator';
import RigidCalculator from '../../../calculator/_modules/rigid/components/RigidCalculator';

interface QuoteProps {
  cartProps: CartProps;
  selectedCategory: ProductCategory;
  typeFilterElement: JSX.Element;
  isLoading: boolean;
  areFinishedProductsAvailable?: boolean;
}

const Quote: React.SFC<QuoteProps> = props => {
  const {
    cartProps,
    selectedCategory,
    typeFilterElement,
    isLoading,
    areFinishedProductsAvailable,
  } = props;
  const { editedItem, onSaveItem } = cartProps;

  return (
    <div className="row ml-0 mr-0">
      <Col className={'col-md-8 laptop-padding-right'}>
        <div className="quote-form-container">
          <div className="form-page-container">
            <div className="row">
              <div className="col-md-12">
                <Card title="CONFIGUREZ VOTRE PRODUIT">
                  {typeFilterElement}
                  <FlexibleCalculator
                    isHidden={
                      selectedCategory.type !== ORDER_ITEM_TYPES.CALCULATOR ||
                      selectedCategory.selectedCalculator !== CALCULATORS.FLEXIBLE
                    }
                    isLoading={isLoading}
                    editedItem={editedItem}
                    onSaveOrderItem={onSaveItem}
                    cartProps={cartProps}
                  />
                  <RigidCalculator
                    isHidden={
                      selectedCategory.type !== ORDER_ITEM_TYPES.CALCULATOR ||
                      selectedCategory.selectedCalculator !== CALCULATORS.RIGID
                    }
                    isLoading={isLoading}
                    editedItem={editedItem}
                    onSaveOrderItem={onSaveItem}
                    cartProps={cartProps}
                  />
                  {areFinishedProductsAvailable && (
                    <FinishedProductCalculator
                      isHidden={
                        selectedCategory.type !== ORDER_ITEM_TYPES.CALCULATOR ||
                        selectedCategory.selectedCalculator !== CALCULATORS.FINISHED_PRODUCT
                      }
                      isLoading={isLoading}
                      editedItem={editedItem}
                      onSaveOrderItem={onSaveItem}
                      cartProps={cartProps}
                    />
                  )}
                  {/*
                  <SmartLabelCalculator
                    isHidden={selectedCategory.type !== ORDER_ITEM_TYPES.SMART_LABEL}
                    isLoading={isLoading}
                    editedItem={editedItem}
                    onSaveOrderItem={onSaveItem}
                    cartProps={cartProps}
                  />
                  */}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </Col>

      <Col className={'col-md-4 laptop-padding-left'}>
        {' '}
        <Cart {...cartProps} isOnQuotePage />
      </Col>
    </div>
  );
};

export default Quote;
