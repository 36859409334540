"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function assertNever(x) {
  throw new Error('Unexpected case: ' + x);
}

exports.assertNever = assertNever;