import './CostCardForm.scss';
import * as Either from 'fp-ts/lib/Either';
import * as React from 'react';
import {
  CalculatedPriceData,
  PRODUCTION_MODES,
  getDeliveryDate,
  stripTimeFromDate,
} from '@oyp/shared-lib';
import { Card, formatter } from '@oyp/shared-components';
import { CartProps, FrontOrder } from '../types';
import { Col } from 'react-materialize';
import { FrontCalculator } from './Calculator';
import LoadingText from '../../../components/LoadingText';

const { formatDeliveryDateString, formatPercentage, formatPrice } = formatter;

type Result = FrontCalculator.Result<{
  price: CalculatedPriceData;
  delivery: {
    delay: number;
  };
  warnings?: any[];
}>;

interface CostCardFormProps {
  productionMode: PRODUCTION_MODES;
  calculatorResult: Result;
  cartProps: CartProps;
  additionalWarningsElement?: JSX.Element;
  isLoading: boolean;
}

const CostCardForm: React.FC<CostCardFormProps> = props => {
  const {
    cartProps,
    calculatorResult,
    productionMode,
    additionalWarningsElement,
    isLoading,
  } = props;
  const { order } = cartProps;

  const hasError = Either.isLeft(calculatorResult);

  const price = Either.isRight(calculatorResult)
    ? calculatorResult.right.price
    : {
        publicPrice: 0,
        discountPercentage: 0,
        resellerPrice: 0,
      };

  const itemDeliveryDate = getItemDeliveryDate(productionMode, calculatorResult, order);
  const displayDateChangeWarning =
    !hasError &&
    cartProps.order.orderItems.length > 0 &&
    stripTimeFromDate(itemDeliveryDate) > stripTimeFromDate(cartProps.order.deliveryDate);

  const loaderTextStyle: any = { width: '100px', height: '20px', float: 'right' };

  return (
    <div>
      <Card
        title="TARIFS"
        internalTopPadding
        className="bg-primary-darker-card-form cost-card-form"
      >
        <div className="row pl-3 pr-3">
          <div className="col-sm-7">Prix de vente HT</div>
          <div className="col-sm-5 text-right">
            {isLoading ? (
              <LoadingText style={loaderTextStyle} />
            ) : hasError ? (
              '-'
            ) : (
              formatPrice(price.publicPrice)
            )}
          </div>
        </div>

        <div className="row important-text  pl-3 pr-3">
          <div className="col-sm-7">Remise complémentaire</div>
          <div className="col-sm-5 text-right">
            {isLoading ? (
              <LoadingText style={loaderTextStyle} />
            ) : hasError || price.discountPercentage === 0 ? (
              '-'
            ) : (
              formatPercentage(price.discountPercentage)
            )}
          </div>
        </div>

        <div className="row important-text  pl-3 pr-3">
          <div className="col-sm-7 font-weight-bold">Votre prix partenaire</div>
          <div className="col-sm-5 text-right font-weight-bold">
            {isLoading ? (
              <LoadingText style={loaderTextStyle} />
            ) : hasError ? (
              '-'
            ) : (
              formatPrice(price.resellerPrice)
            )}
          </div>
        </div>
      </Card>

      <div className="row delivery-alert">
        <Col s={12}>
          {displayDateChangeWarning && (
            <div className="alert alert-warning warning-color">
              <i className="material-icons">access_time</i> La date de livraison estimée de la
              commande va changer :
              <br />
              <div style={{ textAlign: 'center', fontSize: '16px', color: '#333' }}>
                {formatDeliveryDateString(itemDeliveryDate)}
              </div>
            </div>
          )}
        </Col>
      </div>
      {additionalWarningsElement}
    </div>
  );
};

export default CostCardForm;

function getItemDeliveryDate(
  productionMode: PRODUCTION_MODES,
  result: Result,
  order: FrontOrder
): Date | null {
  if (Either.isLeft(result)) {
    return null;
  }
  const delivery = result.right.delivery;

  return getDeliveryDate(
    delivery.delay,
    new Date(),
    productionMode,
    order.shippingMode,
    order.recommendedTransporter
  );
}
