import * as React from 'react';
import { ModelOrder } from '@oyp/shared-lib';
import Button from '../../../components/buttons/Button';
import OrderShippingDetails from '../_modules/shipping-details/OrderShippingDetails';

interface OrderDisplayShippingInfoButtonProps {
  order: ModelOrder;
}

interface OrderDisplayShippingInfoButtonState {
  isDisplayingShippingInfo: boolean;
}

class OrderDisplayShippingInfoButton extends React.Component<
  OrderDisplayShippingInfoButtonProps,
  OrderDisplayShippingInfoButtonState
> {
  state = {
    isDisplayingShippingInfo: false,
  };

  render() {
    const { order } = this.props;
    const { isDisplayingShippingInfo } = this.state;

    return (
      <React.Fragment>
        <Button
          look="primary"
          size="sm"
          style={{ margin: '10px auto 0 auto', display: 'block' }}
          onClick={() => this.setState({ isDisplayingShippingInfo: true })}
        >
          SUIVRE MES COLIS
        </Button>
        <OrderShippingDetails
          currentOrder={isDisplayingShippingInfo ? order : null}
          onClose={() => this.setState({ isDisplayingShippingInfo: false })}
        />
      </React.Fragment>
    );
  }
}

export default OrderDisplayShippingInfoButton;
