import * as Either from 'fp-ts/lib/Either';
import * as React from 'react';
import {
  BUNDLE_TYPES,
  CalculatorFinishedProductFormPermanentResources,
  CalculatorMaterial,
  CalculatorRequestData,
  IMAGERY_COUNT_TYPES,
  InprintCalculator,
  ModelInputFinishedProduct,
  ORDER_ITEM_TYPES,
} from '@oyp/shared-lib';
import { Card, SelectOption, form } from '@oyp/shared-components';
import { FrontCalculator } from '../../../components/Calculator';
import { finishedProductFileApiEndpoint } from '../module';
import {
  getDefaultBundleTypeForProduct,
  getDefaultMaterialForPrint,
  getMaterialsForFinishedProductWithPrint,
} from '../calculator_helper';
import { getRemoteMessages } from '../../../validation';
import AddToCartButton from '../../../components/AddToCartButton';
import AmalgamumTooltip from '../../../components/AmalgamumTooltip';
import Button from '../../../../../components/buttons/Button';
import CardActionFooter from '../../../../../components/CardActionFooter';
import CostCardForm from '../../../components/CostCardForm';
import FilterButton from '../../../components/FilterButton';
import GlobalCalculateMessages from '../../../components/GlobalCalculateMessages';
import InputGroup from '../../../../../components/form/InputGroup';
import Markdown from 'react-markdown';
import OrderItemFields from '../../../components/OrderItemFields';
import ProductChooser from './ProductChooser';
import ResetButton from '../../../components/ResetButton';
import SelectGroup from '../../../../../components/form/SelectGroup';
import TechnicalInformation from './TechnicalInformation';
import styled from 'styled-components';

const { getOnValueChange, getOnEventChange } = form;

const CalculatorForm: React.SFC<FrontCalculator.FormProps<
  CalculatorFinishedProductFormPermanentResources,
  CalculatorRequestData,
  InprintCalculator.SlimResult
>> = props => {
  const {
    permanentResources,
    initialRequestData,
    requestData,
    result,
    isEditingItem,
    isLoading,
    onCalculate,
    onAddToCart,
    onReset,
    cartProps,
  } = props;

  const { products, flexibleMaterials, rigidMaterials, materialTypes } = permanentResources;

  const { quantity, imageryCount, bundleType, imageryCountType } = requestData;

  const selectedProduct = products.find(product => product.id === requestData.finishedProductId);
  const selectedMaterialType = selectedProduct.category;

  const materials = selectedProduct.print
    ? getMaterialsForFinishedProductWithPrint(
        flexibleMaterials,
        rigidMaterials,
        selectedProduct.print
      )
    : [];

  const errorsByProperty = getRemoteMessages(Either.isLeft(result) ? result.left.errors : []);
  const warningsByProperty = getRemoteMessages(Either.isRight(result) ? result.right.warnings : []);

  const materialOptions = selectedProduct.print ? makeMaterialOptions(materials) : [];

  /*const onMaterialChange = getUpdateRequestDataFromItemId(
    'materialId',
    requestData,
    materials,
    onCalculate
  );*/

  const onMaterialChange = getOnValueChange('materialId', requestData, onCalculate);

  const onBundleTypeChange = getOnValueChange('bundleType', requestData, onCalculate);
  const onProductChange = (product: ModelInputFinishedProduct) => {
    onCalculate({
      ...requestData,
      finishedProductId: product.id,
      bundleType: getDefaultBundleTypeForProduct(product),
      materialId: (
        getDefaultMaterialForPrint(product.print, flexibleMaterials, rigidMaterials) ||
        ({} as CalculatorMaterial)
      ).id,
    });
  };

  const imageryCountTypeOptions = [
    { value: IMAGERY_COUNT_TYPES.SAME, label: 'Quantités identiques' },
    { value: IMAGERY_COUNT_TYPES.DIFFERENT, label: 'Quantités différentes' },
  ];

  const onImageryCountTypeChange = getOnValueChange('imageryCountType', requestData, onCalculate);
  const disableImageryCountType = bundleType === BUNDLE_TYPES.STRUCTURE_ONLY || !(imageryCount > 1);

  const canAddToCart =
    Object.keys(errorsByProperty).length === 1 && errorsByProperty.global.length === 0;

  return (
    <div className="form-page-container">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-8 configurator">
              <div className="row">
                <div className="col-md-12">
                  <div className="filter-container" role="group" style={{ display: 'flex' }}>
                    {materialTypes.map((materialType, index) => (
                      <FilterButton
                        key={index}
                        handleClick={category => {
                          const firstProduct = products.find(p => p.category === category);
                          const materialId = firstProduct.print
                            ? firstProduct.print.materialIds[0]
                            : null;

                          onCalculate({
                            ...initialRequestData,
                            finishedProductId: firstProduct.id,
                            materialId,
                          });
                        }}
                        filterType={{
                          ...materialType,
                          isActive: materialType.type === selectedMaterialType,
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 calculator-container">
                  <div className="row">
                    <div className="col-md-5 configurator">
                      <ProductChooser
                        products={products.filter(
                          product =>
                            product.category === selectedMaterialType &&
                            !product.isHiddenForCustomer
                        )}
                        selectedProductId={selectedProduct.id}
                        onProductSelection={onProductChange}
                      />
                    </div>

                    <div className="col-md-7 informations">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-row">
                            <InputGroup
                              name="quantity"
                              label="Quantité"
                              s={6}
                              type="number"
                              error={errorsByProperty.quantity}
                              warning={warningsByProperty.quantity}
                              onChange={getOnEventChange(
                                'quantity',
                                requestData,
                                onCalculate,
                                parseInt
                              )}
                              value={quantity}
                            />
                          </div>
                          <div className="form-row">
                            {selectedProduct.print && (
                              <>
                                <InputGroup
                                  name="imageryCount"
                                  className="amalgame"
                                  label={
                                    <span>
                                      Nb fichiers (amalgame)
                                      <AmalgamumTooltip />
                                    </span>
                                  }
                                  s={6}
                                  type="number"
                                  error={errorsByProperty.imageryCount}
                                  warning={warningsByProperty.imageryCount}
                                  onChange={getOnEventChange(
                                    'imageryCount',
                                    requestData,
                                    onCalculate,
                                    parseInt
                                  )}
                                  value={
                                    bundleType === BUNDLE_TYPES.STRUCTURE_ONLY ? 0 : imageryCount
                                  }
                                  disabled={bundleType === BUNDLE_TYPES.STRUCTURE_ONLY}
                                />

                                <SelectGroup
                                  s={6}
                                  label="Quantités par fichier"
                                  name="imageryCountType"
                                  onChange={(option: SelectOption<IMAGERY_COUNT_TYPES>) =>
                                    onImageryCountTypeChange(option.value)
                                  }
                                  value={disableImageryCountType ? null : imageryCountType}
                                  options={imageryCountTypeOptions}
                                  clearable={false}
                                  searchable={false}
                                  disabled={disableImageryCountType}
                                  placeholder={disableImageryCountType ? 'Non applicable' : ''}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      {selectedProduct.print && (
                        <ExpendableFormRow className="form-row">
                          {selectedProduct.structure && (
                            <SelectGroup
                              s={12}
                              label="Type"
                              options={bundleTypeOptions}
                              name="bundleType"
                              value={bundleType}
                              onChange={(option: SelectOption<BUNDLE_TYPES>) =>
                                onBundleTypeChange(option.value)
                              }
                              clearable={false}
                              searchable={false}
                            />
                          )}

                          {/*selectedProduct.structure && (
                            <SelectGroup
                              s={12}
                              label="BAT"
                              options={[
                                { value: false, label: 'Non' },
                                { value: true, label: 'Oui' },
                              ]}
                              name="passedForPrint"
                              value={passedForPrint}
                              onChange={(option: any) => onPassedForPrintChange(option.value)}
                              clearable={false}
                              searchable={false}
                            />
                          )*/}
                        </ExpendableFormRow>
                      )}

                      {selectedProduct.print && (
                        <div className="form-row">
                          <SelectGroup
                            s={12}
                            label="Matière"
                            options={materialOptions}
                            name="materialId"
                            value={requestData.materialId}
                            onChange={(option: SelectOption<string>) =>
                              onMaterialChange(option.value)
                            }
                            clearable={false}
                            searchable={false}
                            disabled={bundleType === BUNDLE_TYPES.STRUCTURE_ONLY}
                          />
                        </div>
                      )}

                      <div className="row">
                        <div className="col-md-12">
                          <OrderItemFields
                            requestData={requestData}
                            errorsByProperty={errorsByProperty}
                            warningsByProperty={warningsByProperty}
                            onCalculate={onCalculate}
                          />
                        </div>
                      </div>

                      {selectedProduct.description && (
                        <div>
                          <h5>Description</h5>
                          <hr />
                          <br />
                          <Markdown source={selectedProduct.description} />
                        </div>
                      )}

                      {selectedProduct.files.length > 0 && (
                        <div>
                          <h5>Documents</h5>
                          <hr />
                          <br />
                          <div>
                            {selectedProduct.files.map((file, index) => (
                              <Button
                                key={index}
                                look="primary"
                                size="sm"
                                onClick={() =>
                                  window.open(
                                    `${finishedProductFileApiEndpoint}/${file.id}/download`,
                                    '_blank'
                                  )
                                }
                              >
                                {file.label}
                              </Button>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 informations">
              <Card className="height100 calculator-product-informations">
                <div className="container">
                  <TechnicalInformation
                    requestData={requestData}
                    deliveryData={Either.isRight(result) ? result.right.delivery : null}
                  />

                  <CostCardForm
                    productionMode={requestData.productionMode}
                    calculatorResult={result}
                    cartProps={cartProps}
                    isLoading={isLoading}
                    additionalWarningsElement={
                      Either.isRight(result) && (
                        <GlobalCalculateMessages
                          messages={getRemoteMessages(result.right.warnings || []).global}
                          manufacture={result.right.manufacture}
                          requestData={requestData}
                          className="warning"
                          isLoading={isLoading}
                        />
                      )
                    }
                  />
                </div>
                <CardActionFooter>
                  <ResetButton disabled={isLoading} reset={onReset} />

                  <AddToCartButton
                    disabled={!canAddToCart || isLoading}
                    isEditingItem={isEditingItem}
                    onAddToCart={() =>
                      onAddToCart({
                        orderItemType: ORDER_ITEM_TYPES.CALCULATOR,
                        manualReference: requestData.manualReference,
                        description: requestData.description,
                        requestData,
                      })
                    }
                  />
                </CardActionFooter>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatorForm;

function makeMaterialOptions(materials: CalculatorMaterial[]) {
  return materials.map(material => ({
    value: material.id,
    label: material.name,
  }));
}

const BUNDLE_TYPES_TRANSLATIONS = {
  [BUNDLE_TYPES.FULL]: `Kit complet`,
  [BUNDLE_TYPES.MATERIAL_ONLY]: `Impression seule`,
  [BUNDLE_TYPES.STRUCTURE_ONLY]: `Structure seule`,
};

const bundleTypeOptions = [
  BUNDLE_TYPES.FULL,
  BUNDLE_TYPES.MATERIAL_ONLY,
  BUNDLE_TYPES.STRUCTURE_ONLY,
].map(bundleType => ({
  value: bundleType,
  label: BUNDLE_TYPES_TRANSLATIONS[bundleType],
}));

const ExpendableFormRow = styled.div`
  > div {
    flex: 1;
  }
`;
