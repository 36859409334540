import './AccountContainer.scss';
import * as React from 'react';
import { Card, restApiNetwork } from '@oyp/shared-components';
import { ModelUser, USER_TITLES } from '@oyp/shared-lib';
import { UserContext, UserContextInterface } from '../../../UserContext';
import { toast } from 'mdbreact';
import { userApiEndpoint } from '../module';
import Loader from '../../../components/Loader';
import ResellerUserForm from '../components/ResellerUserForm';
import ResellerUserFormPassword from '../components/ResellerUserFormPassword';
import SideNavigationMenu, { NAVIGATION_LINK_IDS } from '../../../components/SideNavigationMenu';
import StyledLink from '../../../components/StyledLink';

const { fetchOne, saveOne } = restApiNetwork;

interface AccountContainerState {
  isLoading: boolean;
  hasLoadingError: boolean;
  resellerUser: any;
  reseller: any;
}

class AccountContainer extends React.Component {
  state: AccountContainerState = {
    resellerUser: {
      firstName: '',
      lastName: '',
      email: '',
      enabled: true,
      title: USER_TITLES.MR,
      password: '',
      passwordConfirmation: '',
    },
    reseller: {},
    isLoading: true,
    hasLoadingError: false,
  };

  context: UserContextInterface;

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const { userId, reseller } = this.context;

    const resellerUser = await fetchOne<ModelUser>(userApiEndpoint, userId);

    this.setState({
      reseller,
      resellerUser: { ...resellerUser, password: '', passwordConfirmation: '' },
      isLoading: false,
      hasLoadingError: false,
    });
  }

  async saveResellerUser(user: ModelUser) {
    await saveOne(userApiEndpoint, user);
    toast.success('Informations enregistrées', { autoClose: 2000 });
  }

  render() {
    const { reseller, isLoading, hasLoadingError } = this.state;

    return (
      <div>
        <div className="reseller-app-page-title">MON COMPTE</div>
        <div className="user-container user-infos-container">
          <div className="row ml-0 mr-0">
            <div className="col-md-3 laptop-padding-right">
              <SideNavigationMenu activeLink={NAVIGATION_LINK_IDS.ACCOUNT} />
            </div>
            <div className="col-md-9 laptop-padding-left">
              <Card className="main-card user-infos" title={'MES INFORMATIONS PERSONNELLES'}>
                {isLoading || hasLoadingError ? (
                  <Loader error={hasLoadingError} />
                ) : (
                  <div className="row ml-0 mr-0">
                    <div className="col-md-8">
                      <Card
                        className="section-wrapper bg-primary-card-form user-informations-container"
                        title={'INFORMATIONS'}
                      >
                        <ResellerUserForm
                          saveResellerUser={this.saveResellerUser}
                          resellerUser={this.state.resellerUser}
                        />
                      </Card>
                      <Card
                        className="section-wrapper bg-primary-lighter-card-form user-password-container"
                        title={'MODIFIER MON MOT DE PASSE'}
                      >
                        <ResellerUserFormPassword
                          saveResellerUser={this.saveResellerUser}
                          resellerUser={this.state.resellerUser}
                        />
                      </Card>
                    </div>
                    <div className="col-md-4">
                      <Card
                        className="section-wrapper bg-primary-card-form user-address-container"
                        title={'ADRESSE DE FACTURATION'}
                      >
                        <div className="col-md-12">
                          <address>
                            {reseller.companyName.toUpperCase()}
                            <br />
                            {reseller.street.toUpperCase()}
                            <br />
                            {reseller.complement.length > 0 ? (
                              <>
                                {reseller.complement.toUpperCase()}
                                <br />
                              </>
                            ) : null}
                            {reseller.postalCode.toUpperCase()}
                            <br />
                            {reseller.city.toUpperCase()}
                          </address>
                          <p>
                            Si vous souhaitez modifier votre adresse de facturation, merci de bien
                            vouloir nous contacter à l&apos;adresse{' '}
                            <StyledLink as="a" href="mailto:contact@in-print.net">
                              contact@in-print.net
                            </StyledLink>
                          </p>
                        </div>
                      </Card>
                    </div>
                  </div>
                )}
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AccountContainer.contextType = UserContext;

export default AccountContainer;
