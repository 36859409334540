"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var t = require("io-ts");

var io_1 = require("../../utils/io");

var fromNullable_1 = require("io-ts-types/lib/fromNullable"); // Enums
// Attention, l'ordre est important !


var ORDER_ITEM_STATUSES;

(function (ORDER_ITEM_STATUSES) {
  ORDER_ITEM_STATUSES["CREATED"] = "ORDER_ITEM_STATUS_CREATED";
  ORDER_ITEM_STATUSES["CONFIRMED"] = "ORDER_ITEM_STATUS_CONFIRMED";
  ORDER_ITEM_STATUSES["FILES_SENT"] = "ORDER_ITEM_STATUS_FILES_SENT";
  ORDER_ITEM_STATUSES["FILES_REFUSED"] = "ORDER_ITEM_STATUS_FILES_REFUSED";
  ORDER_ITEM_STATUSES["VALIDATED_FOR_PRODUCTION"] = "ORDER_ITEM_STATUS_VALIDATED_FOR_PRODUCTION";
  ORDER_ITEM_STATUSES["PENDING_PRINT"] = "ORDER_ITEM_STATUS_PENDING_PRINT";
  ORDER_ITEM_STATUSES["PRINTED"] = "ORDER_ITEM_STATUS_PRINTED";
  ORDER_ITEM_STATUSES["LAMINATED"] = "ORDER_ITEM_STATUS_LAMINATED";
  ORDER_ITEM_STATUSES["PACKAGED"] = "ORDER_ITEM_STATUS_PACKAGED";
  ORDER_ITEM_STATUSES["SHIPPED"] = "ORDER_ITEM_STATUS_SHIPPED";
  ORDER_ITEM_STATUSES["DELIVERED"] = "ORDER_ITEM_STATUS_DELIVERED";
})(ORDER_ITEM_STATUSES = exports.ORDER_ITEM_STATUSES || (exports.ORDER_ITEM_STATUSES = {})); // TODO transformer en "BILLING_COMPANIES"


var PLATFORM_CODES;

(function (PLATFORM_CODES) {
  PLATFORM_CODES["INPRINT"] = "PlatformCodeInPrint";
  PLATFORM_CODES["PRINTDOC"] = "PlatformCodePrintDoc";
  PLATFORM_CODES["PPC"] = "PLATFORM_CODE_PPC";
  PLATFORM_CODES["PRINT_CENTER"] = "PLATFORM_CODE_PRINT_CENTER";
  PLATFORM_CODES["ROLLUP"] = "PLATFORM_CODE_ROLLUP";
})(PLATFORM_CODES = exports.PLATFORM_CODES || (exports.PLATFORM_CODES = {}));

exports.PlatformCodeD = io_1.createEnum(PLATFORM_CODES, 'PLATFORM_CODES');
var ORDER_DELIVERY_MODES;

(function (ORDER_DELIVERY_MODES) {
  ORDER_DELIVERY_MODES["FIXED_DATE"] = "ORDER_DELIVERY_MODE_FIXED_DATE";
  ORDER_DELIVERY_MODES["GROUP_WITH_RECALCULATION"] = "ORDER_DELIVERY_MODE_GROUP_WITH_RECALCULATION";
})(ORDER_DELIVERY_MODES = exports.ORDER_DELIVERY_MODES || (exports.ORDER_DELIVERY_MODES = {}));

var BILLING_MODES;

(function (BILLING_MODES) {
  BILLING_MODES["IMMEDIATE"] = "BILLING_MODE_IMMEDIATE";
  BILLING_MODES["MONTHLY_TRANSFER"] = "BILLING_MODE_MONTHLY_TRANSFER";
  BILLING_MODES["MONTHLY_DEBIT"] = "BILLING_MODE_MONTHLY_DEBIT";
  BILLING_MODES["INTERNAL"] = "BILLING_MODE_INTERNAL";
})(BILLING_MODES = exports.BILLING_MODES || (exports.BILLING_MODES = {}));

var PAYMENT_METHODS;

(function (PAYMENT_METHODS) {
  PAYMENT_METHODS["STRIPE"] = "PAYMENT_METHOD_STRIPE";
})(PAYMENT_METHODS = exports.PAYMENT_METHODS || (exports.PAYMENT_METHODS = {}));

var PAYMENT_STATUSES;

(function (PAYMENT_STATUSES) {
  PAYMENT_STATUSES["NEW"] = "PAYMENT_STATUS_NEW";
  PAYMENT_STATUSES["PROCESSING"] = "PAYMENT_STATUS_PROCESSING";
  PAYMENT_STATUSES["PROCESSED"] = "PAYMENT_STATUS_PROCESSED";
  PAYMENT_STATUSES["ERROR"] = "PAYMENT_STATUS_ERROR";
})(PAYMENT_STATUSES = exports.PAYMENT_STATUSES || (exports.PAYMENT_STATUSES = {}));

var INVOICE_STATUSES;

(function (INVOICE_STATUSES) {
  INVOICE_STATUSES["NEW"] = "INVOICE_STATUS_NEW";
  INVOICE_STATUSES["SENT"] = "INVOICE_STATUS_SENT";
  INVOICE_STATUSES["PAID"] = "INVOICE_STATUS_PAID";
  INVOICE_STATUSES["REMINDED"] = "INVOICE_STATUS_REMINDED";
})(INVOICE_STATUSES = exports.INVOICE_STATUSES || (exports.INVOICE_STATUSES = {}));

var ORDER_ITEM_TYPES;

(function (ORDER_ITEM_TYPES) {
  ORDER_ITEM_TYPES["CALCULATOR"] = "Calculator"; //SAXOPRINT = 'Saxoprint',

  ORDER_ITEM_TYPES["SMART_LABEL"] = "SmartLabel";
})(ORDER_ITEM_TYPES = exports.ORDER_ITEM_TYPES || (exports.ORDER_ITEM_TYPES = {}));

var ORDER_CHANGE_NOTIFICATION_TYPE;

(function (ORDER_CHANGE_NOTIFICATION_TYPE) {
  ORDER_CHANGE_NOTIFICATION_TYPE["SHIPPING_DATE"] = "ORDER_CHANGE_NOTIFICATION_TYPE_SHIPPING_DATE";
  ORDER_CHANGE_NOTIFICATION_TYPE["ORDER_COMMENT"] = "ORDER_CHANGE_NOTIFICATION_TYPE_ORDER_COMMENT";
  ORDER_CHANGE_NOTIFICATION_TYPE["ADDRESS"] = "ORDER_CHANGE_NOTIFICATION_TYPE_ADDRESS";
  ORDER_CHANGE_NOTIFICATION_TYPE["ORDER_ITEM_DESCRIPTION"] = "ORDER_CHANGE_NOTIFICATION_TYPE_ORDER_ITEM_DESCRIPTION";
  ORDER_CHANGE_NOTIFICATION_TYPE["ORDER_ITEM_COMMENT"] = "ORDER_CHANGE_NOTIFICATION_TYPE_ORDER_ITEM_COMMENT";
})(ORDER_CHANGE_NOTIFICATION_TYPE = exports.ORDER_CHANGE_NOTIFICATION_TYPE || (exports.ORDER_CHANGE_NOTIFICATION_TYPE = {}));
/********** Opération order *********/


exports.CreateOrderAddressData = t.intersection([t.type({
  address: t.type({
    street: t.string,
    city: t.string,
    postalCode: t.string,
    countryCode: t.string,
    additionalInformation: fromNullable_1.fromNullable(t.string, ''),
    companyName: fromNullable_1.fromNullable(t.string, ''),
    complement: fromNullable_1.fromNullable(t.string, ''),
    email: fromNullable_1.fromNullable(t.string, ''),
    name: fromNullable_1.fromNullable(t.string, ''),
    phoneNumber: fromNullable_1.fromNullable(t.string, '')
  }),
  orderId: t.string,
  platformCode: exports.PlatformCodeD,
  manualShippingReference: fromNullable_1.fromNullable(t.string, '')
}), t.partial({
  // Seulement pour le PDC
  saveInAddressBook: t.boolean
})]);
/* eslint-enable @typescript-eslint/no-empty-interface */
// Renouvellement

var ORDER_ITEM_RENEWAL_FAILURE_REASONS;

(function (ORDER_ITEM_RENEWAL_FAILURE_REASONS) {
  ORDER_ITEM_RENEWAL_FAILURE_REASONS["INVALID"] = "ORDER_ITEM_RENEWAL_FAILURE_REASON_INVALID";
  ORDER_ITEM_RENEWAL_FAILURE_REASONS["NO_LONGER_AVAILABLE"] = "ORDER_ITEM_RENEWAL_FAILURE_REASON_NO_LONGER_AVAILABLE";
})(ORDER_ITEM_RENEWAL_FAILURE_REASONS = exports.ORDER_ITEM_RENEWAL_FAILURE_REASONS || (exports.ORDER_ITEM_RENEWAL_FAILURE_REASONS = {}));