"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var shared_lib_1 = require("@oyp/shared-lib");

exports.translateValidationError = function (validationError) {
  switch (validationError) {
    case shared_lib_1.VALIDATION_ERROR_MESSAGES.INVALID_LENGTH:
      return "Le nombre de caract\xE8res est incorrect";

    case shared_lib_1.VALIDATION_ERROR_MESSAGES.INVALID_OPTION:
      return "Option invalide";

    case shared_lib_1.VALIDATION_ERROR_MESSAGES.REQUIRED:
      return "Requis";

    case shared_lib_1.VALIDATION_ERROR_MESSAGES.MUST_BE_UNIQUE:
      return "La valeur est d\xE9j\xE0 utilis\xE9e";

    case 'email must be unique':
      return "L'adresse email est d\xE9j\xE0 utilis\xE9e";

    default:
      return filterNotNullMessage(validationError);
  }
};

exports.validateValue = function (validate, value) {
  if (Array.isArray(validate)) {
    return validate.reduce(function (completeErrorMessage, validateFunction) {
      var currentErrorMessage = validateFunction(value);

      if (currentErrorMessage) {
        if (!completeErrorMessage) {
          return currentErrorMessage;
        }

        return "".concat(completeErrorMessage, ", ").concat(currentErrorMessage);
      }

      return completeErrorMessage;
    }, '');
  }

  return validate(value);
};

function validateForm(formData, constraints) {
  return Object.keys(constraints).reduce(function (validationObject, propName) {
    var validationError = exports.validateValue(constraints[propName], formData[propName]);

    if (validationError) {
      validationObject[propName] = validationError;
    }

    return validationObject;
  }, {});
}

exports.validateForm = validateForm;

var filterNotNullMessage = function filterNotNullMessage(validationError) {
  if (validationError.indexOf("cannot be null") > -1) {
    return exports.translateValidationError(shared_lib_1.VALIDATION_ERROR_MESSAGES.REQUIRED);
  }

  return validationError;
};