import {
  CALCULATORS,
  CalculatorFlexibleFormPermanentResources,
  CalculatorRequestData,
  InprintCalculator,
  ModelOrderItem,
  ORDER_ITEM_TYPES,
  OrderItemClientSource,
} from '@oyp/shared-lib';
import { CartProps } from '../../../types';
import { fetchFlexibleCalculationResult } from '../actions';
import {
  fetchFlexiblePermanentResources,
  getFlexibleInitialRequestData,
} from '../calculator_helper';
import { onFlexibleRequestDataChange } from '@oyp/shared-components';
import Calculator from '../../../components/Calculator';
import CalculatorForm from './CalculatorForm';
import React from 'react';

const FlexibleCalculator: React.FC<{
  isHidden: boolean;
  editedItem?: ModelOrderItem;
  cartProps: CartProps;
  isLoading: boolean;
  onSaveOrderItem: (orderItem: OrderItemClientSource) => void;
}> = props => {
  const { editedItem } = props;
  const editedItemRequestData =
    editedItem &&
    editedItem.orderItemType === ORDER_ITEM_TYPES.CALCULATOR &&
    editedItem.requestData.calculator === CALCULATORS.FLEXIBLE
      ? editedItem.requestData
      : null;

  return (
    !props.isHidden && (
      <Calculator<
        CalculatorFlexibleFormPermanentResources,
        CalculatorRequestData,
        InprintCalculator.SlimResult
      >
        isHidden={props.isHidden}
        isParentLoading={props.isLoading}
        editedItemRequestData={editedItemRequestData}
        onSaveOrderItem={props.onSaveOrderItem}
        cartProps={props.cartProps}
        FormType={CalculatorForm}
        getInitialRequestData={(permanentResources: CalculatorFlexibleFormPermanentResources) => ({
          ...getFlexibleInitialRequestData(permanentResources),
          productionMode: props.cartProps.productionMode,
        })}
        onRequestDataChange={onFlexibleRequestDataChange}
        fetchPermanentResources={fetchFlexiblePermanentResources}
        fetchCalculateResult={fetchFlexibleCalculationResult}
      />
    )
  );
};

export default FlexibleCalculator;
