"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function getUserDisplayName(user) {
  var firstName = user.firstName,
      lastName = user.lastName,
      email = user.email;
  return firstName || lastName ? "".concat(firstName, " ").concat(lastName) : email;
}

exports.getUserDisplayName = getUserDisplayName;

function getCustomerDisplayName(customer) {
  var firstName = customer.firstName,
      lastName = customer.lastName,
      company = customer.company;
  return "".concat(firstName, " ").concat(lastName).concat(company ? " (".concat(company.name, ")") : null);
}

exports.getCustomerDisplayName = getCustomerDisplayName;

function getStatusDisplayName(status) {
  var label = status.label;
  return "".concat(label);
}

exports.getStatusDisplayName = getStatusDisplayName;