"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function capitalize(str) {
  return "".concat(str.charAt(0).toUpperCase()).concat(str.slice(1));
}

exports.capitalize = capitalize;