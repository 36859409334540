"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

require("./UploadZone.scss");

var React = require("react");

var react_materialize_1 = require("react-materialize");

var RejectedFileItem_1 = require("./RejectedFileItem");

var UploadContainer_1 = require("./UploadContainer");

var UploadFileItem_1 = require("./UploadFileItem");

var UploadingFileItem_1 = require("./UploadingFileItem");

exports.UploadZone = function (props) {
  var _props$files = props.files,
      files = _props$files === void 0 ? [] : _props$files,
      _props$filesInProgres = props.filesInProgress,
      filesInProgress = _props$filesInProgres === void 0 ? [] : _props$filesInProgres,
      _props$filesRejected = props.filesRejected,
      filesRejected = _props$filesRejected === void 0 ? [] : _props$filesRejected,
      _props$onFileUpload = props.onFileUpload,
      onFileUpload = _props$onFileUpload === void 0 ? function () {} : _props$onFileUpload,
      _props$onFileDelete = props.onFileDelete,
      onFileDelete = _props$onFileDelete === void 0 ? function () {} : _props$onFileDelete,
      _props$onFileDownload = props.onFileDownload,
      onFileDownload = _props$onFileDownload === void 0 ? function () {} : _props$onFileDownload,
      _props$onRejectedFile = props.onRejectedFileDismiss,
      onRejectedFileDismiss = _props$onRejectedFile === void 0 ? function () {} : _props$onRejectedFile,
      isOrderStatus = props.isOrderStatus,
      isLimitedToOneFile = props.isLimitedToOneFile;
  return React.createElement(UploadContainer_1.UploadContainer, {
    className: "upload-zone-container",
    dragEnabled: true,
    files: files,
    filesInProgress: filesInProgress,
    filesRejected: filesRejected,
    onFileUpload: onFileUpload,
    onFileDelete: onFileDelete,
    onFileDownload: onFileDownload,
    onRejectedFileDismiss: onRejectedFileDismiss,
    UploadedFileComponent: UploadFileItem_1.UploadFileItem,
    PendingUploadFileComponent: UploadingFileItem_1.UploadingFileItem,
    RejectedFileComponent: RejectedFileItem_1.RejectedFileItem,
    overlayContent: React.createElement("div", null, "D\xE9posez vos fichiers dans cette zone."),
    fileChooserContent: React.createElement("div", {
      className: "flex-content"
    }, React.createElement("div", null, React.createElement(react_materialize_1.Icon, null, "file_upload"), React.createElement("div", null, "Cliquer pour choisir"), React.createElement("div", {
      className: "sub-title"
    }, "ou glisser/d\xE9poser"))),
    isOrderStatus: isOrderStatus,
    isLimitedToOneFile: isLimitedToOneFile
  });
};