import * as React from 'react';
import { CustomerFileWithVersions } from '../../../files/actions';
import { TooltipContainer } from '@oyp/shared-components';
import { UPLOAD_STATUSES } from './constants';
import { UploadingCustomerFile } from '../../../files/reducers';
import { fileExtensionIcon, truncateFileNameIfNeeded } from './utils';
import InvalidIcon from './img/icon-library/invalide.svg';
import UploadStatusChip from './UploadStatusChip';
import styles from './FileLine.module.scss';

interface FileLineProps {
  file: InputFile;
  uploadStatus: UPLOAD_STATUSES;
  buttons?: JSX.Element;
}

type InputFile = CustomerFileWithVersions | UploadingCustomerFile;

const isUploadingCustomerFile = (file: InputFile): file is UploadingCustomerFile => {
  return file.hasOwnProperty('percentComplete');
};

const FileLine: React.SFC<FileLineProps> = props => {
  const { file, uploadStatus, buttons } = props;

  const percentComplete = isUploadingCustomerFile(file)
    ? getPercentComplete(file.percentComplete, uploadStatus)
    : 0;
  const fileName = isUploadingCustomerFile(file) ? file.file.name : file.name;

  const icon = uploadStatus === UPLOAD_STATUSES.ERROR ? InvalidIcon : fileExtensionIcon(fileName);

  const mainClassNames = [styles['file-line']];
  const barStyles = [styles.bar];

  if (props.uploadStatus === UPLOAD_STATUSES.ERROR) {
    mainClassNames.push(styles['error-line']);
    barStyles.push(styles['bar-error']);
  }

  const fileSizeInBytes = isUploadingCustomerFile(file)
    ? file.file.size
    : file.versions[0].sizeInBytes;

  return (
    <div className={mainClassNames.join(' ')}>
      <div className={styles.icon}>
        {!isUploadingCustomerFile(file) && file.thumbnailUrl ? (
          <TooltipContainer tooltipContent={<img src={file.thumbnailUrl} />} place="right">
            <img src={file.thumbnailUrl} className={styles['icon-img']} height={48} />
          </TooltipContainer>
        ) : (
          <img src={icon} />
        )}
      </div>
      <div className={styles['title-and-bar']}>
        <div>
          <div className={styles['title-and-buttons']}>
            <div className={styles.title}>
              <TooltipContainer tooltipContent={fileName} place="top">
                {' '}
                <UploadStatusChip status={uploadStatus} className={styles['status-chip']} />{' '}
                <span className={styles['file-name']}> {truncateFileNameIfNeeded(fileName)}</span>
                {uploadStatus === UPLOAD_STATUSES.PENDING && (
                  <span className={styles['neutral-message']}>
                    Téléchargement : {percentComplete} %
                  </span>
                )}
                {uploadStatus === UPLOAD_STATUSES.SUCCESS && (
                  <span className={styles['neutral-message']}>
                    {getFileSizeWithUnit(fileSizeInBytes)}
                  </span>
                )}
              </TooltipContainer>
            </div>
            <div className={styles.buttons}>{buttons}</div>
          </div>
        </div>

        <div className={barStyles.join(' ')}>
          {uploadStatus === UPLOAD_STATUSES.ERROR ? (
            <span className={styles['error-message']}>Type de fichier invalide</span>
          ) : (
            <div
              className="bg-primary-darker"
              style={{ width: percentComplete + '%', height: '3px' }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FileLine;

function getPercentComplete(percentComplete: number, uploadStatus: UPLOAD_STATUSES) {
  if (uploadStatus === UPLOAD_STATUSES.SUCCESS) {
    return 100;
  }

  const value = isNaN(percentComplete) ? 0 : Math.round(percentComplete * 100);

  return value > 100 ? 100 : value;
}

function getFileSizeWithUnit(sizeInBytes: number) {
  if (sizeInBytes < 1000000) {
    return `${Math.round(sizeInBytes / 1000)} Ko`;
  }

  return `${Math.round(sizeInBytes / 1000000)} Mo`;
}
