import * as React from 'react';
import { ModelReseller } from '@oyp/shared-lib';

export interface UserContextInterface {
  userId: string;
  reseller: ModelReseller;
  resellerUserId: string;
  token: string;
  startupAppVersion?: string;
}

export const defaultUserContext: UserContextInterface = {
  userId: null,
  reseller: null,
  resellerUserId: null,
  token: null,
};

export const UserContext = React.createContext(defaultUserContext);
