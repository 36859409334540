"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
}); // Enums

var USER_ROLES;

(function (USER_ROLES) {
  USER_ROLES["ADMIN"] = "ROLE_ADMIN";
  USER_ROLES["SUPER_ADMIN"] = "ROLE_SUPER_ADMIN";
  USER_ROLES["USER"] = "ROLE_USER";
  USER_ROLES["RESELLER_USER"] = "ROLE_RESELLER_USER";
})(USER_ROLES = exports.USER_ROLES || (exports.USER_ROLES = {}));

var USER_GROUPS;

(function (USER_GROUPS) {
  USER_GROUPS["RESELLER_SALES_CONTACT"] = "RESELLER_SALES_CONTACT"; // Groupe PAO

  USER_GROUPS["DTP_OPERATOR"] = "DTP_OPERATOR";
})(USER_GROUPS = exports.USER_GROUPS || (exports.USER_GROUPS = {}));

var USER_SCOPES;

(function (USER_SCOPES) {
  USER_SCOPES["PROD_CENTER"] = "USER_SCOPE_PROD_CENTER";
})(USER_SCOPES = exports.USER_SCOPES || (exports.USER_SCOPES = {}));

var USER_TITLES;

(function (USER_TITLES) {
  USER_TITLES["MR"] = "TITLE_MR";
  USER_TITLES["MRS"] = "TITLE_MRS";
})(USER_TITLES = exports.USER_TITLES || (exports.USER_TITLES = {}));

var HASH_ALGORITHMS;

(function (HASH_ALGORITHMS) {
  HASH_ALGORITHMS["SHA512"] = "ALGORITHM_SHA512";
})(HASH_ALGORITHMS = exports.HASH_ALGORITHMS || (exports.HASH_ALGORITHMS = {}));