import * as React from 'react';
import { Link } from 'react-router-dom';
import { MDBInput } from 'mdbreact';
import { authEndpoint } from '../../../env-config';
import { restApiNetwork } from '@oyp/shared-components';
import Button from '../../../components/buttons/Button';
import logoBlanc from '../../../img/printcenter_logo-blanc.svg';
import styles from './ResetForms.module.scss';

const { useState } = React;
const { fetchWithToken } = restApiNetwork;

interface RequestPasswordResetFormState {
  isLoading?: boolean;
  errorMessage?: string;
  hasSucceeded?: boolean;
}

const RequestPasswordResetForm: React.FC<{}> = () => {
  const [state, setState] = useState(initialState);
  const { isLoading, errorMessage, hasSucceeded } = state;

  return (
    <div className="container">
      <div className="row">
        <div className="login col-md-6 col-lg-6 col-sm-8 offset-md-4 offset-md-3 offset-lg-3">
          <div className="login-logo-container">
            <Link to="/">
              <img className="logo" src={logoBlanc} alt={'logo'} />
            </Link>
          </div>

          {hasSucceeded ? (
            <div className={`alert alert-success ${styles.success} col-md-10 offset-md-1`}>
              <h4 className="alert-heading">Lien envoyé !</h4>
              Si l&apos;adresse entrée correspond à un compte PrintCenter, vous allez recevoir un
              lien de récupération de mot de passe par email.
            </div>
          ) : (
            <form onSubmit={getOnSubmit(state, setState)}>
              <h4 className={`text-center ${styles.title}`}>
                Demande de récupération de mot de passe
              </h4>

              <div className={`col-md-10 offset-md-1 ${styles.explanation}`}>
                <p className="text-center">
                  Si vous avez oublié votre mot de passe ou perdu l&apos;accès à votre compte,
                  entrez votre adresse email ci-dessous pour obtenir un lien de récupération de mot
                  de passe.
                </p>
              </div>
              <div className="col-md-10 offset-md-1">
                <label>Adresse Email</label>
                <MDBInput
                  name="email"
                  type="email"
                  className="form-control "
                  required
                  disabled={isLoading}
                />
                {errorMessage && <div className="alert alert-warning">{errorMessage}</div>}
              </div>
              <div className="login-bottom col-md-12">
                <Button
                  disabled={isLoading}
                  look="secondary"
                  type="submit"
                  className="waves-effect waves-light"
                >
                  {isLoading ? (
                    <span>Chargement...</span>
                  ) : (
                    'Envoyer un lien de récupération de mot de passe'
                  )}
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestPasswordResetForm;

const initialState: RequestPasswordResetFormState = {
  isLoading: false,
};

interface TargetWithElements extends EventTarget {
  elements: {
    email: {
      value: string;
    };
  };
}

interface FormEventWithElements extends React.FormEvent<HTMLFormElement> {
  target: TargetWithElements;
}

const getOnSubmit = (
  state: RequestPasswordResetFormState,
  setState: (state: RequestPasswordResetFormState) => void
) => async (event: FormEventWithElements) => {
  event.preventDefault();

  const elements = event.target.elements;
  const email = elements.email.value;

  setState({
    ...state,
    isLoading: true,
  });

  try {
    const response = await fetchWithToken(`${authEndpoint}/request-password-reset`, {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (response.status !== 200) {
      throw new Error();
    }

    setState({
      ...state,
      isLoading: false,
      hasSucceeded: true,
    });
  } catch (error) {
    setState({
      ...state,
      isLoading: false,
      errorMessage: 'Une erreur est survenue',
    });
  }
};
