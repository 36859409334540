import './OrderItemLabel.scss';
import * as React from 'react';
import { ModelOrderItem } from '@oyp/shared-lib';
import { ShortenedName } from '@oyp/shared-components';

interface OrderItemLabelProps {
  item: ModelOrderItem;
  onSaveItem?: (item: ModelOrderItem) => void;
  orderDetailOrientation?: string;
}

const OrderItemLabel: React.SFC<OrderItemLabelProps> = props => {
  const { item } = props;

  return (
    <div className="order-item-label">
      <ShortenedName name={item.manualReference || item.label} maxNameLength={25} />
    </div>
  );
};

export default OrderItemLabel;
