/* global process */

const isTestEnv = process.env.NODE_ENV === 'test';
export const isPreprod = process.env.REACT_APP_IS_PREPROD === 'true';

export const CENTRAL_STATION_HOST = !isTestEnv
  ? `${process.env.REACT_APP_CENTRAL_STATION_HOST}`
  : 'http://central-station.fake';

export const centralStationApiEndpoint = !isTestEnv
  ? `${process.env.REACT_APP_CENTRAL_STATION_HOST}${process.env.REACT_APP_CENTRAL_STATION_API_PREFIX}`
  : 'http://central-station.fake/api';

export const authEndpoint = !isTestEnv
  ? `${process.env.REACT_APP_CENTRAL_STATION_HOST}${process.env.REACT_APP_CENTRAL_STATION_AUTH_PREFIX}`
  : 'http://auth-host.com/auth';

export const AUTH_COOKIE_NAME = process.env.REACT_APP_AUTH_COOKIE_NAME;

export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

// En millisecondes
export const DEFAULT_DEBOUNCE_DELAY = 4;

// Feature switches

export const FEATURE_SWITCH_ORDER_RENEWAL =
  process.env.REACT_APP_FEATURE_SWITCH_ORDER_RENEWAL === 'true';
export const FEATURE_SWITCH_SMART_LABEL_FORM =
  process.env.REACT_APP_FEATURE_SWITCH_SMART_LABEL_FORM === 'true';
