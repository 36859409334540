import * as cookie from 'react-cookies';
import { DARK_GREY, PRIMARY_COLOR_LIGHTER } from '../../style_variables';
import { MDBIcon } from 'mdbreact';
import { TooltipContainer } from '@oyp/shared-components';
import { UserContext, UserContextInterface } from '../../UserContext';
import { WS_MESSAGES } from '@oyp/shared-lib';
import Markdown from 'react-markdown';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import styles from './Changelog.module.scss';
import websocket from '../../websocket';

interface ChangelogProps {
  content: string;
  versionNumber: string;
}

const CHANGELOG_COOKIE_NAME = `oyp_print_center_changelog_version`;

const Changelog: React.FC<ChangelogProps> = props => {
  const { content, versionNumber } = props;

  const lastSeenVersion = cookie.load(CHANGELOG_COOKIE_NAME);
  const isNewVersion = lastSeenVersion !== versionNumber;

  const [isOpen, setIsOpen] = useState<boolean>(isNewVersion);
  const context = useContext<UserContextInterface>(UserContext);
  const [isDisplayingUpdateReady, setIsDisplayingUpdateReady] = useState<boolean>(false);

  useEffect(() => {
    if (context.startupAppVersion) {
      const onGreet = ({ version }: { version: string }) => {
        if (version && context.startupAppVersion && context.startupAppVersion !== version) {
          setIsDisplayingUpdateReady(true);
        }
      };

      websocket.subscribe(WS_MESSAGES.GREET, onGreet);

      return () => websocket.unsubscribe(WS_MESSAGES.GREET, onGreet);
    }

    return () => {};
  }, [context.startupAppVersion]);

  const buttonClasses = [styles.button];
  if (isOpen) {
    buttonClasses.push(styles.active);
  }

  const onToggle = (newIsOpen?: boolean) => {
    cookie.save(CHANGELOG_COOKIE_NAME, versionNumber);
    setIsOpen(newIsOpen);
  };

  return (
    <>
      <TooltipContainer
        tooltipContent="Voir les mises à jour"
        tooltipLook="dark"
        className={styles['tooltip-container']}
      >
        <span className={buttonClasses.join(' ')} onClick={() => onToggle(!isOpen)}>
          <MDBIcon icon="clipboard" />
        </span>
      </TooltipContainer>
      {isDisplayingUpdateReady && <RefreshButtonContainer />}
      {isOpen && (
        <ChangelogWindow
          content={content}
          onClose={() => onToggle(false)}
          closeOnGlobalClick={!isNewVersion}
        />
      )}
    </>
  );
};

export default Changelog;

interface ChangelogWindowProps {
  content: string;
  closeOnGlobalClick?: boolean;
  onClose: () => void;
}

const ChangelogWindow: React.FC<ChangelogWindowProps> = props => {
  const { content, onClose, closeOnGlobalClick } = props;
  const windowRef = useRef(null);

  const onGlobalClick = (event: Event) => {
    if (windowRef.current && !windowRef.current.contains(event.target) && closeOnGlobalClick) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', onGlobalClick, false);

    return () => {
      document.removeEventListener('click', onGlobalClick, false);
    };
  }, []);

  return (
    <div ref={windowRef} className={`z-depth-3 ${styles.window}`}>
      <div>
        <div className={styles.title}>
          Mises à jour
          <span className={styles['close-button']} onClick={onClose}>
            <i className="material-icons">close</i>
          </span>
        </div>

        <div className={styles.content}>
          <Markdown source={content} />
        </div>
      </div>
    </div>
  );
};

const RefreshButtonContainer: React.FC = () => (
  <StyledRefreshButtonContainer className="z-depth-3" onClick={() => window.location.reload()}>
    Nouvelle version disponible !<span className="refresh-link">Cliquez ici pour rafraîchir</span>
  </StyledRefreshButtonContainer>
);

const StyledRefreshButtonContainer = styled.div`
  position: fixed;
  z-index: 11;
  bottom: 2rem;
  right: 2rem;
  padding: 1.2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 3px;
  cursor: pointer;
  background-color: ${DARK_GREY};
  color: #fff;

  .refresh-link {
    color: ${PRIMARY_COLOR_LIGHTER};
  }
`;
