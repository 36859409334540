import { ERROR_MESSAGE_VALIDATION, NetworkError, logError } from '@oyp/shared-components';
import { History } from 'history';
import { MDBModal, MDBModalBody, MDBModalHeader, toast } from 'mdbreact';
import { ModelOrder, ModelOrderItem, OrderItemRenewalFailure } from '@oyp/shared-lib';
import { checkOrderRenewalApiEndpoint, renewOrderApiEndpoint } from '../module';
import { fetchWithToken } from '@oyp/shared-components/dist/lib/rest-api/network';
import Button from '../../../components/buttons/Button';
import CardActionFooter from '../../../components/CardActionFooter';
import ManufactureInfoPanel from '../_modules/order-items/containers/ManufactureInfoPanel';
import React, { useEffect, useState } from 'react';

interface Props {
  order: ModelOrder;
  orderItems: ModelOrderItem[];
  history: History;
}

const RenewalAction: React.FC<Props> = props => {
  const { order, orderItems, history } = props;
  const [renewalFailures, setRenewalFailures] = useState<OrderItemRenewalFailure[]>(null);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchCheckRenewal(order.id).then(setRenewalFailures);
  }, []);

  if (!renewalFailures) {
    return <></>;
  }

  const toggleModal = () => setModalOpen(!isModalOpen);

  return (
    <>
      {renewalFailures.length > 0 && (
        <MDBModal isOpen={isModalOpen} toggle={toggleModal}>
          <MDBModalHeader toggle={toggleModal}>
            <div className="text-center">Renouveler la commande</div>
          </MDBModalHeader>

          <MDBModalBody>
            <p>{`Attention : 1 ou plusieurs produits ne sont plus disponibles, et ne pourront pas être renouvellés :`}</p>
            <table className="table table-borderless table-striped">
              {renewalFailures.map((renewalFailure, index) => {
                const orderItem = orderItems.find(oI => oI.id === renewalFailure.renewedFromId);

                return (
                  <tr key={index}>
                    <td>
                      <ManufactureInfoPanel
                        item={orderItem}
                        surfaceParams={{ offset: 0, place: 'right' }}
                      />
                    </td>
                    <td>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {orderItem.reference}
                        {orderItem.manualReference}
                      </div>
                    </td>
                    <td>
                      <div style={{ display: 'flex' }}>{orderItem.label}</div>
                    </td>
                  </tr>
                );
              })}
            </table>

            <div style={{ paddingTop: '77px', marginLeft: '-16px' }}>
              <CardActionFooter>
                <Button look="primary" variation="flat" sm onClick={toggleModal}>
                  Annuler
                </Button>
                <Button look="primary" sm onClick={() => renewOrder(order, history)}>
                  Valider
                </Button>
              </CardActionFooter>
            </div>
          </MDBModalBody>
        </MDBModal>
      )}

      <Button
        look="secondary"
        variation="flat"
        size="sm"
        onClick={renewalFailures.length > 0 ? toggleModal : () => renewOrder(order, history)}
      >
        Renouveler
      </Button>
    </>
  );
};

export default RenewalAction;

async function fetchCheckRenewal(orderId: string): Promise<OrderItemRenewalFailure[]> {
  return (await (await fetchWithToken(`${checkOrderRenewalApiEndpoint}/${orderId}`)).json())
    .renewalFailures;
}

async function renewOrder(order: ModelOrder, history: History) {
  try {
    const res = await fetchWithToken(`${renewOrderApiEndpoint}/${order.id}`, {
      method: 'POST',
    });

    if (res.status !== 200) {
      throw new NetworkError(ERROR_MESSAGE_VALIDATION, await res.json());
    }
    const result = (await res.json()) as { order: ModelOrder; orderItems: ModelOrderItem };

    return history.push(`/quote/${result.order.id}`);
  } catch (error) {
    logError(error);
    toast('Une erreur est survenue', 2000);
  }
}
