import * as React from 'react';
import { resellerUserPasswordValidationConstraints } from '../validation';
import { validator } from '@oyp/shared-components';
import Button from '../../../components/buttons/Button';
import CardActionFooter from '../../../components/CardActionFooter';
import InputGroup from '../../../components/form/InputGroup';

const { validateForm } = validator;

interface ResellerUserFormProps {
  resellerUser: any;
  saveResellerUser: (reseller: any) => void;
}

class ResellerUserFormPassword extends React.Component<ResellerUserFormProps> {
  state: { resellerUser: any; passwordMatch: boolean };

  constructor(props: ResellerUserFormProps) {
    super(props);

    const { resellerUser } = this.props;
    this.state = {
      resellerUser,
      passwordMatch: true,
    };
  }

  handleChange(fieldName: any, event: Event) {
    const { value: fieldValue } = event.target as HTMLInputElement;

    if (this.state.resellerUser.hasOwnProperty(fieldName)) {
      this.setState(
        {
          ...this.state,
          resellerUser: {
            ...this.state.resellerUser,
            [fieldName]: fieldValue,
          },
        },
        () => {
          const match =
            this.state.resellerUser.password === this.state.resellerUser.passwordConfirmation;

          this.setState({
            ...this.state,
            passwordMatch: match,
          });
        }
      );
    }
  }

  componentDidUpdate(prevProps: ResellerUserFormProps) {
    if (this.props !== prevProps) {
      const { resellerUser } = this.props;
      this.setState({
        ...this.state,
        resellerUser,
      });
    }
  }

  render() {
    const { saveResellerUser } = this.props;
    const { resellerUser } = this.state;

    const validationErrors: any = validateForm(
      resellerUser,
      resellerUserPasswordValidationConstraints
    );

    const isValid =
      Object.keys(validationErrors).length === 0 &&
      resellerUser.passwordConfirmation === resellerUser.password;

    return (
      <form>
        <div className="col-md-12">
          <div className="form-row">
            <InputGroup
              name="password"
              type="password"
              label="Nouveau mot de passe*"
              error={validationErrors.password}
              onChange={this.handleChange.bind(this, 'password')}
              value={resellerUser.password}
              s={6}
            />

            <InputGroup
              name="passwordConfirmation"
              type="password"
              label="Confirmation du mot de passe*"
              error={!this.state.passwordMatch ? `Les mots de passe ne correspondent pas` : null}
              onChange={this.handleChange.bind(this, 'passwordConfirmation')}
              value={resellerUser.passwordConfirmation}
              s={6}
            />
          </div>
        </div>

        <CardActionFooter>
          <Button
            look="secondary"
            disabled={!isValid}
            onClick={() => saveResellerUser(resellerUser)}
          >
            Enregistrer
          </Button>
        </CardActionFooter>
      </form>
    );
  }
}

export default ResellerUserFormPassword;
