import './SideNavigationMenu.scss';
import * as React from 'react';
import { Card } from '@oyp/shared-components';
import { Icon } from 'react-materialize';
import { MDBBadge } from 'mdbreact';
import { NavLink } from 'react-router-dom';
import { UserMenuContext, UserMenuContextInterface } from '../UserMenuContext';

export enum NAVIGATION_LINK_IDS {
  ACCOUNT = 'account',
  ORDERS_LIST = 'orders-list',
  QUOTES_LIST = 'quotes-list',
  INVOICES_LIST = 'invoices-list',
  ADDRESSES_LIST = 'addresses-list',
}

interface SideNavigationMenuProps {
  activeLink: NAVIGATION_LINK_IDS;
}

const linkList = [
  {
    label: 'MES INFORMATIONS PERSONNELLES',
    icon: 'person',
    link: '/account',
    id: NAVIGATION_LINK_IDS.ACCOUNT,
  },
  { label: 'MES COMMANDES', icon: 'list', link: '/orders', id: NAVIGATION_LINK_IDS.ORDERS_LIST },
  {
    label: 'MES DEVIS',
    icon: 'shopping_cart',
    link: '/quotes',
    id: NAVIGATION_LINK_IDS.QUOTES_LIST,
  },
  {
    label: 'MES FACTURES',
    icon: 'credit_card',
    link: '/invoices',
    id: NAVIGATION_LINK_IDS.INVOICES_LIST,
  },
  {
    label: 'MES ADRESSES',
    icon: 'place',
    link: '/addresses',
    id: NAVIGATION_LINK_IDS.ADDRESSES_LIST,
  },
];

const SideNavigation: React.FC<SideNavigationMenuProps> = props => {
  return (
    <UserMenuContext.Consumer>
      {(context: UserMenuContextInterface) => (
        <Card
          className="section-wrapper bg-primary-card-form main-card user-side-nav"
          title={'NAVIGATION'}
        >
          <div className="row ml-0 mr-0">
            {linkList.map((link, key) => (
              <MenuLink
                key={key}
                context={context}
                isActive={link.id === props.activeLink}
                link={link}
              />
            ))}
          </div>
        </Card>
      )}
    </UserMenuContext.Consumer>
  );
};

export default SideNavigation;

interface MenuLinkProps {
  context: UserMenuContextInterface;
  isActive: boolean;
  link: {
    label: string;
    icon: string;
    link: string;
    id: NAVIGATION_LINK_IDS;
  };
}

const MenuLink: React.FC<MenuLinkProps> = props => {
  const { link, context, isActive } = props;

  return (
    <div className="col-md-12 navigation-item">
      <div
        className={
          isActive ? 'active-link navigation-item-content row' : 'row navigation-item-content'
        }
      >
        <div className="col-md-12 laptop-padding-left laptop-padding-right pr-0 pl-0">
          <NavLink className="link" to={link.link}>
            <Icon>{link.icon}</Icon> <span>{link.label}</span>{' '}
            {link.id === 'orders-list' && context.invalidOrdersCount ? (
              <MDBBadge pill color="danger">
                {context.invalidOrdersCount}
              </MDBBadge>
            ) : null}
            {link.id === 'quotes-list' && context.quotesToValidateCount ? (
              <MDBBadge pill color="warning">
                {context.quotesToValidateCount}
              </MDBBadge>
            ) : null}
            {link.id === 'invoices-list' && context.lateInvoicesCount ? (
              <MDBBadge pill color="danger">
                {context.lateInvoicesCount}
              </MDBBadge>
            ) : null}
          </NavLink>
        </div>
      </div>
    </div>
  );
};
