"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function createTimer() {
  return {
    startTimestamp: new Date().getTime(),
    end: function end() {
      return new Date().getTime() - this.startTimestamp;
    }
  };
}

exports.createTimer = createTimer;