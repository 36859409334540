"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.franceCountryCode = 'FR';
exports.countries = [{
  value: 'FR',
  label: 'France'
}, {
  value: 'BE',
  label: 'Belgique'
}, {
  value: 'LU',
  label: 'Luxembourg'
}];