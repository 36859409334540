import { CalculatorLamination, CalculatorMaterial } from '@oyp/shared-lib';
import { fetchCollection } from '@oyp/shared-components/dist/lib/rest-api/network';
import { materialEndpoint as flexibleMaterialEndpoint } from './_modules/flexible/module';
import { laminationEndpoint } from './module';
import { materialEndpoint as rigidMaterialEndpoint } from './_modules/rigid/module';

export async function fetchFlexibleMaterials(criteria: any = {}) {
  return (await fetchCollection<CalculatorMaterial>(flexibleMaterialEndpoint, criteria)).data;
}

export async function fetchRigidMaterials(criteria: any = {}) {
  return (await fetchCollection<CalculatorMaterial>(rigidMaterialEndpoint, criteria)).data;
}

export async function fetchLaminations(criteria: any = {}) {
  return (await fetchCollection<CalculatorLamination>(laminationEndpoint, criteria)).data;
}

export async function fetchMaterialsAndLaminations(): Promise<{
  materials: CalculatorMaterial[];
  laminations: CalculatorLamination[];
}> {
  const [flexibleMaterials, rigidMaterials, laminations] = await Promise.all([
    fetchFlexibleMaterials(),
    fetchRigidMaterials(),
    fetchLaminations(),
  ]);

  return {
    materials: [...flexibleMaterials, ...rigidMaterials],
    laminations,
  };
}
