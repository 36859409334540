import './UploadZone.scss';
import { Icon } from 'react-materialize';
import { UploadingCustomerFile } from '../../../files/reducers';
import React from 'react';
import RejectedFileItem from './RejectedFileItem';
import UploadContainer from './UploadContainer';
import UploadFileItem from './/UploadFileItem';
import UploadingFileItem from './UploadingFileItem';

interface UploadZoneProps {
  files?: any[];
  filesInProgress?: UploadingCustomerFile[];
  filesRejected?: UploadingCustomerFile[];
  onFileUpload?: (files: any[], metaData?: any) => void;
  onFileDelete?: (file: any) => void;
  onFileDownload?: (file: any) => void;
  onRejectedFileDismiss?: (file: any) => void;
  isPlacingOrder?: boolean;
}

const UploadZone: React.SFC<UploadZoneProps> = props => {
  const {
    files = [],
    filesInProgress = [],
    filesRejected = [],
    onFileUpload = () => {},
    onFileDelete = () => {},
    onFileDownload = () => {},
    onRejectedFileDismiss = () => {},
    isPlacingOrder,
  } = props;

  return (
    <UploadContainer
      className="reseller-upload-zone-container"
      dragEnabled={true}
      files={files}
      filesInProgress={filesInProgress}
      filesRejected={filesRejected}
      onFileUpload={onFileUpload}
      onFileDelete={onFileDelete}
      onFileDownload={onFileDownload}
      onRejectedFileDismiss={onRejectedFileDismiss}
      UploadedFileComponent={UploadFileItem}
      PendingUploadFileComponent={UploadingFileItem}
      RejectedFileComponent={RejectedFileItem}
      overlayContent={<div>Déposez vos fichiers dans cette zone.</div>}
      fileChooserContent={
        <div className="flex-content">
          <div>
            <Icon>cloud_upload</Icon>
            <div className="sub-title">
              <p>
                Déposez vos fichiers ici <br />
                ou
              </p>
            </div>
          </div>
        </div>
      }
      isPlacingOrder={isPlacingOrder}
    />
  );
};

export default UploadZone;
