import * as Either from 'fp-ts/lib/Either';
import {
  CALCULATORS,
  CalculatorMaterial,
  CalculatorRequestData,
  InprintCalculator,
  ModelFinishedProductFile,
  ModelInputFinishedProduct,
  ORDER_ITEM_TYPES,
} from '@oyp/shared-lib';
import { FrontCalculator } from '../../components/Calculator';
import { fetchCalculationResult } from '../../calculator_helpers';
import { finishedProductApiEndpoint, finishedProductFileApiEndpoint } from './module';
import { materialEndpoint as flexibleMaterialApiEndpoint } from '../flexible/module';
import { restApiNetwork } from '@oyp/shared-components';
import { materialEndpoint as rigidMaterialApiEndpoint } from '../rigid/module';

const { fetchCollection, fetchOne } = restApiNetwork;

export async function fetchFinishedProductCalculationResult(
  requestData: CalculatorRequestData
): Promise<FrontCalculator.Result<InprintCalculator.SlimResult>> {
  const result = await fetchCalculationResult({
    orderItemType: ORDER_ITEM_TYPES.CALCULATOR,
    ...requestData,
  });

  return result.hasOwnProperty('errors') && result.errors.length > 0
    ? Either.left({ errors: result.errors })
    : Either.right({
        delivery: result.delivery,
        manufacture: result.manufacture,
        price: result.price,
        warnings: result.warnings,
      });
}

export async function fetchAvailableFinishedProducts(criteria: any = {}) {
  return (
    await fetchCollection<ModelInputFinishedProduct>(finishedProductApiEndpoint, {
      count: 0,
      isOutOfStock: false,
      isActive: true,
      ...criteria,
    })
  ).data;
}

export async function fetchFinishedProductFiles(productId: string) {
  return (
    await fetchCollection<ModelFinishedProductFile>(finishedProductFileApiEndpoint, {
      productId,
    })
  ).data;
}

export async function fetchOneFinishedProduct(
  productId: string
): Promise<ModelInputFinishedProduct | null> {
  return fetchOne(finishedProductApiEndpoint, productId);
}

export async function fetchMaterialForProduct(
  product: ModelInputFinishedProduct,
  materialId: string | null
): Promise<CalculatorMaterial | null> {
  if (!product.print || !materialId) {
    return null;
  }

  const materialEndpoint =
    product.print.calculator === CALCULATORS.FLEXIBLE
      ? flexibleMaterialApiEndpoint
      : rigidMaterialApiEndpoint;

  return fetchOne(materialEndpoint, materialId);
}
