import * as H from 'history';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { authEndpoint } from '../../../env-config';
import { resellerUserPasswordValidationConstraints } from '../../user/validation';
import { restApiNetwork, validator } from '@oyp/shared-components';
import { toast } from 'mdbreact';
import Button from '../../../components/buttons/Button';
import ExternalLink from '../../../components/ExternalLink';
import InputGroup from '../../../components/form/InputGroup';
import Loader from '../../../components/Loader';
import React, { useEffect, useState } from 'react';
import StyledGoBackLink from '../../../components/StyledGoBackLink';
import StyledLink from '../../../components/StyledLink';
import logoBlanc from '../../../img/printcenter_logo-blanc.svg';
import styles from './ResetForms.module.scss';

const { fetchWithToken } = restApiNetwork;
const { validateForm } = validator;

enum TokenFoundStates {
  NOT_FOUND = 'NOT_FOUND',
  FOUND = 'FOUND',
}

interface ResetPasswordFormState {
  password: string;
  passwordConfirmation: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  isTokenFound?: TokenFoundStates;
  errorMessage?: string;
  validationErrors: {
    password?: string;
    passwordConfirmation?: string;
  };
}

interface MatchParams {
  token: string;
}

const ResetPasswordForm: React.FC<RouteComponentProps<MatchParams>> = props => {
  const { token } = props.match.params;
  const [state, setState] = useState<ResetPasswordFormState>(initialState);
  const { isLoading, isSubmitting, errorMessage, validationErrors, isTokenFound } = state;

  useEffect(() => {
    if (!isTokenFound) {
      setState({ ...state, isLoading: true, isTokenFound: TokenFoundStates.FOUND });
      fetchIsTokenFound(token).then(isTF =>
        setState({ ...state, isLoading: false, isTokenFound: isTF })
      );
    }
  }, [isTokenFound]);

  // TODO remplacer avec query-string
  const isFirstTime = props.location.search.includes('first-time=true');

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="container">
      <div className="row">
        <StyledGoBackLink to="/">
          <i className="material-icons">chevron_left</i> {`Revenir à l'accueil`}
        </StyledGoBackLink>

        <div className="login col-md-6 col-lg-6 col-sm-8 offset-md-4 offset-md-3 offset-lg-3">
          <div className="login-logo-container">
            <Link to="/">
              <img className="logo" src={logoBlanc} alt={'logo'} />
            </Link>
          </div>

          {isTokenFound === TokenFoundStates.NOT_FOUND ? (
            <div>
              <p className="text-center">
                {isFirstTime
                  ? `Votre compte a déjà été activé.`
                  : `Le lien de remise à jour de mot de passe est expiré.`}
              </p>
              <p className="text-center">
                <StyledLink to="/">{`Revenir à l'accueil`}</StyledLink>
              </p>
            </div>
          ) : (
            <form onSubmit={getOnSubmit(props.history, token, state, setState)} method="POST">
              <h4 className={`text-center ${styles.title}`}>
                {isFirstTime ? `Activation du compte` : `Nouveau mot de passe`}
              </h4>

              <div className={`col-md-10 offset-md-1 ${styles.explanation}`}>
                <p className="text-center">
                  {isFirstTime && (
                    <>
                      {`Pour des raisons de sécurité, nous vous demandons de choisir vous-même le mot de passe de votre compte.`}
                      <br />
                      <br />
                    </>
                  )}
                  <b>Conseil</b> : Un mot de passe sécurisé comprend au moins 8 caractères, et
                  combine des lettres majuscules et minuscules, des chiffres et des caractères
                  spéciaux (par ex. &, $, *).
                </p>
              </div>
              <div className="col-md-10 offset-md-1">
                <InputGroup
                  name="password"
                  type="password"
                  label="Mot de passe"
                  required
                  disabled={isSubmitting}
                  error={validationErrors.password}
                  value={state.password}
                  onChange={event => setState({ ...state, password: event.target.value })}
                />
                <InputGroup
                  name="passwordConfirmation"
                  type="password"
                  label="Confirmation du mot de passe"
                  required
                  disabled={isSubmitting}
                  error={validationErrors.passwordConfirmation}
                  value={state.passwordConfirmation}
                  onChange={event =>
                    setState({ ...state, passwordConfirmation: event.target.value })
                  }
                />
                {errorMessage && <div className="alert alert-warning">{errorMessage}</div>}
              </div>

              <div className={`col-md-10 offset-md-1 ${styles.explanation}`}>
                <p className="text-center">
                  <b>Une question ? Un problème ?</b>
                </p>

                <p className="text-center">
                  Contactez le support technique par téléphone au{' '}
                  <ExternalLink href="tel:+335531618257">05 31 61 82 57</ExternalLink> ou par email
                  à {`l'adresse`} suivante :{' '}
                  <ExternalLink href="mailto:contact@printcenter.app">
                    contact@printcenter.app
                  </ExternalLink>
                </p>
              </div>

              <div className="login-bottom col-md-12">
                <Button look="secondary" disabled={isSubmitting} type="submit">
                  {isSubmitting ? (
                    <span>Chargement...</span>
                  ) : isFirstTime ? (
                    'Activer mon compte'
                  ) : (
                    'Mettre à jour le mot de passe'
                  )}
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

const initialState: ResetPasswordFormState = {
  password: '',
  passwordConfirmation: '',
  isLoading: false,
  isSubmitting: false,
  validationErrors: {},
};

export default ResetPasswordForm;

interface TargetWithElements extends EventTarget {
  elements: {
    password: {
      value: string;
    };
    passwordConfirmation: {
      value: string;
    };
  };
}

interface FormEventWithElements extends React.FormEvent<HTMLFormElement> {
  target: TargetWithElements;
}

const getOnSubmit = (
  history: H.History,
  token: string,
  state: ResetPasswordFormState,
  setState: (state: ResetPasswordFormState) => void
) => async (event: FormEventWithElements) => {
  event.preventDefault();

  const { password, passwordConfirmation } = state;

  const validationErrors = validateForm(
    { password, passwordConfirmation },
    resellerUserPasswordValidationConstraints
  );

  const isValid = Object.keys(validationErrors).length === 0 && passwordConfirmation === password;

  if (!isValid) {
    return setState({
      ...state,
      validationErrors,
      errorMessage:
        password !== passwordConfirmation ? 'Les mots de passe ne correspondent pas' : null,
    });
  }

  setState({
    ...state,
    isSubmitting: true,
  });

  try {
    const response = await fetchWithToken(`${authEndpoint}/reset-password`, {
      method: 'POST',
      body: JSON.stringify({ resetToken: token, password, passwordConfirmation }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 404) {
      return setState({
        ...state,
        isSubmitting: false,
        isTokenFound: TokenFoundStates.NOT_FOUND,
      });
    }

    if (response.status !== 200) {
      throw new Error();
    }

    toast.success(`Mot de passe mis à jour`, { autoClose: 2000 });
    history.push('/');
  } catch (error) {
    setState({
      ...state,
      isSubmitting: false,
      errorMessage: 'Une erreur est survenue',
    });
  }
};

async function fetchIsTokenFound(resetToken: string) {
  const res = await fetchWithToken(`${authEndpoint}/is-reset-token-valid/${resetToken}`);

  return res.status === 200 ? TokenFoundStates.FOUND : TokenFoundStates.NOT_FOUND;
}
