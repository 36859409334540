import { ModelPassedForPrintFile } from '@oyp/shared-lib';
import { fetchCollection } from '@oyp/shared-components/dist/lib/rest-api/network';
import { passedForPrintFileApiEndpoint } from '../module';

export async function fetchOrderItemPassedForPrintFiles(orderItemId: string) {
  return (
    await fetchCollection<ModelPassedForPrintFile>(passedForPrintFileApiEndpoint, {
      orderItemId,
    })
  ).data;
}
