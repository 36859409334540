import {
  DANGER_COLOR,
  PRIMARY_COLOR,
  PRIMARY_COLOR_DARKER,
  SUCCESS_COLOR,
  WARNING_COLOR,
} from '../../style_variables';
import React, { useState } from 'react';
import styled from 'styled-components';

export interface InputGroupProps {
  label?: string | JSX.Element;
  value?: any;
  type?: string;
  name?: string;
  inputId?: string;
  inputClassNames?: string;
  error?: string;
  warning?: string;
  success?: string;
  alert?: InputAlert;
  s?: number;
  m?: number;
  l?: number;
  className?: string;
  displayClearInputButton?: boolean;
  onChange?: (event: any) => void;
  onClearInput?: () => void;
  leadingIcon?: JSX.Element;
  disabled?: boolean;
  placeHolder?: string;
  [key: string]: any;
}

const InputGroup: React.SFC<InputGroupProps> = props => {
  const { className = '', displayClearInputButton, onClearInput, leadingIcon, disabled } = props;

  const groupClass = getClassStringFromArray([className, ...getResponsiveClasses(props)]);

  const inputGroupProps = { ...props };

  delete inputGroupProps.handleChange;
  delete inputGroupProps.disableDebounce;
  delete inputGroupProps.displayClearInputButton;
  delete inputGroupProps.onClearInput;
  delete inputGroupProps.leadingIcon;
  delete inputGroupProps.s;
  delete inputGroupProps.m;
  delete inputGroupProps.l;
  const alert = getAlertFromProps(props);

  return (
    <StyledFormGroup className={`${groupClass}`} disabled={disabled}>
      {leadingIcon}
      <Input
        {...inputGroupProps}
        value={inputGroupProps.value || inputGroupProps.value === 0 ? inputGroupProps.value : ''}
        label={inputGroupProps.label || ''}
        type={inputGroupProps.type || ''}
        onChange={inputGroupProps.onChange || (() => null)}
        alert={alert}
      />
      {displayClearInputButton && (
        <StyledInputClearButton className="material-icons" onClick={onClearInput}>
          clear
        </StyledInputClearButton>
      )}
    </StyledFormGroup>
  );
};

export default InputGroup;

function getAlertFromProps(props: InputGroupProps): InputAlert | undefined {
  if (props.alert) {
    return props.alert;
  }
  if (props.error) {
    return { kind: 'error', content: props.error };
  }
  if (props.warning) {
    return { kind: 'warning', content: props.warning };
  }
  if (props.success) {
    return { kind: 'success', content: props.success };
  }

  return undefined;
}

function getResponsiveClasses({ s, m, l }: InputGroupProps): string[] {
  const classes = [];

  if (s) {
    classes.push(`col-sm-${s}`);
  }
  if (m) {
    classes.push(`col-md-${m}`);
  }
  if (l) {
    classes.push(`col-lg-${l}`);
  }

  return classes;
}

function getClassStringFromArray(classes: string[]): string {
  return classes.length > 0 ? ` ${classes.join(' ')}` : '';
}

interface InputAlert {
  kind: 'error' | 'warning' | 'success';
  content: string;
}

interface InputProps {
  value: string;
  type: string;
  label: string | JSX.Element;
  alert?: InputAlert;
  disabled?: boolean;
  placeholder?: string;
  onChange: (event: any) => void;
}

const Input: React.SFC<InputProps> = props => {
  const { value, onChange, label, type, alert, disabled, placeholder } = props;
  const [isFocused, setIsFocused] = useState(false);
  return (
    <>
      <StyledLabel isFocused={isFocused}>{label}</StyledLabel>
      <StyledInput
        type={type}
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        isFocused={isFocused}
        alert={alert}
        disabled={disabled}
        placeholder={placeholder}
      />
      {alert && <InputAlert alert={alert} />}
    </>
  );
};

const InputAlert: React.SFC<{ alert: InputAlert }> = props => {
  const { alert } = props;
  switch (alert.kind) {
    case 'error':
      return <StyledInputError>{alert.content}</StyledInputError>;
    case 'warning':
      return <StyledInputWarning>{alert.content}</StyledInputWarning>;
    case 'success':
      return <StyledInputSuccess>{alert.content}</StyledInputSuccess>;
  }
};

interface StyledInputProps {
  type: string;
  value: string;
  isFocused: boolean;
  disabled?: boolean;
  alert?: InputAlert;
  placeholder?: string;
  onChange: (event: any) => void;
  onFocus: () => void;
  onBlur: () => void;
}

const StyledInput: React.FC<StyledInputProps> = styled.input<StyledInputProps>`
  position:relative;
  border-right: none;
  margin-bottom: 30px;
  padding:0;
  border-left: none;
  border-top: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  width: 100%;
  background: none;
  transition: all ease 0.3s;
  ${props =>
    props.isFocused &&
    `border-bottom: 1px solid ${PRIMARY_COLOR}; box-shadow: 0 1px 0 0 ${PRIMARY_COLOR}; outline:0; transition: all ease 0.3s;`}
  ${props =>
    props.alert &&
    props.alert.kind === 'error' &&
    `border-bottom: 1px solid ${DANGER_COLOR}; box-shadow: 0 1px 0 0 ${DANGER_COLOR}; transition: all ease 0.3s;`}
  ${props =>
    props.alert &&
    props.alert.kind === 'warning' &&
    `border-bottom: 1px solid ${WARNING_COLOR}; box-shadow: 0 1px 0 0 ${WARNING_COLOR}; transition: all ease 0.3s;`}
  ${props =>
    props.alert &&
    props.alert.kind === 'success' &&
    `border-bottom: 1px solid ${SUCCESS_COLOR};box-shadow: 0 1px 0 0 ${SUCCESS_COLOR}; transition: all ease 0.3s;`}
`;

const StyledInputError = styled.span`
  position: absolute;
  top: 55px;
  line-height: 0.8rem;
  left: 5px;
  color: ${DANGER_COLOR};
  font-size: 0.8rem;
  font-weight: bold;
`;

const StyledInputWarning = styled.span`
  position: absolute;
  top: 55px;
  line-height: 0.8rem;
  left: 5px;
  color: ${WARNING_COLOR};
  font-size: 0.8rem;
  font-weight: bold;
`;

const StyledInputSuccess = styled.span`
  position: absolute;
  top: 55px;
  line-height: 0.8rem;
  left: 5px;
  color: ${SUCCESS_COLOR};
  font-size: 0.8rem;
  font-weight: bold;
`;

interface StyledLabelProps {
  isFocused: boolean;
}

const StyledLabel: React.FC<StyledLabelProps> = styled.label<StyledLabelProps>`
  font-size: 0.8rem;
  font-weight: bold;
  transition: all ease 0.3s;
  ${props => props.isFocused && `color :${PRIMARY_COLOR}; transition: all ease 0.3s;`}
`;

interface StyledFormGroupProps {
  disabled?: boolean;
  className?: string;
}

const StyledFormGroup: React.FC<StyledFormGroupProps> = styled.div<StyledFormGroupProps>`
  position: relative;
  ${props => props.disabled && `opacity:0.5;`}
`;

const StyledInputClearButton = styled.span`
  display: block;
  position: absolute;
  right: 0px;
  bottom: 30px;
  cursor: pointer;
  height: 25px;
  text-align: center;
  color: ${PRIMARY_COLOR_DARKER};
`;
