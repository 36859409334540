import * as Either from 'fp-ts/lib/Either';
import { CENTRAL_STATION_HOST } from '../../../../../env-config';
import {
  CUT_TYPES,
  CUT_TYPE_TRANSLATIONS,
  CalculatorFlexibleFormPermanentResources,
  CalculatorLamination,
  CalculatorMaterial,
  CalculatorRequestData,
  EYELET_INTERVALS,
  EYELET_INTERVAL_TRANSLATIONS,
  EYELET_TYPES,
  FLEXIBLE_EYELET_TYPES,
  FLEXIBLE_EYELET_TYPE_TRANSLATIONS,
  IMAGERY_COUNT_TYPES,
  InprintCalculator,
  ORDER_ITEM_TYPES,
  PRINTING_TYPES,
  TYPE_TAPE,
  WHITE_LAYER_TYPES,
} from '@oyp/shared-lib';
import { Card, form, getFlexibleFormConfig } from '@oyp/shared-components';
import { FrontCalculator } from '../../../components/Calculator';
import { WHITE_LAYER_RADIO_OPTIONS } from '../../../calculator_helpers';
import { getRemoteMessages } from '../../../validation';
import AddToCartButton from '../../../components/AddToCartButton';
import AmalgamumTooltip from '../../../components/AmalgamumTooltip';
import CardActionFooter from '../../../../../components/CardActionFooter';
import CostCardForm from '../../../components/CostCardForm';
import FilterButton from '../../../components/FilterButton';
import GlobalCalculateMessages from '../../../components/GlobalCalculateMessages';
import InfoTooltip from '../../../../../components/InfoTooltip';
import InputGroup from '../../../../../components/form/InputGroup';
import Markdown from 'react-markdown';
import OrderItemFields from '../../../components/OrderItemFields';
import React from 'react';
import ResetButton from '../../../components/ResetButton';
import SelectGroup, { SelectOption } from '../../../../../components/form/SelectGroup';
import TechnicalInformation from '../../../components/TechnicalInformation';

const { getOnValueChange, getOnEventChange } = form;

const CalculatorForm: React.SFC<FrontCalculator.FormProps<
  CalculatorFlexibleFormPermanentResources,
  CalculatorRequestData,
  InprintCalculator.SlimResult
>> = props => {
  const {
    permanentResources,
    initialRequestData,
    requestData,
    result,
    isLoading,
    onCalculate,
    onAddToCart,
    onReset,
    cartProps,
    isEditingItem,
  } = props;

  const { materials, laminations, materialTypes } = permanentResources;

  const selectedMaterial: CalculatorMaterial = materials.find(m => m.id === requestData.materialId);
  const selectedMaterialType = selectedMaterial.type;

  const {
    disableLamination,
    disableEyelet,
    disableEyeletInterval,
    disableUseWhiteLayer,
  } = getFlexibleFormConfig(permanentResources, requestData);

  const {
    dimensions,
    cutType,
    whiteLayerType,
    quantity,
    imageryCount,
    imageryCountType,
    eyelet,
    eyeletInterval,
    printingType,
    // passedForPrint = false,
  } = requestData;

  const errorsByProperty = getRemoteMessages(Either.isLeft(result) ? result.left.errors : []);
  const warningsByProperty = getRemoteMessages(Either.isRight(result) ? result.right.warnings : []);

  const materialOptions = makeMaterialOptions(materials, selectedMaterialType);
  const laminationOptions = makeLaminationOptions(laminations);

  const onMaterialChange = (id: string) => {
    const isWhiteLayer = materials.find(m => m.id === id).whiteLayer;
    const updatedRequestData = isWhiteLayer
      ? { ...requestData, whiteLayerType: WHITE_LAYER_TYPES.SELECTIVE }
      : requestData;
    onCalculate({ ...updatedRequestData, materialId: id });
  };
  const onLaminationChange = getOnValueChange('laminationId', requestData, onCalculate);
  const onEyeletChange = getOnValueChange('eyelet', requestData, onCalculate);
  const onEyeletIntervalChange = getOnValueChange('eyeletInterval', requestData, onCalculate);

  const onPrintingTypeChange = (value: PRINTING_TYPES) => {
    const isWhiteLayer = materials.find(m => m.id === requestData.materialId).whiteLayer;
    //Reset de l'option par défaut au switch de l'option d'impression
    const updatedRequestData =
      (isWhiteLayer && disableUseWhiteLayer) ||
      (isWhiteLayer &&
        printingType === PRINTING_TYPES.MIRROR &&
        whiteLayerType === WHITE_LAYER_TYPES.TOTAL)
        ? { ...requestData, whiteLayerType: WHITE_LAYER_TYPES.SELECTIVE }
        : requestData;
    onCalculate({ ...updatedRequestData, printingType: value });
  };

  const onWhiteLayerTypeChange = getOnValueChange('whiteLayerType', requestData, onCalculate);
  const onCutTypeChange = getOnValueChange('cutType', requestData, onCalculate);
  const onImageryCountTypeChange = getOnValueChange('imageryCountType', requestData, onCalculate);
  const disableImageryCountType = !(imageryCount > 1) || printingType === PRINTING_TYPES.NONE;

  const canAddToCart =
    Object.keys(errorsByProperty).length === 1 && errorsByProperty.global.length === 0;

  const printingTypeOptions = [
    { value: PRINTING_TYPES.ONE_SIDED, label: 'Recto' },
    ...(selectedMaterial.mirrorPrintingType
      ? [{ value: PRINTING_TYPES.MIRROR, label: 'Miroir' }]
      : []),
    { value: PRINTING_TYPES.NONE, label: 'Sans' },
  ];

  //On supprime l'option miroir pour le recto (uniquement en flexible)
  const whiteLayerOptions =
    materials.find(m => m.id === requestData.materialId).whiteLayer &&
    printingType === PRINTING_TYPES.ONE_SIDED
      ? WHITE_LAYER_RADIO_OPTIONS.filter(option => option.value !== WHITE_LAYER_TYPES.TOTAL)
      : WHITE_LAYER_RADIO_OPTIONS;

  const imageryCountTypeOptions = [
    { value: IMAGERY_COUNT_TYPES.SAME, label: 'Quantités identiques' },
    { value: IMAGERY_COUNT_TYPES.DIFFERENT, label: 'Quantités différentes' },
  ];

  return (
    <div className="form-page-container">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-8 configurator">
              <div className="row">
                <div className="col-md-12">
                  <div className="filter-container" role="group">
                    {materialTypes.map((materialType, index) => (
                      <FilterButton
                        key={index}
                        handleClick={type =>
                          onCalculate({
                            ...initialRequestData,
                            materialId: materials.find(m => m.type === type).id,
                          })
                        }
                        filterType={{
                          ...materialType,
                          isActive: materialType.type === selectedMaterialType,
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 calculator-container">
                  <div className="form-row">
                    <SelectGroup
                      s={12}
                      label={
                        <span>
                          Matière
                          {selectedMaterial.description && (
                            <InfoTooltip
                              left={55}
                              tooltipContent={<Markdown source={selectedMaterial.description} />}
                              contentClassName="calculator-tooltip-description"
                            />
                          )}
                        </span>
                      }
                      options={materialOptions}
                      name="material"
                      value={requestData.materialId}
                      onChange={(option: SelectOption<string>) => onMaterialChange(option.value)}
                      clearable={false}
                      searchable={false}
                    />
                  </div>

                  <div className="form-row">
                    <InputGroup
                      name="dimensions.width"
                      label="Largeur (cm)"
                      s={2}
                      type="number"
                      error={errorsByProperty.width || errorsByProperty.surface}
                      warning={warningsByProperty.width || warningsByProperty.surface}
                      onChange={getOnEventChange('dimensions.width', requestData, onCalculate)}
                      value={dimensions.width}
                    />

                    <InputGroup
                      name="dimensions.height"
                      label="Hauteur (cm)"
                      s={2}
                      type="number"
                      error={errorsByProperty.height}
                      warning={warningsByProperty.height}
                      onChange={getOnEventChange('dimensions.height', requestData, onCalculate)}
                      value={dimensions.height}
                    />

                    <InputGroup
                      name="quantity"
                      label="Quantité"
                      s={2}
                      type="number"
                      error={errorsByProperty.quantity}
                      warning={warningsByProperty.quantity}
                      onChange={getOnEventChange('quantity', requestData, onCalculate)}
                      value={quantity}
                    />

                    <InputGroup
                      name="imageryCount"
                      className="amalgame"
                      label={
                        <span>
                          Nb fichiers (amalgame)
                          <AmalgamumTooltip />
                        </span>
                      }
                      s={3}
                      type="number"
                      error={errorsByProperty.imageryCount}
                      warning={warningsByProperty.imageryCount}
                      onChange={getOnEventChange('imageryCount', requestData, onCalculate)}
                      value={printingType === PRINTING_TYPES.NONE ? 0 : imageryCount}
                      disabled={printingType === PRINTING_TYPES.NONE}
                    />

                    <SelectGroup
                      s={3}
                      label="Quantités par fichier"
                      name="imageryCountType"
                      onChange={(option: SelectOption<IMAGERY_COUNT_TYPES>) =>
                        onImageryCountTypeChange(option.value)
                      }
                      value={disableImageryCountType ? null : imageryCountType}
                      options={imageryCountTypeOptions}
                      clearable={false}
                      searchable={false}
                      disabled={disableImageryCountType}
                      placeholder={disableImageryCountType ? 'Non applicable' : ''}
                    />
                  </div>

                  <div className="form-row">
                    <SelectGroup
                      s={4}
                      label="Type d'impression"
                      options={printingTypeOptions}
                      name="printingType"
                      value={printingType}
                      onChange={(option: SelectOption<PRINTING_TYPES>) =>
                        onPrintingTypeChange(option.value)
                      }
                      clearable={false}
                      searchable={false}
                    />
                    <SelectGroup
                      s={4}
                      label="Blanc de soutien"
                      options={whiteLayerOptions}
                      name="whiteLayerType"
                      value={disableUseWhiteLayer ? null : whiteLayerType}
                      onChange={(option: SelectOption<WHITE_LAYER_TYPES>) =>
                        onWhiteLayerTypeChange(option.value)
                      }
                      clearable={false}
                      searchable={false}
                      disabled={disableUseWhiteLayer}
                      placeholder={disableUseWhiteLayer ? 'Non applicable' : ''}
                    />
                    <SelectGroup
                      s={4}
                      label="Découpe"
                      options={[
                        {
                          value: CUT_TYPES.STRAIGHT,
                          label: CUT_TYPE_TRANSLATIONS[CUT_TYPES.STRAIGHT],
                        },
                        {
                          value: CUT_TYPES.SHAPE,
                          label: CUT_TYPE_TRANSLATIONS[CUT_TYPES.SHAPE],
                        },
                      ]}
                      name="cutType"
                      value={cutType}
                      onChange={(option: SelectOption<CUT_TYPES>) => onCutTypeChange(option.value)}
                      clearable={false}
                      searchable={false}
                    />

                    {/*
                        <SelectGroup
                          s={3}
                          label="BAT"
                            options= {[
                              { value: false, label: 'Non' },
                              { value: true, label: 'Oui' },
                            ]}
                            name= 'passedForPrint'
                            value= {passedForPrint}
                            onChange= {option => onPassedForPrintChange(option.value)}
                            clearable= {false}
                            searchable= {false}
                        />*/}
                  </div>

                  <div className="form-row">
                    <SelectGroup
                      s={4}
                      label={
                        <span>
                          Lamination
                          {selectedMaterialType === TYPE_TAPE && (
                            <InfoTooltip
                              tooltipWidth={300}
                              left={75}
                              tooltipContent={
                                <div>
                                  La lamination ou plastification apporte une propriété
                                  supplémentaire (mate, brillante, antidérapante pour les sols, …) à
                                  l’adhésif.
                                  <br /> Cette option n’est pas disponible sur le micro-perforé.
                                </div>
                              }
                            />
                          )}
                        </span>
                      }
                      options={laminationOptions}
                      name="lamination"
                      value={requestData.laminationId || ''}
                      onChange={(option: SelectOption<string>) =>
                        onLaminationChange(option.value.length !== 0 ? option.value : null)
                      }
                      clearable={false}
                      searchable={false}
                      disabled={disableLamination}
                      placeholder={disableLamination ? 'Non applicable' : ''}
                    />

                    <SelectGroup
                      s={4}
                      label="Oeillets"
                      options={eyeletOptions}
                      name="eyelet"
                      value={disableEyelet ? null : eyelet}
                      onChange={(option: SelectOption<EYELET_TYPES>) =>
                        onEyeletChange(option.value)
                      }
                      clearable={false}
                      searchable={false}
                      disabled={disableEyelet}
                      placeholder={disableEyelet ? 'Non applicable' : ''}
                    />

                    <SelectGroup
                      s={4}
                      label="Espacement entre les oeillets"
                      options={eyeletIntervalOptions}
                      name="eyeletInterval"
                      value={eyeletInterval}
                      onChange={(option: SelectOption<EYELET_INTERVALS>) =>
                        onEyeletIntervalChange(option.value)
                      }
                      clearable={false}
                      searchable={false}
                      disabled={disableEyeletInterval}
                      placeholder={disableEyeletInterval ? 'Non applicable' : ''}
                      warning={warningsByProperty.eyeletInterval}
                    />
                  </div>

                  <OrderItemFields
                    requestData={requestData}
                    errorsByProperty={errorsByProperty}
                    warningsByProperty={warningsByProperty}
                    onCalculate={onCalculate}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 informations">
              <Card className="height100 calculator-product-informations">
                <div className="container">
                  <TechnicalInformation
                    requestData={requestData}
                    material={selectedMaterial}
                    technicalFileLink={`${CENTRAL_STATION_HOST}/documents/fiche-technique-souple.pdf`}
                  />

                  <CostCardForm
                    productionMode={requestData.productionMode}
                    calculatorResult={result}
                    cartProps={cartProps}
                    isLoading={isLoading}
                    additionalWarningsElement={
                      Either.isRight(result) && (
                        <GlobalCalculateMessages
                          messages={getRemoteMessages(result.right.warnings || []).global}
                          manufacture={result.right.manufacture}
                          requestData={requestData}
                          className="warning"
                          isLoading={isLoading}
                        />
                      )
                    }
                  />
                </div>
                <CardActionFooter>
                  <ResetButton disabled={isLoading} reset={onReset} />

                  <AddToCartButton
                    disabled={!canAddToCart || isLoading}
                    isEditingItem={isEditingItem}
                    onAddToCart={() =>
                      onAddToCart({
                        orderItemType: ORDER_ITEM_TYPES.CALCULATOR,
                        manualReference: requestData.manualReference,
                        description: requestData.description,
                        requestData,
                      })
                    }
                  />
                </CardActionFooter>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatorForm;

function makeMaterialOptions(materials: CalculatorMaterial[], selectedMaterialType: string) {
  return materials
    .filter(material => material.type === selectedMaterialType && !material.isHiddenForCustomer)
    .map(material => {
      return {
        value: material.id,
        label: material.name,
        type: material.type,
      };
    });
}

function makeLaminationOptions(laminations: CalculatorLamination[]): SelectOption<string>[] {
  return [
    {
      value: '',
      label: 'Aucune',
    },
    ...laminations.map(lamination => {
      return {
        value: lamination.id,
        label: lamination.name,
      };
    }),
  ];
}

const eyeletOptions = FLEXIBLE_EYELET_TYPES.map(type => ({
  value: type,
  label: FLEXIBLE_EYELET_TYPE_TRANSLATIONS[type],
}));

const eyeletIntervalOptions = [
  {
    value: EYELET_INTERVALS.HUNDRED,
    label: EYELET_INTERVAL_TRANSLATIONS[EYELET_INTERVALS.HUNDRED],
  },
  { value: EYELET_INTERVALS.FIFTY, label: EYELET_INTERVAL_TRANSLATIONS[EYELET_INTERVALS.FIFTY] },
  {
    value: EYELET_INTERVALS.TWENTY_FIVE,
    label: EYELET_INTERVAL_TRANSLATIONS[EYELET_INTERVALS.TWENTY_FIVE],
  },
  { value: EYELET_INTERVALS.TEN, label: EYELET_INTERVAL_TRANSLATIONS[EYELET_INTERVALS.TEN] },
];
