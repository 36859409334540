"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var BlankDocumentIcon = require("../../img/blank-document.png");

var React = require("react");

var Card_1 = require("../card/Card");

var ConfirmationButton_1 = require("../ConfirmationButton");

var shared_lib_1 = require("@oyp/shared-lib");

var FileInput_1 = require("./FileInput");

var HoverSurface_1 = require("../HoverSurface");

var react_materialize_1 = require("react-materialize");

var TooltipContainer_1 = require("../TooltipContainer");

var formatter_1 = require("../../utils/formatter");

exports.UploadFileItem = function (_ref) {
  var _ref$file = _ref.file,
      file = _ref$file === void 0 ? {} : _ref$file,
      _ref$onFileDelete = _ref.onFileDelete,
      onFileDelete = _ref$onFileDelete === void 0 ? function () {} : _ref$onFileDelete,
      _ref$onFileDownload = _ref.onFileDownload,
      onFileDownload = _ref$onFileDownload === void 0 ? function () {} : _ref$onFileDownload,
      _ref$onFileUpload = _ref.onFileUpload,
      _onFileUpload = _ref$onFileUpload === void 0 ? function () {} : _ref$onFileUpload;

  var isRefused = file.status === shared_lib_1.FILE_STATUSES.FILE_REFUSED;
  return React.createElement("div", {
    className: "file-item-wrapper uploaded-file no-preview".concat(isRefused ? ' refused' : '')
  }, React.createElement("div", {
    className: "title"
  }, React.createElement(TooltipContainer_1.TooltipContainer, {
    tooltipContent: file.name
  }, truncateFileNameIfNeeded(file.name))), React.createElement(TooltipContainer_1.TooltipContainer, {
    place: "top",
    tooltipContent: "T\xE9l\xE9charger"
  }, React.createElement("div", {
    className: "placeholder",
    onClick: function onClick() {
      return onFileDownload(file);
    }
  }, file.thumbnailUrl ? React.createElement("div", {
    className: "inner-placeholder"
  }, React.createElement(HoverSurface_1.HoverSurface, {
    place: "right",
    surfaceContent: React.createElement("div", null, React.createElement("img", {
      src: file.thumbnailUrl,
      alt: file.name
    }), React.createElement(react_materialize_1.Icon, null, "file_download"))
  }, React.createElement(Card_1.Card, {
    className: "thumbnail-big-container",
    title: "Aper\xE7u"
  }, React.createElement("img", {
    className: "thumbnail-big",
    src: file.thumbnailUrl
  })))) : React.createElement("div", {
    className: "inner-placeholder default-placeholder"
  }, React.createElement("img", {
    src: BlankDocumentIcon,
    alt: "Pas d'aper\xE7u"
  }), React.createElement(react_materialize_1.Icon, null, "file_download")))), isRefused ? React.createElement(FileInput_1.FileInput, {
    className: "btn btn-reupload",
    onFileUpload: function onFileUpload(files) {
      return _onFileUpload(files, {
        fileEntityId: file.id
      });
    }
  }, "Renvoyer") : React.createElement(ConfirmationButton_1.ConfirmationButton, {
    iconName: "remove",
    parameters: file,
    onConfirmation: onFileDelete
  }));
};

function truncateFileNameIfNeeded(name) {
  if (name.length < 15) {
    return name;
  }

  var splitName = name.split('.');
  var extension = splitName[splitName.length - 1];
  var firstSegment = splitName.slice(0, splitName.length - 1).join('.');
  return "".concat(formatter_1.truncateString(firstSegment, 15), ".").concat(extension);
}