"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

require("./UploadButton.scss");

var React = require("react");

var react_materialize_1 = require("react-materialize");

var UploadContainer_1 = require("./UploadContainer");

exports.UploadButton = function (props) {
  var _props$files = props.files,
      files = _props$files === void 0 ? [] : _props$files,
      _props$filesInProgres = props.filesInProgress,
      filesInProgress = _props$filesInProgres === void 0 ? [] : _props$filesInProgres,
      _props$filesRejected = props.filesRejected,
      filesRejected = _props$filesRejected === void 0 ? [] : _props$filesRejected,
      _props$onFileUpload = props.onFileUpload,
      onFileUpload = _props$onFileUpload === void 0 ? function () {} : _props$onFileUpload,
      _props$onFileDelete = props.onFileDelete,
      onFileDelete = _props$onFileDelete === void 0 ? function () {} : _props$onFileDelete,
      _props$onFileDownload = props.onFileDownload,
      onFileDownload = _props$onFileDownload === void 0 ? function () {} : _props$onFileDownload,
      _props$onRejectedFile = props.onRejectedFileDismiss,
      onRejectedFileDismiss = _props$onRejectedFile === void 0 ? function () {} : _props$onRejectedFile;
  return React.createElement(UploadContainer_1.UploadContainer, {
    className: "upload-button-container",
    files: files,
    filesInProgress: filesInProgress,
    filesRejected: filesRejected,
    onFileUpload: onFileUpload,
    onFileDelete: onFileDelete,
    onFileDownload: onFileDownload,
    onRejectedFileDismiss: onRejectedFileDismiss,
    overlayContent: React.createElement(react_materialize_1.Icon, null, "file_upload"),
    fileChooserContent: React.createElement("span", null, React.createElement(react_materialize_1.Icon, null, "insert_drive_file"))
  });
};