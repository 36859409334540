import * as cookie from 'react-cookies';
import { AUTH_COOKIE_NAME, authEndpoint } from '../../env-config';
import { AuthenticationError } from './errors/AuthenticationError';
import { InternalServerError } from './errors/InternalServerError';
import { ModelUser } from '@oyp/shared-lib';
import { restApiNetwork } from '@oyp/shared-components';
import { userApiEndpoint } from '../user/module';
import fetch from 'isomorphic-fetch';

const { fetchOne } = restApiNetwork;

const generateTokenEndpoint = `${authEndpoint}/generate-token`;
const verifyTokenEndpoint = `${authEndpoint}/verify-token`;

interface GenerateOrCheckTokenResponse extends UserInfo {
  token: string;
}

export async function generateToken(requestData = {}): Promise<GenerateOrCheckTokenResponse> {
  try {
    const res = await fetch(`${generateTokenEndpoint}`, {
      method: 'POST',
      body: JSON.stringify(requestData),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    checkResponseStatus(res.status);

    const { userId, token } = await res.json();
    cookie.save(AUTH_COOKIE_NAME, token, { path: '/' });

    const userInfo = await getUserInfo(userId, token);

    return {
      token,
      ...userInfo,
    };
  } catch (error) {
    if (error instanceof AuthenticationError) {
      throw error;
    }
    throw new InternalServerError();
  }
}

export async function checkToken(): Promise<GenerateOrCheckTokenResponse | void> {
  const token = cookie.load(AUTH_COOKIE_NAME);

  if (token) {
    const response = await fetch(`${verifyTokenEndpoint}`, {
      method: 'POST',
      body: JSON.stringify({ token }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return handleCheckTokenResponse({ response, token });
  }

  resetToken();
}

export async function handleCheckTokenResponse({
  response,
  token,
}: {
  response: any;
  token: string;
}): Promise<GenerateOrCheckTokenResponse> {
  checkResponseStatus(response.status);

  const { userId } = await response.json();

  const userInfo = await getUserInfo(userId, token);

  if (!isUserInfoCorrect(userInfo)) {
    resetToken();

    throw new AuthenticationError();
  }

  cookie.save(AUTH_COOKIE_NAME, token, { path: '/' });

  return {
    token,
    ...userInfo,
  };
}

export const resetToken = () => {
  cookie.remove(AUTH_COOKIE_NAME, { path: '/' });
};

interface UserInfo {
  userId: string;
  role: string;
  email: string;
  resellerId: string;
  resellerUserId: string;
}

async function getUserInfo(userId: string, token: string): Promise<UserInfo> {
  const user = await fetchOne<ModelUser>(userApiEndpoint, userId);

  const tokenData = parseJwt(token);

  const { resellerId, resellerUserId } = tokenData.payload;

  return {
    userId: user.id,
    role: user.role,
    email: user.email,
    resellerId,
    resellerUserId,
  };
}

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

function isUserInfoCorrect(userInfo: any) {
  const requiredKeys = ['resellerId', 'resellerUserId'];
  const userInfoKeys = Object.keys(userInfo);

  return !requiredKeys.some(propName => !userInfoKeys.includes(propName));
}

function checkResponseStatus(status: number): void {
  if (status !== 200) {
    if (status === 500) {
      throw new InternalServerError();
    }

    resetToken();
    throw new AuthenticationError();
  }
}

export function getAuthenticationToken() {
  return cookie.load(AUTH_COOKIE_NAME);
}
