"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.getElementOffsetTop = function (element) {
  var currentOffset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

  if (element.offsetParent) {
    return exports.getElementOffsetTop(element.offsetParent, currentOffset + element.offsetTop);
  }

  return currentOffset;
};

function animateScroll(targetPosition) {
  var initialPosition = window.scrollY;
  var SCROLL_DURATION = 30;
  var maxDistance = document.body.scrollHeight - window.innerHeight;
  var scrollDistance = maxDistance > targetPosition ? targetPosition - initialPosition : maxDistance;
  var stepX = Math.PI / SCROLL_DURATION;
  var stepCount = 0; //if (window.requestAnimationFrame) {

  requestAnimationFrame(step); //}

  function step() {
    if (stepCount < SCROLL_DURATION) {
      requestAnimationFrame(step);
      stepCount++;
      window.scrollTo(0, initialPosition + scrollDistance * 0.25 * Math.pow(1 - Math.cos(stepX * stepCount), 2));
    }
  }
}

exports.animateScroll = animateScroll;