import * as Either from 'fp-ts/lib/Either';
import { CalculatorInputRequestData, InprintCalculator, ORDER_ITEM_TYPES } from '@oyp/shared-lib';
import { FrontCalculator } from '../../components/Calculator';
import { fetchCalculationResult } from '../../calculator_helpers';

export async function fetchRigidCalculationResult(
  requestData: CalculatorInputRequestData
): Promise<FrontCalculator.Result<InprintCalculator.SlimResult>> {
  const result = await fetchCalculationResult({
    orderItemType: ORDER_ITEM_TYPES.CALCULATOR,
    ...requestData,
  });

  return result.hasOwnProperty('errors') && result.errors.length > 0
    ? Either.left({ errors: result.errors })
    : Either.right({
        delivery: result.delivery,
        manufacture: result.manufacture,
        price: result.price,
        warnings: result.warnings,
      });
}
