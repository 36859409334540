import InvalidFileTypeIcon from './img/icon-library/invalide.svg';
import JPGIcon from './img/icon-library/jpg.svg';
import PDFIcon from './img/icon-library/pdf.svg';
import RarIcon from './img/icon-library/rar.svg';
import TifIcon from './img/icon-library/tif.svg';
import ZipIcon from './img/icon-library/zip.svg';

export const truncateFileNameIfNeeded = (name: string, threshold = 15) => {
  if (name.length < threshold) {
    return name;
  }

  const splitName = name.split('.');
  const extension = splitName[splitName.length - 1];
  const firstSegment = splitName.slice(0, splitName.length - 1).join('.');
  return `${firstSegment.substr(0, 12)}[...].${extension}`;
};

export function fileExtensionIcon(name: string) {
  const splitName = name.split('.');
  switch (splitName[splitName.length - 1]) {
    case 'pdf':
      return PDFIcon;
    case 'jpg':
    case 'jpeg':
    case 'JPG':
      return JPGIcon;
    case 'zip':
    case '7z':
      return ZipIcon;
    case 'rar':
      return RarIcon;
    case 'tif':
    case 'tiff':
      return TifIcon;
    default:
      return InvalidFileTypeIcon;
  }
}
