import { ModelResellerCustomerAddress } from '@oyp/shared-lib';
import { TooltipContainer } from '@oyp/shared-components';
import ConfirmationRow from '../../../components/list/ConfirmationRow';
import InlineIconButton from '../../../components/buttons/InlineIconButton';
import React from 'react';

interface AddressListRowProps {
  address: ModelResellerCustomerAddress;
  onDelete: (address: ModelResellerCustomerAddress) => void;
  onEdit: (address: ModelResellerCustomerAddress) => void;
}

const AddressListRow: React.FC<AddressListRowProps> = props => {
  const { address, onDelete, onEdit } = props;

  return (
    <ConfirmationRow
      diffHolder={address.id}
      render={({ onToggle }) => (
        <>
          <td className="actions-cell">
            <TooltipContainer tooltipContent="Modifier l'adresse" place="top">
              <InlineIconButton tabIndex={0} iconName="edit" onClick={() => onEdit(address)} />
            </TooltipContainer>

            <TooltipContainer tooltipContent="Supprimer l'adresse" place="top">
              <InlineIconButton
                tabIndex={1}
                iconName="delete"
                onClick={() =>
                  onToggle('Êtes-vous sûr(e) de vouloir supprimer cette adresse ?', () =>
                    onDelete(address)
                  )
                }
              ></InlineIconButton>
            </TooltipContainer>
          </td>
          <td>{address.companyName}</td>
          <td>{address.name}</td>
          <td>{address.street}</td>
          <td>{address.complement}</td>
          <td>{address.postalCode}</td>
          <td>{address.city}</td>
          <td>{address.email}</td>
          <td>{address.phoneNumber}</td>
        </>
      )}
    />
  );
};

export default AddressListRow;
