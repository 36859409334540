"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function isPostalCodeOutsideMetropolitanFrance() {
  var postalCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return !!postalCode.match(/^(20|97|98[^0])/);
}

exports.isPostalCodeOutsideMetropolitanFrance = isPostalCodeOutsideMetropolitanFrance;