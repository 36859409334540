import * as React from 'react';
import { MDBIcon } from 'mdbreact';
import { ModelInputFinishedProduct } from '@oyp/shared-lib';
import { TooltipContainer } from '@oyp/shared-components';
import InputGroup from '../../../../../components/form/InputGroup';
import styles from './ProductChooser.module.scss';

interface ProductChooserProps {
  products: ModelInputFinishedProduct[];
  selectedProductId: string;
  onProductSelection: (product: ModelInputFinishedProduct) => void;
}

const ProductChooser: React.SFC<ProductChooserProps> = props => {
  const { products, selectedProductId, onProductSelection } = props;

  const [state, setState] = React.useState({
    fullList: products,
    filteredList: products,
    searchValue: '',
  });

  React.useEffect(() => {
    if (state.fullList !== products) {
      setState({
        fullList: products,
        filteredList: products,
        searchValue: '',
      });
    }
  });

  return (
    <div className={`${styles.list}`}>
      <div className={styles['search-box']}>
        <div className="form-row">
          <InputGroup
            s={12}
            type="text"
            value={state.searchValue}
            placeHolder="Rechercher"
            onChange={event => {
              setState({
                fullList: state.fullList,
                searchValue: event.target.value,
                filteredList: getFilteredList(event.target.value, products),
              });
            }}
            displayClearInputButton={state.searchValue.length > 0}
            onClearInput={() =>
              setState({
                fullList: state.fullList,
                searchValue: '',
                filteredList: products,
              })
            }
            leadingIcon={<MDBIcon icon="search" />}
          />
        </div>
      </div>

      {state.filteredList.map((product, index) => (
        <div
          key={index}
          className={`${styles.item} ${product.id === selectedProductId ? styles.selected : ''} ${
            product.isOutOfStock ? styles.disabled : ''
          }`}
          onClick={() => !product.isOutOfStock && onProductSelection(product)}
        >
          {product.isOutOfStock ? (
            <TooltipContainer tooltipContent="En rupture de stock">
              <span className={styles['disabled-text']}>{product.label}</span>
            </TooltipContainer>
          ) : (
            product.label
          )}
        </div>
      ))}
    </div>
  );
};

export default ProductChooser;

function getFilteredList(
  searchValue: string,
  products: ModelInputFinishedProduct[]
): ModelInputFinishedProduct[] {
  return products.filter(product =>
    product.label.toLowerCase().includes(searchValue.toLowerCase())
  );
}
