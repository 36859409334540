import * as React from 'react';
import { TooltipContainer } from '@oyp/shared-components';
import InfoIcon from './InfoIcon';
import styles from './InfoTooltip.module.scss';

interface InfoTooltipProps {
  tooltipContent: string | JSX.Element;
  tooltipWidth?: number;
  top?: number;
  right?: number;
  left?: number;
  iconColor?: 'primary' | 'white';
  place?: 'right' | 'top' | 'left';
  forceDisplay?: boolean;
  contentClassName?: string;
  hasWarning?: boolean;
}

const InfoTooltip: React.SFC<InfoTooltipProps> = props => {
  const tooltipWidth = props.tooltipWidth || 420;
  const top = props.top || 0;
  const right = props.right || 0;
  const left = props.left || null;
  const place = props.place || 'right';
  const hasWarning = props.hasWarning;

  return (
    <div
      className={styles.container}
      style={{
        right: `${right}px`,
        top: `${top}px`,
        ...(!!left && { left: `${left}px` }),
        height: `24px`,
        width: `24px`,
      }}
    >
      <TooltipContainer
        tooltipContent={
          <div
            className={[styles['tooltip-content'], props.contentClassName].join(' ')}
            style={{
              width: `${tooltipWidth}px`,
            }}
          >
            {props.tooltipContent}
          </div>
        }
        place={place}
        tooltipLook="light"
        forceDisplay={props.forceDisplay}
      >
        <InfoIcon iconColor={props.iconColor} hasWarning={hasWarning} />
      </TooltipContainer>
    </div>
  );
};

export default InfoTooltip;
