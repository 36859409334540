import './AddToCartButton.scss';
import * as React from 'react';
import Button from '../../../components/buttons/Button';

interface AddToCartButtonProps {
  isEditingItem: boolean;
  disabled: boolean;
  onAddToCart: () => void;
}

const AddToCartButton: React.FC<AddToCartButtonProps> = ({
  onAddToCart,
  disabled: hasError,
  isEditingItem,
}) => (
  <Button look="secondary" onClick={onAddToCart} disabled={hasError} size="sm">
    {isEditingItem ? 'Mettre à jour' : 'Ajouter au panier'}
  </Button>
);

export default AddToCartButton;
