"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.mapId = function (x) {
  return x.id;
};

exports.identity = function (x) {
  return x;
};

function getUniqueValues(values) {
  return values.reduce(uniqueValuesReducer, []);
}

exports.getUniqueValues = getUniqueValues;

function uniqueValuesReducer(values, value) {
  if (!values.includes(value)) {
    values.push(value);
  }

  return values;
}

exports.uniqueValuesReducer = uniqueValuesReducer;

exports.safeParseInt = function (s) {
  return parseInt(s, 10);
};

function sortByCreatedAtAsc(a, b) {
  if (!a.createdAt || !b.createdAt) {
    return 0;
  }

  var aDate = new Date(a.createdAt);
  var bDate = new Date(b.createdAt);
  return aDate.getTime() < bDate.getTime() ? -1 : 1;
}

exports.sortByCreatedAtAsc = sortByCreatedAtAsc;

function sortByCreatedAtDesc(a, b) {
  if (!a.createdAt || !b.createdAt) {
    return 0;
  }

  var aDate = new Date(a.createdAt);
  var bDate = new Date(b.createdAt);
  return aDate.getTime() > bDate.getTime() ? -1 : 1;
}

exports.sortByCreatedAtDesc = sortByCreatedAtDesc;

function flatten(input) {
  return Array.prototype.concat.apply([], input);
}

exports.flatten = flatten;