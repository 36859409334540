import { Card } from '@oyp/shared-components';
import { FrontOrder } from '../../../calculator/types';
import { SHIPPING_MODES } from '@oyp/shared-lib';
import { formatPrice, formatWeight } from '@oyp/shared-components/dist/lib/utils/formatter';
import InfoTooltip from '../../../../components/InfoTooltip';
import React from 'react';

interface OrderTotalsProps {
  order: FrontOrder;
  mustDisplayTrackingButton: boolean;
  hasDeliveryDate: boolean;
}

const OrderTotals: React.FC<OrderTotalsProps> = props => {
  const { mustDisplayTrackingButton, hasDeliveryDate, order } = props;
  const {
    totalWeight,
    taxExcludedTotal,
    taxAmount,
    taxIncludedTotal,
    shippingTotalCost,
    shippingMode,
  } = order;

  return (
    <Card
      title={
        <div>
          TOTAL
          <InfoTooltip
            tooltipContent={
              <div>{`Pour certains produits, l'expédition est comprise dans le calcul du prix`}</div>
            }
            iconColor="white"
            tooltipWidth={200}
            right={12}
            top={10}
            place="left"
          />
        </div>
      }
      className={`bg-primary-card-form half-card ${
        mustDisplayTrackingButton ? 'with-delivery-tracking' : ''
      }`}
    >
      <div className="row sub-header">
        <div className="col-sm-6 cart-recap-price-label">Total HT</div>
        <div className="col-sm-6 cart-recap-price-amount text-right">
          {hasDeliveryDate ? formatPrice(taxExcludedTotal) : '-'}
        </div>
      </div>
      <div className="recap-line-container">
        <div className="row recap-line">
          <div className="col-md-6" style={{ whiteSpace: 'nowrap' }}>
            {shippingMode === SHIPPING_MODES.PICKUP ? `Dont préparation` : `Dont expédition`}
          </div>
          <div className="col-md-6 text-right">
            {hasDeliveryDate ? formatPrice(shippingTotalCost) : '-'}
          </div>
        </div>
        {order.priceModifier && order.priceModifier !== 0 ? (
          <div className="row recap-line">
            <div className="col-md-6">
              {order.priceModifier > 0 ? `Dont supplément` : `Dont remise`}
            </div>
            <div className="col-md-6 text-right">{formatPrice(order.priceModifier)}</div>
          </div>
        ) : null}
        <div className="row recap-line">
          <div className="col-md-6">TVA</div>
          <div className="col-md-6 text-right">
            {hasDeliveryDate ? formatPrice(taxAmount) : '-'}
          </div>
        </div>
        <div className="row recap-line">
          <div className="col-md-6">Total TTC</div>
          <div className="col-md-6 text-right">
            {hasDeliveryDate ? formatPrice(taxIncludedTotal) : '-'}
          </div>
        </div>
        <div className="row recap-line">
          <div className="col-md-6">Poids total</div>
          <div className="col-md-6 text-right">
            {hasDeliveryDate ? formatWeight(totalWeight) : '-'}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default OrderTotals;
