import styled from 'styled-components';

const LoadingText = styled.div`
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: rgba(100, 100, 100, 0.3);
  background: linear-gradient(
    to right,
    rgba(100, 100, 100, 0.3) 8%,
    rgba(255, 255, 255, 0.1) 18%,
    rgba(100, 100, 100, 0.3) 33%
  );
  background-size: 800px 104px;
  height: 96px;
  position: relative;
  opacity: 0.5;
`;

export default LoadingText;
