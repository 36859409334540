import { ModelResellerCustomerAddress } from '@oyp/shared-lib';
import { deleteOne, saveOne } from '@oyp/shared-components/dist/lib/rest-api/network';
import { resellerCustomerAddressEndpoint } from '../module';
import { restApiNetwork } from '@oyp/shared-components';

const { fetchCollection } = restApiNetwork;

export async function fetchResellerCustomerAddresses(resellerId: string, options = {}) {
  return (
    await fetchCollection<ModelResellerCustomerAddress>(resellerCustomerAddressEndpoint, {
      resellerId,
      ...options,
    })
  ).data;
}

export async function saveCustomerAddress(address: ModelResellerCustomerAddress) {
  return saveOne<ModelResellerCustomerAddress>(resellerCustomerAddressEndpoint, address);
}

export async function deleteCustomerAddress(address: ModelResellerCustomerAddress): Promise<void> {
  return deleteOne(resellerCustomerAddressEndpoint, address);
}
