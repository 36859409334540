"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var constants_1 = require("../constants");

function isMimetypeAuthorized(mimetype) {
  return constants_1.RESELLER_AUTHORIZED_EXTENSIONS.filter(function (ext) {
    return mimetype.includes(ext);
  }).length > 0;
}

exports.isMimetypeAuthorized = isMimetypeAuthorized;

function isSizeAuthorized(sizeInBytes) {
  return sizeInBytes <= constants_1.RESELLER_AUTHORIZED_SIZE_IN_BYTES;
}

exports.isSizeAuthorized = isSizeAuthorized;