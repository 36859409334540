"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = require("react");

var react_materialize_1 = require("react-materialize");

var react_select_1 = require("react-select");

function SelectWrapper(props) {
  var selectProps = props.selectProps,
      _props$s = props.s,
      s = _props$s === void 0 ? 12 : _props$s,
      label = props.label,
      warning = props.warning;
  var selectClassNames = ["type-select"];

  if (warning) {
    selectClassNames.push("with-warning");
  }

  var Wrapper = props.renderWrapper || getDefaultWrapper(s);
  return React.createElement(Wrapper, null, label && React.createElement("label", {
    className: "material-select-label"
  }, label), React.createElement(react_select_1.default, Object.assign({
    className: selectClassNames.join(' ')
  }, selectProps)), warning && React.createElement("div", {
    className: "select-warning-message warning-message",
    style: {
      padding: 0,
      position: 'absolute'
    }
  }, warning));
}

exports.SelectWrapper = SelectWrapper;

var getDefaultWrapper = function getDefaultWrapper(s) {
  return function (_ref) {
    var children = _ref.children;
    return React.createElement(react_materialize_1.Col, {
      s: s
    }, children);
  };
};