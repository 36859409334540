import * as Either from 'fp-ts/lib/Either';
import { CartProps } from '../types';
import {
  OrderItemClientSource,
  PRODUCTION_MODES,
  ValidationPropertyError,
  getDeliveryDate,
} from '@oyp/shared-lib';
import { debouncePromise } from '@oyp/shared-components/dist/lib/utils/execution';
import { formatDeliveryDateString } from '@oyp/shared-components/dist/lib/utils/formatter';
import CalculatorLoaderWrapper from './CalculatorLoaderWrapper';
import Loader from '../../../components/Loader';
import React, { useEffect, useState } from 'react';

interface ProductionModeOwner {
  productionMode: PRODUCTION_MODES;
}

interface DeliveryOwner {
  delivery: {
    delay: number;
  };
}

export declare module FrontCalculator {
  export interface Props<
    PermanentResourcesType,
    RequestDataType extends ProductionModeOwner,
    SuccessfulResultType extends DeliveryOwner
  > {
    isHidden: boolean;
    isParentLoading: boolean;
    FormType: React.FC<FormProps<PermanentResourcesType, RequestDataType, SuccessfulResultType>>;
    editedItemRequestData?: RequestDataType;
    cartProps: CartProps;
    fetchPermanentResources: () => Promise<PermanentResourcesType>;
    getInitialRequestData: (permanentResources: PermanentResourcesType) => RequestDataType;
    onRequestDataChange: (
      permanentResources: PermanentResourcesType,
      requestData: RequestDataType
    ) => RequestDataType;
    fetchCalculateResult: FetchCalculateResultFn<RequestDataType, SuccessfulResultType>;
    onSaveOrderItem: (orderItem: OrderItemClientSource) => void;
  }

  export interface ErrorsBearer {
    errors: ValidationPropertyError[];
  }

  export type Result<SuccessfulResultType extends DeliveryOwner> = Either.Either<
    ErrorsBearer,
    SuccessfulResultType
  >;

  export type FetchCalculateResultFn<
    RequestDataType extends ProductionModeOwner,
    SuccessfulResultType extends DeliveryOwner
  > = (requestData: RequestDataType) => Promise<Result<SuccessfulResultType>>;

  export interface FormProps<
    PermanentResourcesType,
    RequestDataType extends ProductionModeOwner,
    SuccessfulResultType extends DeliveryOwner
  > {
    permanentResources: PermanentResourcesType;
    initialRequestData: RequestDataType;
    requestData: RequestDataType;
    result: Result<SuccessfulResultType>;
    isLoading: boolean;
    isEditingItem: boolean;
    cartProps: CartProps;
    onCalculate: (requestData: RequestDataType) => void;
    onReset: () => void;
    onAddToCart: (orderItem: OrderItemClientSource) => void;
  }
}

interface InitializedState<PermanentResourcesType, RequestDataType extends ProductionModeOwner> {
  kind: 'INITIALIZED';
  permanentResources: PermanentResourcesType;
  initialRequestData: RequestDataType;
  requestData: RequestDataType;
}

interface CalculationResult<SuccessfulResultType extends DeliveryOwner> {
  result: FrontCalculator.Result<SuccessfulResultType>;
  isLoading: boolean;
}

type State<PermanentResourcesType, RequestDataType extends ProductionModeOwner> =
  | {
      kind: 'INITIALIZING';
    }
  | {
      kind: 'ERROR';
      error: string;
    }
  | InitializedState<PermanentResourcesType, RequestDataType>;

function Calculator<
  PermanentResourcesType,
  RequestDataType extends ProductionModeOwner,
  SuccessfulResultType extends DeliveryOwner
>(props: FrontCalculator.Props<PermanentResourcesType, RequestDataType, SuccessfulResultType>) {
  const {
    FormType,
    fetchCalculateResult,
    onRequestDataChange,
    isHidden,
    editedItemRequestData,
    cartProps,
  } = props;

  const [state, setState] = useState<State<PermanentResourcesType, RequestDataType>>({
    kind: 'INITIALIZING',
  });
  const [calculationResult, setCalculationResult] = useState<
    CalculationResult<SuccessfulResultType>
  >();

  // Chargement initial
  useEffect(() => {
    props.fetchPermanentResources().then(permanentResources => {
      const initialRequestData = props.getInitialRequestData(permanentResources);

      return fetchCalculateResult(initialRequestData).then(result => {
        setCalculationResult({
          result,
          isLoading: false,
        });

        setState({
          kind: 'INITIALIZED',
          permanentResources,
          initialRequestData,
          requestData: initialRequestData,
        });
      });
    });
  }, []);

  // Remise à 0 du formulaire quand il devient hidden
  useEffect(() => {
    if (isHidden && state.kind === 'INITIALIZED') {
      const initialRequestData = props.getInitialRequestData(state.permanentResources);

      setState({
        ...state,
        requestData: initialRequestData,
      });

      fetchCalculateResult(initialRequestData).then(result =>
        setCalculationResult({
          result,
          isLoading: false,
        })
      );
    }
  }, [isHidden]);

  // Prise en compte de l'item édité en cours
  useEffect(() => {
    if (state.kind === 'INITIALIZED') {
      const newRequestData = editedItemRequestData || state.initialRequestData;

      setState({
        ...state,
        requestData: newRequestData,
      });

      fetchCalculateResult(newRequestData).then(result =>
        setCalculationResult({
          result,
          isLoading: false,
        })
      );
    }
  }, [editedItemRequestData]);

  // Prise en compte du changement de productionMode
  useEffect(() => {
    if (
      state.kind === 'INITIALIZED' &&
      state.requestData.productionMode !== cartProps.order.productionMode
    ) {
      const newRequestData = {
        ...state.requestData,
        productionMode: cartProps.order.productionMode,
      };

      setState({
        ...state,
        initialRequestData: {
          ...state.initialRequestData,
          productionMode: cartProps.order.productionMode,
        },
        requestData: newRequestData,
      });

      setCalculationResult({
        ...calculationResult,
        isLoading: true,
      });

      fetchCalculateResult(newRequestData).then(result =>
        setCalculationResult({
          result,
          isLoading: false,
        })
      );
    }
  }, [cartProps.order.productionMode]);

  switch (state.kind) {
    case 'INITIALIZING':
      return (
        <div style={{ display: isHidden ? 'none' : 'block' }}>
          <CalculatorLoaderWrapper>
            <Loader />
          </CalculatorLoaderWrapper>
        </div>
      );
    case 'ERROR':
      return (
        <div style={{ display: isHidden ? 'none' : 'block' }}>
          <CalculatorLoaderWrapper>
            <Loader error={state.error} />
          </CalculatorLoaderWrapper>
        </div>
      );
    case 'INITIALIZED': {
      const { permanentResources, requestData, initialRequestData } = state;
      const { result, isLoading } = calculationResult;

      return (
        <div style={{ display: isHidden ? 'none' : 'block' }}>
          <FormType
            permanentResources={permanentResources}
            initialRequestData={initialRequestData}
            requestData={requestData}
            result={result}
            cartProps={props.cartProps}
            isLoading={isLoading || props.isParentLoading}
            isEditingItem={!!editedItemRequestData}
            onCalculate={async (newRequestData: RequestDataType) => {
              const derivedRequestData = onRequestDataChange(permanentResources, newRequestData);

              const newState = {
                ...state,
                isLoading: true,
                requestData: derivedRequestData,
              };
              setState(newState);

              // TODO trouver comment typechecker
              // Attention, ceci n'est pas typechecké !
              debouncedFetchCalculateAndSetState(
                cartProps,
                derivedRequestData,
                calculationResult.result,
                fetchCalculateResult,
                setCalculationResult
              );
            }}
            onAddToCart={props.onSaveOrderItem}
            onReset={() => {
              setState({
                ...state,
                requestData: state.initialRequestData,
              });

              return fetchCalculateAndSetResult(
                cartProps,
                state.initialRequestData,
                calculationResult.result,
                fetchCalculateResult,
                setCalculationResult
              );
            }}
          />
        </div>
      );
    }
  }
}

export default Calculator;

async function fetchCalculateAndSetResult<
  RequestDataType extends ProductionModeOwner,
  SuccessfulResultType extends DeliveryOwner
>(
  cartProps: CartProps,
  requestData: RequestDataType,
  previousResult: CalculationResult<SuccessfulResultType>['result'],
  fetchCalculateResult: FrontCalculator.FetchCalculateResultFn<
    RequestDataType,
    SuccessfulResultType
  >,
  setCalculationResult: (calculationResult: CalculationResult<SuccessfulResultType>) => void
) {
  setCalculationResult({
    result: previousResult,
    isLoading: true,
  });

  const result = await fetchCalculateResult(requestData);

  setCalculationResult({
    result,
    isLoading: false,
  });

  if (Either.isRight(result)) {
    updateDOMDeliveryDateIfNeeded(
      getDeliveryDate(
        result.right.delivery.delay,
        new Date(),
        cartProps.order.productionMode,
        cartProps.order.shippingMode,
        cartProps.order.recommendedTransporter
      )
    );
  }
}

const debouncedFetchCalculateAndSetState = debouncePromise(fetchCalculateAndSetResult, 200);

// Hack affreux permettant de mettre à jour la date de livraison dans le HTML du cart
// Probable source de bugs d'affichage
function updateDOMDeliveryDateIfNeeded(deliveryDate?: Date) {
  const deliveryDateElements = document.querySelectorAll(
    '.cart-delivery-date-container.no-order-value'
  );

  if (deliveryDate) {
    deliveryDateElements.forEach(elem => {
      elem.textContent = formatDeliveryDateString(deliveryDate);
    });
  }
}
