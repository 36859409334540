import * as React from 'react';
import styles from './CardActionFooter.module.scss';

const CardActionFooter: React.SFC<{}> = props => {
  const classNames = [styles['card-action-footer'], styles['fixed-bottom'], 'float-right'];

  return (
    <div className={classNames.join(' ')}>
      <div className={styles['actions-container']}>{props.children}</div>
    </div>
  );
};

export default CardActionFooter;
