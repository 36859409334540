import { Card, WindowInfiniteLoader } from '@oyp/shared-components';
import { ModelResellerCustomerAddress } from '@oyp/shared-lib';
import { UserContextInterface } from '../../../UserContext';
import {
  deleteCustomerAddress,
  fetchResellerCustomerAddresses,
  saveCustomerAddress,
} from '../../order/actions/address';
import { toast } from 'mdbreact';
import AddressListRow from '../components/AddressListRow';
import AddressModalForm from '../components/AddressModalForm';
import LoaderRow from '../../../components/list/LoaderRow';
import React, { useEffect, useState } from 'react';
import SideNavigationMenu, { NAVIGATION_LINK_IDS } from '../../../components/SideNavigationMenu';

interface AddressListContainerProps {
  context: UserContextInterface;
}

interface AddressListContainerState {
  isLoading: boolean;
  filter: string;
  addresses: ModelResellerCustomerAddress[];
  limit: number;
  addressCurrentlyEdited: ModelResellerCustomerAddress | null;
}

const AddressListContainer: React.FC<AddressListContainerProps> = props => {
  const [state, setState] = useState<AddressListContainerState>({
    isLoading: false,
    addresses: [],
    filter: '',
    limit: 20,
    addressCurrentlyEdited: null,
  });
  const { isLoading, addresses, filter, limit, addressCurrentlyEdited } = state;

  useEffect(() => {
    if (!isLoading) {
      loadAddresses(props.context.reseller.id, state, setState);
    }
  }, [filter, limit]);

  return (
    <div>
      <AddressModalForm
        address={addressCurrentlyEdited}
        onClose={() => setState({ ...state, addressCurrentlyEdited: null })}
        onSave={async address => {
          try {
            await saveCustomerAddress(address);
            toast.success('Adresse sauvegardée', 1000);
          } catch (error) {
            toast.error('Une erreur est survenue', 2000);
          }
          loadAddresses(
            props.context.reseller.id,
            { ...state, addressCurrentlyEdited: null },
            setState
          );
        }}
      />

      <div className="reseller-app-page-title">MON COMPTE</div>
      <div className="user-container user-infos-container">
        <div className="row ml-0 mr-0">
          <div className="col-md-3 laptop-padding-right">
            <SideNavigationMenu activeLink={NAVIGATION_LINK_IDS.ADDRESSES_LIST} />
          </div>
          <div className="col-md-9 laptop-padding-left">
            <Card className="main-card user-infos" title={'MES ADRESSES'}>
              <div className="result-container">
                <table className="table table-borderless table-striped">
                  <thead>
                    <tr>
                      <ListHeader />
                      <ListHeader>Société</ListHeader>
                      <ListHeader>Destinataire</ListHeader>
                      <ListHeader>Adresse</ListHeader>
                      <ListHeader>Complément d&apos;adresse</ListHeader>
                      <ListHeader>Code postal</ListHeader>
                      <ListHeader>Ville</ListHeader>
                      <ListHeader>Email de notification</ListHeader>
                      <ListHeader>Téléphone</ListHeader>
                    </tr>
                  </thead>

                  <tbody>
                    {addresses.map((address, index) => (
                      <AddressListRow
                        key={index}
                        address={address}
                        onEdit={addressToEdit =>
                          setState({ ...state, addressCurrentlyEdited: addressToEdit })
                        }
                        onDelete={async addressToDelete => {
                          await deleteCustomerAddress(addressToDelete);
                          toast.success('Adresse supprimée', 1000);
                          loadAddresses(props.context.reseller.id, state, setState);
                        }}
                      />
                    ))}
                    {isLoading ? <LoaderRow /> : null}
                  </tbody>
                </table>
                <WindowInfiniteLoader
                  isLoading={isLoading}
                  onLoadMore={() => {
                    if (addresses.length === limit) {
                      setState({
                        ...state,
                        limit: limit + 20,
                      });
                    }
                  }}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressListContainer;

const ListHeader: React.FC<{}> = props => {
  return (
    <th scope="col" className="bg-primary-lighter text-left font-weight-bold text-white">
      {props.children}
    </th>
  );
};

async function loadAddresses(
  resellerId: string,
  state: AddressListContainerState,
  setState: (state: AddressListContainerState) => void
) {
  const { filter, limit } = state;

  const fetchedAddresses = await fetchResellerCustomerAddresses(resellerId, {
    q: filter,
    count: limit,
    sort: '-createdAt',
  });

  setState({ ...state, isLoading: false, addresses: fetchedAddresses });
}
