import * as React from 'react';
import { TooltipContainer } from '@oyp/shared-components';
import { UPLOAD_STATUSES } from './constants';
import { UploadingCustomerFile } from '../../../files/reducers';
import FileLine from './FileLine';
import InlineIconButton from '../../../../components/buttons/InlineIconButton';

interface UploadingFileItemProps {
  file: UploadingCustomerFile;
}

const UploadingFileItem: React.SFC<UploadingFileItemProps> = ({ file }) => {
  return (
    <FileLine
      file={file}
      uploadStatus={UPLOAD_STATUSES.PENDING}
      buttons={
        <TooltipContainer tooltipContent="Supprimer" place="top">
          <InlineIconButton onClick={() => file.xhr.abort()} iconName="cancel" />
        </TooltipContainer>
      }
    />
  );
};

export default UploadingFileItem;
