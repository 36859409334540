import { isPostalCodeOutsideMetropolitanFrance } from '@oyp/shared-lib';
import { validationConstraints } from '@oyp/shared-components';

const { isEmail, isPhoneNumber, maxLength, minLength, required } = validationConstraints;

const minLength2 = minLength(2);
const maxLength35 = maxLength(35);
const maxLength5 = maxLength(5);
const maxLength80 = maxLength(80);
const maxLength70 = maxLength(70);
const isNotOutsideMetropolitanFrance = (postalCode: string) =>
  isPostalCodeOutsideMetropolitanFrance(postalCode) ? `Destination invalide` : undefined;

export const addressValidationConstraints = {
  name: [minLength2, maxLength35],
  companyName: [minLength2, maxLength35],
  email: [isEmail, maxLength80],
  phoneNumber: [required, isPhoneNumber],
  additionalInformation: [maxLength70],
  street: [required, minLength2, maxLength35],
  complement: [maxLength35],
  postalCode: [required, maxLength5, isNotOutsideMetropolitanFrance],
  city: [required, maxLength35],
};
