import { WHITE_LAYER_TYPES, WHITE_LAYER_TYPE_TRANSLATIONS } from '@oyp/shared-lib';
import { centralStationApiEndpoint } from '../../env-config';
import { restApiNetwork } from '@oyp/shared-components';

const { fetchWithToken } = restApiNetwork;

export async function fetchCalculationResult(queryObject: { [key: string]: any }) {
  const response = await fetchWithToken(`${centralStationApiEndpoint}/reseller/calculate`, {
    method: 'POST',
    body: JSON.stringify(queryObject),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  if (response.status !== 400 && response.status !== 200) {
    throw new Error(response.text());
  }

  return response.json();
}

export const WHITE_LAYER_RADIO_OPTIONS = [
  WHITE_LAYER_TYPES.SELECTIVE,
  WHITE_LAYER_TYPES.TOTAL,
  WHITE_LAYER_TYPES.SELECTIVE_WHITE,
  WHITE_LAYER_TYPES.NONE,
].map(wLT => ({
  value: wLT,
  label: WHITE_LAYER_TYPE_TRANSLATIONS[wLT],
}));
