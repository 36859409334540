import { OrderItemInformationRendererProps } from '../../../../../types';
import { SmartLabel } from '@oyp/shared-lib';
import { WARNING_COLOR_DARKER } from '../../../../../../../style_variables';
import React from 'react';

const SmartLabelOrderItemInformation: React.FC<OrderItemInformationRendererProps> = props => {
  const { requestData } = props.orderItem as SmartLabel.OrderItem;

  return (
    <div>
      <div>
        <b>Petit format - Étiquettes</b>
      </div>
      <div>
        Dimensions : {requestData.width} x {requestData.height} cm
      </div>
      <div>Matière : {SmartLabel.MATERIAL_TRANSLATIONS[requestData.material]}</div>
      <div>
        Impression : {SmartLabel.translateColorMode(requestData.material, requestData.colorMode)}
      </div>
      <div>Finition : {SmartLabel.FINISH_TRANSLATIONS[requestData.finish]}</div>
      <br />
      <div>Pose : {SmartLabel.APPLICATION_TRANSLATIONS[requestData.application]}</div>
      {(() => {
        switch (requestData.application) {
          case SmartLabel.APPLICATIONS.MANUAL:
            return <ManualDescription requestData={requestData} />;
          case SmartLabel.APPLICATIONS.AUTOMATIC:
            return <AutomaticDescription requestData={requestData} />;
        }
      })()}
      <br />

      <hr />
      <div style={{ color: WARNING_COLOR_DARKER }}>
        Ce produit est fabriqué chez un prestataire. La date de livraison de la commande peut donc
        être impactée.
      </div>
      <hr />
      <br />
    </div>
  );
};

export default SmartLabelOrderItemInformation;

const ManualDescription: React.FC<{ requestData: SmartLabel.RequestData }> = props => {
  const { quantitiesPerModel } = props.requestData;

  return (
    <>
      <div>Nombre de modèles : {quantitiesPerModel.length}</div>
      {quantitiesPerModel.map((qty, index) => (
        <div key={index}>
          Quantité modèle {index + 1} : {qty}
        </div>
      ))}
    </>
  );
};

const AutomaticDescription: React.FC<{ requestData: SmartLabel.RequestData }> = props => {
  const { quantitiesPerModel, labelsPerRoll } = props.requestData;

  return (
    <>
      <div>Nombre de modèles : {quantitiesPerModel.length}</div>
      <div>Étiquettes par rouleau : {labelsPerRoll}</div>
      {quantitiesPerModel.map((qty, index) => (
        <div key={index}>
          Nb. rouleaux modèle {index + 1} : {qty / labelsPerRoll}
        </div>
      ))}
    </>
  );
};
