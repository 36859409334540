import * as React from 'react';
import { MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import {
  ModelOrder,
  ModelOrderItem,
  ModelParcel,
  ModelShippingAddress,
  ModelUser,
  TRANSPORTERS,
  getArrayFromEnum,
  getUniqueValues,
} from '@oyp/shared-lib';
import { ParcelWithShipment } from './types';
import { fetchParcels, fetchShipmentById } from './actions';
import Loader from '../../../../components/Loader';
import ShipmentParcelList from './ShipmentParcelList';

interface ModelOrderWithSubEntities extends ModelOrder {
  orderItems: ModelOrderItem[];
  shippingAddress: ModelShippingAddress;
  creator: ModelUser;
}

interface OrderShippingDetailsProps {
  currentOrder: any;
  onClose: () => void;
}

interface OrderShippingDetailsState {
  parcels: ParcelWithShipment[];
  isLoading: boolean;
}

class OrderShippingDetails extends React.Component<
  OrderShippingDetailsProps,
  OrderShippingDetailsState
> {
  state: OrderShippingDetailsState = {
    parcels: [],
    isLoading: true,
  };

  async componentDidUpdate(prevProps: OrderShippingDetailsProps) {
    const { currentOrder } = this.props;

    if (
      currentOrder &&
      (!prevProps.currentOrder || prevProps.currentOrder.id !== currentOrder.id)
    ) {
      this.setState({
        parcels: [],
        isLoading: true,
      });

      const parcels = await fetchOrderParcels(currentOrder);

      this.setState({
        parcels,
        isLoading: false,
      });
    }
  }

  render() {
    const { onClose, currentOrder } = this.props;

    const { isLoading, parcels } = this.state;

    const parcelListsByTransporter = getArrayFromEnum(TRANSPORTERS)
      .map(transporter => {
        return parcels.filter(parcel => parcel.shipment.transporter === transporter);
      })
      .filter(parcelList => parcelList.length > 0);

    return (
      <MDBModal
        fullHeight
        position="right"
        isOpen={currentOrder ? true : false}
        backdrop
        toggle={onClose}
      >
        <MDBModalHeader toggle={onClose}>
          <div className="text-center">
            Suivi de colis - {currentOrder ? currentOrder.orderReference : null}
          </div>
        </MDBModalHeader>

        <MDBModalBody>
          {isLoading ? (
            <Loader />
          ) : (
            parcelListsByTransporter.map((p, index) => (
              <ShipmentParcelList key={index} parcels={p} />
            ))
          )}
        </MDBModalBody>
      </MDBModal>
    );
  }
}

async function fetchOrderParcels(order: ModelOrderWithSubEntities): Promise<ParcelWithShipment[]> {
  const parcelsListsByOrderItem: ModelParcel[][] = await Promise.all(
    order.orderItems.map(async orderItem => {
      return fetchParcels({
        orderItemId: orderItem.id,
      });
    })
  );

  const parcels = parcelsListsByOrderItem.reduce((acc, parcelList) => [...acc, ...parcelList], []);

  const shipmentIds = getUniqueValues(parcels.map(p => p.shipmentId));

  const shipments = await Promise.all(shipmentIds.map(fetchShipmentById));

  return parcels.map(parcel => ({
    ...parcel,
    shipment: shipments.find(shipment => shipment.id === parcel.shipmentId),
  }));
}

export default OrderShippingDetails;
