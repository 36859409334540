import { FrontOrder, OrderWithProprietaryEntities } from '../../calculator/types';
import {
  ModelOrder,
  ModelPayment,
  ModelReseller,
  ModelResellerUser,
  ModelUser,
  PAYMENT_STATUSES,
  PRODUCTION_MODES,
  QUOTE_STATUS_CREATED,
  identity,
} from '@oyp/shared-lib';
import { fetchOrderItemsForOrder } from './order_item';
import {
  orderApiEndpoint,
  orderRecalculateDatesApiEndpoint,
  paymentEndpoint,
  shippingAddressApiEndpoint,
} from '../module';
import {
  resellerApiEndpoint,
  resellerUserApiEndpoint,
} from '@oyp/prod-center/src/_modules/reseller/module';
import { restApiNetwork } from '@oyp/shared-components';
import { userApiEndpoint } from '../../user/module';

const { fetchCollection, fetchOne, fetchWithToken, saveOne } = restApiNetwork;

export const initialOrder = {
  orderItems: [],
  productionMode: PRODUCTION_MODES.STANDARD,
} as FrontOrder;

export async function loadCartOrder(
  queryObject: any,
  reseller: ModelReseller
): Promise<FrontOrder> {
  const order = await fetchCartOrder(queryObject);

  if (!order) {
    return { ...initialOrder, billingMode: reseller.billingMode };
  }

  const orderItems = await fetchOrderItemsForOrder(order.id);

  return {
    ...order,
    orderItems: orderItems
      .map(orderItem => ({ ...orderItem, order }))
      .sort((a, b) => (a.rank > b.rank ? 1 : -1)),
  };
}

export async function fetchOrdersWithProprietaryEntities(
  criteria: any
): Promise<{ orders: OrderWithProprietaryEntities[]; meta: any }> {
  const orderCollection = await fetchCollection<ModelOrder>(orderApiEndpoint, criteria);

  const ordersWithProprietaryEntities: any = await Promise.all(
    orderCollection.data.map(getOrderWithProprietaryProperties)
  );

  return {
    orders: ordersWithProprietaryEntities,
    meta: orderCollection.meta,
  };
}

export async function fetchResellerByResellerUserId(
  resellerUserId: string
): Promise<ModelReseller> {
  const resellerUser = await fetchOne<ModelResellerUser>(resellerUserApiEndpoint, resellerUserId);
  const reseller = await fetchOne<ModelReseller>(resellerApiEndpoint, resellerUser.resellerId);
  return reseller;
}

export async function fetchOrderWithProprietaryEntities(
  orderId: string
): Promise<OrderWithProprietaryEntities> {
  const order = await fetchOne<ModelOrder>(orderApiEndpoint, orderId);

  return getOrderWithProprietaryProperties(order);
}

async function getOrderWithProprietaryProperties(
  order: ModelOrder
): Promise<OrderWithProprietaryEntities> {
  const [creator, shippingAddress, reseller, payment] = await Promise.all([
    order.creatorId
      ? await fetchOne<ModelUser>(userApiEndpoint, order.creatorId)
          .then(identity)
          .catch(() => null)
      : Promise.resolve(null),
    order.shippingAddressId
      ? await fetchOne(shippingAddressApiEndpoint, order.shippingAddressId)
      : Promise.resolve(null),
    order.resellerUserId
      ? await fetchResellerByResellerUserId(order.resellerUserId)
      : Promise.resolve(null),
    fetchPaymentForOrder(order.id),
  ]);

  return {
    ...order,
    creator,
    shippingAddress,
    reseller,
    payment,
  };
}

async function fetchCartOrder(queryObject: { [key: string]: any }) {
  const orderId = queryObject.orderId
    ? queryObject.orderId
    : await findFirstNonSavedOrderId(queryObject);

  if (!orderId) {
    return null;
  }

  return fetchOrderWithProprietaryEntities(orderId);
}

async function fetchOrders(criteria: any = {}) {
  return (await fetchCollection<ModelOrder>(orderApiEndpoint, criteria)).data;
}

async function findFirstNonSavedOrderId(queryObject: { [key: string]: any }) {
  const orders = await fetchOrders({
    ...queryObject,
    status: QUOTE_STATUS_CREATED,
    shippingAddressId: 'null',
  });

  return orders.length > 0 ? orders[0].id : null;
}

export async function recalculateQuoteDates(orderId: string) {
  return fetchWithToken(`${orderRecalculateDatesApiEndpoint}/${orderId}`, {
    method: 'PUT',
  });
}

export async function saveOneOrder(order: ModelOrder): Promise<ModelOrder> {
  return saveOne<ModelOrder>(orderApiEndpoint, order);
}

export async function fetchPaymentForOrder(orderId: string): Promise<ModelPayment | undefined> {
  const payments = (
    await fetchCollection<ModelPayment>(paymentEndpoint, {
      orderId,
    })
  ).data
    .map(payment => ({
      ...payment,
      createdAt: new Date(payment.createdAt),
      updatedAt: new Date(payment.updatedAt),
    }))
    // On ignore les payments "new" (s'ils n'ont pas été transformés en "processing", c'est que la personne a quitté la page paiement avant de finir)
    .filter(payment => payment.status !== PAYMENT_STATUSES.NEW);

  return payments.sort((a, b) => (a.createdAt.getTime() > b.createdAt.getTime() ? -1 : 1))[0];
}
