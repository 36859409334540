"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function range() {
  var length = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return Array.from(Array(length).keys());
}

exports.range = range;