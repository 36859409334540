"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = require("react");

var TooltipContainer_1 = require("./TooltipContainer");

var formatter_1 = require("../utils/formatter");

exports.ShortenedName = function (props) {
  var _props$name = props.name,
      name = _props$name === void 0 ? '' : _props$name,
      _props$tooltipPlaceme = props.tooltipPlacement,
      tooltipPlacement = _props$tooltipPlaceme === void 0 ? 'top' : _props$tooltipPlaceme,
      _props$maxNameLength = props.maxNameLength,
      maxNameLength = _props$maxNameLength === void 0 ? 20 : _props$maxNameLength;

  if (name.length <= maxNameLength) {
    return React.createElement("span", null, name);
  }

  return React.createElement(TooltipContainer_1.TooltipContainer, {
    tooltipContent: name,
    place: tooltipPlacement
  }, formatter_1.truncateString(name, maxNameLength));
};