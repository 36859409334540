import { CalculatorLamination, CalculatorMaterial, ModelOrderItem } from '@oyp/shared-lib';
import { fetchMaterialsAndLaminations } from '../../../../calculator/actions';
import { getItemInformationRenderer } from '../utils';
import React, { useEffect, useState } from 'react';

interface ItemInformationContainerProps {
  orderItem: ModelOrderItem;
}

interface ItemInformationContainerState {
  materials: CalculatorMaterial[];
  laminations: CalculatorLamination[];
}

const ItemInformationContainer: React.FC<ItemInformationContainerProps> = props => {
  const { orderItem } = props;
  const ItemInformation = getItemInformationRenderer(orderItem);

  const [{ materials, laminations }, setState] = useState<ItemInformationContainerState>({
    materials: [],
    laminations: [],
  });
  useEffect(() => {
    fetchMaterialsAndLaminations().then(result => setState(result));
  }, [orderItem]);

  return <ItemInformation orderItem={orderItem} materials={materials} laminations={laminations} />;
};

export default ItemInformationContainer;
