import * as React from 'react';
import { TooltipContainer } from '@oyp/shared-components';
import { UPLOAD_STATUSES } from './constants';
import FileLine from './FileLine';
import InlineIconButton from '../../../../components/buttons/InlineIconButton';

interface RejectedFileItemProps {
  file: any;
  onRejectedFileDismiss?: (file: any) => void;
}

const RejectedFileItem: React.SFC<RejectedFileItemProps> = props => {
  const { file = {}, onRejectedFileDismiss = (a: any) => a } = props;

  return (
    <React.Fragment>
      <FileLine
        file={file}
        uploadStatus={UPLOAD_STATUSES.ERROR}
        buttons={
          <TooltipContainer tooltipContent="Supprimer" place="top">
            <InlineIconButton onClick={() => onRejectedFileDismiss(file)} iconName="cancel" />
          </TooltipContainer>
        }
      />
    </React.Fragment>
  );
};

export default RejectedFileItem;
