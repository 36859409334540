"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function cleanInt(input) {
  return typeof input === 'string' ? parseInt(input, 10) : input;
}

exports.cleanInt = cleanInt;

function cleanFloat(input) {
  return typeof input === 'string' ? parseFloat("".concat(input).replace(',', '.')) : input;
}

exports.cleanFloat = cleanFloat;