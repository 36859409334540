"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var toast = function toast() {};

exports.MaterializeMock = {
  toast: toast
};