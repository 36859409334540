import { MDBIcon } from 'mdbreact';
import { PRIMARY_COLOR_DARKER, PRIMARY_COLOR_LIGHTER } from '../../style_variables';
import { TooltipContainer } from '@oyp/shared-components';
import Changelog from './Changelog';
import ExternalLink from '../ExternalLink';
import React from 'react';
import raw from 'raw.macro';
import styled from 'styled-components';

const ChangelogText = raw('../../CHANGELOG.md');

const versionNumber = ChangelogText.split('\n')[0].split('# Version ')[1];

interface FooterProps {
  isAuthenticated?: boolean;
}

const Footer: React.FC<FooterProps> = props => (
  <StyledFooter>
    {/* Active et Désactive le bandeau d'annonce à dates données */}
    {new Date().getTime() > new Date('2022-12-18').getTime() && //2022-12-18
      new Date().getTime() < new Date('2023-01-04').getTime() && (
        <div
          style={{
            backgroundColor: '#fbda3b',
            color: 'darkred',
            marginTop: '-5px',
            padding: '5px',
          }}
        >
          {/*Pont de l&apos;Ascension : Fermeture exceptionnelle le Vendredi 27 Mai - Merci de votre compréhension*/}
          🎅 Vacances de Noël : Fermeture exceptionnelle du 26/12 au 30/12 : reprise des livraisons
          à partir du 3 Janvier. L&apos;équipe IN-PRINT vous souhaite de Joyeuses Fêtes.
          {/* 🏖 Le service commercial ferme du 8 au 19 Août, la production des commandes est maintenue.
          Pour toutes questions pendant cette période, merci d&apos;écrire à &nbsp;
          <a href="mailto:atelier@oyp.fr">atelier@oyp.fr</a> */}
        </div>
      )}

    <div>
      PrintCenter | &copy; 2019 - {new Date().getFullYear()} In-print | Service commercial : &nbsp;
      <ExternalLink href="tel:+33561246535">05 61 24 65 35</ExternalLink>
      &nbsp;- &nbsp;
      <ExternalLink href="mailto:contact@in-print.net">contact@in-print.net</ExternalLink>
      {/*Support informatique (<ExternalLink href="tel:+33531616564">05 31 61 65 64</ExternalLink>
      &nbsp;- &nbsp;
      <ExternalLink href="mailto:contact@in-print.net">contact@in-print.net</ExternalLink>)*/}
      {props.isAuthenticated && (
        <>
          {' | '}
          <Changelog content={ChangelogText} versionNumber={versionNumber} />
        </>
      )}
      | &nbsp;
      <ExternalLink href="/document/documentation-printcenter.pdf">
        <TooltipContainer tooltipContent="Lire la documentation" tooltipLook="dark">
          <StyledIconContainer>
            <MDBIcon icon="book" />
          </StyledIconContainer>
        </TooltipContainer>{' '}
      </ExternalLink>
    </div>
  </StyledFooter>
);

export default Footer;

const StyledFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: ${PRIMARY_COLOR_LIGHTER};
  color: #fff;
  font-size: 14px;
  padding: 5px 0;
  z-index: 9999;

  > div {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a,
  a:visited {
    color: ${PRIMARY_COLOR_DARKER};

    &:hover {
      color: ${PRIMARY_COLOR_DARKER};
    }
  }
`;

const StyledIconContainer = styled.span`
  color: #ffffff;
`;
