"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function formatDateToDmy(date) {
  var year = date.getFullYear();

  var _getFormattedDayMonth = getFormattedDayMonth(date),
      day = _getFormattedDayMonth.day,
      month = _getFormattedDayMonth.month;

  return "".concat(day, "/").concat(month, "/").concat(year);
}

exports.formatDateToDmy = formatDateToDmy;

var getFormattedDayMonth = function getFormattedDayMonth(date) {
  var day = "".concat(date.getDate()).padStart(2, '0');
  var month = "".concat(date.getMonth() + 1).padStart(2, '0');
  return {
    day: day,
    month: month
  };
};