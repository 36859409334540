import './InlineConfirmationButton.scss';
import * as React from 'react';
import { ConfirmationButton, formatter } from '@oyp/shared-components';
import Button, { ButtonProps } from './buttons/Button';
import InlineIconButton from './buttons/InlineIconButton';

const { capitalizeFirstLetter } = formatter;

interface InlineConfirmationButtonProps {
  iconName: string;
  confirmText: string;
  onConfirmation: () => void;
}

const InlineConfirmationButton: React.SFC<InlineConfirmationButtonProps> = props => {
  const { confirmText = '' } = props;

  return (
    <ConfirmationButton
      onConfirmation={props.onConfirmation}
      containerClassName="inline-confirmation-button-container"
      iconName={props.iconName}
      MainButton={getConfirmationButtonMainButton(confirmText)}
      CancelButton={ConfirmationButtonCancelButton}
      ConfirmButton={getConfirmationButtonConfirmButton(confirmText)}
    />
  );
};

export default InlineConfirmationButton;

const getConfirmationButtonMainButton: (
  confirmText: string
) => React.FC<ButtonProps> = confirmText => props => (
  <InlineIconButton
    iconName={props.iconName}
    className="button-delete"
    onClick={props.onClick}
    style={{ display: props.isHidden ? 'none' : 'inline-block' }}
    data-tip={capitalizeFirstLetter(confirmText)}
    data-effect="solid"
  />
);
const ConfirmationButtonCancelButton: React.FC<ButtonProps> = props => (
  <Button
    look="white"
    variation="flat"
    size="xs"
    className="option-button__no"
    style={{ display: props.isHidden ? 'none' : 'inline-block' }}
    onClick={props.onClick}
  >
    annuler
  </Button>
);
const getConfirmationButtonConfirmButton: (
  confirmText: string
) => React.FC<ButtonProps> = confirmText => props => (
  <Button
    look="white"
    size="xs"
    className="option-button__yes"
    style={{ display: props.isHidden ? 'none' : 'inline-block' }}
    onClick={props.onClick}
  >
    {confirmText}
  </Button>
);
