import { ModelReseller, ModelUser } from '@oyp/shared-lib';
import { fetchOne } from '@oyp/shared-components/dist/lib/rest-api/network';
import { resellerApiEndpoint, userApiEndpoint } from './module';

export function fetchOneReseller(resellerId: string): Promise<ModelReseller> {
  return fetchOne(resellerApiEndpoint, resellerId);
}

export function fetchOneUser(userId: string): Promise<ModelUser> {
  return fetchOne(userApiEndpoint, userId);
}
