import * as React from 'react';
import { RESELLER_AUTHORIZED_EXTENSIONS } from '@oyp/shared-lib';
import { extractFilesFromDataTransfer, extractFilesFromFileList } from './files';
import Button from '../../../../components/buttons/Button';

interface UploadContainerProps {
  className?: string;
  dragEnabled?: boolean;
  overlayContent?: JSX.Element;
  UploadedFileComponent?: (props: any) => JSX.Element;
  PendingUploadFileComponent?: (props: any) => JSX.Element;
  RejectedFileComponent?: (props: any) => JSX.Element;
  fileChooserContent?: JSX.Element;
  files?: any[]; // TODO préciser le type
  filesInProgress: any[];
  filesRejected: any[];
  onFileUpload: (files: any, metaData?: any) => void;
  onFileDelete: (file: any) => void;
  onFileDownload: (file: any) => void;
  onRejectedFileDismiss: (file: any) => void;
  isPlacingOrder?: boolean;
}

class UploadContainer extends React.Component<UploadContainerProps> {
  state = { isDraggedOver: false };
  fileChooserWrapper: any = null;

  render() {
    const {
      className,
      dragEnabled = false,
      overlayContent,
      UploadedFileComponent = defaultUploadedFileComponent,
      PendingUploadFileComponent = defaultPendingUploadFileComponent,
      RejectedFileComponent = defaultRejectedFileComponent,
      fileChooserContent,
      files = [],
      filesInProgress = [],
      filesRejected = [],
      onFileUpload = (a: any) => a,
      onFileDelete = (a: any) => a,
      onFileDownload = (a: any) => a,
      onRejectedFileDismiss = (a: any) => a,
      isPlacingOrder,
    } = this.props;

    const hasNoFile =
      files.length === 0 && filesInProgress.length === 0 && filesRejected.length === 0;

    return (
      <div
        className={className}
        onDragOver={event => {
          event.preventDefault();
          this.setState({ ...this.state, isDraggedOver: true });
        }}
      >
        {dragEnabled ? (
          <div
            className={`drag-overlay ${this.state.isDraggedOver ? 'active' : ''}`}
            onDrop={event => {
              event.preventDefault();
              this.setState({ ...this.state, isDraggedOver: false });
              event.preventDefault();

              const droppedFiles = extractFilesFromDataTransfer(event.dataTransfer);
              onFileUpload(droppedFiles);
            }}
            onDragOver={event => {
              event.preventDefault();
              this.setState({ ...this.state, isDraggedOver: true });
            }}
            onDragLeave={event => {
              event.preventDefault();
              this.setState({ ...this.state, isDraggedOver: false });
            }}
          >
            <div>{overlayContent}</div>
          </div>
        ) : null}

        <div className={`files-container ${hasNoFile ? 'chooser-extended' : ''}`}>
          <div className="files-list" style={{ maxWidth: '100%' }}>
            {files.length
              ? files.map(file => (
                  <UploadedFileComponent
                    key={file.id}
                    file={file}
                    onFileUpload={onFileUpload}
                    onFileDelete={onFileDelete}
                    onFileDownload={onFileDownload}
                    isPlacingOrder={isPlacingOrder}
                  />
                ))
              : null}

            {filesInProgress.length
              ? filesInProgress.map((fileInProgress, index) => (
                  <PendingUploadFileComponent key={index} file={fileInProgress} />
                ))
              : null}

            {filesRejected.length > 0 &&
              filesRejected.map((fileRejected, index) => (
                <RejectedFileComponent
                  key={index}
                  file={fileRejected}
                  onRejectedFileDismiss={onRejectedFileDismiss}
                />
              ))}
          </div>
          {isPlacingOrder && (
            <div className="file-chooser-container">
              <div
                className="file-chooser"
                ref={fileChooserWrapper => {
                  this.fileChooserWrapper = fileChooserWrapper;
                }}
                onClick={event => {
                  if (
                    this.fileChooserWrapper &&
                    event.target !== this.fileChooserWrapper.lastChild
                  ) {
                    const fileInput = this.fileChooserWrapper.lastChild;
                    fileInput.focus();
                    fileInput.click();
                  }
                }}
              >
                {fileChooserContent}

                <div className="upload-btn-wrapper">
                  <Button look="primary">SÉLECTIONNER DES FICHIERS</Button>
                  <input
                    type="file"
                    multiple
                    onChange={event => {
                      onFileUpload(extractFilesFromFileList(event.target.files));
                      event.target.value = '';
                    }}
                  />
                </div>

                <div className="restrictions">
                  <p>
                    Fichiers acceptés : {RESELLER_AUTHORIZED_EXTENSIONS.join(', ')}
                    <br />
                    Taille maximale par envoi : 500Mo.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default UploadContainer;

const defaultUploadedFileComponent: React.FC<any> = props => <span>{props.file.name}</span>;
const defaultPendingUploadFileComponent: React.FC<any> = props => <span>{props.file.name}</span>;
const defaultRejectedFileComponent: React.FC<any> = props => <span>{props.file.name}</span>;
