import './Loader.scss';
import * as React from 'react';
import { MDBSpinner } from 'mdbreact';
import Button from './buttons/Button';

interface LoaderProps {
  isWholePage?: boolean;
  error?: any;
  onRetry?: () => void;
  size?: string;
}

const Loader: React.SFC<LoaderProps> = ({ error, onRetry, isWholePage = false, size = 'big' }) => {
  const loaderSize = size ? { [size]: true } : {};

  if (error) {
    return (
      <div className="center">
        <div className="image-error" />
        <p>Oops ! Une erreur est survenue.</p>
        <p className="grey-text">{error}</p>
        <p>
          <Button look="primary" variation="flat" onClick={onRetry}>
            Réessayer
          </Button>
        </p>
      </div>
    );
  }

  return (
    <div className={`spinner-wrapper ${isWholePage ? 'page-loader' : ''}`}>
      <MDBSpinner {...loaderSize} />
    </div>
  );
};

export default Loader;
