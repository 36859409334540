import { CUSTOMER_FILE_ACTIONS, CustomerFileAction, CustomerFileWithVersions } from './actions';
import { mapId, sortByCreatedAtAsc } from '@oyp/shared-lib';

export interface CustomerFileState {
  uploadingFiles: UploadingCustomerFile[];
  uploadedFiles: CustomerFileWithVersions[];
  rejectedFiles: UploadingCustomerFile[];
}

export interface UploadingCustomerFile {
  file: File;
  xhr: XMLHttpRequest;
  orderItemId: string;
  percentComplete: number;
}

const initialCustomerFileState: CustomerFileState = {
  uploadingFiles: [],
  uploadedFiles: [],
  rejectedFiles: [],
};

export const reduceCustomerFile = (
  state: CustomerFileState = initialCustomerFileState,
  action: CustomerFileAction
): CustomerFileState => {
  switch (action.type) {
    case CUSTOMER_FILE_ACTIONS.UPLOAD_INIT:
      return {
        ...state,
        uploadingFiles: [
          ...state.uploadingFiles,
          {
            xhr: action.xhr,
            file: action.file,
            orderItemId: action.orderItemId,
            percentComplete: 0,
          },
        ],
        uploadedFiles: state.uploadedFiles.filter(
          file => file.id !== action.previousCustomerFileId
        ),
      };

    case CUSTOMER_FILE_ACTIONS.UPLOAD_PROGRESS:
      return {
        ...state,
        uploadingFiles: state.uploadingFiles.map(file =>
          file.xhr === action.xhr ? { ...file, percentComplete: action.percentComplete } : file
        ),
      };

    case CUSTOMER_FILE_ACTIONS.UPLOAD_SUCCESS:
      return {
        ...state,
        uploadingFiles: state.uploadingFiles.filter(file => file.xhr !== action.xhr),
        uploadedFiles: [...state.uploadedFiles, action.customerFile].sort(sortByCreatedAtAsc),
      };

    case CUSTOMER_FILE_ACTIONS.UPLOAD_FAILURE:
      return {
        ...state,
        uploadingFiles: state.uploadingFiles.filter(file => file.xhr !== action.xhr),
        rejectedFiles: [
          ...state.rejectedFiles,
          {
            file: action.file,
            orderItemId: action.orderItemId,
            percentComplete: 0,
            xhr: action.xhr,
          },
        ],
      };

    case CUSTOMER_FILE_ACTIONS.UPLOAD_CANCEL:
      return {
        ...state,
        uploadingFiles: state.uploadingFiles.filter(file => file.xhr !== action.xhr),
      };

    case CUSTOMER_FILE_ACTIONS.FETCH_SUCCESS:
      return {
        ...state,
        uploadedFiles: [
          ...state.uploadedFiles.filter(file => !action.uploadedFiles.map(mapId).includes(file.id)),
          ...action.uploadedFiles,
        ],
      };

    case CUSTOMER_FILE_ACTIONS.DELETE_SUCCESS:
      return {
        ...state,
        uploadedFiles: state.uploadedFiles.filter(cF => cF.id !== action.customerFileId),
      };

    case CUSTOMER_FILE_ACTIONS.DISMISS_REJECTED:
      return {
        ...state,
        rejectedFiles: state.rejectedFiles.filter(file => file.file === action.file),
      };

    default:
      return state;
  }
};
