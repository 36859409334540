import './CartFooter.scss';
import { Card } from '@oyp/shared-components';
import { FrontOrder } from '../../../calculator/types';
import {
  LIMIT_SHORT_DELAY_HOUR,
  ModelOrder,
  ModelReseller,
  ORDER_STATUS_SHIPPED,
  PRODUCTION_MODES,
  PRODUCTION_MODE_TRANSLATIONS,
  SHIPPING_MODES,
  SHIPPING_MODE_TRANSLATIONS,
  getArrayFromEnum,
  isCalculatorOrderItem,
} from '@oyp/shared-lib';
import { WARNING_COLOR_DARKER } from '../../../../style_variables';
import { formatDeliveryDateString } from '@oyp/shared-components/dist/lib/utils/formatter';
import AddressDisplay from '../../../../components/AddressDisplay';
import InfoTooltip from '../../../../components/InfoTooltip';
import LoadingText from '../../../../components/LoadingText';
import OrderDisplayShippingInfoButton from '../OrderDisplayShippingInfoButton';
import OrderTotals from './OrderTotals';
import RadioGroup from '../../../../components/form/RadioGroup';
import React from 'react';
import ShippingModeChooser from './ShippingModeChooser';

interface CartFooterProps {
  order: FrontOrder;
  reseller?: ModelReseller;
  readOnly?: boolean;
  isLoading?: boolean;
  onProductionModeChange?: (newProductionMode: PRODUCTION_MODES) => void;
  onSaveOrder?: (order: ModelOrder) => void;
}

const CartFooter: React.FC<CartFooterProps> = props => {
  const {
    reseller,
    order,
    readOnly,
    isLoading,
    onProductionModeChange = () => {},
    onSaveOrder,
  } = props;

  const { orderItems, productionMode, deliveryDate, shippingMode } = order;

  const deliveryLabel =
    !orderItems.length || shippingMode !== SHIPPING_MODES.PICKUP
      ? 'Date de livraison : '
      : "Date d'enlèvement : ";

  const canChangeDelivery = !readOnly && orderItems.length > 0;
  const mustDisplayDeliveryOptions = !readOnly || (orderItems.length > 0 && !order.shippingAddress);
  const mustDisplayTrackingButton =
    order.status === ORDER_STATUS_SHIPPED &&
    ![SHIPPING_MODES.COURIER, SHIPPING_MODES.PICKUP].includes(shippingMode);

  const hasDeliveryDate = order.itemCount > 0 && !!deliveryDate;
  const hasDeliveryWarning = orderItems.some(item => !isCalculatorOrderItem(item));

  //Has only finished products
  const isProductionRushDisabled =
    orderItems.length > 0 &&
    orderItems.filter(item => 'product' in item.requestData).length === orderItems.length;

  return (
    <div className="cart-totals-and-delivery">
      <div className="row">
        <div className="col-md-6 laptop-padding-right">
          <Card
            title={
              <div>
                EXPEDITION
                <InfoTooltip
                  tooltipContent={
                    <div>
                      Les dates de livraison changent chaque jour ouvré à {LIMIT_SHORT_DELAY_HOUR}h
                      et sont données à titre indicatif.
                      <br />
                      Nous ne pouvons pas fixer de rendez-vous avec le transporteur ou vous faire
                      choisir un créneau de livraison.
                      {hasDeliveryWarning && (
                        <>
                          <br />
                          <hr />
                          <div style={{ color: WARNING_COLOR_DARKER }}>
                            Certains produits de la commande sont fabriqués chez un prestataire. La
                            date de livraison peut donc être impactée.
                          </div>
                        </>
                      )}
                    </div>
                  }
                  iconColor="white"
                  tooltipWidth={330}
                  right={12}
                  top={10}
                  place="top"
                  hasWarning={hasDeliveryWarning}
                />
              </div>
            }
            className={`bg-primary-card-form half-card ${
              mustDisplayTrackingButton ? 'with-delivery-tracking' : ''
            }`}
          >
            <div className="row sub-header text-center">
              <div
                className="col-sm-12"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {deliveryLabel}

                {isLoading ? (
                  <LoadingText style={{ width: '90px', height: '20px' }} />
                ) : (
                  <span
                    className={`cart-delivery-date-container${
                      hasDeliveryDate ? '' : ' no-order-value'
                    }`}
                    style={{ width: '90px', textAlign: 'right' }}
                  >
                    {hasDeliveryDate ? formatDeliveryDateString(deliveryDate) : '-'}
                  </span>
                )}
              </div>
            </div>
            {mustDisplayDeliveryOptions ? (
              <div className="delivery-types-container">
                <RadioGroup
                  name="productionMode"
                  onChange={onProductionModeChange}
                  selectedValue={productionMode}
                  radios={getProductionModeRadios(isProductionRushDisabled)}
                  disabled={!canChangeDelivery || isLoading}
                />
                <hr />
                Livraison :{' '}
                {canChangeDelivery ? (
                  <ShippingModeChooser
                    shippingMode={shippingMode}
                    onShippingModeChange={newShippingMode =>
                      onSaveOrder({ ...order, shippingMode: newShippingMode })
                    }
                    radios={getShippingModeRadios(reseller)}
                    isLoading={isLoading}
                  />
                ) : (
                  SHIPPING_MODE_TRANSLATIONS[shippingMode || SHIPPING_MODES.TRANSPORTER]
                )}
              </div>
            ) : (
              <div className="address-display-container">
                <AddressDisplay shippingMode={shippingMode} address={order.shippingAddress} />
                {mustDisplayTrackingButton ? (
                  <OrderDisplayShippingInfoButton order={order} />
                ) : null}
              </div>
            )}
          </Card>
        </div>

        <div className="col-md-6 laptop-padding-left">
          <OrderTotals
            order={order}
            mustDisplayTrackingButton={mustDisplayTrackingButton}
            hasDeliveryDate={hasDeliveryDate}
          />
        </div>
      </div>
    </div>
  );
};

export default CartFooter;

function getProductionModeRadios(isProductionRushDisabled: boolean) {
  const radios = [
    {
      value: PRODUCTION_MODES.STANDARD,
    },
    {
      value: PRODUCTION_MODES.EXPRESS,
    },
    {
      value: PRODUCTION_MODES.OVERNIGHT,
    },
  ];

  return radios.map(radio => ({
    ...radio,
    Label: getModeLabelComponent(radio.value),
    disabled: isProductionRushDisabled && radio.value === PRODUCTION_MODES.OVERNIGHT,
  }));
}

function getShippingModeRadios(reseller: ModelReseller) {
  const radios = [
    {
      value: SHIPPING_MODES.TRANSPORTER,
    },
    {
      value: SHIPPING_MODES.PICKUP,
    },
  ];

  if (reseller.isCourierAvailable) {
    radios.push({
      value: SHIPPING_MODES.COURIER,
    });
  }

  return radios.map(radio => ({
    ...radio,
    Label: getModeLabelComponent(radio.value),
  }));
}

function getModeLabelComponent(mode: PRODUCTION_MODES | SHIPPING_MODES) {
  return () => {
    return (
      <span>
        {getArrayFromEnum(PRODUCTION_MODES).includes(mode)
          ? PRODUCTION_MODE_TRANSLATIONS[mode]
          : SHIPPING_MODE_TRANSLATIONS[mode]}
        {mode === SHIPPING_MODES.PICKUP && (
          <>
            <br />
            (colis enlevables à partir de 8h)
          </>
        )}
      </span>
    );
  };
}
