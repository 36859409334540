import * as React from 'react';
import Button from '../../../components/buttons/Button';

const ResetButton: React.FC<{
  reset: () => void;
  disabled?: boolean;
}> = ({ reset, disabled }) => (
  <Button look="secondary" variation="flat" disabled={disabled} onClick={reset} size="sm">
    ANNULER
  </Button>
);

export default ResetButton;
