import './Page.scss';
import * as React from 'react';
import {
  BILLING_MODES,
  FILE_STATUSES,
  ModelOrderItem,
  ORDER_ITEM_STATUSES,
  ORDER_STATUS_CONFIRMED,
  QUOTE_ONLY_STATUSES,
} from '@oyp/shared-lib';
import { Card, TooltipContainer, logError, restApiNetwork } from '@oyp/shared-components';
import { CustomerFileWithVersions, fetchCustomerFiles } from '../../../files/actions';
import { FrontOrder } from '../../../calculator/types';
import { ReduxState } from '../../../../reducers';
import { RouteComponentProps } from 'react-router';
import { UserContext, UserContextInterface } from '../../../../UserContext';
import { centralStationApiEndpoint } from '../../../../env-config';
import { connect } from 'react-redux';
import { fetchCollection, fetchWithToken } from '@oyp/shared-components/dist/lib/rest-api/network';
import { fetchOrderItemsForOrder } from '../../actions/order_item';
import { findFilesForOrderItemId } from '../../file_utils';
import { loadCartOrder } from '../../actions/order';
import { orderApiEndpoint } from '../../module';
import { orderItemApiEndpoint } from '@oyp/prod-center/src/_modules/order/module';
import { toast } from 'mdbreact';
import BreadCrumb, { ORDER_BREADCRUMB_STEPS } from '../../../../components/BreadCrumb';
import Button from '../../../../components/buttons/Button';
import CardActionFooter from '../../../../components/CardActionFooter';
import Cart from '../../components/cart/Cart';
import FileUploadItemContainer from './FileUploadItemContainer';
import Loader from '../../../../components/Loader';

const { saveOne } = restApiNetwork;

type OwnProps = RouteComponentProps<{ id: string }>;

interface StateToProps {
  customerFiles: CustomerFileWithVersions[];
  hasFilesUploading: boolean;
  hasRejectedFiles: boolean;
}

type PageProps = OwnProps & StateToProps;

interface PageState {
  isLoading: boolean;
  orderItems: ModelOrderItem[];
  order: FrontOrder;
  isReupload?: boolean;
  isReadOnly?: boolean;
  isDuplicateCartWithDifference?: boolean;
}

enum FOOTER_ACTION_TYPES {
  VALIDATE_AFTER_SAVE = 'VALIDATE_AFTER_SAVE',
  PAYMENT_AFTER_SAVE = 'PAYMENT_AFTER_SAVE',
  REUPLOAD = 'REUPLOAD',
  SEE_FILES = 'SEE_FILES',
}

class Page extends React.Component<PageProps, PageState> {
  state: PageState = {
    isLoading: true,
    orderItems: [],
    order: { orderItems: [] } as FrontOrder,
    isDuplicateCartWithDifference: false,
  };
  context: UserContextInterface;

  constructor(props: PageProps, context: UserContextInterface) {
    super(props, context);

    loadData(this.setState.bind(this), props, context);
    this.validateOrder = this.validateOrder.bind(this);
    this.redirectToDetails = this.redirectToDetails.bind(this);
    this.saveQuote = this.saveQuote.bind(this);
    this.redirectToDetailsWithFileSave = this.redirectToDetailsWithFileSave.bind(this);
    this.redirectToPayment = this.redirectToPayment.bind(this);
  }

  //Permet de vérifier qu'une commande en cours n'a pas été modifiée dans un autre onglet navigateur
  componentDidUpdate() {
    if (this.state.isDuplicateCartWithDifference) {
      loadData(this.setState.bind(this), this.props, this.context);
      this.setState({ isDuplicateCartWithDifference: false });
      toast.error(
        'Votre panier a été modifié dans une nouvelle fenêtre. Merci de vérifier et modifier votre commande si nécessaire',
        { autoClose: 10000 }
      );
    }
  }

  render() {
    const { customerFiles, hasFilesUploading, hasRejectedFiles } = this.props;
    const { orderItems, isLoading, order, isReupload, isReadOnly } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const { billingMode } = order;

    const canValidateOrder =
      !this.state.isDuplicateCartWithDifference &&
      !orderItems.some(
        item => !item.isWithoutPrint && findFilesForOrderItemId(customerFiles, item.id).length === 0
      ) &&
      !hasFilesUploading &&
      !hasRejectedFiles;

    const orderHasRefusedItems = customerFiles.some(
      file => file.status === FILE_STATUSES.FILE_REFUSED
    );

    const isPlacingOrder = !isReupload && !isReadOnly;

    return (
      <div
        className={`file-upload-container quote-container ${
          isPlacingOrder ? 'breadcrumb-padded' : ''
        }`}
      >
        {isPlacingOrder && (
          <BreadCrumb selectedStepId={ORDER_BREADCRUMB_STEPS.FILES} order={order} />
        )}

        <div className="row ml-0 mr-0">
          <div className="col-sm-12 col-md-7 col-lg-8 laptop-padding-right">
            <Card className="section-wrapper upload-files-container" title="ENVOYEZ VOS FICHIERS">
              {orderItems.map((item, index) => {
                return (
                  <FileUploadItemContainer
                    key={index}
                    isOpen={true}
                    item={item}
                    disabled={
                      isReadOnly ||
                      (isReupload && item.status !== ORDER_ITEM_STATUSES.FILES_REFUSED)
                    }
                    isPlacingOrder={isPlacingOrder}
                  />
                );
              })}
            </Card>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-4 laptop-padding-left">
            <Cart
              files={customerFiles}
              order={order}
              readOnly
              isLoading={false}
              footer={
                <CardActionFooter>
                  <FooterActions
                    actionType={getActionType(isPlacingOrder, isReupload, billingMode)}
                    canValidateOrder={canValidateOrder}
                    orderHasRefusedItems={orderHasRefusedItems}
                    onSaveQuote={this.saveQuote}
                    onValidateOrder={this.validateOrder}
                    onRedirectToPayment={this.redirectToPayment}
                    onRedirectToDetails={this.redirectToDetails}
                    onRedirectToDetailsWithFileSave={this.redirectToDetailsWithFileSave}
                  />
                </CardActionFooter>
              }
            />
          </div>
        </div>
      </div>
    );
  }

  async validateFiles() {
    const { order } = this.state;

    await fetchWithToken(`${centralStationApiEndpoint}/set-files-ready-for-transfer/${order.id}`, {
      method: 'PUT',
    });
  }

  async validateOrder() {
    //On vérifie d'abord que le panier n'a pas été modifié dans un autre onglet du navigateur
    const orderItemsInState = this.state.orderItems;
    const orderItemsInDb = (
      await fetchCollection<ModelOrderItem>(orderItemApiEndpoint, { orderId: this.state.order.id })
    ).data;

    const isSameStateAndCart =
      orderItemsInState.length === orderItemsInDb.length &&
      orderItemsInDb.every(dbItem =>
        orderItemsInState.some(stateItem => dbItem.id === stateItem.id)
      ) &&
      orderItemsInState.every(stateItem =>
        orderItemsInDb.some(dbItem => stateItem.id === dbItem.id)
      );

    if (!isSameStateAndCart) {
      this.setState({ isDuplicateCartWithDifference: true, isLoading: true });
    } else {
      try {
        this.setState({
          isLoading: true,
        });

        await this.validateFiles();
        await saveOne(orderApiEndpoint, {
          ...this.state.order,
          status: ORDER_STATUS_CONFIRMED,
        });

        toast.success('Commande enregistrée');
        this.props.history.push(`/order/${this.state.order.id}/recap`);
      } catch (error) {
        logError('Error: ', error);
        this.setState({
          isLoading: false,
        });
        toast.error('Une erreur est survenue');
      }
    }
  }

  async redirectToPayment() {
    this.props.history.push(`/order/${this.state.order.id}/payment`);
  }

  async redirectToDetails() {
    this.props.history.push(`/orders/${this.state.order.id}/details`);
  }

  async redirectToDetailsWithFileSave() {
    await this.validateFiles();
    toast.success('Fichiers renvoyés');
    this.redirectToDetails();
  }

  async saveQuote() {
    toast.success('Devis sauvegardé', { autoClose: 2000 });
    return this.props.history.push(`/orders/${this.state.order.id}/details`);
  }
}
Page.contextType = UserContext;

function mapStateToProps(state: ReduxState): StateToProps {
  return {
    hasFilesUploading: state.files.uploadingFiles.length > 0,
    customerFiles: state.files.uploadedFiles,
    hasRejectedFiles: state.files.rejectedFiles.length > 0,
  };
}

export default connect<StateToProps, {}, OwnProps, ReduxState>(mapStateToProps)(Page);

async function loadData(
  setState: (state: PageState) => void,
  props: PageProps,
  context: UserContextInterface
) {
  const orderId = props.match.params.id;
  const order = await loadCartOrder({ orderId }, context.reseller);

  const customerFilesByOrderItem = await Promise.all(
    order.orderItems.map(item => fetchCustomerFiles({ orderItemId: item.id }))
  );

  const orderItems = await fetchOrderItemsForOrder(orderId);

  const allFiles = customerFilesByOrderItem.reduce(
    (acc, orderItemFiles) => [...acc, ...orderItemFiles],
    []
  );

  const isOrderStatus = !QUOTE_ONLY_STATUSES.some(status => order.status === status);
  const hasInvalidFiles = allFiles.some(file => file.status === FILE_STATUSES.FILE_REFUSED);

  setState({
    isLoading: false,
    orderItems,
    order: {
      ...order,
      orderItems: orderItems.map(oI => ({ ...oI, order })),
    },
    isReupload: isOrderStatus && hasInvalidFiles,
    isReadOnly: isOrderStatus && !hasInvalidFiles,
  });
}

function getActionType(
  isPlacingOrder: boolean,
  isReupload: boolean,
  billingMode: BILLING_MODES
): FOOTER_ACTION_TYPES {
  if (isPlacingOrder) {
    return billingMode === BILLING_MODES.IMMEDIATE
      ? FOOTER_ACTION_TYPES.PAYMENT_AFTER_SAVE
      : FOOTER_ACTION_TYPES.VALIDATE_AFTER_SAVE;
  }

  return isReupload ? FOOTER_ACTION_TYPES.REUPLOAD : FOOTER_ACTION_TYPES.SEE_FILES;
}

interface FooterActionsProps {
  actionType: FOOTER_ACTION_TYPES;
  canValidateOrder: boolean;
  orderHasRefusedItems: boolean;
  onSaveQuote: () => void;
  onValidateOrder: () => void;
  onRedirectToPayment: () => void;
  onRedirectToDetails: () => void;
  onRedirectToDetailsWithFileSave: () => void;
}
const FooterActions: React.FC<FooterActionsProps> = props => {
  const {
    actionType,
    canValidateOrder,
    orderHasRefusedItems,
    onSaveQuote,
    onValidateOrder,
    onRedirectToPayment,
    onRedirectToDetails,
    onRedirectToDetailsWithFileSave,
  } = props;

  switch (actionType) {
    case FOOTER_ACTION_TYPES.VALIDATE_AFTER_SAVE:
      return (
        <>
          <Button
            look="secondary"
            variation="flat"
            size="sm"
            disabled={!canValidateOrder}
            onClick={onSaveQuote}
          >
            Enregistrer le devis
          </Button>
          <Button look="secondary" size="sm" disabled={!canValidateOrder} onClick={onValidateOrder}>
            Terminer la commande
          </Button>
        </>
      );
    case FOOTER_ACTION_TYPES.PAYMENT_AFTER_SAVE:
      return (
        <>
          <Button
            look="secondary"
            variation="flat"
            size="sm"
            disabled={!canValidateOrder}
            onClick={onSaveQuote}
          >
            Enregistrer le devis
          </Button>
          <Button
            look="secondary"
            size="sm"
            disabled={!canValidateOrder}
            onClick={onRedirectToPayment}
          >
            Valider
          </Button>
        </>
      );
    case FOOTER_ACTION_TYPES.REUPLOAD:
      return (
        <>
          <TooltipContainer
            tooltipContent={
              <span>
                Envoyez tous vos fichiers
                <br /> pour valider la commande
              </span>
            }
          >
            <Button
              look="secondary"
              size="sm"
              disabled={orderHasRefusedItems}
              onClick={onRedirectToDetailsWithFileSave}
            >
              Valider
            </Button>
          </TooltipContainer>
        </>
      );
    case FOOTER_ACTION_TYPES.SEE_FILES:
      return (
        <Button look="secondary" variation="flat" size="sm" onClick={onRedirectToDetails}>
          Retourner à la commande
        </Button>
      );
    default:
      return <></>;
  }
};
