import * as React from 'react';
import { SECONDARY_COLOR_DARKER } from '../../../style_variables';
import { TooltipContainer } from '@oyp/shared-components';
import { filterObjectProperties } from '@oyp/shared-lib';
import Button from '../../../components/buttons/Button';
import styled from 'styled-components';

interface FilterButtonProps {
  filterType: {
    type: string;
    label: string;
    isActive?: boolean;
    disabled?: boolean;
  };
  handleClick?: (type: string) => void;
}

const FilterButton: React.SFC<FilterButtonProps> = props => {
  const { filterType, handleClick = (a: any) => a } = props;
  const { type, label, isActive, disabled } = filterType;

  if (disabled) {
    return (
      <TooltipContainer
        tooltipContent="Catégorie indisponible"
        Wrapper={wrapperProps => {
          const divProps = filterObjectProperties(wrapperProps, [
            'style',
            'className',
            'onClick',
            'onMouseEnter',
            'onMouseLeave',
          ]);

          return (
            <div {...divProps} ref={element => wrapperProps.refCallback(element)}>
              {wrapperProps.children}
            </div>
          );
        }}
      >
        <StyledFilterButton
          look="primary"
          isActive={isActive}
          onClick={() => handleClick(type)}
          disabled={disabled}
        >
          {label}
        </StyledFilterButton>
      </TooltipContainer>
    );
  }

  return (
    <StyledFilterButton look="primary" isActive={isActive} onClick={() => handleClick(type)}>
      {label}
    </StyledFilterButton>
  );
};

export default FilterButton;

const StyledFilterButton = styled(Button)`
  font-size: 14px;
  padding: 10px 20px;

  &:hover {
    background-color: ${SECONDARY_COLOR_DARKER};
  }

  @media screen and (max-width: 1600px) {
    font-size: 13px;
  }

  ${props =>
    props.isActive &&
    `
      background-color: ${SECONDARY_COLOR_DARKER};
    `}
`;
