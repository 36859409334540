import './GlobalCalculateMessage.scss';
import * as React from 'react';
import {
  CALCULATORS,
  CALCULATOR_VALIDATION_ERROR_MESSAGES,
  CalculatorManufacture,
  CalculatorRequestData,
  cleanFloat,
  cleanInt,
} from '@oyp/shared-lib';
import { MDBIcon } from 'mdbreact';
import { formatter } from '@oyp/shared-components';

const { formatCentimeter } = formatter;

interface GlobalCalculateMessagesProps {
  messages?: string[];
  className?: string;
  manufacture?: CalculatorManufacture;
  requestData?: CalculatorRequestData;
  isLoading?: boolean;
}

const GlobalCalculateMessages: React.SFC<GlobalCalculateMessagesProps> = ({
  messages = [],
  className = '',
  manufacture,
  requestData,
  isLoading,
}) => {
  return !isLoading && messages.length > 0 ? (
    <div className={`global-calculate-message alert alert-warning warning-color ${className}`}>
      {messages.map((message, index) => {
        return (
          <div key={index}>
            {' '}
            <MDBIcon icon="crop" />{' '}
            <Message message={message} manufacture={manufacture} requestData={requestData} />
          </div>
        );
      })}
    </div>
  ) : null;
};

export default GlobalCalculateMessages;

interface MessageProps {
  message: any;
  manufacture?: CalculatorManufacture;
  requestData: CalculatorRequestData;
}
const Message: React.FC<MessageProps> = props => {
  const { message, manufacture, requestData } = props;
  const { quantity, calculator } = requestData;
  const { height } = requestData.dimensions;

  // TODO après refonte Flexible, quand rollCount est correct, utiliser rollCount
  const piecesTotalCount =
    calculator === CALCULATORS.FLEXIBLE
      ? cleanInt(quantity) * manufacture.rollCount
      : manufacture.slabCount;

  const pieceWidth = cleanFloat(manufacture.rollWidth || manufacture.slabWidth);
  const pieceHeight = cleanFloat(manufacture.slabHeight || height);
  const piecesPerUnit = piecesTotalCount / cleanInt(quantity);

  if (typeof message !== 'object') {
    return message;
  }
  switch (message.message) {
    case CALCULATOR_VALIDATION_ERROR_MESSAGES.MULTI_ROLL:
    case CALCULATOR_VALIDATION_ERROR_MESSAGES.MULTI_ROLL_LAMINATION:
      return (
        <span>
          Le visuel sera découpé verticalement en {piecesPerUnit} morceaux de{' '}
          {formatCentimeter(pieceWidth)} x {formatCentimeter(pieceHeight)}.
        </span>
      );
    case CALCULATOR_VALIDATION_ERROR_MESSAGES.MULTI_SLAB:
      return (
        <span>
          Le visuel sera découpé en {piecesPerUnit} morceaux de {formatCentimeter(pieceWidth)} x{' '}
          {formatCentimeter(pieceHeight)}.
        </span>
      );
    default:
      return '';
  }
};
