import { ModelParcel, ModelShipment } from '@oyp/shared-lib';
import { fetchCollection, fetchOne } from '@oyp/shared-components/dist/lib/rest-api/network';
import { parcelApiEndpoint, shipmentApiEnpoint } from '../../module';

export async function fetchParcels(criteria: any = {}) {
  return (await fetchCollection<ModelParcel>(parcelApiEndpoint, criteria)).data;
}

export async function fetchShipmentById(id: string) {
  return fetchOne<ModelShipment>(shipmentApiEnpoint, id);
}
