import './Page.scss';
import * as React from 'react';
import {
  CALCULATORS,
  ModelInputFinishedProduct,
  ModelOrder,
  ModelOrderItem,
  ModelReseller,
  ORDER_DELIVERY_MODES,
  ORDER_ITEM_TYPES,
  ORDER_ITEM_UPDATE_MODE_RECALCULATION,
  PLATFORM_CODES,
  PRODUCTION_MODES,
  QUOTE_ONLY_STATUSES,
  QUOTE_STATUS_CREATED,
} from '@oyp/shared-lib';
import { CartProps, FrontOrder } from '../../../calculator/types';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ProductCategory } from './types';
import { UserContext, UserContextInterface } from '../../../../UserContext';
import { fetchAvailableFinishedProducts } from '../../../calculator/_modules/finished_product/actions';
import {
  initialOrder,
  loadCartOrder,
  recalculateQuoteDates,
  saveOneOrder,
} from '../../actions/order';
import { logError, restApiNetwork } from '@oyp/shared-components';
import { orderApiEndpoint, orderItemApiEndpoint } from '../../module';
import { saveOneOrderItem } from '../../actions/order_item';
import { toast } from 'mdbreact';
import BreadCrumb, { ORDER_BREADCRUMB_STEPS } from '../../../../components/BreadCrumb';
import Button from '../../../../components/buttons/Button';
import CalculatorTypeFilters from './CalculatorTypeFilters';
import CardActionFooter from '../../../../components/CardActionFooter';
import Loader from '../../../../components/Loader';
import Quote from './Quote';

const { deleteOne, saveOne } = restApiNetwork;

type PageProps = RouteComponentProps<{ id: string }>;

interface PageState {
  reseller?: ModelReseller;
  order?: FrontOrder;
  productionMode: PRODUCTION_MODES;
  isInitializing: boolean;
  isLoading: boolean;
  hasLoadingError: boolean;
  selectedCategory: ProductCategory;
  availableFinishedProducts?: ModelInputFinishedProduct[];
  editedItem?: ModelOrderItem;
}

class Page extends React.Component<PageProps, PageState> {
  state: PageState = {
    isInitializing: true,
    isLoading: true,
    hasLoadingError: false,
    selectedCategory: {
      type: ORDER_ITEM_TYPES.CALCULATOR,
      selectedCalculator: CALCULATORS.FLEXIBLE,
    },
    productionMode: PRODUCTION_MODES.STANDARD,
  };
  context: UserContextInterface;

  constructor(props: PageProps, context: UserContextInterface) {
    super(props, context);

    this.loadCart(null, context);

    this.loadCart = this.loadCart.bind(this);
    this.onSaveItem = this.onSaveItem.bind(this);
    this.onRemoveItem = this.onRemoveItem.bind(this);
    this.onStartEditItem = this.onStartEditItem.bind(this);
    this.onCancelEditItem = this.onCancelEditItem.bind(this);

    this.onClearCart = this.onClearCart.bind(this);
    this.onSaveOrder = this.onSaveOrder.bind(this);
    this.changeCategorySelection = this.changeCategorySelection.bind(this);
    this.handleProductionModeChange = this.handleProductionModeChange.bind(this);
  }

  render() {
    const {
      reseller,
      order,
      productionMode,
      isInitializing,
      isLoading,
      hasLoadingError,
      editedItem,
      selectedCategory,
      availableFinishedProducts,
    } = this.state;

    if (isInitializing || hasLoadingError) {
      return <Loader isWholePage error={hasLoadingError} onRetry={() => this.loadCart()} />;
    }

    const cartProps: CartProps = {
      reseller,
      order,
      editedItem,
      productionMode,
      isLoading,
      onSaveItem: this.onSaveItem,
      onRemoveItem: this.onRemoveItem,
      onStartEditItem: this.onStartEditItem,
      onCancelEditItem: this.onCancelEditItem,
      onSaveOrder: this.onSaveOrder,
      onProductionModeChange: this.handleProductionModeChange,
      footer: (
        <CardActionFooter>
          <Button
            look="secondary"
            variation="flat"
            size="sm"
            disabled={!order.orderItems.length || isLoading}
            onClick={this.onClearCart}
            title="Vider le panier"
          >
            Vider le panier
          </Button>
          {!!editedItem ||
          order.orderItems.length === 0 ||
          isLoading ||
          order.taxExcludedTotal === 0 ? (
            <Button disabled look="secondary" size="sm">
              Valider
            </Button>
          ) : (
            <Button as={Link as any} to={`/order/${order.id}/validate`} look="secondary" size="sm">
              Valider
            </Button>
          )}
        </CardActionFooter>
      ),
    };

    return (
      <div className="quote-container breadcrumb-padded">
        <BreadCrumb selectedStepId={ORDER_BREADCRUMB_STEPS.QUOTE} order={order} />
        <Quote
          isLoading={isLoading}
          areFinishedProductsAvailable={availableFinishedProducts.length > 0}
          selectedCategory={selectedCategory}
          cartProps={cartProps}
          typeFilterElement={
            <CalculatorTypeFilters
              onSelection={this.changeCategorySelection}
              selectedCategory={selectedCategory}
              areFinishedProductsAvailable={availableFinishedProducts.length > 0}
            />
          }
        />
      </div>
    );
  }

  async loadCart(orderId: string = null, context: UserContextInterface = null) {
    const { userId, reseller } = context || this.context;
    const actualOrderId = orderId || (this.props.match ? this.props.match.params.id : null);

    try {
      const order = await (context
        ? loadCartOrderWithDateRefresh({ creatorId: userId, orderId: actualOrderId }, reseller)
        : loadCartOrder({ creatorId: userId, orderId: actualOrderId }, reseller));

      if (!QUOTE_ONLY_STATUSES.includes(order.status) && order.id) {
        return this.props.history.push(`/orders/${order.id}/details`);
      }

      const availableFinishedProducts =
        this.state.availableFinishedProducts || (await fetchAvailableFinishedProducts());

      this.setState({
        reseller,
        order,
        availableFinishedProducts,
        productionMode: order.productionMode,
        isInitializing: false,
        isLoading: false,
        hasLoadingError: false,
        editedItem: null,
      });
    } catch (error) {
      logError(error);

      this.setState({
        order: initialOrder,
        isInitializing: false,
        isLoading: false,
        hasLoadingError: true,
        editedItem: null,
      });
    }
  }

  async onSaveItem(orderItemSource: ModelOrderItem) {
    try {
      this.setState({
        isLoading: true,
      });

      const order = this.state.order.id
        ? this.state.order
        : await saveOneOrder({
            status: QUOTE_STATUS_CREATED,
            creatorId: this.context.userId,
            platformCode: PLATFORM_CODES.PRINT_CENTER,
            resellerUserId: this.context.resellerUserId,
            deliveryMode: ORDER_DELIVERY_MODES.GROUP_WITH_RECALCULATION,
          } as ModelOrder);

      const orderItemData = {
        ...(this.state.editedItem ? this.state.editedItem : {}),
        ...orderItemSource,
        orderId: order.id,
      };

      await saveOneOrderItem(
        orderItemSource.id
          ? {
              ...orderItemData,
              mode: ORDER_ITEM_UPDATE_MODE_RECALCULATION,
            }
          : orderItemData
      );
      await this.loadCart(order.id);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      toast.error('Une erreur est survenue', 2000);
    }
  }

  async onRemoveItem(orderItem: ModelOrderItem) {
    await deleteOne(orderItemApiEndpoint, orderItem);
    await this.loadCart();
  }

  async onStartEditItem(orderItem: ModelOrderItem) {
    const newCategory = getOrderItemCategory(orderItem);

    this.setState({
      ...this.state,
      editedItem: orderItem,
      selectedCategory: newCategory,
    });
  }

  async onCancelEditItem() {
    this.setState({
      ...this.state,
      editedItem: null,
    });
  }

  async onClearCart() {
    const { order } = this.state;
    const { orderItems } = order;

    await Promise.all(orderItems.map(orderItem => deleteOne(orderItemApiEndpoint, orderItem)));

    await this.loadCart();
  }

  async onSaveOrder(order: ModelOrder) {
    await saveOne(orderApiEndpoint, order);
    await this.loadCart();
  }

  async handleProductionModeChange(newProductionMode: PRODUCTION_MODES) {
    const { order } = this.state;

    this.setState({
      isLoading: true,
    });
    await saveOneOrder({ ...order, productionMode: newProductionMode });
    const updatedOrder = await loadCartOrder({ orderId: order.id }, this.context.reseller);

    this.setState({
      ...this.state,
      isLoading: false,
      order: updatedOrder,
      productionMode: newProductionMode,
    });
  }

  changeCategorySelection(newCategory: ProductCategory) {
    this.setState({
      ...this.state,
      selectedCategory: newCategory,
      editedItem: null,
    });
  }
}
Page.contextType = UserContext;

export default Page;

async function loadCartOrderWithDateRefresh(queryObject: any, reseller: ModelReseller) {
  const order = await loadCartOrder(queryObject, reseller);
  if (!order.id) {
    return order;
  }

  await recalculateQuoteDates(order.id);

  return loadCartOrder(queryObject, reseller);
}

function getOrderItemCategory(orderItem: ModelOrderItem): ProductCategory {
  switch (orderItem.orderItemType) {
    case ORDER_ITEM_TYPES.CALCULATOR:
      return {
        type: ORDER_ITEM_TYPES.CALCULATOR,
        selectedCalculator: orderItem.requestData.calculator,
      };
    default:
      return {
        type: orderItem.orderItemType,
      };
  }
}
