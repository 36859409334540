import * as cookie from 'react-cookies';
import {
  AUTHORIZED_INPUT_ARCHIVE_EXTENSIONS,
  ModelCustomerFile,
  ModelCustomerFileVersion,
  isMimetypeAuthorized,
  isSizeAuthorized,
  sortByCreatedAtAsc,
  sortByCreatedAtDesc,
} from '@oyp/shared-lib';
import { AUTH_COOKIE_NAME } from '../../env-config';
import { ActionCreatorFn } from '../../utils/redux';
import { Dispatch } from 'redux';
import {
  customerFileApiEndpoint,
  customerFileArchiveUploadApiEndpoint,
  customerFileVersionApiEndpoint,
} from '../order/module';
import { deleteOne, fetchOne } from '@oyp/shared-components/dist/lib/rest-api/network';
import { logError, restApiNetwork } from '@oyp/shared-components';
import { toast } from 'mdbreact';

const { fetchCollection, AUTH_TOKEN_HEADER_NAME } = restApiNetwork;

export enum CUSTOMER_FILE_ACTIONS {
  FETCH = 'CUSTOMER_FILE_FETCH',
  FETCH_SUCCESS = 'CUSTOMER_FILE_FETCH_SUCCESS',
  FETCH_FAILURE = 'CUSTOMER_FILE_FETCH_FAILURE',
  DELETE = 'CUSTOMER_FILE_DELETE',
  DELETE_SUCCESS = 'CUSTOMER_FILE_DELETE_SUCCESS',
  DELETE_FAILURE = 'CUSTOMER_FILE_DELETE_FAILURE',
  UPLOAD_INIT = 'CUSTOMER_FILE_UPLOAD_INIT',
  UPLOAD_PROGRESS = 'CUSTOMER_FILE_UPLOAD_PROGRESS',
  UPLOAD_CANCEL = 'CUSTOMER_FILE_UPLOAD_CANCEL',
  UPLOAD_SUCCESS = 'CUSTOMER_FILE_UPLOAD_SUCCESS',
  UPLOAD_FAILURE = 'CUSTOMER_FILE_UPLOAD_FAILURE',
  DISMISS_REJECTED = 'CUSTOMER_FILE_DISMISS_REJECTED',
}

export type CustomerFileAction =
  | { type: CUSTOMER_FILE_ACTIONS.FETCH }
  | {
      type: CUSTOMER_FILE_ACTIONS.FETCH_SUCCESS;
      uploadedFiles: CustomerFileWithVersions[];
    }
  | { type: CUSTOMER_FILE_ACTIONS.FETCH_FAILURE }
  | { type: CUSTOMER_FILE_ACTIONS.DELETE; customerFileId: string }
  | { type: CUSTOMER_FILE_ACTIONS.DELETE_SUCCESS; customerFileId: string }
  | { type: CUSTOMER_FILE_ACTIONS.DELETE_FAILURE; customerFileId: string }
  | {
      type: CUSTOMER_FILE_ACTIONS.UPLOAD_INIT;
      file: File;
      xhr: XMLHttpRequest;
      orderItemId: string;
      previousCustomerFileId?: string;
    }
  | {
      type: CUSTOMER_FILE_ACTIONS.UPLOAD_PROGRESS;
      file: File;
      xhr: XMLHttpRequest;
      orderItemId: string;
      percentComplete: number;
    }
  | {
      type: CUSTOMER_FILE_ACTIONS.UPLOAD_CANCEL;
      file: File;
      xhr: XMLHttpRequest;
      orderItemId: string;
    }
  | {
      type: CUSTOMER_FILE_ACTIONS.UPLOAD_SUCCESS;
      file: File;
      xhr: XMLHttpRequest;
      customerFile: CustomerFileWithVersions;
    }
  | {
      type: CUSTOMER_FILE_ACTIONS.UPLOAD_FAILURE;
      file: File;
      xhr: XMLHttpRequest;
      orderItemId: string;
    }
  | {
      type: CUSTOMER_FILE_ACTIONS.DISMISS_REJECTED;
      file: File;
    };

type CustomerFileDispatchFn = Dispatch<CustomerFileAction>;
type CustomerFileActionCreator = ActionCreatorFn<CustomerFileDispatchFn>;

export type CustomerFileFetchFn = (orderItemId: string) => CustomerFileActionCreator;
export const customerFileFetch: CustomerFileFetchFn = orderItemId => {
  return async (dispatch: CustomerFileDispatchFn) => {
    dispatch({
      type: CUSTOMER_FILE_ACTIONS.FETCH,
    });

    try {
      const customerFiles = await fetchCustomerFiles({ orderItemId });

      dispatch({
        type: CUSTOMER_FILE_ACTIONS.FETCH_SUCCESS,
        uploadedFiles: customerFiles,
      });
    } catch (error) {
      logError(error);
      dispatch({
        type: CUSTOMER_FILE_ACTIONS.FETCH_FAILURE,
      });
    }
  };
};

export type CustomerFileDeleteFn = (customerFileId: string) => CustomerFileActionCreator;
export const customerFileDelete: CustomerFileDeleteFn = customerFileId => {
  return async (dispatch: CustomerFileDispatchFn) => {
    dispatch({
      type: CUSTOMER_FILE_ACTIONS.DELETE,
      customerFileId,
    });

    try {
      await deleteOne(customerFileApiEndpoint, { id: customerFileId });

      dispatch({
        type: CUSTOMER_FILE_ACTIONS.DELETE_SUCCESS,
        customerFileId,
      });
      toast('Le fichier a été supprimé', 2000);
    } catch (error) {
      logError(error);
      dispatch({
        type: CUSTOMER_FILE_ACTIONS.DELETE_FAILURE,
        customerFileId,
      });
    }
  };
};

export type CustomerFileUploadFn = (
  file: File,
  orderItemId: string,
  previousCustomerFileId?: string
) => CustomerFileActionCreator;
export const customerFileUpload: CustomerFileUploadFn = (
  file,
  orderItemId,
  previousCustomerFileId
) => {
  return async (dispatch: CustomerFileDispatchFn) => {
    const xhr = new XMLHttpRequest();

    try {
      if (!isMimetypeAuthorized(file.type || file.name) || !isSizeAuthorized(file.size)) {
        return dispatch({
          type: CUSTOMER_FILE_ACTIONS.UPLOAD_FAILURE,
          file,
          xhr,
          orderItemId,
        });
      }

      const uploadUrl = AUTHORIZED_INPUT_ARCHIVE_EXTENSIONS.some(authorizedExtension =>
        file.name.includes(authorizedExtension)
      )
        ? customerFileArchiveUploadApiEndpoint
        : previousCustomerFileId
        ? customerFileVersionApiEndpoint
        : customerFileApiEndpoint;

      const additionalFormData = previousCustomerFileId
        ? {
            customerFileId: previousCustomerFileId,
          }
        : {
            orderItemId,
          };

      xhr.addEventListener('load', function(this: any) {
        // Hack pour accommoder les tests dans lesquels le callback n'est pas bindé
        // this.responseText peut-être à undefined visiblement

        const self = this as any;

        const responseText = self && self.responseText ? self.responseText : xhr.responseText;

        const parsedResponse = JSON.parse(responseText);

        //Gère les erreurs dans les fichiers archives
        if (parsedResponse.hasOwnProperty('errors') && parsedResponse.errors.length > 0) {
          toast("L'archive contient des éléments invalides", 2000);
          dispatch({
            type: CUSTOMER_FILE_ACTIONS.UPLOAD_FAILURE,
            file,
            xhr,
            orderItemId,
          });
        }

        //Gère le cas des fichiers multiples dans les archives
        const customerFiles = parsedResponse.hasOwnProperty('customerFiles')
          ? parsedResponse.customerFiles
          : [].concat(parsedResponse);

        for (const customerFile of customerFiles) {
          const customerFileId = previousCustomerFileId
            ? customerFile.customerFileId
            : customerFile.id;

          fetchCustomerFileWithVersions(customerFileId).then(customerFileWithVersions => {
            dispatch({
              type: CUSTOMER_FILE_ACTIONS.UPLOAD_SUCCESS,
              file,
              xhr,
              customerFile: customerFileWithVersions,
            });
          });
        }
      });

      xhr.addEventListener('error', () => {
        dispatch({
          type: CUSTOMER_FILE_ACTIONS.UPLOAD_FAILURE,
          file,
          xhr,
          orderItemId,
        });
      });

      xhr.addEventListener('abort', () => {
        dispatch({
          type: CUSTOMER_FILE_ACTIONS.UPLOAD_CANCEL,
          file,
          xhr,
          orderItemId,
        });
      });

      xhr.upload.addEventListener('progress', (oEvent: any) => {
        if (oEvent.lengthComputable) {
          const percentComplete = oEvent.loaded / oEvent.total;
          dispatch({
            type: CUSTOMER_FILE_ACTIONS.UPLOAD_PROGRESS,
            xhr,
            percentComplete,
            file,
            orderItemId,
          });
        }
      });

      xhr.open('POST', uploadUrl, true);

      xhr.setRequestHeader(AUTH_TOKEN_HEADER_NAME, cookie.load(AUTH_COOKIE_NAME));

      const formData = new FormData();
      formData.append('file', file);
      Object.keys(additionalFormData).forEach(key => {
        formData.append(key, additionalFormData[key]);
      });

      xhr.send(formData);

      return dispatch({
        type: CUSTOMER_FILE_ACTIONS.UPLOAD_INIT,
        file,
        xhr,
        orderItemId,
        previousCustomerFileId,
      });
    } catch (error) {
      logError(error);
      return dispatch({
        type: CUSTOMER_FILE_ACTIONS.UPLOAD_FAILURE,
        file,
        orderItemId,
        xhr,
      });
    }
  };
};

// Network functions

export interface CustomerFileWithVersions extends ModelCustomerFile {
  versions: ModelCustomerFileVersion[];
  thumbnailUrl?: string;
  name: string;
}

export const fetchCustomerFiles = async (
  initialQueryObject = {}
): Promise<CustomerFileWithVersions[]> => {
  return fetchCollection<ModelCustomerFile>(customerFileApiEndpoint, initialQueryObject).then(
    async ({ data: customerFiles }) => {
      return (await Promise.all(customerFiles.map(fetchAndAddCustomerFileVersions))).sort(
        sortByCreatedAtAsc
      );
    }
  );
};

export async function fetchCustomerFileWithVersions(
  customerFileId: string
): Promise<CustomerFileWithVersions> {
  const [customerFile, versions] = await Promise.all([
    fetchOne<ModelCustomerFile>(customerFileApiEndpoint, customerFileId),
    fetchCollection<ModelCustomerFileVersion>(customerFileVersionApiEndpoint, {
      customerFileId,
    }),
  ]);

  return mapCustomerFileWithVersions(customerFile, versions.data);
}

async function fetchAndAddCustomerFileVersions(
  customerFile: ModelCustomerFile
): Promise<CustomerFileWithVersions> {
  const fileVersions = (
    await fetchCollection<ModelCustomerFileVersion>(customerFileVersionApiEndpoint, {
      customerFileId: customerFile.id,
    })
  ).data;

  return mapCustomerFileWithVersions(customerFile, fileVersions);
}

function mapCustomerFileWithVersions(
  customerFile: ModelCustomerFile,
  inputVersions: ModelCustomerFileVersion[]
): CustomerFileWithVersions {
  const fileVersions = inputVersions.sort(sortByCreatedAtDesc);
  const latestVersion = fileVersions.length > 0 ? fileVersions[0] : null;

  return {
    ...customerFile,
    versions: fileVersions,
    name: latestVersion ? latestVersion.originName : '',
    thumbnailUrl: latestVersion ? latestVersion.thumbnailUrl : null,
  };
}
