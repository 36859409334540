"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var constants_1 = require("../constants");

var types_1 = require("../types");

var DATE_ONE_DAY = 86400000;
/*const noShippingDays = [
  //new Date('2020-12-07'),
];*/

exports.getDeliveryDate = function (delay, startDate, productionMode, shippingMode, transporter) {
  var actualDelay = delay; // On vérifie que l'heure de commande soit inférieure à 10h en semaine sinon on rajoute 1 jour

  var hour = startDate.getHours();
  var dayOfTheWeek = startDate.getDay();

  if (hour >= constants_1.LIMIT_SHORT_DELAY_HOUR && [0, 6].indexOf(dayOfTheWeek) < 0) {
    actualDelay++;
  }

  var deliveryDate = getDateWithOffDays(startDate, actualDelay);
  var shippingDate = exports.getShippingDateFromDeliveryDate(deliveryDate, productionMode, shippingMode, transporter); //Specific case for holidays
  // utilisé pour l'été 2021 mais manque de certitudes ....
  //const holidayStart = new Date('2021-12-28').getTime(); //Il faut renseigner la date de fermeture + le nombre de jours ouvrés en dpd (2 jours) => donc si fermeture le 24/12/21 => on renseigne 28/12/21
  //const holidayEnd = new Date('2022-01-02').getTime();
  //const newStartDateTime = new Date('2022-01-03').getTime();

  var holidayStart = new Date('2022-12-27').getTime(); //Il faut renseigner la date de fermeture + le nombre de jours ouvrés en dpd (2 jours) => donc si fermeture le 24/12/21 => on renseigne 28/12/21

  var holidayEnd = new Date('2023-01-01').getTime();
  var newStartDateTime = new Date('2023-01-02').getTime();
  var shippingDateTime = new Date(shippingDate).getTime(); //console.log('actualDelay', actualDelay);

  if (holidayStart < shippingDateTime && shippingDateTime < holidayEnd) {
    //nombre de jours restants entre la date d'expé et la date de vacances
    var delayDaysRest = Math.ceil((shippingDateTime - holidayStart) / (1000 * 3600 * 24));
    var newDate = exports.addDays(delayDaysRest, newStartDateTime);
    return newDate; //not used - getDateWithOffDays(newStartDate, actualDelay);
  } // not used - if (noShippingDays.find(noShippingDay => isSameDay(noShippingDay, shippingDate))) {
  // actualDelay++;
  //}
  //Specific case pour 1 jour fermé mais livraison possible => ok

  /*
  const startDateTime = new Date(startDate).getTime();
  const shippingDateTime = new Date(shippingDate).getTime();
  const closedDayTime = new Date('2022-05-27').getTime();
  if (startDateTime <= closedDayTime && closedDayTime <= shippingDateTime) {
    actualDelay++;
  }
  */


  return getDateWithOffDays(startDate, actualDelay);
};

exports.getShippingDateFromDeliveryDate = function (deliveryDate, productionMode, shippingMode, transporter) {
  var daysToRemove = getDaysToRemoveForShippingDate(productionMode, shippingMode, transporter);
  var date = getDateWithOffDays(deliveryDate, -daysToRemove);
  date.setHours(12);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

var getDateWithOffDays = function getDateWithOffDays(startDate, delay) {
  var startDateTimestamp = startDate.getTime();
  var totalDays = getDelayWithOffDays(startDate, delay);
  return exports.addDays(totalDays, startDateTimestamp);
};

var getDelayWithOffDays = function getDelayWithOffDays(startDate, initialDelay) {
  var timestamp = startDate.getTime();
  var isDelayPositive = initialDelay > 0;
  var delay = initialDelay; // On vérifie le nombre de jours chomés sur la période (y compris les jours fériés)

  var i = 0;

  var whileCondition = function whileCondition(iterationCount, givenDelay) {
    return isDelayPositive ? iterationCount <= givenDelay : iterationCount >= givenDelay;
  };

  while (whileCondition(i, delay)) {
    var currentDate = exports.addDays(i, timestamp); // On vérifie que le jour n'est pas un samedi ou un dimanche ou un jour férié

    var day = currentDate.getDay();

    if (day === 0 || day === 6 || exports.isHoliday(currentDate)) {
      delay = isDelayPositive ? delay + 1 : delay - 1;
    }

    i = isDelayPositive ? i + 1 : i - 1;
  }

  return delay;
};

var getDaysToRemoveForShippingDate = function getDaysToRemoveForShippingDate(productionMode, shippingMode, transporter) {
  if (productionMode === types_1.PRODUCTION_MODES.STANDARD && shippingMode === types_1.SHIPPING_MODES.TRANSPORTER && transporter !== types_1.TRANSPORTERS.PALLET) {
    return 2;
  }

  return 1;
};

exports.isHoliday = function (date) {
  var utcDate = makeUTCDate(date.getFullYear(), date.getMonth(), date.getDate());
  var foundHoliday = getHolidays(date.getFullYear()).find(function (holiday) {
    return utcDate.getTime() === holiday.getTime();
  });
  return !!foundHoliday;
};

var getHolidays = function getHolidays(year) {
  var currentYear = year;
  var nextYear = currentYear + 1;
  var easterDate = getEasterDate(currentYear);
  var easterTimestamp = easterDate.getTime();
  return [//Attention aux index des mois : cela commence à 0 et non à 1 /!\
  makeUTCDate(currentYear, 0, 1), makeUTCDate(nextYear, 0, 1), exports.addDays(1, easterTimestamp), makeUTCDate(currentYear, 4, 1), makeUTCDate(currentYear, 4, 8), exports.addDays(39, easterTimestamp), exports.addDays(50, easterTimestamp), makeUTCDate(currentYear, 6, 14), //makeUTCDate(currentYear, 6, 15), // Jour après 14/07
  //makeUTCDate(currentYear, 7, 5), // 05/08 (intervention Zund)
  //makeUTCDate(currentYear, 7, 14), //Pont du 14/08/23
  makeUTCDate(currentYear, 7, 15), makeUTCDate(currentYear, 10, 1), makeUTCDate(currentYear, 10, 11), makeUTCDate(currentYear, 11, 25), makeUTCDate(2023, 11, 29)];
};

exports.addDays = function (days, dateTimestamp) {
  var timestamp = dateTimestamp ? dateTimestamp : new Date().getTime();
  return new Date(timestamp + days * DATE_ONE_DAY);
};

var getEasterDate = function getEasterDate(year) {
  var a = (year / 100 | 0) * 1483 - (year / 400 | 0) * 2225 + 2613;
  var b = ((year % 19 * 3510 + (a / 25 | 0) * 319) / 330 | 0) % 29;
  var c = 148 - b - ((year * 5 / 4 | 0) + a - b) % 7;
  var month = (c / 31 | 0) - 1;
  var day = c % 31 + 1;
  return makeUTCDate(year, month, day);
};

var makeUTCDate = function makeUTCDate(year, month, day) {
  return new Date(Date.UTC(year, month, day));
};
/*function isSameDay(date1: Date, date2: Date) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}*/