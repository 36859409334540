import * as React from 'react';
import {
  DANGER_COLOR,
  DANGER_COLOR_DARKER,
  PRIMARY_COLOR,
  SECONDARY_COLOR_DARKER,
} from '../../style_variables';
import { Style } from 'jss/css';
import styled from 'styled-components';

interface Props {
  iconName?: string;
  as?: string | React.ComponentType;
  className?: string;
  iconClassName?: string;
  look?: 'primary' | 'danger';

  // Propriétés des éléments HTML
  onClick?: (event?: any) => void;
  style?: Style;
  disabled?: boolean;
  tabIndex?: number;
  to?: string;
  href?: string;
  download?: boolean;
}

const InlineIconButton: React.FC<Props> = props => {
  const {
    iconName,
    onClick = () => {},
    style = {},
    className = '',
    iconClassName = '',
    look = 'primary',
    children,
  } = props;

  // On garde 'inline-icon-button' pour les styles externes qui dépendent de cette classe
  const classNames = [className, 'inline-icon-button'];

  return (
    <StyledInlineIconButton
      {...props}
      className={classNames.join(' ')}
      onClick={onClick}
      style={style}
      look={look}
    >
      {iconName && <i className={`material-icons ${iconClassName}`}>{iconName}</i>}
      {children}
    </StyledInlineIconButton>
  );
};

export default InlineIconButton;

const StyledInlineIconButton: React.FC<Props> = styled.button<Props>`
  display: inline-block;
  width: 24px;
  height: 24px;
  padding: 0;
  box-shadow: none;
  border: none;
  background: transparent;
  margin: 0;
  cursor: pointer;

  ${({ look }) =>
    look === 'primary' &&
    `
  i {
    color: ${PRIMARY_COLOR};
    &:hover {
      color: ${SECONDARY_COLOR_DARKER};
    }
  }
`}

  ${({ look }) =>
    look === 'danger' &&
    `
  i {
    color: ${DANGER_COLOR};
    &:hover {
      color: ${DANGER_COLOR_DARKER};
    }
  }
`}

  &:disabled {
    .material-icons {
      opacity: 0.3;
    }
  }
`;
