export const PRIMARY_COLOR = '#00838f';
export const PRIMARY_COLOR_LIGHTER = '#4fb3bf';
export const PRIMARY_COLOR_DARKER = '#005762';

export const SECONDARY_COLOR = '#ad1457';
export const SECONDARY_COLOR_LIGHTER = '#cb396f';
export const SECONDARY_COLOR_DARKER = '#560027';

export const TEXT_COLOR = '#212121';
export const DARK_GREY = '#656565';

export const DANGER_COLOR_BACKGROUND = '#f8d7da';
export const DANGER_COLOR_BORDER = '#f5c6cb';
export const DANGER_COLOR = '#ff4444';
export const DANGER_COLOR_DARKER = '#cc0000';

export const WARNING_COLOR = '#ffbb33';
export const WARNING_COLOR_DARKER = '#ff8c00';

export const SUCCESS_COLOR = '#00c851';
export const SUCCESS_COLOR_DARKER = '#007e33';

export const INFO_COLOR = '#33b5e5';
export const INFO_COLOR_DARK = '#0099cc';
