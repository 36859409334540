import * as React from 'react';
import { UPLOAD_STATUSES } from './constants';
import styles from './UploadStatusChip.module.scss';

interface DownloadStatusChipProps {
  status: UPLOAD_STATUSES;
  className?: string;
}

const UploadStatusChip: React.SFC<DownloadStatusChipProps> = props => {
  const givenClassNames = props.className ? [props.className] : [];
  const classes = [styles['download-chip'], CLASS_FOR_STATUS[props.status], ...givenClassNames];

  return <span className={classes.join(' ')} />;
};

export default UploadStatusChip;

const CLASS_FOR_STATUS = {
  [UPLOAD_STATUSES.SUCCESS]: styles.success,
  [UPLOAD_STATUSES.PENDING]: styles.pending,
  [UPLOAD_STATUSES.ERROR]: styles.error,
};
