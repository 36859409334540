import {
  CALCULATOR_VALIDATION_ERROR_MESSAGES,
  VALIDATION_ERROR_MESSAGES,
  ValidationPropertyError,
} from '@oyp/shared-lib';

const translateRemoteMessage = (error: ValidationPropertyError) => {
  switch (error.message) {
    case CALCULATOR_VALIDATION_ERROR_MESSAGES.MULTI_ROLL:
    case CALCULATOR_VALIDATION_ERROR_MESSAGES.MULTI_SLAB:
      if (error.property === 'width') {
        return `Largeur max. de lé : ${error.maxWidth} cm`;
      } else {
        return `Longueur max. de lé : ${error.maxHeight} cm`;
      }
    case CALCULATOR_VALIDATION_ERROR_MESSAGES.MULTI_ROLL_LAMINATION:
      return `Largeur max. de lé de lamination : ${error.maxLaminatedWidth} cm`;
    case VALIDATION_ERROR_MESSAGES.INVALID_VALUE:
      return `Valeur invalide`;
    case VALIDATION_ERROR_MESSAGES.REQUIRED:
      return `Requis`;
    case VALIDATION_ERROR_MESSAGES.TOO_LOW:
      return `Valeur trop basse${error.min ? ` (min ${error.min})` : ''}`;
    case VALIDATION_ERROR_MESSAGES.TOO_HIGH:
      if (error.property === 'surface') {
        return `Surface trop grande`;
      } else if (error.property === 'eyeletInterval') {
        return `L'espacement des oeillets dépasse les dimensions entrées`;
      }
      return `Valeur trop haute${error.max ? ` (max ${error.max})` : ''}`;
    case CALCULATOR_VALIDATION_ERROR_MESSAGES.MULTI_SLAB_UNAVAILABLE:
      return `Max dim. ${error.maxWidth} x ${error.maxHeight} cm`;
    case CALCULATOR_VALIDATION_ERROR_MESSAGES.MULTI_SLAB_PERFORATION:
      return `Appliquée à chaque morceau`;

    default:
      return error.message;
  }
};

export const getRemoteMessages = (errors: ValidationPropertyError[]) => {
  return errors.reduce(
    (acc, error) => {
      if (error.property) {
        acc[error.property] = translateRemoteMessage(error);
      } else {
        acc.global.push(translateRemoteMessage(error));
      }
      // Si on a à la fois MULTI_ROLL et MULTI_ROLL_LAMINATION, il ne faut en mettre qu'un
      if (
        [
          CALCULATOR_VALIDATION_ERROR_MESSAGES.MULTI_ROLL,
          CALCULATOR_VALIDATION_ERROR_MESSAGES.MULTI_ROLL_LAMINATION,
          CALCULATOR_VALIDATION_ERROR_MESSAGES.MULTI_SLAB,
        ].indexOf(error.message as CALCULATOR_VALIDATION_ERROR_MESSAGES) > -1 &&
        !acc.global.find(err => err.message === CALCULATOR_VALIDATION_ERROR_MESSAGES.MULTI_ROLL) &&
        !acc.global.find(
          err => err.message === CALCULATOR_VALIDATION_ERROR_MESSAGES.MULTI_ROLL_LAMINATION
        ) &&
        !acc.global.find(err => err.message === CALCULATOR_VALIDATION_ERROR_MESSAGES.MULTI_SLAB)
      ) {
        acc.global.push(error);
      }
      return acc;
    },
    { global: [] }
  ) as { global: string[]; [key: string]: any };
};

export function getCalculatorErrors(errors: ValidationPropertyError[] = []) {
  return [...errors, ...validateFrontRequestData()];
}

interface PropertyError {
  property: string;
  message: string;
}

// TODO élucider ce mystère
function validateFrontRequestData() {
  const errors: PropertyError[] = [];

  return errors;
}
