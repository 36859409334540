"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SECONDS_IN_A_DAY = 86400;

exports.isToday = function (date) {
  var dateObject = new Date(date);
  var now = new Date();
  return isSameDayMonthAndYear(dateObject, now);
};

exports.isYesterday = function (date) {
  var dateObject = new Date(date);
  var yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return isSameDayMonthAndYear(dateObject, yesterday);
};

function isTomorrow(date) {
  var dateObject = new Date(date);
  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return isSameDayMonthAndYear(dateObject, tomorrow);
}

exports.isTomorrow = isTomorrow;

function getDaysDifference(date1) {
  var date2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();
  var date1Object = new Date(date1);
  var date2Object = new Date(date2);
  date1Object.setUTCHours(0, 0, 0, 0);
  date2Object.setUTCHours(0, 0, 0, 0);
  return (date1Object.getTime() - date2Object.getTime()) / (exports.SECONDS_IN_A_DAY * 1000);
}

exports.getDaysDifference = getDaysDifference;

function getDaysDifferenceLabel(date1) {
  var date2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();
  var daysDifference = getDaysDifference(date1, date2);
  var dayLabel = Math.abs(daysDifference);

  if (daysDifference === 0) {
    return '-';
  } else if (daysDifference > 0) {
    return "J+".concat(dayLabel);
  } else {
    return "il y a ".concat(dayLabel);
  }
}

exports.getDaysDifferenceLabel = getDaysDifferenceLabel;

function isSameDayMonthAndYear(date1, date2) {
  return date1.getDate() === date2.getDate() && isSameMonthAndYear(date1, date2);
}

var isSameMonthAndYear = function isSameMonthAndYear(date1, date2) {
  return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
};