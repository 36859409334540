"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.getArrayFromEnum = function (inputEnum) {
  return Object.keys(inputEnum).map(function (k) {
    return inputEnum[k];
  });
};