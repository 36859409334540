import './BreadCrumb.scss';
import * as React from 'react';
import { BILLING_MODES, ModelOrder } from '@oyp/shared-lib';
import StyledLink from './StyledLink';

interface BreadCrumbProps {
  selectedStepId: ORDER_BREADCRUMB_STEPS;
  order: ModelOrder;
}

export enum ORDER_BREADCRUMB_STEPS {
  QUOTE = 'QUOTE',
  ADDRESS = 'ADDRESS',
  FILES = 'FILES',
  PAYMENT = 'PAYMENT',
  RECAP = 'RECAP',
}

interface BreadCrumbStep {
  id: ORDER_BREADCRUMB_STEPS;
  num: number;
  title: string;
  link: string;
}

const BreadCrumb: React.FC<BreadCrumbProps> = props => {
  const { order, selectedStepId } = props;

  const breadCrumbStepsBase = [
    {
      title: 'Produit',
      link: order.id ? `/quote/${order.id}` : '/',
      id: ORDER_BREADCRUMB_STEPS.QUOTE,
    },
    {
      title: 'Adresse',
      link: order.id ? `/order/${order.id}/validate` : '/',
      id: ORDER_BREADCRUMB_STEPS.ADDRESS,
    },
    {
      title: 'Fichiers',
      link: `/order/${order.id}/upload-files`,
      id: ORDER_BREADCRUMB_STEPS.FILES,
    },
  ];

  if (order.billingMode === BILLING_MODES.IMMEDIATE) {
    breadCrumbStepsBase.push({
      title: 'Paiement',
      link: `/order/${order.id}/payment`,
      id: ORDER_BREADCRUMB_STEPS.PAYMENT,
    });
  }

  breadCrumbStepsBase.push({ title: 'Récapitulatif', link: '/', id: ORDER_BREADCRUMB_STEPS.RECAP });

  const breadCrumbSteps: BreadCrumbStep[] = breadCrumbStepsBase.map((breadcrumbBase, index) => ({
    ...breadcrumbBase,
    num: index + 1,
  }));

  return (
    <div>
      <div className="reseller-app-page-title">COMMANDE</div>
      <div className="breadcrumb-container">
        <div>
          {breadCrumbSteps.map((stepProps, index) => (
            <BreadcrumbStep
              key={index}
              currentStep={stepProps}
              selectedStep={breadCrumbSteps.find(step => step.id === selectedStepId)}
              isLastStep={index === breadCrumbSteps.length - 1}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
// currentStepNumber
// selectedStepNumber

function circleColor(selectedStepNumber: number, currentStepNumber: number) {
  switch (true) {
    case currentStepNumber === selectedStepNumber:
      return 'round selected';
    case currentStepNumber < selectedStepNumber:
      return 'round';
    case currentStepNumber > selectedStepNumber:
      return 'round inactive';
    default:
      return '';
  }
}

function titleColor(selectedStepNumber: number, currentStepNumber: number) {
  switch (true) {
    case currentStepNumber === selectedStepNumber:
      return 'text';
    case currentStepNumber < selectedStepNumber:
      return 'text';
    case currentStepNumber > selectedStepNumber:
      return 'text inactive';
    default:
      return '';
  }
}

export default BreadCrumb;

interface BreadCrumbStepProps {
  selectedStep: BreadCrumbStep;
  currentStep: BreadCrumbStep;
  isLastStep?: boolean;
}

const BreadcrumbStep: React.FC<BreadCrumbStepProps> = props => {
  const { selectedStep, currentStep, isLastStep } = props;

  const isInactive = currentStep.num > selectedStep.num;
  const isCurrentStep = currentStep.num === selectedStep.num;

  return (
    <div className="step">
      <BreadCrumbLink
        className={`step-elements text-link ${isInactive ? 'inactive' : ''}`}
        link={isCurrentStep ? '#' : currentStep.link}
        disabled={isInactive}
      >
        <div className={circleColor(selectedStep.num, currentStep.num)}>{currentStep.num}</div>
        <div className={titleColor(selectedStep.num, currentStep.num)}> {currentStep.title}</div>
      </BreadCrumbLink>
      {!isLastStep && <div className="ligne" />}
    </div>
  );
};

interface BreadCrumbLinkProps {
  disabled: boolean;
  className?: string;
  link?: string;
}

const BreadCrumbLink: React.FC<BreadCrumbLinkProps> = props =>
  props.disabled ? (
    <div className={props.className}>{props.children}</div>
  ) : (
    <StyledLink className={props.className} to={props.link}>
      {props.children}
    </StyledLink>
  );
