import { centralStationApiEndpoint } from '../../env-config';

export const apiEndpoint = centralStationApiEndpoint;
const moduleStatePath = ['api', 'OrderModule'];

// Entités
export const orderApiEndpoint = `${apiEndpoint}/orders`;
export const orderItemApiEndpoint = `${apiEndpoint}/order-items`;
export const shippingAddressApiEndpoint = `${apiEndpoint}/shipping-addresses`;
export const shipmentApiEndpoint = `${apiEndpoint}/shipments`;
export const parcelApiEndpoint = `${apiEndpoint}/parcels`;
export const orderItemEventApiEndpoint = `${apiEndpoint}/order-item-events`;
export const orderChangeNotificationApiEndpoint = `${apiEndpoint}/order-change-notifications`;
export const paymentApiEndpoint = `${apiEndpoint}/payments`;

// Opérations
export const orderRecalculateDatesApiEndpoint = `${apiEndpoint}/order-recalculate-dates`;
export const renewOrderApiEndpoint = `${apiEndpoint}/renew-order`;
export const checkOrderRenewalApiEndpoint = `${apiEndpoint}/check-renewal`;
export const createOrderAddressApiEndpoint = `${apiEndpoint}/create-order-address`;

export const orderStatePath = [...moduleStatePath, 'Order'];
export const orderItemStatePath = [...moduleStatePath, 'ModelOrderItem'];
export const shipmentStatePath = [...moduleStatePath, 'Shipment'];
export const parcelStatePath = [...moduleStatePath, 'Parcels'];
