import {
  BILLING_MODES,
  ModelOrder,
  ModelOrderItem,
  ORDER_ITEM_CANCELLABLE_STATUSES,
  ORDER_STATUS_SHIPPED,
  PRODUCTION_MODE_TRANSLATIONS,
  SHIPPING_MODE_TRANSLATIONS,
} from '@oyp/shared-lib';
import { DANGER_COLOR } from '../../../style_variables';
import { Icon } from 'react-materialize';
import { Link } from 'react-router-dom';
import { ORDER_INVALIDITY_REASONS, getInvalidityReason, getOrderStatusLabel } from '../utils';
import { OrderWithProprietaryEntities } from '../../calculator/types';
import { TooltipContainer, formatter } from '@oyp/shared-components';
import { getUserDisplayName } from '@oyp/shared-components/dist/lib/utils/names';
import Button, { ButtonProps } from '../../../components/buttons/Button';
import ConfirmationRow from '../../../components/list/ConfirmationRow';
import InlineIconButton from '../../../components/buttons/InlineIconButton';
import React from 'react';
import styled from 'styled-components';

const { formatDateStringToDayMonth, formatDateStringToShortText } = formatter;

interface OrderListRowProps {
  order: OrderWithProprietaryEntities;
  orderItems: ModelOrderItem[];
  onTrackingDisplay: () => void;
  onCancelOrder: (order: ModelOrder) => void;
  onEditOrder: (order: ModelOrder) => void;
}

const OrderListRow: React.FC<OrderListRowProps> = props => {
  const { order, orderItems, onTrackingDisplay, onCancelOrder, onEditOrder } = props;

  const invalidityReason = getInvalidityReason(order, orderItems);

  const hasDelivery = order.status === ORDER_STATUS_SHIPPED;
  const canDisableOrEditOrder =
    orderItems.every(hasOrderItemNotEnteredManufacture) &&
    order.billingMode !== BILLING_MODES.IMMEDIATE;

  function getDetailsAction() {
    switch (invalidityReason) {
      case ORDER_INVALIDITY_REASONS.HAS_INVALID_PAYMENT:
        return (
          <TooltipContainer tooltipContent="Payment refusé">
            <InlineIconButton as={Link as any} to={`orders/${order.id}/details`}>
              <IconWithWarning>
                <Icon>search</Icon>
              </IconWithWarning>
            </InlineIconButton>
          </TooltipContainer>
        );
      case ORDER_INVALIDITY_REASONS.HAS_INVALID_FILES:
        return (
          <TooltipContainer tooltipContent="Fichiers invalides">
            <InlineIconButton as={Link as any} to={`orders/${order.id}/details`}>
              <IconWithWarning>
                <Icon>search</Icon>
              </IconWithWarning>
            </InlineIconButton>
          </TooltipContainer>
        );
      default:
        return (
          <InlineIconButton as={Link as any} to={`orders/${order.id}/details`}>
            <Icon>search</Icon>
          </InlineIconButton>
        );
    }
  }

  function getStatusCell() {
    switch (invalidityReason) {
      case ORDER_INVALIDITY_REASONS.HAS_INVALID_PAYMENT:
        return (
          <td>
            <TooltipContainer
              tooltipContent={
                <span>
                  Votre paiement a été refusé.
                  <br />
                  Cliquez pour réessayer.
                </span>
              }
            >
              <InvalidStateActionButton
                as={Link as any}
                to={`/order/${order.id}/payment`}
                look="danger"
                size="xs"
                className="order-list-invalid-files"
              >
                Payment refusé
              </InvalidStateActionButton>
            </TooltipContainer>
          </td>
        );
      case ORDER_INVALIDITY_REASONS.HAS_INVALID_FILES:
        return (
          <td>
            <TooltipContainer
              tooltipContent={
                <span>
                  Vos fichiers sont invalides.
                  <br />
                  Cliquez pour renvoyer vos fichiers.
                </span>
              }
            >
              <InvalidStateActionLink
                to={`/orders/${order.id}/files`}
                look="danger"
                size="xs"
                className="order-list-invalid-files"
              >
                Fichiers invalides
              </InvalidStateActionLink>
            </TooltipContainer>
          </td>
        );
      default:
        return <td>{getOrderStatusLabel(order)}</td>;
    }
  }

  return (
    <ConfirmationRow
      className={invalidityReason ? 'invalid-order' : ''}
      diffHolder={order.id}
      render={({ onToggle }) => (
        <>
          <td className="actions-cell">
            {getDetailsAction()}

            {hasDelivery ? (
              <TooltipContainer tooltipContent="Suivi de colis disponible" place="top">
                <InlineIconButton
                  tabIndex={0}
                  disabled={order.status !== ORDER_STATUS_SHIPPED}
                  onClick={onTrackingDisplay}
                  iconName="local_shipping"
                />
              </TooltipContainer>
            ) : (
              <InlineIconButton
                tabIndex={0}
                disabled={order.status !== ORDER_STATUS_SHIPPED}
                onClick={onTrackingDisplay}
                iconName="local_shipping"
              />
            )}

            {canDisableOrEditOrder ? (
              <>
                <TooltipContainer tooltipContent="Annuler la commande" place="top">
                  <InlineIconButton
                    iconName="delete"
                    onClick={() =>
                      onToggle('Êtes-vous sûr de vouloir annuler la commande ?', () =>
                        onCancelOrder(order)
                      )
                    }
                  />
                </TooltipContainer>
                <TooltipContainer
                  tooltipContent="Repasser la commande à l'état Devis pour la modifier"
                  place="top"
                >
                  <InlineIconButton
                    iconName="edit"
                    onClick={() =>
                      onToggle(
                        <span className="custom-text-warning">
                          {`Attention : la commande repasse à l'état Devis (n°${order.quoteReference}) et ne sera pas prise en compte jusqu'à la validation (étape 4).`}
                        </span>,
                        () => onEditOrder(order)
                      )
                    }
                  />
                </TooltipContainer>
              </>
            ) : (
              <>
                <TooltipContainer tooltipContent="Commande non-annulable, merci de contacter le service commercial">
                  <InlineIconButton iconName="delete" disabled />
                </TooltipContainer>
                <TooltipContainer tooltipContent="Commande non-modifiable, merci de contacter le service commercial">
                  <InlineIconButton iconName="edit" disabled />
                </TooltipContainer>
              </>
            )}
          </td>
          <td>{order.orderReference}</td>
          <td>{order.manualOrderReference}</td>
          <td>
            {order.shippingAddress &&
              `${order.shippingAddress.companyName} (${order.shippingAddress.city})`}
          </td>
          <td>
            {formatDateStringToDayMonth(order.deliveryDate)} (
            {PRODUCTION_MODE_TRANSLATIONS[order.productionMode]} -{' '}
            {SHIPPING_MODE_TRANSLATIONS[order.shippingMode]})
          </td>
          {getStatusCell()}

          <td>{order.taxIncludedTotal.toFixed(2)} €</td>
          <td>{formatDateStringToShortText(order.createdAt)}</td>
          <td>{getUserDisplayName(order.creator)}</td>
        </>
      )}
    />
  );
};

export default OrderListRow;

const hasOrderItemNotEnteredManufacture = (item: ModelOrderItem) =>
  ORDER_ITEM_CANCELLABLE_STATUSES.includes(item.status);

const InvalidStateActionButton = styled(Button)`
  padding: 4px 8px;
`;
const InvalidStateActionLink: React.FC<ButtonProps> = props => (
  <Button as={Link as any} style={{ padding: '4px 8px' }} {...props} />
);

const IconWithWarning: React.FC = props => (
  <StyledIconWithWarning>
    {props.children}
    <Icon className="warning">warning</Icon>
  </StyledIconWithWarning>
);

const StyledIconWithWarning: React.FC = styled.div`
  &:first-child i {
    color: ${DANGER_COLOR} !important;
    &.warning {
      font-size: 12px;
      position: absolute;
      top: -5px;
      left: 12px;
    }
  }
`;
