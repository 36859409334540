import './TechnicalInformation.scss';
import * as React from 'react';
import { CalculatorDeliveryData, CalculatorRequestData, floorToTwoDecimals } from '@oyp/shared-lib';
import { Card, formatter } from '@oyp/shared-components';

const { formatWeight } = formatter;

interface TechnicalInformationProps {
  requestData: CalculatorRequestData;
  deliveryData?: CalculatorDeliveryData;
}

const TechnicalInformation: React.FC<TechnicalInformationProps> = props => {
  const { requestData, deliveryData } = props;

  const quantity = requestData.quantity as number;
  const unitWeight = floorToTwoDecimals((deliveryData ? deliveryData.totalWeight : 0) / quantity);

  return (
    <div>
      <Card
        title="DONNEES TECHNIQUES"
        internalTopPadding
        className="bg-primary-lighter-card-form technical-informations"
      >
        <div>
          <div className="row pl-3 pr-3">
            <div className="col-sm-7">Poids unitaire</div>
            <div className="col-sm-5 text-right">
              {deliveryData ? formatWeight(unitWeight) : '-'}
            </div>
          </div>

          <div className="row pl-3 pr-3">
            <div className="col-sm-7">Poids total</div>
            <div className="col-sm-5 text-right">
              {deliveryData ? formatWeight(floorToTwoDecimals(deliveryData.totalWeight)) : '-'}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default TechnicalInformation;
