import * as React from 'react';
import { MDBInput } from 'mdbreact';

interface RadioGroupProps {
  radios?: any[]; // TODO préciser le type
  name?: string;
  selectedValue: any;
  disabled?: boolean;
  className?: string;
  onChange: (value: any) => void;
}

const RadioGroup: React.SFC<RadioGroupProps> = props => {
  const { radios = [], name, onChange, disabled, selectedValue, className = '' } = props;

  return (
    <div className={`radio-group ${className}`}>
      {radios.map((radio, index) => {
        const { value, label, Label } = radio;

        return (
          <MDBInput
            key={index}
            value={value}
            id={`${name}-${value}`}
            label={Label ? <Label /> : label || `${value}`}
            onClick={() => onChange(value)}
            disabled={disabled || radio.disabled}
            checked={value === selectedValue}
            type="radio"
          />
        );
      })}
    </div>
  );
};

export default RadioGroup;
