"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var SAXOPRINT_PRODUCT_CODES;

(function (SAXOPRINT_PRODUCT_CODES) {
  SAXOPRINT_PRODUCT_CODES["FLYER"] = "SAXOPRINT_PRODUCT_CODE_FLYER";
  SAXOPRINT_PRODUCT_CODES["DESK_CALENDAR"] = "SAXOPRINT_PRODUCT_DESK_CALENDAR";
  SAXOPRINT_PRODUCT_CODES["WALL_CALENDAR"] = "SAXOPRINT_PRODUCT_WALL_CALENDAR";
})(SAXOPRINT_PRODUCT_CODES = exports.SAXOPRINT_PRODUCT_CODES || (exports.SAXOPRINT_PRODUCT_CODES = {}));

var SAXOPRINT_JOB_STATES;

(function (SAXOPRINT_JOB_STATES) {
  SAXOPRINT_JOB_STATES["INVALID"] = "Invalid";
  SAXOPRINT_JOB_STATES["ORDERED"] = "Ordered";
  SAXOPRINT_JOB_STATES["UPLOADED"] = "Uploaded";
  SAXOPRINT_JOB_STATES["DATACHECK_SUCCEED"] = "DatacheckSucceed";
  SAXOPRINT_JOB_STATES["WAITING_FOR_APPROVAL"] = "WaitingForApproval";
  SAXOPRINT_JOB_STATES["PRINTFILES_FAULTY"] = "PrintfilesFaulty";
  SAXOPRINT_JOB_STATES["PRINT_APPROVAL_SUCCEED"] = "PrintApprovalSucceed";
  SAXOPRINT_JOB_STATES["IN_PRINT"] = "InPrint";
  SAXOPRINT_JOB_STATES["FINISHED"] = "Finished";
  SAXOPRINT_JOB_STATES["DISPATCHED"] = "Dispatched";
  SAXOPRINT_JOB_STATES["CANCELLED"] = "Cancelled";
})(SAXOPRINT_JOB_STATES = exports.SAXOPRINT_JOB_STATES || (exports.SAXOPRINT_JOB_STATES = {}));