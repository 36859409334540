import './LoaderRow.scss';
import * as React from 'react';
import Loader from '../Loader';

interface LoaderProps {
  error?: any;
  onRetry?: () => void;
}

const LoaderRow: React.SFC<LoaderProps> = props => {
  return (
    <tr className="loader-row">
      <td colSpan={99}>
        <Loader {...props} />
      </td>
    </tr>
  );
};

export default LoaderRow;
