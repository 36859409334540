"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var types_1 = require("./types");

var countries_1 = require("../../data/countries");

exports.PROD_CENTER_PLATFORM_CODES = [types_1.PLATFORM_CODES.INPRINT, types_1.PLATFORM_CODES.PRINTDOC]; // Adresses

exports.PICKUP_ADDRESS = {
  countryCode: countries_1.franceCountryCode,
  companyName: 'In-Print',
  name: 'In-Print',
  street: '31 Rue Aristide Berges',
  postalCode: '31270',
  city: 'Cugnaux',
  phoneNumber: '0561246535'
}; // Commande

exports.QUOTE_STATUS_CREATED = 'QUOTE_STATUS_CREATED';
exports.QUOTE_STATUS_PENDING_CUSTOMER_APPROVAL = 'QUOTE_STATUS_PENDING_CUSTOMER_APPROVAL';
exports.ORDER_STATUS_PENDING_PAYMENT_VALIDATION = 'ORDER_STATUS_PENDING_PAYMENT_VALIDATION';
exports.ORDER_STATUS_CONFIRMED = 'ORDER_STATUS_CONFIRMED';
exports.ORDER_STATUS_SHIPPED = 'ORDER_STATUS_SHIPPED';
exports.QUOTE_ONLY_STATUSES = [exports.QUOTE_STATUS_CREATED, exports.QUOTE_STATUS_PENDING_CUSTOMER_APPROVAL];
exports.ORDER_ONLY_STATUSES = [exports.ORDER_STATUS_PENDING_PAYMENT_VALIDATION, exports.ORDER_STATUS_CONFIRMED, exports.ORDER_STATUS_SHIPPED];
exports.ALL_ORDER_STATUSES = [exports.QUOTE_STATUS_CREATED, exports.QUOTE_STATUS_PENDING_CUSTOMER_APPROVAL, exports.ORDER_STATUS_PENDING_PAYMENT_VALIDATION, exports.ORDER_STATUS_CONFIRMED, exports.ORDER_STATUS_SHIPPED];
exports.ORDER_SCOPE_QUOTE_ONLY = 'ORDER_SCOPE_QUOTE_ONLY';
exports.ORDER_SCOPE_ORDER_ONLY = 'ORDER_SCOPE_ORDER_ONLY';
exports.ORDER_SCOPE_ALL = 'ORDER_SCOPE_ALL';
exports.ORDER_SCOPE_DISABLED = 'ORDER_SCOPE_DISABLED'; // Produit commandé

exports.ORDER_ITEM_SCOPE_MANUFACTURE = 'ORDER_ITEM_SCOPE_MANUFACTURE';
exports.ORDER_ITEM_SCOPE_INCLUDE_DELETED = 'ORDER_ITEM_SCOPE_INCLUDE_DELETED'; // PAO

exports.ORDER_ITEM_SCOPE_DTP_FILE_VALIDATOR = 'ORDER_ITEM_SCOPE_DTP_FILE_VALIDATOR';
exports.ORDER_ITEM_SCOPE_DTP_PASSED_FOR_PRINT = 'ORDER_ITEM_SCOPE_DTP_PASSED_FOR_PRINT';
exports.ORDER_ITEM_SCOPE_DTP_INVALID_FILES_MANAGER = 'ORDER_ITEM_SCOPE_DTP_INVALID_FILES_MANAGER';
exports.ORDER_ITEM_SCOPE_DTP_FILES = 'ORDER_ITEM_SCOPE_DTP_FILES';
exports.ORDER_ITEM_CONFIRMED_STATUSES = [types_1.ORDER_ITEM_STATUSES.CONFIRMED, types_1.ORDER_ITEM_STATUSES.FILES_SENT, types_1.ORDER_ITEM_STATUSES.FILES_REFUSED, types_1.ORDER_ITEM_STATUSES.VALIDATED_FOR_PRODUCTION, types_1.ORDER_ITEM_STATUSES.PENDING_PRINT, types_1.ORDER_ITEM_STATUSES.PRINTED, types_1.ORDER_ITEM_STATUSES.LAMINATED, types_1.ORDER_ITEM_STATUSES.PACKAGED, types_1.ORDER_ITEM_STATUSES.SHIPPED, types_1.ORDER_ITEM_STATUSES.DELIVERED];
exports.ORDER_ITEM_MANUFACTURE_SCOPE_STATUSES = [types_1.ORDER_ITEM_STATUSES.VALIDATED_FOR_PRODUCTION, types_1.ORDER_ITEM_STATUSES.PENDING_PRINT, types_1.ORDER_ITEM_STATUSES.PRINTED, types_1.ORDER_ITEM_STATUSES.LAMINATED, types_1.ORDER_ITEM_STATUSES.PACKAGED];
exports.ORDER_ITEM_SHIPPED_STATUSES = [types_1.ORDER_ITEM_STATUSES.SHIPPED, types_1.ORDER_ITEM_STATUSES.DELIVERED];
exports.ORDER_ITEM_CANCELLABLE_STATUSES = [types_1.ORDER_ITEM_STATUSES.CREATED, types_1.ORDER_ITEM_STATUSES.CONFIRMED, types_1.ORDER_ITEM_STATUSES.FILES_SENT, types_1.ORDER_ITEM_STATUSES.FILES_REFUSED]; // Le mode "simple" est utilisé par PPC

exports.ORDER_ITEM_UPDATE_MODE_SIMPLE = 'ORDER_ITEM_UPDATE_MODE_SIMPLE'; // Mode permettant le recalcul des prix et dates du produit

exports.ORDER_ITEM_UPDATE_MODE_RECALCULATION = 'ORDER_ITEM_UPDATE_MODE_RECALCULATION'; // Expéditions

exports.PARCEL_STATUS_CREATED = 'parcel.status.created';
exports.PARCEL_STATUS_SHIPPED = 'parcel.status.shipped';
exports.ALL_PARCEL_STATUSES = [exports.PARCEL_STATUS_CREATED, exports.PARCEL_STATUS_SHIPPED]; // Workshop

exports.ORDER_ITEM_WORKSHOP_STATUSES = [types_1.ORDER_ITEM_STATUSES.VALIDATED_FOR_PRODUCTION, types_1.ORDER_ITEM_STATUSES.PENDING_PRINT, types_1.ORDER_ITEM_STATUSES.PRINTED, types_1.ORDER_ITEM_STATUSES.LAMINATED, types_1.ORDER_ITEM_STATUSES.PACKAGED]; //Events

exports.ORDER_ITEM_EVENTS_SCOPE_FILES_REFUSED = 'ORDER_ITEM_EVENTS_SCOPE_FILES_REFUSED';