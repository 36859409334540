import './ManufactureInfoPanel.scss';
import * as React from 'react';
import { Card, HoverSurface, HoverSurfaceProps } from '@oyp/shared-components';
import { ModelOrderItem } from '@oyp/shared-lib';
import InfoIcon from '../../../../../components/InfoIcon';
import ManufactureData from '../components/ManufactureData';

interface ManufactureInfoPanelProps {
  item: ModelOrderItem;
  surfaceParams?: HoverSurfaceProps;
  saveOrderItem?: (orderItem: ModelOrderItem) => void;
  hasWarning?: boolean;
}

const ManufactureInfoPanel: React.SFC<ManufactureInfoPanelProps> = props => {
  const { item, saveOrderItem, surfaceParams = {}, hasWarning } = props;

  return (
    <HoverSurface {...surfaceParams} surfaceContent={<InfoIcon hasWarning={hasWarning} />}>
      <div className={`order-item-detail-container`}>
        <Card className="manufacture-info-panel">
          <div className="title">Détails techniques</div>
          <ManufactureData
            item={item}
            displaySmartLabelDeliveryDate
            onSaveOrderItem={saveOrderItem}
          />
        </Card>
      </div>
    </HoverSurface>
  );
};

export default ManufactureInfoPanel;
