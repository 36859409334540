"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ERROR_MESSAGE_VALIDATION = 'error.message.validation';
exports.ERROR_MESSAGE_INTERNAL_SERVER_ERROR = 'error.message.internal_server_error';

exports.translateErrorMessage = function (errorMessage) {
  switch (errorMessage) {
    case exports.ERROR_MESSAGE_VALIDATION:
      return "Les donn\xE9es sont invalides.";

    case exports.ERROR_MESSAGE_INTERNAL_SERVER_ERROR:
      return "Une erreur inattendue est survenue. Merci de contacter l'administrateur.";

    default:
      return "Une erreur est survenue.";
  }
};