"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function promisifyReadFileAsText(file) {
  var reader = new FileReader();
  return new Promise(function (resolve, reject) {
    reader.onload = function (event) {
      resolve(event.target.result);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsText(file);
  });
}

exports.promisifyReadFileAsText = promisifyReadFileAsText;