"use strict";

var _defineProperty = require("/var/www/vhosts/preprod.oyp.fr/deploy_tools/apps/demo-calm-panda/releases/20240115155012/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _exports$ALL_TOTEBAG_;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var types_1 = require("./types");

exports.ORDER_ITEM_MANUFACTURED_CALCULATORS = [types_1.CALCULATORS.FLEXIBLE, types_1.CALCULATORS.RIGID, types_1.CALCULATORS.KAKEMONO, types_1.CALCULATORS.FINISHED_PRODUCT];
exports.FLEXIBLE_ORIGIN_NAME = 'Souple';
exports.KAKEMONO_ORIGIN_NAME = 'Kakémono';
exports.RIGID_ORIGIN_NAME = 'Rigide';
exports.TOTEBAG_ORIGIN_NAME = 'Totebag';
exports.FINISHED_PRODUCT_ORIGIN_NAME = 'Rollup & Banner'; // Matières Flexible

exports.TYPE_TAPE = 'tape';
exports.TYPE_TARP = 'tarp';
exports.TYPE_PAPER = 'paper';
exports.TYPE_MAGNETIC = 'magnetic';
exports.TYPE_FLEXIBLE_PVC = 'flexible.pvc';
exports.MATERIAL_TYPES_FLEXIBLE = [exports.TYPE_TAPE, exports.TYPE_TARP, exports.TYPE_PAPER, exports.TYPE_MAGNETIC, exports.TYPE_FLEXIBLE_PVC]; // Matières Rigide

exports.TYPE_AKILUX = 'akilux';
exports.TYPE_DIBOND = 'dibond';
exports.TYPE_PLEXI = 'plexi';
exports.TYPE_CARDBOARD = 'carton';
exports.TYPE_RIGID_PVC = 'rigid.pvc';
exports.MATERIAL_TYPES_RIGID = [exports.TYPE_AKILUX, exports.TYPE_DIBOND, exports.TYPE_PLEXI, exports.TYPE_RIGID_PVC, exports.TYPE_CARDBOARD]; // Totebag

exports.COLOR_TYPE_ECRU = 'totebag.color.ecru';
exports.COLOR_TYPE_COLORED = 'totebag.color.colored';
exports.ALL_TOTEBAG_COLORS = [exports.COLOR_TYPE_ECRU, exports.COLOR_TYPE_COLORED];
exports.PRINTING_MODE_ONE_SIDED = 'totebag.printing_mode.one_sided';
exports.PRINTING_MODE_TWO_SIDED = 'totebag.printing_mode.two_sided';
exports.ALL_TOTEBAG_PRINTING_MODES = [exports.PRINTING_MODE_ONE_SIDED, exports.PRINTING_MODE_TWO_SIDED]; // Quadri-numérique

exports.INK_TYPE_QUADRI = 'totebag.ink_type.quadri'; // Sérigraphie

exports.INK_TYPE_SILKSCREEN = 'totebag.ink_type.silkscreen';
exports.ALL_TOTEBAG_INK_TYPES = [exports.INK_TYPE_QUADRI, exports.INK_TYPE_SILKSCREEN];
exports.PRINTING_LEVEL_QUADRI_ZONE_15_20 = 'totebag.level.quadri_zone_15_20';
exports.PRINTING_LEVEL_QUADRI_ZONE_20_30 = 'totebag.level.quadri_zone_20_30';
exports.PRINTING_LEVEL_QUADRI_ZONE_37_37 = 'totebag.level.quadri_zone_37_37';
exports.PRINTING_LEVEL_SILKSCREEN_ONE_COLOR = 'totebag.level.silkscreen_one_color';
exports.PRINTING_LEVEL_SILKSCREEN_TWO_COLORS = 'totebag.level.silkscreen_two_colors';
exports.PRINTING_LEVEL_SILKSCREEN_THREE_COLORS = 'totebag.level.silkscreen_three_colors';
exports.ALL_TOTEBAG_PRINTING_LEVELS = [exports.PRINTING_LEVEL_QUADRI_ZONE_15_20, exports.PRINTING_LEVEL_QUADRI_ZONE_20_30, exports.PRINTING_LEVEL_QUADRI_ZONE_37_37, exports.PRINTING_LEVEL_SILKSCREEN_ONE_COLOR, exports.PRINTING_LEVEL_SILKSCREEN_TWO_COLORS, exports.PRINTING_LEVEL_SILKSCREEN_THREE_COLORS];
exports.ALL_TOTEBAG_PRINTING_LEVELS_BY_INK_TYPE = (_exports$ALL_TOTEBAG_ = {}, _defineProperty(_exports$ALL_TOTEBAG_, exports.INK_TYPE_QUADRI, [exports.PRINTING_LEVEL_QUADRI_ZONE_15_20, exports.PRINTING_LEVEL_QUADRI_ZONE_20_30, exports.PRINTING_LEVEL_QUADRI_ZONE_37_37]), _defineProperty(_exports$ALL_TOTEBAG_, exports.INK_TYPE_SILKSCREEN, [exports.PRINTING_LEVEL_SILKSCREEN_ONE_COLOR, exports.PRINTING_LEVEL_SILKSCREEN_TWO_COLORS, exports.PRINTING_LEVEL_SILKSCREEN_THREE_COLORS]), _exports$ALL_TOTEBAG_);
exports.LIMIT_SHORT_DELAY_HOUR = 11;
exports.TRANSPORTERS_WITHOUT_LABELS = [types_1.TRANSPORTERS.PALLET];