import { Route, Switch } from 'react-router-dom';
import { UserContext } from '../UserContext';
import AccountContainer from '../_modules/user/containers/AccountContainer';
import AddressListContainer from '../_modules/customer_address/containers/AddressListContainer';
import DTPRoutes from '../_modules/dtp/Routes';
import FileUploadPage from '../_modules/order/pages/FileUpload/Page';
import InvoiceListContainer from '../_modules/order/containers/InvoiceListContainer';
import ListContainer from '../_modules/order/containers/ListContainer';
import OrderDetailsPage from '../_modules/order/containers/OrderDetailsPage';
import OrderValidationPage from '../_modules/order/containers/OrderValidationPage';
import PaymentPage from '../_modules/order/pages/Payment/Page';
import QuoteListContainer from '../_modules/order/containers/QuoteListContainer';
import QuotePage from '../_modules/order/pages/Quote/Page';
import React from 'react';

const Main: React.FC<{}> = () => (
  <UserContext.Consumer>
    {context => (
      <Switch>
        <Route exact path="/" render={props => <QuotePage {...props} />} />
        <Route exact path="/order/:id/validate" component={OrderValidationPage} />
        <Route exact path="/quote/:id" render={props => <QuotePage {...props} />} />
        <Route exact path="/order/:id/upload-files" component={FileUploadPage} />
        <Route exact path="/order/:id/payment" component={PaymentPage} />
        <Route exact path="/order/:id/recap" component={OrderDetailsPage} />
        <Route exact path="/account" component={AccountContainer} />
        <Route exact path="/orders" component={ListContainer} />
        <Route exact path="/orders/:id/files" component={FileUploadPage} />
        <Route exact path="/orders/:id/details" component={OrderDetailsPage} />
        <Route exact path="/quotes" component={QuoteListContainer} />
        <Route exact path="/invoices" component={InvoiceListContainer} />
        <Route exact path="/addresses" render={() => <AddressListContainer context={context} />} />
        <DTPRoutes />
      </Switch>
    )}
  </UserContext.Consumer>
);

export default Main;
