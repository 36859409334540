import { centralStationApiEndpoint } from '../../../../env-config';
import { fetchWithToken } from '@oyp/shared-components/dist/lib/rest-api/network';

export async function initiateOrderWithPayment(
  orderId: string,
  resellerCompanyName: string,
  quoteReference: string,
  amount: number,
  creatorEmail: string,
  creatorName: string
): Promise<{
  clientSecret: string;
  paymentIntentId: string;
}> {
  const response: Response = await fetchWithToken(
    `${centralStationApiEndpoint}/initiate-order-payment/${orderId}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        resellerCompanyName,
        quoteReference,
        amount,
        creatorEmail,
        creatorName,
      }),
    }
  );

  if (response.status !== 200) {
    throw new Error(`An error has occurred: ${await response.text()}`);
  }

  return response.json();
}

export async function validateOrderWithPaymentIntent(
  orderId: string,
  paymentIntentId: string
): Promise<any> {
  return fetchWithToken(
    `${centralStationApiEndpoint}/validate-order-with-payment-intent/${orderId}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        paymentIntentId,
      }),
    }
  );
}
