"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function extractState(state) {
  var statePath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

  if (!statePath.length) {
    return state;
  }

  var value = null;
  var currentExtractedItem = Object.assign({}, state);
  var count = 0;
  statePath.forEach(function (step) {
    count++;

    if (currentExtractedItem.hasOwnProperty(step)) {
      currentExtractedItem = Object.assign({}, currentExtractedItem[step]);
    } else {
      return;
    }

    if (count === statePath.length) {
      value = Object.assign({}, currentExtractedItem);
    }
  });
  return value;
}

exports.extractState = extractState;

function applyPartialState(globalState) {
  var newPartialState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var statePath = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var newGlobalState = deepAssign({}, globalState);
  var currentNode = newGlobalState;
  var i;

  for (i = 0; i < statePath.length - 1; i++) {
    if (!currentNode.hasOwnProperty(statePath[i])) {
      currentNode[statePath[i]] = {};
    }

    currentNode = currentNode[statePath[i]];
  }

  currentNode[statePath[i]] = newPartialState;
  return newGlobalState;
}

exports.applyPartialState = applyPartialState; // Attention : non-prévu avec des fonctions ou des symboles !

function deepAssign(target) {
  for (var _len = arguments.length, origins = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    origins[_key - 1] = arguments[_key];
  }

  origins.forEach(function (origin) {
    return deepAssignOne(target, origin);
  });
  return target;
}

exports.deepAssign = deepAssign;

function deepAssignOne(target, origin) {
  for (var key in origin) {
    if (origin.hasOwnProperty(key)) {
      target[key] = getAssignableProp(origin[key], target[key]);
    }
  }

  return target;
}

function getAssignableProp(value, previousValue) {
  if (Array.isArray(value)) {
    return value.map(getAssignableProp);
  } else if (value && typeof value === 'object') {
    return typeof previousValue === 'object' ? deepAssign({}, previousValue, value) : deepAssignOne({}, value);
  }

  return value;
}

exports.getPageCountForData = function (itemsTotal, itemsPerPage) {
  return Math.ceil(itemsTotal / itemsPerPage) || 1;
};