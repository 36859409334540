import './CartOrderItemLine.scss';
import * as React from 'react';
import {
  FILE_STATUSES,
  ModelOrderItem,
  ORDER_ITEM_CONFIRMED_STATUSES,
  ORDER_ITEM_STATUSES,
  ORDER_ONLY_STATUSES,
  PASSED_FOR_PRINT_STATUSES,
  isCalculatorOrderItem,
} from '@oyp/shared-lib';
import { FrontOrder } from '../../../calculator/types';
import { Icon } from 'react-materialize';
import { Link } from 'react-router-dom';
import { TooltipContainer, animations, formatter } from '@oyp/shared-components';
import { findFilesForOrderItemId } from '../../file_utils';
import CellWideButton from '../../../../components/CellWideButton';
import InlineConfirmationButton from '../../../../components/InlineConfirmationButton';
import InlineIconButton from '../../../../components/buttons/InlineIconButton';
import ManufactureInfoPanel from '../../_modules/order-items/containers/ManufactureInfoPanel';
import Markdown from 'react-markdown';
import OrderItemLabel from './OrderItemLabel';

const { animateScroll, getElementOffsetTop } = animations;
const { formatPrice } = formatter;

interface CartOrderItemLineProps {
  isOnQuotePage?: boolean;
  files?: any;
  order: FrontOrder;
  item: ModelOrderItem;
  orderDetailOrientation?: 'right' | 'top' | 'bottom' | 'left';
  isCurrentlyEdited?: boolean;
  displayStatus?: boolean;
  onSaveItem?: (orderItem: ModelOrderItem) => void;
  onRemoveItem?: (orderItem: ModelOrderItem) => void;
  onStartEditItem?: (orderItem: ModelOrderItem) => void;
  onCancelEditItem?: () => void;
}

const CartOrderItemLine: React.SFC<CartOrderItemLineProps> = props => {
  const {
    files,
    item,
    orderDetailOrientation,
    isCurrentlyEdited,
    displayStatus,
    onSaveItem,
    order,
  } = props;

  const isOrderItemConfirmed =
    ORDER_ONLY_STATUSES.includes(order.status) &&
    ORDER_ITEM_CONFIRMED_STATUSES.find(status => status === item.status);

  // TODO unifier ces styles
  const firstCellClassName = files ? 'file-info-container' : 'cart-item-actions';

  return (
    <tr className={`item cart-item-line ${isCurrentlyEdited ? `table-info` : ``}`}>
      <td className={`text-left ${firstCellClassName}`} style={{ position: 'relative' }}>
        {files ? (
          <FileUploadActions {...props} />
        ) : !isOrderItemConfirmed ? (
          <BeforeOrderActions {...props} />
        ) : (
          <AfterOrderActions {...props} />
        )}
      </td>

      <td className="text-left cart-item-label">
        <OrderItemLabel
          item={item}
          onSaveItem={onSaveItem}
          orderDetailOrientation={orderDetailOrientation}
        />
      </td>
      {displayStatus && (
        <td className="text-left cart-item-statut">{ORDER_ITEM_STATUS_LABELS[item.status]}</td>
      )}
      <td className="text-center cart-item-quantity">{item.quantity}</td>
      <td className="text-right cart-item-price">{formatPrice(item.taxExcludedTotal)}</td>
    </tr>
  );
};

export default CartOrderItemLine;

const ORDER_ITEM_STATUS_LABELS: { [key in ORDER_ITEM_STATUSES]: string } = {
  [ORDER_ITEM_STATUSES.CREATED]: 'Créé',
  [ORDER_ITEM_STATUSES.CONFIRMED]: 'En cours',
  [ORDER_ITEM_STATUSES.FILES_SENT]: 'Fichiers en vérification',
  [ORDER_ITEM_STATUSES.FILES_REFUSED]: 'Fichiers refusés',
  [ORDER_ITEM_STATUSES.VALIDATED_FOR_PRODUCTION]: 'Validé pour production',
  [ORDER_ITEM_STATUSES.PENDING_PRINT]: `En cours d'impression`,
  [ORDER_ITEM_STATUSES.PRINTED]: 'Imprimé',
  [ORDER_ITEM_STATUSES.LAMINATED]: 'Imprimé',
  [ORDER_ITEM_STATUSES.PACKAGED]: 'Imprimé',
  [ORDER_ITEM_STATUSES.SHIPPED]: 'Expédié',
  [ORDER_ITEM_STATUSES.DELIVERED]: 'Livré',
};

const FileUploadActions = (props: CartOrderItemLineProps) => {
  const { files, item, onSaveItem, orderDetailOrientation } = props;
  const { isWithoutPrint } = item;

  const itemFiles = findFilesForOrderItemId(files, item.id);

  const areFilesInvalid =
    !isWithoutPrint &&
    (itemFiles.length === 0 || itemFiles.some(file => file.status === FILE_STATUSES.FILE_REFUSED));

  const badgeClass = getBadgeClass(isWithoutPrint, areFilesInvalid);

  return (
    <React.Fragment>
      <ManufactureInfoPanel
        item={item}
        saveOrderItem={onSaveItem}
        surfaceParams={{
          offset: 0,
          place: orderDetailOrientation,
          className: 'order-item-info-chip',
        }}
      />
      <span>
        <span className={`custom-badge item-badge ${badgeClass}`}>
          {!isWithoutPrint ? itemFiles.length : '-'}
        </span>
      </span>
      <TooltipContainer
        tooltipContent="Voir le(s) fichier(s) du produit"
        wrapperStyle={{ display: 'inline-block' }}
      >
        <span
          tabIndex={0}
          className="file-icon-navigate"
          onClick={() => {
            const orderItemElement = document.querySelector(`#file-upload-item-${item.id}`);
            const offset = getElementOffsetTop(orderItemElement, 0);
            animateScroll(offset - 170); // on prend en compte la hauteur du header fixed
          }}
        >
          <Icon className="pointer">insert_drive_file</Icon>
        </span>
      </TooltipContainer>
    </React.Fragment>
  );
};

const BeforeOrderActions = (props: CartOrderItemLineProps) => {
  const {
    item,
    isCurrentlyEdited,
    onCancelEditItem,
    onStartEditItem,
    onRemoveItem,
    onSaveItem,
    orderDetailOrientation,
    isOnQuotePage,
  } = props;

  return (
    <React.Fragment>
      <ManufactureInfoPanel
        item={item}
        saveOrderItem={onSaveItem}
        surfaceParams={{
          offset: 0,
          place: orderDetailOrientation,
          className: 'order-item-info-chip',
        }}
        hasWarning={!isCalculatorOrderItem(item)}
      />

      {isOnQuotePage && (
        <React.Fragment>
          {isCurrentlyEdited ? (
            <CellWideButton onClick={onCancelEditItem}>Annuler la modification</CellWideButton>
          ) : (
            <InlineIconButton onClick={() => onStartEditItem(item)} iconName="edit" />
          )}
          <InlineConfirmationButton
            iconName="delete"
            confirmText="supprimer"
            onConfirmation={() => onRemoveItem(item)}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const AfterOrderActions = (props: CartOrderItemLineProps) => {
  const { item, onSaveItem, orderDetailOrientation } = props;
  const { isWithoutPrint } = item;

  const displayPassedForPrintAction = item.passedForPrintStatus === PASSED_FOR_PRINT_STATUSES.SENT;

  return (
    <React.Fragment>
      <ManufactureInfoPanel
        item={item}
        saveOrderItem={onSaveItem}
        surfaceParams={{
          offset: 0,
          place: orderDetailOrientation,
          className: 'order-item-info-chip',
        }}
      />
      <TooltipContainer tooltipContent="Nb. de fichiers envoyés">
        <div
          style={{
            display: 'inline-block',
            marginRight: displayPassedForPrintAction ? '10px' : '0',
          }}
        >
          <span className="custom-badge item-badge recap-item-badge">
            {!isWithoutPrint ? item.customerFileCount : '-'}
          </span>
        </div>
      </TooltipContainer>
      {displayPassedForPrintAction && (
        <TooltipContainer tooltipContent="Voir le BAT">
          <Link to={`/valider-bat/${item.id}`}>
            <InlineIconButton iconName="assignment_turned_in" iconClassName="warning-text" />
          </Link>
        </TooltipContainer>
      )}
      {item.shippingComment && (
        <TooltipContainer
          tooltipLook="light"
          tooltipContent={
            <Markdown
              source={`
### Note concernant l'expédition
--------

${item.shippingComment}
`}
            />
          }
        >
          <InlineIconButton iconName="comment" />
        </TooltipContainer>
      )}
    </React.Fragment>
  );
};

function getBadgeClass(isWithoutPrint: boolean, areFilesInvalid: boolean): string {
  if (isWithoutPrint) {
    return 'badge-light';
  }

  return areFilesInvalid ? 'badge-danger' : 'badge-success';
}
