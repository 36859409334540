import './Header.scss';
import * as React from 'react';
import { Icon } from 'react-materialize';
import { Link, NavLink } from 'react-router-dom';
import {
  MDBBadge,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBNavItem,
  MDBNavbarNav,
} from 'mdbreact';
import { UserMenuContext, UserMenuContextInterface } from '../UserMenuContext';
import Button from '../components/buttons/Button';
import ExternalLink from '../components/ExternalLink';
import logo from '../img/printcenter_logo.svg';

interface HeaderProps {
  onLogout: () => void;
}

const Header: React.FC<HeaderProps> = props => (
  <UserMenuContext.Consumer>
    {(context: UserMenuContextInterface) => (
      <nav className="navbar navbar-expand-lg fixed-top navbar-dark white black-text">
        <Link className="navbar-brand black-text" to="/">
          <img src={logo} alt={'logo'} />
        </Link>
        <div className="collapse navbar-collapse" id="main-navbar">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Button as={Link as any} look="secondary" size="sm" to="/">
                Nouvelle commande
              </Button>
            </li>
          </ul>
        </div>
        {localStorage.mascaradeMode === 'true' && (
          <div className="mascarade-label">🕵️ Mode Mascarade en cours</div>
        )}
        <div className="user-icon-link">
          <MDBNavbarNav right>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <Icon>person</Icon> Menu
                </MDBDropdownToggle>
                <MDBDropdownMenu className="dropdown-default" right>
                  <MDBDropdownItem>
                    <NavLink to="/account">Mes informations personnelles</NavLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <NavLink to="/orders" className="link-with-badge">
                      Mes commandes{' '}
                      {context.invalidOrdersCount > 0 && (
                        <MDBBadge pill color="danger">
                          {context.invalidOrdersCount}
                        </MDBBadge>
                      )}
                    </NavLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <NavLink to="/quotes" className="link-with-badge">
                      Mes devis{' '}
                      {context.quotesToValidateCount > 0 && (
                        <MDBBadge pill color="warning">
                          {context.quotesToValidateCount}
                        </MDBBadge>
                      )}
                    </NavLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <NavLink to="/invoices" className="link-with-badge">
                      Mes factures{' '}
                      {context.lateInvoicesCount > 0 && (
                        <MDBBadge pill color="danger">
                          {context.lateInvoicesCount}
                        </MDBBadge>
                      )}
                    </NavLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <NavLink to="/addresses">Mes adresses</NavLink>
                  </MDBDropdownItem>
                  <hr />
                  <MDBDropdownItem>
                    <ExternalLink href="/document/documentation-printcenter.pdf">
                      Documentation
                    </ExternalLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem className="logout-button" onClick={props.onLogout}>
                    Déconnexion
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          </MDBNavbarNav>
        </div>
      </nav>
    )}
  </UserMenuContext.Consumer>
);

export default Header;
