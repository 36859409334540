import './OrderValidationPage.scss';
import * as React from 'react';
import {
  AddressFields,
  ModelResellerCustomerAddress,
  PICKUP_ADDRESS,
  PLATFORM_CODES,
  QUOTE_ONLY_STATUSES,
  SHIPPING_MODES,
} from '@oyp/shared-lib';
import { FrontOrder } from '../../calculator/types';
import { MDBInput, toast } from 'mdbreact';
import { RouteComponentProps } from 'react-router';
import { UserContext, UserContextInterface } from '../../../UserContext';
import { addressValidationConstraints } from '../validation';
import { extractAddressFields } from '../utils';
import { initialAddressValues, saveShippingAddressFromSelector } from '../address_helper';
import { loadCartOrder } from '../actions/order';
import { logError, restApiNetwork, validator } from '@oyp/shared-components';
import { resellerCustomerAddressEndpoint } from '../module';
import BreadCrumb, { ORDER_BREADCRUMB_STEPS } from '../../../components/BreadCrumb';
import Button from '../../../components/buttons/Button';
import CardActionFooter from '../../../components/CardActionFooter';
import Cart from '../components/cart/Cart';
import Loader from '../../../components/Loader';
import ShippingAddressFormContainer from './ShippingAddressFormContainer';

const { saveOne } = restApiNetwork;
const { validateForm } = validator;

type OrderValidationPageProps = RouteComponentProps<{ id: string }>;

interface OrderValidationPageState {
  order: FrontOrder;
  address: AddressFields;
  selectedCustomerAddress?: ModelResellerCustomerAddress;
  isLoading?: boolean;
  hasLoadingError?: boolean;
  isDeliveryPickup?: boolean;
  mustSaveAddressToAddressBook?: boolean;
  manualShippingReference: string;
}

class OrderValidationPage extends React.Component<
  OrderValidationPageProps,
  OrderValidationPageState
> {
  state: OrderValidationPageState = initialState;
  context: UserContextInterface;

  constructor(props: OrderValidationPageProps, context: UserContextInterface) {
    super(props, context);

    this.loadData(context);

    this.loadData = this.loadData.bind(this);
    this.saveQuote = this.saveQuote.bind(this);
  }

  render() {
    const {
      isLoading,
      hasLoadingError,
      order,
      address,
      isDeliveryPickup,
      mustSaveAddressToAddressBook,
      selectedCustomerAddress,
      manualShippingReference,
    } = this.state;
    const { reseller } = this.context;

    if (isLoading || hasLoadingError) {
      return <Loader error={hasLoadingError} onRetry={() => this.loadData(this.context)} />;
    }

    const addressValidationErrors = validateForm(
      address,
      isDeliveryPickup ? {} : addressValidationConstraints
    );
    const isAddressValid = Object.keys(addressValidationErrors).length === 0;

    return (
      <div className="quote-validation-page quote-container breadcrumb-padded">
        <BreadCrumb selectedStepId={ORDER_BREADCRUMB_STEPS.ADDRESS} order={order} />
        <div
          className="row  ml-0 mr-0"
          style={{
            display: isLoading || hasLoadingError ? 'none' : 'flex',
          }}
        >
          <div className="col-md-8 laptop-padding-right">
            <ShippingAddressFormContainer
              isDeliveryPickup={isDeliveryPickup}
              address={address}
              selectedCustomerAddress={selectedCustomerAddress}
              addressValidationErrors={addressValidationErrors}
              title={
                isDeliveryPickup ? 'Adresse de livraison' : 'Renseignez votre adresse de livraison'
              }
              resellerId={reseller.id}
              processButtons={
                !isDeliveryPickup ? (
                  <MDBInput
                    label="Enregistrer cette adresse dans mon carnet d'adresses"
                    type="checkbox"
                    id="checkbox1"
                    className="filled-in"
                    checked={mustSaveAddressToAddressBook && !selectedCustomerAddress}
                    isDisabled={!!selectedCustomerAddress}
                    onChange={() =>
                      this.setState({
                        mustSaveAddressToAddressBook: !mustSaveAddressToAddressBook,
                      })
                    }
                  />
                ) : null
              }
              manualShippingReference={manualShippingReference}
              onChange={subState => {
                this.setState({
                  ...this.state,
                  address: { ...this.state.address, ...subState.address },
                  selectedCustomerAddress: subState.selectedCustomerAddress,
                  manualShippingReference: subState.manualShippingReference,
                });
              }}
              onResetAddress={() => {
                this.setState({
                  address: { ...initialAddressValues },
                  selectedCustomerAddress: null,
                });
              }}
            />
          </div>

          <div className="col-md-4 laptop-padding-left">
            <Cart
              order={order}
              readOnly
              isLoading={false}
              footer={
                <CardActionFooter>
                  <Button
                    look="secondary"
                    variation="flat"
                    size="sm"
                    disabled={!isAddressValid}
                    onClick={this.saveQuote}
                  >
                    Enregistrer pour plus tard
                  </Button>

                  <Button
                    look="secondary"
                    size="sm"
                    disabled={!isAddressValid}
                    onClick={() => {
                      this.redirectToNextStep();
                    }}
                  >
                    Valider
                  </Button>
                </CardActionFooter>
              }
            />
          </div>
        </div>
      </div>
    );
  }

  async loadData(context: UserContextInterface, showLoader = false) {
    const { match } = this.props;

    const orderId = match ? match.params.id : null;

    if (showLoader) {
      this.setState({
        ...initialState,
      });
    }

    try {
      const order = await loadCartOrder({ orderId }, context.reseller);

      if (!QUOTE_ONLY_STATUSES.includes(order.status) && order.id) {
        return this.props.history.push(`/orders/${order.id}/details`);
      }

      const isDeliveryPickup = order.shippingMode === SHIPPING_MODES.PICKUP;

      if (isDeliveryPickup) {
        return this.setState({
          isDeliveryPickup,
          order,
          address: PICKUP_ADDRESS,
          isLoading: false,
          hasLoadingError: false,
        });
      }

      const address = order.shippingAddress ? extractAddressFields(order.shippingAddress) : {};

      this.setState({
        isDeliveryPickup,
        order,
        address: { ...this.state.address, ...address },
        isLoading: false,
        hasLoadingError: false,
      });
    } catch (error) {
      logError(error);
      toast.error(`Une erreur est survenue au chargement du panier`, { autoClose: 2000 });

      this.setState({
        ...initialState,
        isLoading: false,
        hasLoadingError: true,
      });
    }
  }

  async saveQuote() {
    try {
      await this.saveAddress();

      toast.success('Devis sauvegardé', { autoClose: 2000 });
      return this.props.history.push(`/orders/${this.state.order.id}/details`);
    } catch (error) {
      logError(error);
      toast.error('Une erreur est survenue', { autoClose: 2000 });
    }
  }

  async redirectToNextStep() {
    try {
      await this.saveAddress();

      return this.props.history.push(`/order/${this.state.order.id}/upload-files`);
    } catch (error) {
      logError(error);
      toast.error('Une erreur est survenue', { autoClose: 2000 });
    }
  }

  async saveAddress() {
    const {
      order,
      address,
      isDeliveryPickup,
      selectedCustomerAddress,
      mustSaveAddressToAddressBook,
      manualShippingReference,
    } = this.state;

    if (mustSaveAddressToAddressBook && !isDeliveryPickup) {
      await saveAddressToAddressBook({
        ...address,
        ...selectedCustomerAddress,
        resellerId: this.context.reseller.id,
      });
    }

    await saveShippingAddressFromSelector({
      address: {
        street: address.street || '',
        city: address.city || '',
        postalCode: address.postalCode || '',
        countryCode: address.countryCode || '',
        additionalInformation: address.additionalInformation,
        companyName: address.companyName,
        complement: address.complement,
        email: address.email,
        name: address.name,
        phoneNumber: address.phoneNumber,
      },
      orderId: order.id,
      platformCode: PLATFORM_CODES.PRINT_CENTER,
      manualShippingReference,
    });
  }
}

OrderValidationPage.contextType = UserContext;

export default OrderValidationPage;

const initialState: OrderValidationPageState = {
  isDeliveryPickup: false,
  order: { orderItems: [] } as FrontOrder,
  isLoading: true,
  hasLoadingError: false,
  address: { ...initialAddressValues },
  selectedCustomerAddress: null,
  mustSaveAddressToAddressBook: true,
  manualShippingReference: '',
};

async function saveAddressToAddressBook(address: ModelResellerCustomerAddress) {
  return saveOne<ModelResellerCustomerAddress>(resellerCustomerAddressEndpoint, address);
}
