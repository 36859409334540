/* global process */

const isTestEnv = process.env.NODE_ENV === 'test';
export const isPreprod = process.env.REACT_APP_IS_PREPROD === 'true';

export const CENTRAL_STATION_HOST = process.env.REACT_APP_CENTRAL_STATION_HOST;

export const centralStationHttpHost = !isTestEnv
  ? CENTRAL_STATION_HOST
  : 'http://central-station.fake';

export const centralStationApiEndpoint = !isTestEnv
  ? `${CENTRAL_STATION_HOST}${process.env.REACT_APP_CENTRAL_STATION_API_PREFIX}`
  : 'http://central-station.fake/api';

export const authEndpoint = !isTestEnv
  ? `${CENTRAL_STATION_HOST}${process.env.REACT_APP_CENTRAL_STATION_AUTH_PREFIX}`
  : 'http://auth-host.com/auth';

export const AUTH_COOKIE_NAME = process.env.REACT_APP_AUTH_COOKIE_NAME;

// En millisecondes
export const DEFAULT_DEBOUNCE_DELAY = 400;

// Feature switches

export const FEATURE_SWITCH_SMART_LABEL_FORM =
  process.env.REACT_APP_FEATURE_SWITCH_SMART_LABEL_FORM === 'true';

export const PRINTCENTER_HOST = process.env.REACT_APP_PRINTCENTER_HOST;

export const MASCARADE_KEY = process.env.REACT_APP_MASCARADE_KEY;

export const USER_TEST_ID = process.env.REACT_APP_USER_TEST_ID;
