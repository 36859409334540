import * as React from 'react';
import {
  BUNDLE_TYPES,
  CalculatorMaterial,
  CalculatorOrderItem,
  FINISHED_PRODUCT_CATEGORIES_TRANSLATIONS,
  IMAGERY_COUNT_TYPES,
  ModelInputFinishedProduct,
} from '@oyp/shared-lib';
import { OrderItemInformationRendererProps } from '../../../../../types';
import { ShortenedName } from '@oyp/shared-components';
import {
  fetchMaterialForProduct,
  fetchOneFinishedProduct,
} from '../../../../../../calculator/_modules/finished_product/actions';
import Loader from '../../../../../../../components/Loader';

interface FinishedProductOrderItemInformationState {
  orderItemId: string;
  isLoading: boolean;
  product?: ModelInputFinishedProduct;
  material?: CalculatorMaterial;
}

const FinishedProductOrderItemInformation: React.SFC<OrderItemInformationRendererProps> = ({
  orderItem,
}) => {
  const { requestData } = orderItem as CalculatorOrderItem;

  const { quantity, imageryCount, passedForPrint, imageryCountType } = requestData;

  const initalState: FinishedProductOrderItemInformationState = {
    isLoading: true,
    orderItemId: orderItem.id,
  };

  const [{ isLoading, product, orderItemId, material }, setState] = React.useState(initalState);
  React.useEffect(() => {
    if (!product || orderItem.id !== orderItemId) {
      loadProductAndMaterial(
        requestData.finishedProductId,
        requestData.materialId,
        orderItem.id,
        setState
      );
    }
  });

  const imageryCountInfo = imageryCount > 1 ? `Amalgame : ${imageryCount} visuel(s)` : null;
  const imageryCountTypeInfo =
    imageryCountType === IMAGERY_COUNT_TYPES.DIFFERENT ? 'Qtés différentes' : 'Qtés identiques';

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div>
        <b>{FINISHED_PRODUCT_CATEGORIES_TRANSLATIONS[product.category]}</b>
      </div>
      <div>
        Produit : <ShortenedName name={orderItem.label} maxNameLength={30} />
      </div>
      <div>Quantité : {quantity}</div>
      {requestData.bundleType !== BUNDLE_TYPES.STRUCTURE_ONLY && imageryCount > 1 && (
        <div>
          {imageryCountInfo} - {imageryCountTypeInfo}
        </div>
      )}
      {material && (
        <div>
          Matière : <ShortenedName name={material.name} maxNameLength={30} />
        </div>
      )}
      {passedForPrint && <div>BAT : oui</div>}
    </div>
  );
};

export default FinishedProductOrderItemInformation;

async function loadProductAndMaterial(
  productId: string,
  materialId: string,
  orderItemId: string,
  setState: (state: FinishedProductOrderItemInformationState) => void
) {
  const product: ModelInputFinishedProduct = await fetchOneFinishedProduct(productId);

  const material = materialId ? await fetchMaterialForProduct(product, materialId) : null;

  setState({
    isLoading: false,
    product,
    material,
    orderItemId,
  });
}
