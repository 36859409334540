import * as React from 'react';
import { form } from '@oyp/shared-components';
import InputGroup from '../../../components/form/InputGroup';

const { getOnEventChange } = form;

interface OrderItemFieldsProps {
  requestData: any; // TODO préciser le type
  errorsByProperty: any; // TODO préciser le type
  warningsByProperty: any; // TODO préciser le type
  onCalculate: (data: any) => void;
}

const OrderItemFields: React.SFC<OrderItemFieldsProps> = props => {
  const { requestData, errorsByProperty, warningsByProperty, onCalculate } = props;

  return (
    <div className="form-row">
      <InputGroup
        name="description"
        label="Commentaire"
        s={6}
        error={errorsByProperty.description}
        warning={warningsByProperty.description}
        onChange={getOnEventChange('description', requestData, onCalculate)}
        value={requestData.description}
      />

      <InputGroup
        name="manualReference"
        label="Référence de votre produit"
        s={6}
        onChange={getOnEventChange('manualReference', requestData, onCalculate)}
        value={requestData.manualReference}
      />
    </div>
  );
};

export default OrderItemFields;
