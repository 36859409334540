"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function filterObjectProperties(source) {
  var propertyNames = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return Object.keys(source).filter(function (key) {
    return propertyNames.includes(key);
  }).reduce(function (outputObject, key) {
    outputObject[key] = source[key];
    return outputObject;
  }, {});
}

exports.filterObjectProperties = filterObjectProperties;
/** Mute l'objet cible en settant sur lui toutes les propriétés de l'objet source */

function setPropertiesFromSource(source, target) {
  Object.keys(source).forEach(function (key) {
    target[key] = source[key];
  });
}

exports.setPropertiesFromSource = setPropertiesFromSource;