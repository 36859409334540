import { Card } from '@oyp/shared-components';
import { SHIPPING_MODES, SHIPPING_MODE_TRANSLATIONS } from '@oyp/shared-lib';
import InlineIconButton from '../../../../components/buttons/InlineIconButton';
import RadioGroup from '../../../../components/form/RadioGroup';
import React, { useEffect, useRef, useState } from 'react';
import styles from './ShippingModeChooser.module.scss';

interface ShippingModeChooserProps {
  shippingMode: SHIPPING_MODES;
  radios: any[];
  isLoading: boolean;
  onShippingModeChange: (newShippingMode: SHIPPING_MODES) => void;
}

const ShippingModeChooser: React.FC<ShippingModeChooserProps> = props => {
  const { shippingMode, radios, onShippingModeChange, isLoading } = props;

  const [isOpen, setOpen] = useState<boolean>(false);
  const toggleIsOpen = () => setOpen(!isOpen);
  const close = () => setOpen(false);
  const node = useRef();
  const handleGlobalClick = getHandleGlobalClick(node, close);
  useEffect(() => {
    document.addEventListener('mousedown', handleGlobalClick);
    return () => {
      document.removeEventListener('mousedown', handleGlobalClick);
    };
  }, []);

  return (
    <div ref={node} className={styles['chooser-wrapper']}>
      <span>
        <span onClick={close}>{SHIPPING_MODE_TRANSLATIONS[shippingMode]} </span>
        <InlineIconButton
          className={styles['edit-icon']}
          iconName="edit"
          onClick={toggleIsOpen}
          disabled={isLoading}
        />
      </span>
      {isOpen && (
        <Card className={styles['radio-container']}>
          <RadioGroup
            name="shippingMode"
            onChange={newShippingMode => {
              onShippingModeChange(newShippingMode);
              close();
            }}
            selectedValue={shippingMode}
            radios={radios}
          />
        </Card>
      )}
    </div>
  );
};

export default ShippingModeChooser;

const getHandleGlobalClick = (node: any, close: () => void) => (event: Event) => {
  if (!node.current.contains(event.target)) {
    close();
  }
};
