"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = require("react");

var utils_1 = require("../../utils");

exports.Input = function (props) {
  var value = props.value,
      _props$type = props.type,
      type = _props$type === void 0 ? 'text' : _props$type,
      name = props.name,
      label = props.label,
      inputId = props.inputId,
      inputClassNames = props.inputClassNames,
      onChange = props.onChange,
      onValueChange = props.onValueChange,
      s = props.s,
      error = props.error,
      warning = props.warning,
      _props$handleBlur = props.handleBlur,
      handleBlur = _props$handleBlur === void 0 ? function (val) {
    return val;
  } : _props$handleBlur,
      disabled = props.disabled;

  if (onValueChange && onChange) {
    utils_1.logWarn("Les fonctions 'onValueChange' et 'onChange' sont mutuellement exclusives, mais les deux ont \xE9t\xE9 pass\xE9es ont composant. Seule onValueChange sera appel\xE9e.");
  }

  var inputValidateClass = '';

  if (error) {
    inputValidateClass = "invalid";
  } else if (warning) {
    inputValidateClass = "warning";
  }

  var isActive = !!(error || warning || typeof value !== 'undefined' && value !== null && "".concat(value).length);
  var inputProps = {
    id: inputId,
    name: name,
    className: "validate ".concat(inputValidateClass, " ").concat(inputClassNames || ''),
    onBlur: function onBlur(val) {
      return handleBlur(val);
    },
    onChange: getInputOnChange(onValueChange, onChange),
    disabled: disabled
  };
  var wrapperClasses = ['input-field'];

  if (s) {
    wrapperClasses.push('col', "s".concat(s));
  }

  return React.createElement("div", {
    className: wrapperClasses.join(' ')
  }, type !== 'textarea' ? React.createElement("input", Object.assign({
    type: type,
    value: value
  }, inputProps)) : React.createElement("textarea", Object.assign({}, inputProps, {
    className: "materialize-textarea ".concat(inputProps.className),
    value: value
  })), React.createElement("label", {
    htmlFor: inputId,
    className: isActive ? 'active' : '',
    "data-error": error,
    "data-warning": warning
  }, label));
};

function getInputOnChange(onValueChange) {
  var onChange = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};

  if (onValueChange) {
    return function (event) {
      return onValueChange(event.target.value);
    };
  }

  return onChange;
}