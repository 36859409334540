"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var Either = require("fp-ts/lib/Either");

var Option = require("fp-ts/lib/Option");

function logEitherLeft(either) {
  if (Either.isLeft(either)) {
    /* eslint-disable no-console,no-undef */
    console.log(either.left);
    /* eslint-enable no-console,no-undef */
  }

  return either;
}

exports.logEitherLeft = logEitherLeft;

function logEitherRight(either) {
  if (Either.isRight(either)) {
    /* eslint-disable no-console,no-undef */
    console.log(either.right);
    /* eslint-enable no-console,no-undef */
  }

  return either;
}

exports.logEitherRight = logEitherRight;

function unsafeFromSome(input) {
  warnTestsOnly();

  if (Option.isNone(input)) {
    throw new Error('Value should be some');
  }

  return input.value;
}

exports.unsafeFromSome = unsafeFromSome;

function unsafeFromLeft(input) {
  warnTestsOnly();

  if (Either.isRight(input)) {
    throw new Error('Value should be left');
  }

  return input.left;
}

exports.unsafeFromLeft = unsafeFromLeft;

function unsafeFromRight(input) {
  warnTestsOnly();

  if (Either.isLeft(input)) {
    throw new Error('Value should be right');
  }

  return input.right;
}

exports.unsafeFromRight = unsafeFromRight;

function warnTestsOnly() {
  /* eslint-disable no-undef,no-console */
  if (!process || !process.env || process.env.NODE_ENV !== 'test') {
    console.warn("Cette fonction est seulement pr\xE9vue pour les tests");
  }
  /* eslint-enable no-undef,no-console */

}