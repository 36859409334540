import * as React from 'react';
import { execution } from '@oyp/shared-components';
import InputGroup, { InputGroupProps } from './form/InputGroup';

const { debounce } = execution;

export type DebouncedInputProps = InputGroupProps & {
  value: string | undefined;
  placeholder?: string;
  label?: string;
  disableDebounce?: boolean;
  handleChange?: (value: string) => void;
};

class DebouncedInputGroup extends React.Component<DebouncedInputProps> {
  static defaultProps: DebouncedInputProps = {
    value: '',
    disableDebounce: false,
    handleChange: a => a,
  };

  state = {
    searchValue: '',
  };

  constructor(props: DebouncedInputProps) {
    super(props);

    if (!props.disableDebounce) {
      this.handleValueChange = debounce(this.handleValueChange, 500);
    }

    this.state = {
      searchValue: props.value || '',
    };
  }

  handleChangeEvent(event: Event) {
    const newValue = (event.target as HTMLInputElement).value;
    this.setState({ searchValue: newValue });
    this.handleValueChange(newValue);
  }

  handleDirectChange(newValue: string) {
    this.setState({ searchValue: newValue });
    // On contourne le debounce

    this.props.handleChange(newValue);
  }

  handleValueChange(newValue: string) {
    this.props.handleChange(newValue);
  }

  render() {
    const { placeholder = '' } = this.props;

    return (
      <React.Fragment>
        <InputGroup
          type="text"
          placeholder={placeholder}
          {...this.props}
          value={this.state.searchValue}
          onChange={event => this.handleChangeEvent(event)}
          displayClearInputButton={!!this.state.searchValue}
          onClearInput={() => this.handleDirectChange('')}
        />
      </React.Fragment>
    );
  }
}

export default DebouncedInputGroup;
