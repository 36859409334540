"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/* eslint-disable no-console */

function logError() {
  var _console;

  (_console = console).error.apply(_console, arguments);
}

exports.logError = logError;

function logWarn() {
  var _console2;

  (_console2 = console).warn.apply(_console2, arguments);
}

exports.logWarn = logWarn;
/* eslint-enable no-console */