import * as React from 'react';
import { CALCULATORS, ModelOrderItem, ORDER_ITEM_TYPES } from '@oyp/shared-lib';
import { OrderItemInformationRendererProps } from '../../types';
import FinishedProductOrderItemInformation from './components/item-information/calculator/FinishedProductOrderItemInformation';
import FlexibleOrderItemInformation from './components/item-information/calculator/FlexibleOrderItemInformation';
import RigidOrderItemInformation from './components/item-information/calculator/RigidOrderItemInformation';
import SmartLabelOrderItemInformation from './components/item-information/external_products/SmartLabelOrderItemInformation';

export function getItemInformationRenderer(
  orderItem: ModelOrderItem
): React.FC<OrderItemInformationRendererProps> {
  switch (orderItem.orderItemType) {
    case ORDER_ITEM_TYPES.CALCULATOR:
      return renderers[orderItem.requestData.calculator] || defaultItemInformationRenderer;
    case ORDER_ITEM_TYPES.SMART_LABEL:
      return SmartLabelOrderItemInformation;
    default:
      return () => <div>&nbsp;</div>;
  }
}

const defaultItemInformationRenderer = (props: OrderItemInformationRendererProps) => (
  <div>{props.orderItem.label}</div>
);

const renderers = {
  [CALCULATORS.FLEXIBLE]: FlexibleOrderItemInformation,
  [CALCULATORS.RIGID]: RigidOrderItemInformation,
  [CALCULATORS.FINISHED_PRODUCT]: FinishedProductOrderItemInformation,
};
