import { ModelOrderItem } from '@oyp/shared-lib';
import { fetchOne } from '@oyp/shared-components/dist/lib/rest-api/network';
import { orderItemApiEndpoint } from '../module';
import { restApiNetwork } from '@oyp/shared-components';

const { fetchCollection, saveOne } = restApiNetwork;

export async function fetchOrderItemsForOrder(orderId: string) {
  return (
    await fetchCollection<ModelOrderItem>(orderItemApiEndpoint, {
      orderId,
      count: 0,
      sort: 'rank',
    })
  ).data;
}

export async function fetchOrderItemById(orderItemId: string): Promise<ModelOrderItem> {
  return fetchOne(orderItemApiEndpoint, orderItemId);
}

export async function saveOneOrderItem(orderItem: ModelOrderItem): Promise<ModelOrderItem> {
  return saveOne(orderItemApiEndpoint, orderItem);
}
