import {
  AddressFields,
  CreateOrderAddressData,
  ModelResellerCustomerAddress,
  franceCountryCode,
} from '@oyp/shared-lib';
import { areAddressesEqual } from './utils';
import { createOrderAddressApiEndpoint } from './module';
import { fetchWithToken } from '@oyp/shared-components/dist/lib/rest-api/network';

export const initialAddressValues = {
  countryCode: franceCountryCode,
  name: '',
  companyName: '',
  street: '',
  complement: '',
  postalCode: '',
  city: '',
  email: '',
  phoneNumber: '',
  additionalInformation: '',
};

export function getCustomerAddressForAddressData(
  customerAddresses: ModelResellerCustomerAddress[],
  addressData: AddressFields
) {
  return customerAddresses.find(address => areAddressesEqual(address, addressData));
}

export async function saveShippingAddressFromSelector(data: CreateOrderAddressData) {
  return fetchWithToken(createOrderAddressApiEndpoint, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}
