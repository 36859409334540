"use strict";

var _defineProperty = require("/var/www/vhosts/preprod.oyp.fr/deploy_tools/apps/demo-calm-panda/releases/20240115155012/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

Object.defineProperty(exports, "__esModule", {
  value: true
});

var shared_lib_1 = require("@oyp/shared-lib"); // Enlever en-dessous


function getUpdatedRequestDataAndFormConfigRigid(requestData, permanentFormResources) {
  var formConfig = getRigidFormConfig(permanentFormResources, requestData);
  return {
    formConfig: formConfig,
    requestData: getUpdatedRequestDataFromRigidFormConfig(requestData, formConfig)
  };
}

exports.getUpdatedRequestDataAndFormConfigRigid = getUpdatedRequestDataAndFormConfigRigid;

function getUpdatedRequestDataFromRigidFormConfig(requestData, formConfig) {
  var updatedRequestData = _objectSpread({}, requestData);

  if (formConfig.disableLamination) {
    updatedRequestData.laminationId = undefined;
  }

  if (formConfig.disableEyelet) {
    updatedRequestData.eyelet = shared_lib_1.EYELET_TYPES.NONE;
  }

  if (formConfig.disableUseWhiteLayer) {
    updatedRequestData.whiteLayerType = shared_lib_1.WHITE_LAYER_TYPES.NONE;
  }

  if (formConfig.disablePerforation) {
    updatedRequestData.perforation = shared_lib_1.PERFORATION_TYPES.NONE;
  }

  if (formConfig.disableCutTypeShape) {
    updatedRequestData.cutType = shared_lib_1.CUT_TYPES.STRAIGHT;
  }

  if (formConfig.disableCreasing) {
    updatedRequestData.creasing = false;
  }

  return updatedRequestData;
} // Enlever au-dessus


function onRigidRequestDataChange(permanentResources, requestData) {
  var _getRigidFormConfig = getRigidFormConfig(permanentResources, requestData),
      disableLamination = _getRigidFormConfig.disableLamination,
      disableEyelet = _getRigidFormConfig.disableEyelet,
      disableUseWhiteLayer = _getRigidFormConfig.disableUseWhiteLayer,
      disablePerforation = _getRigidFormConfig.disablePerforation,
      disableCutTypeShape = _getRigidFormConfig.disableCutTypeShape,
      disableCreasing = _getRigidFormConfig.disableCreasing;

  return _objectSpread({}, requestData, {
    laminationId: disableLamination ? undefined : requestData.laminationId,
    eyelet: disableEyelet ? shared_lib_1.EYELET_TYPES.NONE : requestData.eyelet,
    whiteLayerType: disableUseWhiteLayer ? shared_lib_1.WHITE_LAYER_TYPES.NONE : requestData.whiteLayerType,
    perforation: disablePerforation ? shared_lib_1.PERFORATION_TYPES.NONE : requestData.perforation,
    cutType: disableCutTypeShape ? shared_lib_1.CUT_TYPES.STRAIGHT : requestData.cutType,
    creasing: disableCreasing ? false : requestData.creasing
  });
}

exports.onRigidRequestDataChange = onRigidRequestDataChange;

function getRigidFormConfig(permanentFormResources, requestData) {
  var material = permanentFormResources.materials.find(function (m) {
    return m.id === requestData.materialId;
  });
  var lamination = material.lamination,
      eyelet = material.eyelet,
      white = material.white,
      perforation = material.perforation,
      shapeCutOption = material.shapeCutOption,
      creasing = material.creasing;
  var printingType = requestData.printingType;
  var isTwoSidedPrintingType = printingType ? printingType.includes(shared_lib_1.PRINTING_TYPES.TWO_SIDED) : false;
  return {
    disableLamination: !lamination || isTwoSidedPrintingType,
    disableEyelet: !eyelet,
    disableUseWhiteLayer: !white || printingType === shared_lib_1.PRINTING_TYPES.NONE,
    disablePerforation: !perforation,
    disableCutTypeShape: !shapeCutOption,
    disableCreasing: !creasing || material.type !== 'carton' && isTwoSidedPrintingType
  };
}

exports.getRigidFormConfig = getRigidFormConfig;

function translateRigidWhiteLayerType(printingType, whiteLayerType) {
  if (printingType === shared_lib_1.PRINTING_TYPES.MIRROR) {
    if (whiteLayerType === shared_lib_1.WHITE_LAYER_TYPES.SELECTIVE) {
      return shared_lib_1.WHITE_LAYER_TYPE_TRANSLATIONS[shared_lib_1.WHITE_LAYER_TYPES.SELECTIVE_MIRROR];
    } else if (whiteLayerType === shared_lib_1.WHITE_LAYER_TYPES.TOTAL) {
      return shared_lib_1.WHITE_LAYER_TYPE_TRANSLATIONS[shared_lib_1.WHITE_LAYER_TYPES.TOTAL_MIRROR];
    }
  }

  return shared_lib_1.WHITE_LAYER_TYPE_TRANSLATIONS[whiteLayerType];
}

exports.translateRigidWhiteLayerType = translateRigidWhiteLayerType;