"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = require("react");

var styles = require("./NewChip.module.scss");

exports.NewChip = function (props) {
  var active = props.active,
      onClick = props.onClick,
      _props$type = props.type,
      type = _props$type === void 0 ? 'danger' : _props$type;
  var classes = [styles.chip, styles[type]];

  if (active) {
    classes.push(styles.active);
  }

  if (onClick) {
    classes.push(styles.clickable);
  }

  return React.createElement("span", {
    className: classes.join(' '),
    onClick: onClick || function (a) {
      return a;
    }
  });
};