"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
}); // Enums

var CUSTOMER_FILE_SYNC_STATUSES;

(function (CUSTOMER_FILE_SYNC_STATUSES) {
  CUSTOMER_FILE_SYNC_STATUSES["INIT"] = "CUSTOMER_FILE_SYNC_STATUS_INIT";
  CUSTOMER_FILE_SYNC_STATUSES["READY"] = "CUSTOMER_FILE_SYNC_STATUS_READY";
  CUSTOMER_FILE_SYNC_STATUSES["SYNCING"] = "CUSTOMER_FILE_SYNC_STATUS_SYNCING";
  CUSTOMER_FILE_SYNC_STATUSES["SYNCED"] = "CUSTOMER_FILE_SYNC_STATUS_SYNCED";
  CUSTOMER_FILE_SYNC_STATUSES["ERROR"] = "CUSTOMER_FILE_SYNC_STATUS_ERROR";
})(CUSTOMER_FILE_SYNC_STATUSES = exports.CUSTOMER_FILE_SYNC_STATUSES || (exports.CUSTOMER_FILE_SYNC_STATUSES = {}));

var FILE_STATUSES;

(function (FILE_STATUSES) {
  FILE_STATUSES["NO_FILE"] = "file.status.no_file";
  FILE_STATUSES["FILE_UPLOADED"] = "file.status.file_uploaded";
  FILE_STATUSES["FILE_VALIDATED"] = "file.status.file_validated";
  FILE_STATUSES["FILE_REFUSED"] = "file.status.file_refused";
})(FILE_STATUSES = exports.FILE_STATUSES || (exports.FILE_STATUSES = {}));

var THUMBNAIL_PRODUCTION_STATUSES;

(function (THUMBNAIL_PRODUCTION_STATUSES) {
  THUMBNAIL_PRODUCTION_STATUSES["NOT_STARTED"] = "THUMBNAIL_PRODUCTION_STATUS_NOT_STARTED";
  THUMBNAIL_PRODUCTION_STATUSES["PENDING"] = "THUMBNAIL_PRODUCTION_STATUS_PENDING";
  THUMBNAIL_PRODUCTION_STATUSES["DONE"] = "THUMBNAIL_PRODUCTION_STATUS_DONE";
  THUMBNAIL_PRODUCTION_STATUSES["ERROR"] = "THUMBNAIL_PRODUCTION_STATUS_ERROR";
})(THUMBNAIL_PRODUCTION_STATUSES = exports.THUMBNAIL_PRODUCTION_STATUSES || (exports.THUMBNAIL_PRODUCTION_STATUSES = {}));

var PASSED_FOR_PRINT_STATUSES;

(function (PASSED_FOR_PRINT_STATUSES) {
  PASSED_FOR_PRINT_STATUSES["TO_MAKE"] = "PASSED_FOR_PRINT_STATUS_TO_MAKE";
  PASSED_FOR_PRINT_STATUSES["SENT"] = "PASSED_FOR_PRINT_STATUS_SENT";
  PASSED_FOR_PRINT_STATUSES["ACCEPTED"] = "PASSED_FOR_PRINT_STATUS_ACCEPTED";
  PASSED_FOR_PRINT_STATUSES["REFUSED"] = "PASSED_FOR_PRINT_STATUS_REFUSED";
})(PASSED_FOR_PRINT_STATUSES = exports.PASSED_FOR_PRINT_STATUSES || (exports.PASSED_FOR_PRINT_STATUSES = {}));