import './FileUploadItemContainer.scss';
import {
  CUSTOMER_FILE_ACTIONS,
  CustomerFileDeleteFn,
  CustomerFileFetchFn,
  CustomerFileUploadFn,
  CustomerFileWithVersions,
  customerFileDelete,
  customerFileFetch,
  customerFileUpload,
} from '../../../files/actions';
import {
  CalculatorLamination,
  FILE_STATUSES,
  FILE_TYPE_CUSTOMER_FILE,
  ModelCustomerFile,
  ModelOrderItem,
  ModelOrderItemEvent,
  ORDER_ITEM_STATUSES,
} from '@oyp/shared-lib';
import { DARK_GREY } from '../../../../style_variables';
import { MDBCollapse, MDBCollapseHeader } from 'mdbreact';
import { ReduxState } from '../../../../reducers';
import { UploadingCustomerFile } from '../../../files/reducers';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchCollection } from '@oyp/shared-components/dist/lib/rest-api/network';
import { getFileDownloadUrl, orderItemEventApiEndpoint } from '../../module';
import { laminationEndpoint } from '../../../calculator/module';
import React from 'react';
import UploadFileItem from './UploadFileItem';
import UploadInfoColumn from './UploadInfoColumn';
import UploadZone from './UploadZone';
import websocket from '../../../../websocket';

interface OwnProps {
  item: ModelOrderItem;
  isOpen: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  isPlacingOrder?: boolean;
}

interface StateToProps {
  uploadedFiles: CustomerFileWithVersions[];
  filesInProgress: UploadingCustomerFile[];
  rejectedFiles: UploadingCustomerFile[];
}

interface DispatchToProps {
  customerFileFetch: CustomerFileFetchFn;
  customerFileDelete: CustomerFileDeleteFn;
  customerFileUpload: CustomerFileUploadFn;
  dismissRejectedFile: (file: File) => void;
}

type FileUploadItemProps = OwnProps & StateToProps & DispatchToProps;

interface FileUploadItemState {
  isOpen: boolean;
  events: ModelOrderItemEvent[];
  laminations: CalculatorLamination[];
}

class FileUploadItemContainer extends React.Component<FileUploadItemProps, FileUploadItemState> {
  state: FileUploadItemState = {
    isOpen: this.props.isOpen,
    events: [],
    laminations: [],
  };

  constructor(props: FileUploadItemProps) {
    super(props);

    Promise.all([
      loadData(props, this.setState.bind(this)),
      props.customerFileFetch(props.item.id),
    ]).then(() => websocket.subscribeToCustomerFileUpdates(this.onCustomerFileWsUpdate.bind(this)));

    this.handleFilesUpload = this.handleFilesUpload.bind(this);
    this.handleCustomerFileDelete = this.handleCustomerFileDelete.bind(this);
  }

  toggleCollapse() {
    this.setState({ ...this.state, isOpen: !this.state.isOpen });
  }

  render() {
    const {
      item,
      disabled,
      uploadedFiles,
      filesInProgress,
      rejectedFiles,
      isPlacingOrder,
    } = this.props;
    const { isWithoutPrint } = item;
    const { events, laminations } = this.state;

    const isRefused = !!uploadedFiles.find(file => file.status === FILE_STATUSES.FILE_REFUSED);
    const refusalEvents = events.filter(
      event => event.updatedData.status === ORDER_ITEM_STATUSES.FILES_REFUSED
    );
    const latestRefusalEvent = refusalEvents[refusalEvents.length - 1];

    return (
      <div id={`file-upload-item-${item.id}`} className="file-upload-item-container ml-3 mr-3">
        <MDBCollapseHeader onClick={() => this.toggleCollapse()}>
          PRODUIT : {item.manualReference || item.label}{' '}
          {isWithoutPrint && (
            <>
              -{' '}
              <em style={{ fontSize: '1rem', textTransform: 'initial', color: DARK_GREY }}>
                Pas de fichier requis - Produit sans impression
              </em>
            </>
          )}
          <i className={this.state.isOpen === true ? 'fa fa-angle-up' : 'fa fa-angle-down'} />
        </MDBCollapseHeader>
        <MDBCollapse isOpen={this.state.isOpen}>
          <div className="row rejected-file">
            {disabled ? (
              <div className="col-sm-12 col-md-8 col-lg-8 laptop-padding-right upload-zone-column">
                <div className="reseller-upload-zone-container">
                  {isWithoutPrint ? (
                    <div className="file-not-required-text">
                      <p>Pas de fichier requis - Produit sans impression</p>
                    </div>
                  ) : (
                    <>
                      <p>Fichiers envoyés (aucun à renvoyer) :</p>
                      <div className="files-container">
                        <div className="files-list" style={{ maxWidth: '100%' }}>
                          {uploadedFiles.map((file, key) => (
                            <UploadFileItem
                              onFileDownload={downloadedFile => {
                                window.open(
                                  getFileDownloadUrl(FILE_TYPE_CUSTOMER_FILE, downloadedFile),
                                  '_blank'
                                );
                              }}
                              key={key}
                              file={file}
                              isPlacingOrder={isPlacingOrder}
                            />
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <React.Fragment>
                {isRefused && latestRefusalEvent && (
                  <div className="col-sm-12">
                    <div className="refusal-reason">
                      <p> Raison du refus : {latestRefusalEvent.eventData.refusalReason}</p>
                    </div>
                  </div>
                )}
                <div className="col-sm-12 col-md-8 col-lg-8 laptop-padding-right upload-zone-column">
                  <>
                    <UploadZone
                      files={uploadedFiles}
                      filesInProgress={filesInProgress}
                      filesRejected={rejectedFiles.filter(file => file.orderItemId === item.id)}
                      onFileUpload={this.handleFilesUpload}
                      onFileDelete={this.handleCustomerFileDelete}
                      onFileDownload={file => {
                        window.open(getFileDownloadUrl(FILE_TYPE_CUSTOMER_FILE, file), '_blank');
                      }}
                      onRejectedFileDismiss={file => this.props.dismissRejectedFile(file)}
                      isPlacingOrder={isPlacingOrder}
                    />
                  </>
                </div>
              </React.Fragment>
            )}
            <div className="col-sm-12 col-md-4 col-lg-4 laptop-padding-left product-info-column">
              <UploadInfoColumn orderItem={item} laminations={laminations} />
            </div>
          </div>
        </MDBCollapse>
      </div>
    );
  }

  async handleFilesUpload(files: File[], metaData?: any) {
    const { item } = this.props;

    if (metaData && metaData.fileEntityId) {
      return this.props.customerFileUpload(files[0], item.id, metaData.fileEntityId);
    }

    return files.map(file => {
      this.props.customerFileUpload(file, item.id);
    });
  }

  async handleCustomerFileDelete(file: ModelCustomerFile) {
    this.props.customerFileDelete(file.id);
  }

  onCustomerFileWsUpdate({ orderItemId }: { customerFileId: string; orderItemId: string }) {
    if (this.props.item.id === orderItemId) {
      this.props.customerFileFetch(this.props.item.id);
    }
  }
}

function mapStateToProps(state: ReduxState, ownProps: OwnProps): StateToProps {
  return {
    filesInProgress: state.files.uploadingFiles.filter(
      file => file.orderItemId === ownProps.item.id
    ),
    uploadedFiles: state.files.uploadedFiles.filter(file => file.orderItemId === ownProps.item.id),
    rejectedFiles: state.files.rejectedFiles,
  };
}

function mapDispatchToProps(dispatch: any): any {
  return {
    dismissRejectedFile: (file: File) => {
      dispatch({ type: CUSTOMER_FILE_ACTIONS.DISMISS_REJECTED, file });
    },
    ...bindActionCreators(
      {
        customerFileFetch,
        customerFileDelete,
        customerFileUpload,
      },
      dispatch
    ),
  };
}

export default connect<StateToProps, DispatchToProps, OwnProps, ReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadItemContainer);

async function loadData(
  props: FileUploadItemProps,
  setState: (props: FileUploadItemState) => void
) {
  const [eventCollection, laminationCollection] = await Promise.all([
    fetchCollection<ModelOrderItemEvent>(orderItemEventApiEndpoint, {
      orderItemId: props.item.id,
    }),
    fetchCollection<CalculatorLamination>(laminationEndpoint),
  ]);

  return setState({
    isOpen: true,
    events: eventCollection.data,
    laminations: laminationCollection.data,
  });
}
