import {
  DANGER_COLOR,
  DANGER_COLOR_DARKER,
  PRIMARY_COLOR,
  PRIMARY_COLOR_DARKER,
  SECONDARY_COLOR,
  SECONDARY_COLOR_DARKER,
  SUCCESS_COLOR,
  SUCCESS_COLOR_DARKER,
  TEXT_COLOR,
  WARNING_COLOR,
  WARNING_COLOR_DARKER,
} from '../../style_variables';
import { Style } from 'jss/css';
import React from 'react';
import styled, { css } from 'styled-components';

type Look = 'primary' | 'secondary' | 'danger' | 'warning' | 'white' | 'success';

export interface ButtonProps {
  look?: Look;
  variation?: 'normal' | 'flat' | 'outline';
  size?: 'normal' | 'sm' | 'xs';
  as?: string | React.ComponentType;
  className?: string;
  disabled?: boolean;
  href?: string;
  onClick?: (event: Event) => void;
  title?: string;
  type?: string;
  style?: Style;
  to?: string;
  [key: string]: any;
}

const Button: React.FC<ButtonProps> = styled.button<ButtonProps>`
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  line-height: 1.5;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  font-size: 0.81rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0.375rem;
  border: 0;
  border-radius: 0.125rem;
  cursor: pointer;
  text-transform: uppercase;
  white-space: normal;
  word-wrap: break-word;
  color: ${TEXT_COLOR};
  text-decoration: none;

  &:disabled,
  &.disabled {
    opacity: 0.65;
  }

  &:visited {
    color: ${TEXT_COLOR};
  }

  ${props =>
    props.variation !== 'flat' &&
    !props.disabled &&
    css`
      &:hover,
      &:active,
      &:focus {
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        outline: 0;
      }
    `}

  ${getVariationStyles}
  ${getSizeStyles}
`;

export default Button;

function getFullColorLookStyles(props: ButtonProps, color: string, hoverColor: string): string {
  const { disabled } = props;

  // Les "!important" sont nécessaires pour overrider mdbreact :/
  return ` 
background-color: ${color};
color: #fff !important;

${
  !disabled
    ? `
&:hover {
  background-color: ${hoverColor};
}`
    : ``
}
&:visited, &:hover {
  color: #fff !important;
}
  `;
}

function getLookStyles(props: ButtonProps): string {
  const { look } = props;

  switch (look) {
    case 'primary':
      return getFullColorLookStyles(props, PRIMARY_COLOR, PRIMARY_COLOR_DARKER);
    case 'secondary':
      return getFullColorLookStyles(props, SECONDARY_COLOR, SECONDARY_COLOR_DARKER);
    case 'white':
      return `
  background-color: #fff;
  color: ${PRIMARY_COLOR_DARKER};
`;
    case 'danger':
      return getFullColorLookStyles(props, DANGER_COLOR, DANGER_COLOR_DARKER);
    case 'warning':
      return getFullColorLookStyles(props, WARNING_COLOR, WARNING_COLOR_DARKER);
    case 'success':
      return getFullColorLookStyles(props, SUCCESS_COLOR, SUCCESS_COLOR_DARKER);
    default:
      return ``;
  }
}

function getVariationStyles(props: ButtonProps): string {
  const { variation = 'normal' } = props;

  switch (variation) {
    case 'flat':
      return `
    background-color: transparent;
    box-shadow: 0px 0px 0px;
    border: 0px;
    font-size: 14px;
    font-weight: 500;
  
    &:hover {
      background-color: rgba(100, 100, 100, 0.1);
    }
    &:active, &:focus {
      background-color: rgba(100, 100, 100, 0.2);
    }
  
      ${getFlatLookStyles(props)}
  `;
    case 'outline':
      return `
    background-color: transparent;
    padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  border: 2px solid ${TEXT_COLOR};

  &:hover {
    background-color: transparent;
  }

    ${getOutlineLookStyles(props)}
`;
    default:
      return getLookStyles(props);
  }
}

function getFlatLookStyleForColors(color: string, hoverColor: string): string {
  return `
  color: ${color};
  
  &:hover {
    color: ${hoverColor};
  }
`;
}

function getFlatLookStyles(props: ButtonProps): string {
  const { look } = props;

  switch (look) {
    case 'primary':
      return getFlatLookStyleForColors(PRIMARY_COLOR, PRIMARY_COLOR_DARKER);
    case 'secondary':
      return getFlatLookStyleForColors(SECONDARY_COLOR, SECONDARY_COLOR_DARKER);
    case 'white':
      return getFlatLookStyleForColors('#fff', '#fff');
    case 'danger':
      return getFlatLookStyleForColors(DANGER_COLOR, DANGER_COLOR_DARKER);
    case 'warning':
      return getFlatLookStyleForColors(WARNING_COLOR, WARNING_COLOR_DARKER);
    case 'success':
      return getFlatLookStyleForColors(SUCCESS_COLOR, SUCCESS_COLOR_DARKER);
    default:
      return '';
  }
}

function getOutlineLookStyles(props: ButtonProps): string {
  const { look } = props;

  switch (look) {
    case 'primary':
      return `
      color: ${PRIMARY_COLOR};
      border-color: ${PRIMARY_COLOR};
  
      &:hover {
        color: ${PRIMARY_COLOR_DARKER};
        border-color: ${PRIMARY_COLOR_DARKER};
      }      
`;
    case 'secondary':
      return `
      color: ${SECONDARY_COLOR};
      border-color: ${SECONDARY_COLOR};
  
      &:hover {
        color: ${SECONDARY_COLOR_DARKER};
        border-color: ${SECONDARY_COLOR_DARKER};
      }      
`;
    case 'danger':
      return `
      color: ${DANGER_COLOR};
      border-color: ${DANGER_COLOR};
  
      &:hover {
        color: ${DANGER_COLOR_DARKER};
        border-color: ${DANGER_COLOR_DARKER};
      }      
`;
    case 'warning':
      return `
      color: ${WARNING_COLOR};
      border-color: ${WARNING_COLOR};
  
      &:hover {
        color: ${WARNING_COLOR_DARKER};
        border-color: ${WARNING_COLOR_DARKER};
      }      
`;
    case 'success':
      return `
      color: ${SUCCESS_COLOR};
      border-color: ${SUCCESS_COLOR};
  
      &:hover {
        color: ${SUCCESS_COLOR_DARKER};
        border-color: ${SUCCESS_COLOR_DARKER};
      }      
`;
    default:
      return '';
  }
}

function getSizeStyles(props: ButtonProps): string {
  const { size = 'medium' } = props;

  switch (size) {
    case 'sm':
      return `
      padding: 10px 14px;
  `;
    case 'xs':
      return `
      padding: 4px 10px;
      font-size: 0.64rem;
`;
    default:
      return `
    padding: 0.84rem 2.14rem;
`;
  }
}
