import {
  CALCULATORS,
  CalculatorFinishedProductFormPermanentResources,
  CalculatorRequestData,
  InprintCalculator,
  ModelOrderItem,
  ORDER_ITEM_TYPES,
  OrderItemClientSource,
} from '@oyp/shared-lib';
import { CartProps } from '../../../types';
import { fetchFinishedProductCalculationResult } from '../actions';
import {
  fetchFinishedProductPermanentResources,
  getFinishedProductInitialRequestData,
} from '../calculator_helper';
import Calculator from '../../../components/Calculator';
import CalculatorForm from './CalculatorForm';
import React from 'react';

const FinishedProductCalculator: React.FC<{
  isHidden: boolean;
  isLoading: boolean;
  editedItem?: ModelOrderItem;
  cartProps: CartProps;
  onSaveOrderItem: (orderItem: OrderItemClientSource) => void;
}> = props => {
  const { editedItem } = props;
  const editedItemRequestData =
    editedItem &&
    editedItem.orderItemType === ORDER_ITEM_TYPES.CALCULATOR &&
    editedItem.requestData.calculator === CALCULATORS.FINISHED_PRODUCT
      ? editedItem.requestData
      : null;

  return (
    !props.isHidden && (
      <Calculator<
        CalculatorFinishedProductFormPermanentResources,
        CalculatorRequestData,
        InprintCalculator.SlimResult
      >
        isHidden={props.isHidden}
        isParentLoading={props.isLoading}
        editedItemRequestData={editedItemRequestData}
        onSaveOrderItem={props.onSaveOrderItem}
        cartProps={props.cartProps}
        FormType={CalculatorForm}
        getInitialRequestData={(
          permanentResources: CalculatorFinishedProductFormPermanentResources
        ) => ({
          ...getFinishedProductInitialRequestData(permanentResources),
          productionMode: props.cartProps.productionMode,
        })}
        onRequestDataChange={(_, requestData) => requestData}
        fetchPermanentResources={fetchFinishedProductPermanentResources}
        fetchCalculateResult={fetchFinishedProductCalculationResult}
      />
    )
  );
};

export default FinishedProductCalculator;
