import Button from '../buttons/Button';
import React, { useEffect, useState } from 'react';
import styles from './ConfirmationRow.module.scss';

interface InvokerRenderProps {
  onToggle: (confirmElement: string | JSX.Element, onConfirm: () => void) => void;
}

interface ConfirmationRowProps {
  diffHolder: any;
  render: (props: InvokerRenderProps) => JSX.Element;
  className?: string;
}

interface ConfirmationRowState {
  confirmElement: string | JSX.Element;
  onConfirm: () => void;
  isConfirmationVisible: boolean;
}

const initialState = {
  isConfirmationVisible: false,
  confirmElement: '',
  onConfirm: () => {},
};

const ConfirmationRow: React.FC<ConfirmationRowProps> = props => {
  const { render, diffHolder, className } = props;

  const [state, setState] = useState<ConfirmationRowState>(initialState);

  useEffect(() => {
    setState(initialState);
  }, [diffHolder]);

  const { isConfirmationVisible } = state;

  return (
    <tr className={className}>
      {isConfirmationVisible ? (
        <td colSpan={99} className={styles['delete-confirmation']}>
          {state.confirmElement}{' '}
          <Button look="primary" variation="flat" size="xs" onClick={() => setState(initialState)}>
            Annuler
          </Button>{' '}
          <Button look="primary" size="xs" onClick={state.onConfirm}>
            Confirmer
          </Button>
        </td>
      ) : (
        render({
          onToggle: (confirmText, onConfirm) =>
            setState({
              ...state,
              isConfirmationVisible: !isConfirmationVisible,
              confirmElement: confirmText,
              onConfirm,
            }),
        })
      )}
    </tr>
  );
};

export default ConfirmationRow;
