import { Icon } from 'react-materialize';
import { Link } from 'react-router-dom';
import { ModelOrder } from '@oyp/shared-lib';
import { OrderWithProprietaryEntities } from '../../calculator/types';
import { formatDateStringToShortText } from '@oyp/shared-components/dist/lib/utils/formatter';
import { getUserDisplayName } from '@oyp/shared-components/dist/lib/utils/names';
import Button from '../../../components/buttons/Button';
import ConfirmationRow from '../../../components/list/ConfirmationRow';
import InlineIconButton from '../../../components/buttons/InlineIconButton';
import React from 'react';

interface QuoteListRowProps {
  quote: OrderWithProprietaryEntities;
  onDelete: (quote: ModelOrder) => void;
}

const QuoteListRow: React.FC<QuoteListRowProps> = props => {
  const { quote, onDelete } = props;

  return (
    <ConfirmationRow
      diffHolder={quote.id}
      render={({ onToggle }) => (
        <>
          <td className="actions-cell">
            <InlineIconButton as={Link as any} to={`orders/${quote.id}/details`}>
              <Icon>search</Icon>
            </InlineIconButton>
            <InlineIconButton
              iconName="delete"
              onClick={() =>
                onToggle('Êtes-vous sûr(e) de vouloir supprimer ce devis ?', () => onDelete(quote))
              }
            />
          </td>
          <td>{quote.quoteReference}</td>
          <td>{quote.manualQuoteReference}</td>
          <td>
            {quote.shippingAddress
              ? `${quote.shippingAddress.companyName} (${quote.shippingAddress.city})`
              : 'A définir'}
          </td>
          <td>{formatDateStringToShortText(quote.createdAt)}</td>
          <td>{getUserDisplayName(quote.creator)}</td>
          <td>{quote.taxIncludedTotal.toFixed(2)} €</td>

          <td align="right">
            <Button as={Link as any} to={`/quote/${quote.id}`} look="secondary" size="xs">
              Reprendre le devis
            </Button>
          </td>
        </>
      )}
    />
  );
};

export default QuoteListRow;
