import * as React from 'react';
import { CustomerFileWithVersions } from '../../../files/actions';
import { FILE_STATUSES } from '@oyp/shared-lib';
import { FileInput } from '@oyp/shared-components';
import { UPLOAD_STATUSES } from './constants';
import Button from '../../../../components/buttons/Button';
import FileLine from './FileLine';
import InlineConfirmationButton from '../../../../components/InlineConfirmationButton';
import styles from './UploadFileItem.module.scss';

interface UploadFileItemProps {
  file: CustomerFileWithVersions;
  onFileDelete?: (file: any) => void;
  onFileDownload?: (file: any) => void;
  onFileUpload?: (files: any[], metaData: any) => void;
  readOnly?: boolean;
  isPlacingOrder?: boolean;
}

const UploadFileItem: React.SFC<UploadFileItemProps> = props => {
  const {
    file,
    onFileDelete = () => {},
    onFileDownload = () => {},
    onFileUpload = () => {},
    readOnly,
    isPlacingOrder,
  } = props;

  const isRefused = file.status === FILE_STATUSES.FILE_REFUSED;

  return (
    <React.Fragment>
      <FileLine
        file={file}
        uploadStatus={UPLOAD_STATUSES.SUCCESS}
        buttons={
          <React.Fragment>
            {(readOnly || isRefused) && (
              <Button look="primary" size="xs" onClick={() => onFileDownload(file)}>
                Télécharger
              </Button>
            )}
            {isRefused && (
              <Button
                as={FileInput as any}
                look="primary"
                size="xs"
                className={styles['btn-reupload']}
                onFileUpload={(files: any[]) => onFileUpload(files, { fileEntityId: file.id })}
              >
                Renvoyer
              </Button>
            )}
            {isPlacingOrder && (
              <InlineConfirmationButton
                iconName="delete"
                confirmText="supprimer"
                onConfirmation={() => onFileDelete(file)}
              />
            )}
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

export default UploadFileItem;
