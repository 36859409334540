import * as React from 'react';
import { USER_TITLES } from '@oyp/shared-lib';
import { resellerUserValidationConstraints } from '../validation';
import { validator } from '@oyp/shared-components';
import Button from '../../../components/buttons/Button';
import CardActionFooter from '../../../components/CardActionFooter';
import InputGroup from '../../../components/form/InputGroup';
import SelectGroup from '../../../components/form/SelectGroup';

const { validateForm } = validator;

interface ResellerUserFormProps {
  resellerUser: any;
  saveResellerUser: (reseller: any) => void;
}

class ResellerUserForm extends React.Component<ResellerUserFormProps> {
  state: { resellerUser: any };

  constructor(props: ResellerUserFormProps) {
    super(props);

    const { resellerUser } = this.props;
    this.state = {
      resellerUser,
    };
    this.onSelect = this.onSelect.bind(this);
  }

  handleChange(fieldName: any, event: Event) {
    const { value: fieldValue } = event.target as HTMLInputElement;
    if (this.state.resellerUser.hasOwnProperty(fieldName)) {
      this.setState({
        ...this.state,
        resellerUser: {
          ...this.state.resellerUser,
          [fieldName]: fieldValue,
        },
      });
    }
  }

  onSelect(event: { value: string }) {
    this.setState({
      ...this.state,
      resellerUser: {
        ...this.state.resellerUser,
        title: event.value,
      },
    });
  }

  componentDidUpdate(prevProps: ResellerUserFormProps) {
    if (this.props !== prevProps) {
      const { resellerUser } = this.props;
      this.setState({
        ...this.state,
        resellerUser,
      });
    }
  }

  render() {
    const { saveResellerUser } = this.props;
    const { resellerUser } = this.state;

    const validationErrors: any = validateForm(resellerUser, resellerUserValidationConstraints);

    const isValid = Object.keys(validationErrors).length === 0;

    const titleOptions = [
      { value: USER_TITLES.MR, label: 'Monsieur' },
      { value: USER_TITLES.MRS, label: 'Madame' },
    ];

    return (
      <form>
        <div className="col-md-12">
          <div className="form-row mb-100 mt-15">
            <SelectGroup
              placeholder="Civilité"
              name="title"
              s={3}
              value={resellerUser.title}
              onChange={this.onSelect}
              options={titleOptions}
              clearable={false}
              searchable={false}
              label="Civilité"
            />

            <InputGroup
              name="firstName"
              label="Prénom *"
              s={3}
              error={validationErrors.firstName}
              value={resellerUser.firstName}
              onChange={this.handleChange.bind(this, 'firstName')}
            />
            <InputGroup
              name="lastName"
              label="Nom *"
              s={3}
              error={validationErrors.lastName}
              value={resellerUser.lastName}
              onChange={this.handleChange.bind(this, 'lastName')}
            />
            <InputGroup
              name="email"
              label="Email *"
              s={3}
              type="email"
              error={validationErrors.email}
              value={resellerUser.email}
              onChange={this.handleChange.bind(this, 'email')}
            />
          </div>
        </div>
        <CardActionFooter>
          <Button
            look="secondary"
            disabled={!isValid}
            onClick={() => saveResellerUser(resellerUser)}
          >
            Enregistrer
          </Button>
        </CardActionFooter>
      </form>
    );
  }
}

export default ResellerUserForm;
