import { validationConstraints } from '@oyp/shared-components';

const { isEmail, maxLength, minLength, required } = validationConstraints;

const minLength2 = minLength(2);
const minLength6 = minLength(6);
const maxLength35 = maxLength(35);

const maxLength80 = maxLength(80);

export const resellerUserValidationConstraints = {
  lastName: [required, minLength2, maxLength35],
  firstName: [required, minLength2, maxLength35],
  email: [required, isEmail, maxLength80],
};

export const resellerUserPasswordValidationConstraints = {
  password: [required, minLength6],
};
