import { centralStationApiEndpoint } from '../../env-config';

export const apiEndpoint = centralStationApiEndpoint;

export const resellerApiEndpoint = `${apiEndpoint}/resellers`;
export const resellerUserApiEndpoint = `${apiEndpoint}/reseller-users`;
export const configurationApiEndpoint = `${apiEndpoint}/reseller-configurations`;

export const breadcrumbLinks = [
  {
    name: 'Revendeurs',
    path: '/resellers',
  },
];
