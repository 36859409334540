"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = require("react");

var Radio_1 = require("./Radio");

exports.RadioGroup = function (props) {
  var _props$radios = props.radios,
      radios = _props$radios === void 0 ? [] : _props$radios,
      name = props.name,
      onChange = props.onChange,
      disabled = props.disabled,
      selectedValue = props.selectedValue,
      _props$className = props.className,
      className = _props$className === void 0 ? '' : _props$className;
  return React.createElement("div", {
    className: "radio-group ".concat(className)
  }, radios.map(function (radio, index) {
    var value = radio.value,
        label = radio.label,
        Label = radio.Label;
    return React.createElement(Radio_1.Radio, {
      key: index,
      value: value,
      id: "".concat(name, "-").concat(value),
      label: Label ? React.createElement(Label, null) : label || "".concat(value),
      onChange: onChange,
      disabled: disabled || radio.disabled,
      checked: value === selectedValue
    });
  }));
};