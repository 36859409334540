import { CalculatorMaterial } from '@oyp/shared-lib';

export const sortByReference = (a: { reference?: string }, b: { reference?: string }) => {
  try {
    const intRefA = parseInt(a.reference, 10);
    const intRefB = parseInt(b.reference, 10);

    return intRefA > intRefB;
  } catch (e) {
    return false;
  }
};

export const makeGetOwnFilterType = (filterTypes: string[], defaultFilterType: string) => (
  givenFilterType: string
) => {
  return filterTypes.indexOf(givenFilterType) < 0 ? defaultFilterType : givenFilterType;
};

export function getDefaultMaterialForType(materials: CalculatorMaterial[], filterType: string) {
  return materials.find(material => material.type === filterType);
}
