import { Card, logError } from '@oyp/shared-components';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  StripeProvider,
  injectStripe,
} from 'react-stripe-elements';
import { DANGER_COLOR_DARKER } from '../../../../style_variables';
import { MDBAlert, toast } from 'mdbreact';
import { OrderWithProprietaryEntities } from '../../../calculator/types';
import { QUOTE_ONLY_STATUSES } from '@oyp/shared-lib';
import { RouteComponentProps } from 'react-router';
import { STRIPE_PUBLIC_KEY } from '../../../../env-config';
import { fetchOrderWithProprietaryEntities } from '../../actions/order';
import { formatPrice } from '@oyp/shared-components/dist/lib/utils/formatter';
import { initiateOrderWithPayment, validateOrderWithPaymentIntent } from './network';
import { orderHasRefusedPayment } from '../../utils';
import BreadCrumb, { ORDER_BREADCRUMB_STEPS } from '../../../../components/BreadCrumb';
import Button from '../../../../components/buttons/Button';
import InputGroup from '../../../../components/form/InputGroup';
import Loader from '../../../../components/Loader';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

type PageProps = RouteComponentProps<{ id: string }>;

interface PageState {
  isLoading: boolean;
  order?: OrderWithProprietaryEntities;
}

const Page: React.FC<PageProps> = props => {
  const [state, setState] = useState<PageState>({
    isLoading: true,
  });

  const { order, isLoading } = state;

  useEffect(() => {
    if (!order) {
      fetchOrderWithProprietaryEntities(props.match.params.id).then(fetchedOrder => {
        if (
          !QUOTE_ONLY_STATUSES.includes(fetchedOrder.status) &&
          !orderHasRefusedPayment(fetchedOrder)
        ) {
          return props.history.push(`/orders/${fetchedOrder.id}/details`);
        }

        setState({
          order: fetchedOrder,
          isLoading: false,
        });
      });
    }
  }, [order]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="quote-container breadcrumb-padded">
      <BreadCrumb selectedStepId={ORDER_BREADCRUMB_STEPS.PAYMENT} order={order} />

      <div className="row">
        <div className="col-sm-6 offset-sm-3">
          <Card title="Paiement par carte bancaire">
            <StyledTotalText>
              Référence devis : {order.quoteReference}
              <br />
              Total à payer : <b>{formatPrice(order.taxIncludedTotal)}</b>
            </StyledTotalText>
            <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
              <Elements>
                <InjectedForm
                  orderId={order.id}
                  creatorName={`${order.creator.firstName} ${order.creator.lastName}`}
                  creatorEmail={order.creator.email}
                  resellerCompanyName={order.reseller.companyName}
                  quoteReference={order.quoteReference}
                  amount={order.taxIncludedTotal}
                  {...props}
                />
              </Elements>
            </StripeProvider>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Page;

const StyledTotalText = styled.p`
  text-align: center;
  margin: 2rem 0;
  font-size: 1.2rem;
`;

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '18px',
        fontSmoothing: 'antialiased',
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: '"Roboto", sans-serif',
        '::placeholder': {
          color: '#aab7c4',
        },
        borderBottom: '1px solid silver',
      },
      invalid: {
        color: DANGER_COLOR_DARKER,
      },
    },
  };
};

interface FormProps extends RouteComponentProps {
  orderId: string;
  // stripe?: ReactStripeElements.StripeProps; pas à jour :/
  stripe?: any;
  creatorName?: string;
  creatorEmail?: string;
  resellerCompanyName?: string;
  quoteReference?: string;
  amount?: number;
}

const Form: React.FC<FormProps> = props => {
  const {
    stripe,
    history,
    orderId,
    creatorName,
    creatorEmail,
    resellerCompanyName,
    quoteReference,
    amount,
  } = props;

  const [errorMessage, setErrorMessage] = useState<string>();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isPayed, setIsPayed] = useState<boolean>(false);
  const [paymentIntentData, setPaymentIntentData] = useState<{
    clientSecret: string;
    paymentIntentId: string;
  }>();
  const [cardHolderName, setCardHolderName] = useState<string>('');

  useEffect(() => {
    if (!paymentIntentData) {
      initiateOrderWithPayment(
        orderId,
        resellerCompanyName,
        quoteReference,
        amount,
        creatorEmail,
        creatorName
      ).then(setPaymentIntentData);
    }
  }, [paymentIntentData]);

  if (!paymentIntentData) {
    return <Loader />;
  }

  const { clientSecret, paymentIntentId } = paymentIntentData;

  return (
    <form
      method="POST"
      onSubmit={ev => {
        ev.preventDefault();
        setIsSubmit(true);
        setErrorMessage(null);

        if (stripe) {
          stripe
            .handleCardPayment(clientSecret, {
              /* eslint-disable @typescript-eslint/camelcase*/
              payment_method_data: {
                billing_details: {
                  name: cardHolderName,
                },
              },
              /* eslint-enable @typescript-eslint/camelcase */
            })
            .then((payload: any) => {
              if (payload.error) {
                setErrorMessage(payload.error.message);
                setIsSubmit(false);
              } else {
                if (payload.paymentIntent.status === 'succeeded') {
                  setIsSubmit(false);
                  setIsPayed(true);
                  validateOrderWithPaymentIntent(orderId, paymentIntentId)
                    .then(() => {
                      toast.success('Commande enregistrée');
                      history.push(`/order/${orderId}/recap?card-payment=true`);
                    })
                    .catch(error => {
                      setIsSubmit(false);
                      logError(error);
                      toast.error('Une erreur est survenue');
                    });
                }

                /*validateOrderWithPaymentIntent(orderId, paymentIntentId)
                  .then(() => {
                    setIsSubmit(false);
                    toast.success('Commande enregistrée');
                    history.push(`/order/${orderId}/recap?card-payment=true`);
                  })
                  .catch(error => {
                    setIsSubmit(false);
                    logError(error);
                    toast.error('Une erreur est survenue');
                  });*/
              }
            });
        }
      }}
    >
      <div className="row" style={{ minHeight: '350px' }}>
        <div
          className="col-sm-6 offset-sm-3"
          style={{
            padding: '1rem 0',
          }}
        >
          <div className="row">
            <div className="col-sm-12">
              <InputGroup
                value={cardHolderName}
                label="TITULAIRE DE LA CARTE"
                onChange={event => setCardHolderName(event.target.value)}
              />
            </div>

            <div className="col-sm-12">
              <label style={{ width: '100%', fontWeight: 'bold', fontSize: 12 }}>
                NUMERO DE CARTE BANCAIRE
              </label>
              <div style={{ borderBottom: '1px solid #00838f', padding: 2 }}>
                <CardNumberElement {...createOptions()} />
              </div>
            </div>

            <div className="col-sm-6" style={{ marginTop: '30px' }}>
              <label style={{ width: '100%', fontWeight: 'bold', fontSize: 12 }}>
                {`DATE D'EXPIRATION`}
              </label>
              <div style={{ borderBottom: '1px solid #00838f', padding: 2 }}>
                <CardExpiryElement {...createOptions()} />
              </div>
            </div>

            <div className="col-sm-6" style={{ marginTop: '30px' }}>
              <label style={{ width: '100%', fontWeight: 'bold', fontSize: 12 }}>CODE CVC</label>
              <div style={{ borderBottom: '1px solid #00838f', padding: 2 }}>
                <CardCvcElement {...createOptions()} />
              </div>
            </div>

            {errorMessage && (
              <div className="col-sm-12" style={{ paddingTop: '1rem', marginTop: '20px' }}>
                <MDBAlert color="danger" className="text-center">
                  {errorMessage}
                </MDBAlert>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(218, 218, 218, 0.5)',
          padding: 12,
        }}
      >
        {isSubmit ? (
          <Loader size="small" />
        ) : !isPayed ? (
          <Button
            type="submit"
            look="primary"
            style={{ width: '50%', fontWeight: 'bold', fontSize: 14 }}
            disabled={isSubmit || cardHolderName.length === 0}
            data-effect={clientSecret}
          >
            Payer {formatPrice(amount)}
          </Button>
        ) : (
          `Votre paiement a été accepté. Vous allez être redirigé vers le récapitulatif de votre commande ...`
        )}
      </div>
    </form>
  );
};

const InjectedForm = injectStripe(Form);
