import { Route } from 'react-router-dom';
import React from 'react';
import ValidatePassedForPrintPage from './pages/ValidatePassedForPrintPage';

const Routes = () => (
  <>
    <Route exact path="/valider-bat/:id" component={ValidatePassedForPrintPage} />
  </>
);

export default Routes;
